import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Typography } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'

import { useUserContext } from '@Context/UserContext'
import TopBar from '@Components/TopBar'
import DashboardFooter from '@Components/DashboardFooter'
import useWindowDimensions from '@Hooks/useWindowDimensions'

import './index.less'

const { Title } = Typography
const { Content, Footer } = Layout
const FOOTER_HEIGHT = 150

export const Template = ({ title, classNames, children, showTitle = true }) => {
  const userContext = useUserContext()

  const [topPadding, setTopPadding] = useState(
    userContext.expirationBarPadding ? 50 : 0
  )
  const [topIndent, setTopIndent] = useState(76 + topPadding)
  const { height } = useWindowDimensions()

  useEffect(() => {
    if (userContext.expirationBarPadding) {
      setTopPadding(56)
    } else {
      setTopPadding(0)
    }
  }, [userContext.expirationBarPadding])

  useEffect(() => {
    setTopIndent(76 + topPadding)
  }, [topPadding])

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout className={`page-wrapper full-width ${classNames}`}>
        <TopBar />
        <Scrollbars style={{ height: height - topIndent }} autoHide>
          <Content style={{ minHeight: height - topIndent - FOOTER_HEIGHT }}>
            <div className='site-layout-content'>
              {showTitle && <Title>{title}</Title>}
              {children}
            </div>
          </Content>
          <Footer>
            <DashboardFooter />
          </Footer>
        </Scrollbars>
      </Layout>
    </Layout>
  )
}
