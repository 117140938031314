import { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { useUserContext } from '@Context/UserContext'
import usePrevious from '@Hooks/usePrevious'
import PubSub from 'pubsub-js'

import Session from '@Lib/session'
import { getFormattedSite } from '@Lib/helpers'
import { SITE_STATUS } from '@Constants'
import { message } from 'antd'

import { SITE_EVENTS } from '@Packages/constants/WebsocketEvents'

const useCurrentSite = () => {
  const { t } = useTranslation(['common'])
  const { siteId } = useParams()
  const timer = useRef(null)
  const userContext = useUserContext()
  const previousAccountId = usePrevious(userContext?.accountId)
  const history = useHistory()
  const path = `sites/${siteId}`
  const [
    { data: getData, loading: getIsLoading, error: getError },
    refetchCurrentSite,
    cancelCurrentSite
  ] = useStratticApi(path)

  useEffect(() => {
    if (getError?.statusCode === 401) {
      history.push('/')
    }
  }, [getError, history])
  useErrorMessage(getError)

  const [currentSite, setCurrentSite] = useState({})
  const currentSiteStatus = currentSite?.value?.status
  const previousSiteStatus = usePrevious(currentSite?.value?.status)

  useEffect(() => {
    if (
      previousSiteStatus !== currentSiteStatus &&
      previousSiteStatus === SITE_STATUS.RESTORING
    ) {
      message.success(t('restore_complete'))
    }
  }, [currentSiteStatus, previousSiteStatus, t])

  useEffect(() => {
    if (previousAccountId && userContext?.accountId !== previousAccountId) {
      history.push('/')
    }
    // eslint-disable-next-line
  }, [userContext?.accountId])

  const getSite = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      refetchCurrentSite()
    }, 30 * 1000)
  }
  useEffect(() => {
    getSite()
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [getData])

  useEffect(() => {
    const sessionSiteId = Session.get('currentSite', 'session')?.siteId
    if (parseInt(siteId, 10) === parseInt(sessionSiteId, 10)) {
      setCurrentSite({
        isLoading: false,
        value: Session.get('currentSite', 'session'),
        error: null
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!getData?.result && !getError) return
    const formattedCurrentSite = getFormattedSite(getData?.result)
    setCurrentSite({
      isLoading: getIsLoading,
      value: {
        ...formattedCurrentSite,
        httpBasicPassword: getData?.result?.httpBasicPassword,
        sftpPassword: getData?.result?.sftpPassword,
        sqlPassword: getData?.result?.sqlPassword,
        allowedPermissions: getData?.result?.allowedPermissions,
        status: getData?.result?.status
      },
      error: getError
    })
    Session.set(
      'currentSite',
      {
        ...formattedCurrentSite,
        allowedPermissions: getData?.result?.allowedPermissions,
        status: getData?.result?.status
      },
      'session'
    )

    // eslint-disable-next-line
  }, [getData?.result])

  const siteStatus = currentSite?.value?.status
  useEffect(() => {
    PubSub.publish(SITE_EVENTS.SITE_STATUS_CHANGED, {
      aggregateId: getData?.result?.id,
      data: { newStatus: getData?.result?.status }
    })
  }, [siteStatus, getData])

  return [currentSite, refetchCurrentSite, cancelCurrentSite]
}

export default useCurrentSite
