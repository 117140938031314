import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Modal, Button, Form, Typography, Input, Tooltip } from 'antd'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { useMessageContext } from '@Context/MessageContext'
import useExpiredSubscriptionTooltip from '@Hooks/useExpiredSubscriptionTooltip'
import { useUserContext } from '@Context/UserContext'

import AnalyticsManager from '@Managers/analyticsManager'
import { MAX_BACKUPS } from '@Constants/permissionsConstants'
import { SUBSCRIPTION_STATUS, FORM_STATUS, SITE_STATUS } from '@Constants'

import './index.less'

const { Text, Title, Link } = Typography

const ContactSupport = () => {
  const { t } = useTranslation(['common'])
  return (
    <Title level={4} className='blocked-text'>
      {t('backups_usage_warn', { backupLimit: MAX_BACKUPS })}
      <Link
        href='mailto:support@strattic.com'
        style={{ textTransform: 'lowercase' }}
        onClick={() =>
          AnalyticsManager.getInstance().trackClick('backups_contact_support')
        }
      >
        {t('please_contact_support')}
      </Link>
      {t('will_be_happy_to_help')}
    </Title>
  )
}

const BackupForm = ({
  isModalVisible,
  triggerCreateBackup,
  handleCancel,
  siteName,
  isCreating,
  isBackupsAllowed
}) => {
  const { t } = useTranslation(['common'])
  const [form] = Form.useForm()

  return (
    <Modal
      width={825}
      height={478}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={
        <div className='modal-footer'>
          <Button
            loading={isCreating}
            className='confirm-btn'
            key='submit'
            type='primary'
            disabled={!isBackupsAllowed}
            onClick={() => {
              form
                .validateFields()
                .then(async values => {
                  await triggerCreateBackup(values)
                  form.resetFields()
                })
                .catch(info => {
                  console.error('Validate Failed:', info)
                })
            }}
          >
            {t('backup_now')}
          </Button>
          <Button disabled={isCreating} key='back' onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </div>
      }
      bodyStyle={{ textAlign: 'center', paddingBottom: 0 }}
      className='trigger-backup-modal'
    >
      {isBackupsAllowed ? (
        <>
          <Title level={3}>{t('backups')}</Title>
          <Text className='warning-text'>
            {' '}
            {t('trigger_backup_for_site', { siteName })}
          </Text>
          <br />
          <div className='form-section'>
            <Text strong>{t('select_backup_name')}</Text>
            <Form
              form={form}
              layout='vertical'
              name='backup_form_in_modal'
              requiredMark={false}
            >
              <Form.Item
                name='description'
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || !value.length) {
                        return Promise.reject(new Error(t('backup_name_req')))
                      }
                      if (!/^[A-Za-z]/.test(value)) {
                        return Promise.reject(
                          new Error(t('backup_start_letter'))
                        )
                      }
                      if (value.trim().length < 5) {
                        return Promise.reject(new Error(t('backup_five')))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input width={256} placeholder={t('backup_name')} />
              </Form.Item>
            </Form>
          </div>
        </>
      ) : (
        <ContactSupport />
      )}
    </Modal>
  )
}

const TriggerBackup = ({ currentSite, fetchBackups, isBackupsAllowed }) => {
  const { t } = useTranslation(['common'])
  const messageContext = useMessageContext()
  const userContext = useUserContext()
  const expiredTooltip = useExpiredSubscriptionTooltip()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isExpired, setIsExpired] = useState()
  const showModal = () => {
    setIsModalVisible(true)
  }

  const [
    { data, loading: isCreating, error: CreatingError },
    createBackup
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(CreatingError)

  useEffect(() => {
    if (data?.result) {
      fetchBackups()
      messageContext.setMessage({
        value: t('backup_queued'),
        type: FORM_STATUS.SUCCESS
      })
    }
    // eslint-disable-next-line
  }, [data])

  const triggerCreateBackup = async value => {
    try {
      await createBackup({
        url: `sites/${currentSite?.value?.siteId}/triggerBackup`,
        method: 'POST',
        data: value
      })
      AnalyticsManager.getInstance().trackEvent({
        action: 'trigger_backup_success',
        label: 'trigger_backup'
      })
      setIsModalVisible(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    setIsExpired(
      userContext.accountDetails?.subscriptionStatus ===
        SUBSCRIPTION_STATUS.EXPIRED
    )
  }, [userContext.accountDetails])
  if (currentSite?.value?.status === SITE_STATUS.RESTORING) {
    return (
      <Tooltip title={t('restore_in_progress_tooltip')}>
        <Button disabled type='primary'>
          {t('backup_now')}
        </Button>
      </Tooltip>
    )
  }
  return (
    <>
      <Button
        onClick={showModal}
        disabled={isCreating || isExpired}
        className='primary-add'
        danger={!isExpired}
        type='primary'
        title={isExpired ? expiredTooltip : undefined}
      >
        {t('backup_now')}
      </Button>
      <BackupForm
        isCreating={isCreating}
        siteName={currentSite?.value?.name}
        isModalVisible={isModalVisible}
        triggerCreateBackup={triggerCreateBackup}
        handleCancel={handleCancel}
        isBackupsAllowed={isBackupsAllowed}
      />
    </>
  )
}

export default TriggerBackup
