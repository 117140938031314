import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Button, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { useUserContext } from '@Context/UserContext'
import useAccountPermissions from '@Hooks/useAccountPermissions'

import {
  getDaysLeftOnSubscription,
  isFreeTrial
} from '@Lib/helpers/subscriptionHelpers'
import { SUBSCRIPTION_STATUS } from '@Constants'
import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'

import './index.less'
import Progress from '../Progress'
import ExtendTrialModal from '../ExtendTrialModal'

const { Header } = Layout

const BrowseSitesButton = ({ canViewUpgrade, accountId, freeTrial }) => {
  const { t } = useTranslation(['common', 'errors'])
  const [text, setText] = useState('browse_plans')

  useEffect(() => {
    if (freeTrial) {
      setText('upgrade_now')
    } else {
      setText('browse_plans')
    }
  }, [freeTrial])

  return (
    canViewUpgrade && (
      <Button
        className='sl-browsePlan'
        href={`/account/${accountId}/available-plans`}
      >
        {t(text)}
      </Button>
    )
  )
}

export const TopWarningBar = () => {
  const { t } = useTranslation(['common', 'errors'])
  const userContext = useUserContext()
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const hasPermissionToViewSubscriptions = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_VIEW_SUBSCRIPTIONS
  )

  const [freeTrial, setFreeTrail] = useState(
    isFreeTrial(userContext.accountDetails?.planName)
  )
  const [daysLeft, setDaysLeft] = useState(
    getDaysLeftOnSubscription(userContext.accountDetails?.planEndDate)
  )
  const [canViewUpgrade, setCanViewUpgrade] = useState(
    userContext.accountDetails?.isPlanPublic && hasPermissionToUpgrade
  )
  const [hideWarningBar, setHideWarningBar] = useState(true)

  useEffect(() => {
    setFreeTrail(isFreeTrial(userContext.accountDetails?.planName))
    setDaysLeft(
      getDaysLeftOnSubscription(userContext.accountDetails?.planEndDate)
    )
    setCanViewUpgrade(
      userContext.accountDetails?.isPlanPublic && hasPermissionToUpgrade
    )
  }, [userContext.accountDetails, hasPermissionToUpgrade])

  useEffect(() => {
    if (hideWarningBar) {
      userContext.setExpirationBarPadding(false)
    } else {
      userContext.setExpirationBarPadding(true)
    }

    return () => {
      userContext.setExpirationBarPadding(false)
    }
    // eslint-disable-next-line
  }, [hideWarningBar])

  useEffect(() => {
    if (!userContext.accountId) {
      setHideWarningBar(true)
      return
    }
    // payment card expired show warning bar & is using card for billing
    if (
      userContext?.paymentCardExpired &&
      userContext.accountDetails?.autoBill
    ) {
      setHideWarningBar(false)
      return
    }

    // daysLeft === null there is no endDate to the subscription
    if (daysLeft === null) {
      setHideWarningBar(true)
      return
    }
    // if there is a free trial display
    if (freeTrial) {
      setHideWarningBar(false)
      return
    }
    // it has future subscription then hide
    if (userContext.accountDetails?.futureSubscription) {
      setHideWarningBar(true)
      return
    }
    // hide if more than 30 days left on plan
    if (daysLeft >= 30) {
      setHideWarningBar(true)
    }
  }, [
    userContext.accountDetails,
    daysLeft,
    freeTrial,
    userContext.accountId,
    userContext.paymentCardExpired
  ])

  if (hideWarningBar) {
    return null
  }
  if (
    userContext.accountDetails?.subscriptionStatus ===
      SUBSCRIPTION_STATUS.EXPIRED ||
    daysLeft < 0
  ) {
    if (freeTrial) {
      return (
        <Header className='top-bar-expired'>
          {t('Free trial Expired')}
          <BrowseSitesButton
            canViewUpgrade={canViewUpgrade}
            accountId={userContext?.accountId}
            freeTrial={freeTrial}
          />
        </Header>
      )
    }
    if (daysLeft >= -5) {
      return (
        <Header className='top-bar-expired'>
          {t('your_subscription')}
          <SubscriptionExpiredText days={daysLeft} t={t} />.
          {canViewUpgrade ? (
            <>
              {t('to_avoid_data_loss')}
              <Link
                className='sub-link'
                to={`/account/${userContext?.accountId}/available-plans`}
              >
                {t('click_to_renew')}
              </Link>
            </>
          ) : (
            t('to_avoid_loss_contact')
          )}
        </Header>
      )
    } else {
      return (
        <Header className='top-bar-expired'>
          {t('your_subscription')}
          <SubscriptionExpiredText days={daysLeft} t={t} />.
          {canViewUpgrade && (
            <>
              {t('to_reactivate_avoid_data_loss')}
              <Link
                className='sub-link'
                to={`/account/${userContext?.accountId}/available-plans`}
              >
                {t('click_to_pay')}
              </Link>
            </>
          )}
          <BrowseSitesButton
            canViewUpgrade={canViewUpgrade}
            accountId={userContext?.accountId}
            freeTrial={freeTrial}
          />
        </Header>
      )
    }
  }

  // if plan family is trials (includes free trials and enterprise / custom)
  if (userContext.accountDetails?.planGroupId === 1) {
    return (
      <FreeTrialBar
        accountDetails={userContext.accountDetails}
        accountId={userContext.accountId}
        daysLeft={daysLeft}
        canViewUpgrade={canViewUpgrade}
        freeTrial={freeTrial}
      />
    )
  }

  // card has expired but subscription is still active & is using card payment (not ACH)
  if (
    !daysLeft &&
    userContext?.paymentCardExpired &&
    userContext.accountDetails?.autoBill
  ) {
    return (
      <Header className='top-bar-expired'>
        <strong>{t('card_expired')}</strong> {t('card_expired_update_cta')}
      </Header>
    )
  } else {
    return (
      hasPermissionToViewSubscriptions && (
        <Header className='top-bar-expired'>
          <SubscriptionsWillExpireText days={daysLeft} t={t} />
          {canViewUpgrade && (
            <Link
              className='sub-link'
              to={`/account/${userContext?.accountId}/available-plans`}
            >
              {t('subscription_renew_owner_click')}
            </Link>
          )}
        </Header>
      )
    )
  }
}

const SubscriptionsWillExpireText = ({ days, t }) => {
  const daysLeft = Math.round(Math.abs(days))
  if (daysLeft > 1) {
    return t('subscription_will_expire', { daysLeft })
  }

  if (daysLeft === 1) {
    return t('subscription_will_expire_in_1_day')
  }

  return t('subscription_will_expire_today')
}

const SubscriptionExpiredText = ({ days, t }) => {
  const daysLeft = Math.round(Math.abs(days))
  if (daysLeft > 2) {
    return t('expired_days_ago', { daysLeft: daysLeft - 1 })
  }

  if (daysLeft >= 1) {
    return t('expired_one_day_ago')
  }

  return t('expired_today')
}

const FreeTrialBar = ({
  accountDetails,
  daysLeft,
  canViewUpgrade,
  freeTrial,
  accountId
}) => {
  return (
    <Header className='top-bar-message'>
      <Row justify='space-between'>
        <Col>
          {accountDetails?.planName}
          <Progress
            max={accountDetails?.freePlanLength}
            current={accountDetails?.freePlanLength - Math.round(daysLeft)}
            className='progress'
          />
          <strong>{Math.round(daysLeft)}</strong> days left
        </Col>
        <Col>
          {freeTrial && (
            <>
              <ExtendTrialModal
                canViewUpgrade={canViewUpgrade}
                daysLeft={daysLeft}
              />
              <BrowseSitesButton
                canViewUpgrade={canViewUpgrade}
                accountId={accountId}
                freeTrial={freeTrial}
              />
            </>
          )}
        </Col>
      </Row>
    </Header>
  )
}
