import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip } from 'antd'
import { SITE_STATUS } from '@Constants'
import {
  DetailsIcon,
  WordPressIcon,
  WordPressIconDisabled
} from '@Components/Icons'
import { useUserContext } from '@Context/UserContext'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import { hasSubscriptionExpired } from '@Lib/helpers/subscriptionHelpers'
import useExpiredSubscriptionTooltip from '@Hooks/useExpiredSubscriptionTooltip'
import AnalyticsManager from '@Managers/analyticsManager'

import './index.less'

export const DetailsButton = ({ siteId }) => {
  const { t } = useTranslation(['common'])
  return (
    <Button type='secondary' className='details-btn sl-detailButton'>
      <Link
        onClick={() =>
          AnalyticsManager.getInstance().trackClick('siteCard_details_btn')
        }
        to={`site/${siteId}`}
      >
        {t('details')}
        <DetailsIcon />
      </Link>
    </Button>
  )
}

export const WordPressButton = ({
  siteId,
  runningStatus,
  httpAuthObj,
  siteStatus
}) => {
  const { t } = useTranslation(['common'])
  const expiredTooltip = useExpiredSubscriptionTooltip()
  const userContext = useUserContext()
  const [isSubExpired, setIsSubExpired] = useState(true)
  const [isSiteRunning, setIsSiteRunning] = useState(
    runningStatus === SITE_STATUS.RUNNING
  )
  const [httpAuth, setHttpAuth] = useState(httpAuthObj)
  const expired = userContext?.accountDetails

  const [{ data: site }, getSite] = useStratticApi({}, { manual: true })

  useEffect(() => {
    if (site) {
      setHttpAuth({
        httpBasicUser: site?.result?.httpBasicUser,
        httpBasicPassword: site?.result?.httpBasicPassword,
        stagingDomain: site?.result?.stagingDomain
      })
    }
  }, [site])

  useEffect(() => {
    setIsSiteRunning(runningStatus === SITE_STATUS.RUNNING)
  }, [runningStatus, setIsSiteRunning])

  useEffect(() => {
    setHttpAuth(httpAuthObj)
  }, [httpAuthObj])

  // if site is running and no authObject (ie on dashboard then get httpAuth credentials)
  useEffect(() => {
    if (!httpAuth && runningStatus === SITE_STATUS.RUNNING) {
      getSite({
        url: `/sites/${siteId}`,
        method: 'GET'
      })
    }
    // eslint-disable-next-line
  }, [siteId, runningStatus, httpAuth])

  useEffect(() => {
    setIsSubExpired(hasSubscriptionExpired(expired))
  }, [expired])
  if (siteStatus === SITE_STATUS.RESTORING) {
    return (
      <Tooltip title={t('restore_in_progress_tooltip')}>
        <Button disabled className='wordpress-btn' block>
          {t('edit_in')}
          <WordPressIconDisabled id='wp-icon-restoring-disabled' />
        </Button>
      </Tooltip>
    )
  }
  if (isSubExpired) {
    return (
      <Button disabled className='wordpress-btn' title={expiredTooltip} block>
        {t('edit_in')}
        <WordPressIconDisabled className='wp-icon-disabled' />
      </Button>
    )
  }
  return (
    <Button type='primary' className='wordpress-btn sl-wpButton'>
      {isSiteRunning && httpAuth ? (
        <a
          onClick={() =>
            AnalyticsManager.getInstance().trackClick(
              'wp_btn_site_running_launch'
            )
          }
          target='_blank'
          href={`https://${httpAuth?.httpBasicUser}:${httpAuth?.httpBasicPassword}@${httpAuth?.stagingDomain}/wp-admin/?fromdash=true`}
          rel='noopener noreferrer'
        >
          {t('edit_in')}
          <WordPressIcon />
        </a>
      ) : (
        <Link
          target='_blank'
          to={`/site/${siteId}/launch`}
          onClick={() =>
            AnalyticsManager.getInstance().trackClick('wp_btn_launch_site')
          }
        >
          {t('edit_in')}
          <WordPressIcon />
        </Link>
      )}
    </Button>
  )
}
