import React from 'react'
import { Button, Modal, Typography } from 'antd'

import './index.less'

const { Text, Title } = Typography

const ConfirmationModal = ({
  isVisible,
  onCancel,
  onConfirm,
  processing,
  okText,
  cancelText,
  title,
  text
}) => {
  const footer = (
    <div className='modal-footer'>
      <Button
        className='confirm-btn'
        key='submit'
        type='primary'
        loading={processing}
        onClick={() => {
          if (!processing) {
            onConfirm()
          }
        }}
      >
        {okText}
      </Button>
      <Button
        key='back'
        disabled={processing}
        onClick={() => {
          if (!processing) {
            onCancel()
          }
        }}
      >
        {cancelText}
      </Button>
    </div>
  )

  return (
    <Modal
      className='confirmation-modal'
      open={isVisible}
      footer={footer}
      width={825}
      height={478}
      onCancel={() => {
        if (!processing) {
          onCancel()
        }
      }}
    >
      <div className='heading'>
        <Title level={3}>{title}</Title>
        <br/>
        <Text className='warning-text'>{text}</Text>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
