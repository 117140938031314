import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import AddSite from '@Images/icons/add-site.svg'
import { PlusOutlined } from '@ant-design/icons'
import AnalyticsManager from '@Managers/analyticsManager'

import './index.less'

const AddSiteCard = ({ accountId }) => {
  const { t } = useTranslation(['common'])
  return (
    <Card className='sl-AddASiteCard add-a-site-card' hoverable>
      <Link
        to={`/account/${accountId}/site-welcome`}
        onClick={() =>
          AnalyticsManager.getInstance().trackClick('dashboard_add_site_card')
        }
      >
        <div className='outer-wrapper'>
          <div className='icon-wrapper'>
            <AddSite />
          </div>
        </div>
        <div className='new-site-link'>
          <PlusOutlined /> {t('add_site')}
        </div>
      </Link>
    </Card>
  )
}

export default AddSiteCard
