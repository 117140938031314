import React from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, Typography } from 'antd'

import './index.less'

const { Text } = Typography

const NewSiteNav = ({ selectedKey }) => {
  const { t } = useTranslation(['common', 'errors'])

  return (
    <Menu
      mode='vertical'
      className='new-site-nav'
      selectedKeys={[selectedKey]}
      items={[
        {
          key: 'new-site',
          label: <Text>{t('name_site_1')}</Text>,
          title: null,
          disabled: true
        },
        {
          key: 'setup',
          label: <Text>{t('setup_wp_2')}</Text>,
          title: null,
          disabled: true
        }
      ]}
    />
  )
}

export default NewSiteNav
