import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useExpiredSubscriptionTooltip from '@Hooks/useExpiredSubscriptionTooltip'
import { ROLE_TYPE, SUBSCRIPTION_STATUS } from '@Constants'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Skeleton,
  Typography,
  Tooltip
} from 'antd'
import TeamRoleTooltip from '@Components/TeamRoleTooltip'

import './index.less'

const { Item } = Form
const { Option } = Select
const { Text } = Typography

const ownerOrAdmin = [ROLE_TYPE.OWNER, ROLE_TYPE.ADMIN]

// needs to be a function not a component
const RoleTypeSelectOption = (roleType, className) => {
  return (
    <Option className={`className sl-${roleType}`} value={roleType}>
      <TeamRoleTooltip roleType={roleType} />
    </Option>
  )
}

const AddTeamMemberForm = ({
  visible,
  onCreate,
  onCancel,
  sites,
  loading,
  currentUserRoleType,
  canManageAllSites
}) => {
  const { t } = useTranslation(['common'])

  const [selectedSites, setSelectedSites] = useState([])
  const [roleType, setRoleType] = useState()
  const [allSites, setAllSites] = useState(0)
  const [form] = Form.useForm()
  const selectRef = useRef(null)

  const handleSitesSelection = value => {
    // if  'all' is selected set the display in the dropdown to show only all sites
    // in the option box
    if (!value || value.length === 0) {
      setSelectedSites(value)
    } else if (value.includes('all')) {
      selectRef.current.blur()
      form.setFieldsValue({ permissions: ['all'] })
      setSelectedSites(['all'])
      setAllSites(1)
    }
  }

  const handleCancel = () => {
    onCancel()
    form.resetFields()
  }

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        let payload
        if (allSites === 1) {
          delete values.permissions
          payload = { ...values, allSites }
        } else {
          values.permissions = values.permissions.map(siteId => {
            return { siteId: parseInt(siteId, 10), roleType }
          })
          payload = { ...values, allSites }
        }
        payload.email = payload.email.trim()
        onCreate(payload)
        form.resetFields()
      })
      .catch(info => {
        console.error('Validate Failed:', info)
      })
  }

  return (
    <Modal
      open={visible}
      title={<Text strong>{t('add_team_member')}</Text>}
      onCancel={handleCancel}
      onOk={handleOk}
      className='add-member-modal sl-add-team-member-modal'
      width={500}
      footer={[
        <Button
          key='submit'
          onClick={handleOk}
          className='add-member-ok sl-add-team-member-ok'
          loading={loading}
        >
          {t('add')}
        </Button>,
        <Button
          key='back'
          onClick={handleCancel}
          className='add-member-cancel sl-cancel'
        >
          {t('cancel')}
        </Button>
      ]}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <Form
          form={form}
          requiredMark={false}
          size='large'
          labelCol={{
            xs: {
              span: 8
            }
          }}
          wrapperCol={{
            xs: {
              span: 16
            }
          }}
          name='add-team-member'
        >
          <Item
            name='email'
            labelAlign='left'
            label={<Text strong>{t('email')}</Text>}
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('required')
              },
              {
                type: 'email',
                message: t('email_invalid')
              }
            ]}
          >
            <Input
              autoComplete='username'
              className='sl-team-email-input'
              aria-label={t('email')}
            />
          </Item>
          <Item
            name='roleType'
            labelAlign='left'
            label={<Text strong>{t('role')}</Text>}
            rules={[
              {
                required: true,
                message: t('required')
              }
            ]}
          >
            <Select
              onChange={roleType => {
                setRoleType(roleType)
                if (ownerOrAdmin.includes(roleType)) {
                  form.setFieldsValue({ permissions: ['all'] })
                  setAllSites(1)
                } else {
                  form.setFieldsValue({ permissions: [] })
                  setAllSites(0)
                }
              }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              style={{ textTransform: 'capitalize' }}
              allowClear
            >
              {currentUserRoleType === ROLE_TYPE.OWNER &&
                RoleTypeSelectOption(ROLE_TYPE.OWNER, 'sl-owner')}
              {ownerOrAdmin.includes(currentUserRoleType) &&
                RoleTypeSelectOption(ROLE_TYPE.ADMIN, 'sl-admin')}
              {RoleTypeSelectOption(ROLE_TYPE.MANAGER, 'sl-manager')}
              {RoleTypeSelectOption(ROLE_TYPE.COLLABORATOR, 'sl-collaborator')}
            </Select>
          </Item>
          <Item
            name='permissions'
            labelAlign='left'
            label={<Text strong>{t('site_access')}</Text>}
            rules={[
              {
                required: true,
                message: t('required')
              }
            ]}
          >
            <Select
              mode='multiple'
              allowClear
              showArrow
              ref={selectRef}
              onChange={handleSitesSelection}
              disabled={ownerOrAdmin.includes(roleType)}
            >
              {canManageAllSites && (
                <Option value='all'>{t('all_sites')}</Option>
              )}
              {selectedSites.includes('all') ||
                sites?.value?.map(site => (
                  <Option key={site.id}>{site.displayName}</Option>
                ))}
            </Select>
          </Item>
        </Form>
      )}
    </Modal>
  )
}

const AddTeamMember = ({
  sites,
  accountId,
  fetchTeamManagementData,
  currentUserRoleType,
  loading,
  disabled,
  canAddMemberData,
  canManageAllSites,
  accountSubStatus,
  hasPermissionToUpgrade,
  canAddMemberCheck
}) => {
  const { t } = useTranslation(['common', 'errors'])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [currentUsers] = useState(canAddMemberData?.current)
  const [usersAmount] = useState(canAddMemberData?.max)
  const path = `accounts/${accountId}/invitations`
  const [
    { loading: isPostLoading, error: postError },
    executePost
  ] = useStratticApi(
    {
      url: path,
      method: 'POST'
    },
    { manual: true }
  )

  useErrorMessage(postError)

  const onCreate = async values => {
    await executePost({ data: values })
    AnalyticsManager.getInstance().trackEvent({
      action: 'add_team_member_success',
      label: EVENT_CATEGORIES.TEAM_MANAGEMENT
    })
    fetchTeamManagementData()
    canAddMemberCheck()
    setIsModalVisible(false)
  }

  return (
    <div>
      {canAddMemberData?.max && !loading && (
        <TeamMemberLimits
          currentUsers={currentUsers}
          usersAmount={usersAmount}
          t={t}
        />
      )}
      {disabled || accountSubStatus !== SUBSCRIPTION_STATUS.ACTIVE ? (
        <Tooltip
          title={
            <TooltipTxt
              accountSubStatus={accountSubStatus}
              disabled={disabled}
              hasPermissionToUpgrade={hasPermissionToUpgrade}
              t={t}
            />
          }
        >
          <Button
            className='users-limits-button sl-user-limit-button'
            loading={loading}
            disabled={
              disabled || accountSubStatus !== SUBSCRIPTION_STATUS.ACTIVE
            }
          >
            + {t('add_team_member')}
          </Button>
        </Tooltip>
      ) : (
        <Button
          className='add-team-member sl-add-team-member-button'
          onClick={() => {
            AnalyticsManager.getInstance().trackClick({
              action: 'add_team_member',
              label: EVENT_CATEGORIES.TEAM_MANAGEMENT
            })
            setIsModalVisible(true)
          }}
          loading={loading}
        >
          + {t('add_team_member')}
        </Button>
      )}
      <AddTeamMemberForm
        canManageAllSites={canManageAllSites}
        loading={isPostLoading}
        sites={sites}
        visible={isModalVisible}
        currentUserRoleType={currentUserRoleType}
        onCreate={onCreate}
        onCancel={() => {
          setIsModalVisible(false)
        }}
      />
    </div>
  )
}

const TooltipTxt = ({
  t,
  accountSubStatus,
  disabled,
  hasPermissionToUpgrade
}) => {
  const expiredTooltip = useExpiredSubscriptionTooltip()
  if (accountSubStatus !== SUBSCRIPTION_STATUS.ACTIVE) {
    return expiredTooltip
  }
  if (!disabled || hasPermissionToUpgrade) {
    return t('add_users_upgrade')
  }
  return t('add_users_upgrade')
}

const TeamMemberLimits = ({ currentUsers, usersAmount, t }) => {
  return (
    <span className='users-limits'>
      {currentUsers}/{usersAmount} {t('users')}
    </span>
  )
}

export default AddTeamMember
