import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Row, Layout } from 'antd'
import TopBar from '@Components/TopBar'
import DashboardFooter from '@Components/DashboardFooter'
import './index.less'

const { Content } = Layout

export const Col1 = ({ children }) => (
  <Col className='col-1' xs={24} sm={12}>
    <div className='wrapper'>{children}</div>
  </Col>
)

export const Col2 = ({ featuredImage, children }) => (
  <Col className='col-2' xs={24} sm={12}>
    <div className='wrapper'>{children}</div>
  </Col>
)

export const Template = ({
  title,
  classNames,
  children,
  showTitle = true,
  showFooter = true
}) => (
  <Layout>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Layout className={`page-wrapper full-width two-column ${classNames}`}>
      <TopBar />
      <Content>
        <Row>{children}</Row>
        {showFooter && <DashboardFooter />}
      </Content>
    </Layout>
  </Layout>
)
