import React from 'react'

import { useTranslation } from 'react-i18next'

import Sygnet from '@Images/backgrounds/sygnet.svg'
import DotsSquare from '@Images/backgrounds/dots-square.svg'
import DotsRectangle from '@Images/backgrounds/dots-rectangle.svg'
import Logo from '@Images/logos/logo-horizontal-white.svg'
import { Button, Typography } from 'antd'

import './index.less'

const { Title } = Typography

const SiteRestoreFullPage = () => {
  const { t } = useTranslation(['common'])
  return (
    <div className='restore-page-wrapper'>
      <Sygnet id='sygnet' />
      <DotsSquare id='dots-square' />
      <DotsRectangle id='dots-rectangle' />
      <Logo id='logo' />
      <div className='content'>
        <div className='text'>
          <Title>{t('restore_in_progress_tooltip')}</Title>
          <Button href='/' type='primary' size='large'>
            {t('back_to_dashboard')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SiteRestoreFullPage
