import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { Template, Col1, Col2 } from '@Templates/Login'
import ForgotPasswordForm from '@Components/ForgotPasswordForm'
import websiteImg from '@Images/dashboard-browser.png'
import AnalyticsManager from '@Managers/analyticsManager'

const { Text, Title } = Typography

const ForgotPasswordPage = () => {
  const { t } = useTranslation(['common', 'errors'])
  return (
    <Template title={t('forgot_your_strattic_password')}>
      <Col1>
        <Title>{t('forgot_your_password')}</Title>
        <Text type='secondary'>{t('enter_email_reset_pword')}</Text>
        <ForgotPasswordForm />
        <Text>
          <Link
            onClick={() => {
              window.dataLayer.push({
                event: 'fireEvent',
                event_name: 'element_click',
                action_type: 'login_link'
              })
              AnalyticsManager.getInstance().trackClick({
                action: 'back_to_login',
                label: 'forgot_password'
              })
            }}
            className='login-next-step'
            to='/signin'
          >
            {t('go_back_to_login')}
          </Link>
        </Text>
      </Col1>
      <Col2>
        <img
          className='feature'
          src={websiteImg}
          alt='Illustration of the Strattic Dashboard'
        />
      </Col2>
    </Template>
  )
}

export default ForgotPasswordPage
