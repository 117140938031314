import React, { useEffect } from 'react'
import { Alert, message, Button, Row, Col } from 'antd'

import { useTranslation } from 'react-i18next'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

const SiteDistError = ({ distribution, siteId }) => {
  const { t } = useTranslation(['common'])

  const [
    { error: updateSiteError, response: { status } = {} },
    updateSite
  ] = useStratticApi(
    {
      url: `sites/${siteId}`,
      method: 'PUT'
    },
    { manual: true }
  )

  useErrorMessage(updateSiteError)

  useEffect(() => {
    if (status === 200) {
      message.success(t('site_update_success'))
    }
  }, [status, t])

  const handleClose = () => {
    updateSite()
  }

  const errorString = 'Service: CloudFront, Status Code: 409'

  if (distribution.stackError?.includes(errorString)) {
    return (
      <>
        <Alert
          message={t('warning')}
          description={
            <ErrorDiscription t={t} handleClose={() => handleClose()} />
          }
          type='error'
          showIcon
          closable
        />
        <br />
      </>
    )
  }
  return null
}

const ErrorDiscription = ({ t, handleClose }) => {
  return (
    <>
      <Row justify='space-between'>
        <Col span={18}>
          {t('stack_error_line1')}
          <br />
          {t('stack_error_line2')}
        </Col>
        <Col span={3}>
          <Button size='small' type='ghost' onClick={handleClose}>
            {t('update_site')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SiteDistError
