import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import csvToJson from 'convert-csv-to-json'

import { Button, message } from 'antd'

import ImportModal, { TYPE_CSV } from '../../ImportModal'
import { RulesetContext } from '../../RulesetContext'

const ImportButton = ({ csvObjToRule }) => {
  const { t } = useTranslation(['common'])
  const [importModalVisible, setImportModalVisible] = useState(false)

  const { state: ruleset, api } = useContext(RulesetContext)

  const { loading: loadingGet, doCall: getRules } = api.getRules
  const { loading: loadingImport, doCall: importRuleset } = api.importRuleset

  const handleImport = () => {
    setImportModalVisible(true)
  }

  const onCancelImport = () => {
    setImportModalVisible(false)
  }

  const csvTextToJson = (csvText) => {
    const csvObjArr = csvToJson
      .fieldDelimiter(',')
      .formatValueByType()
      .supportQuotedField(true)
      .csvStringToJson(csvText)

    const ruleset = {
      rules: []
    }
    for (const csvObj of csvObjArr) {
      ruleset.rules.push(csvObjToRule(csvObj))
    }

    return ruleset
  }

  const doImport = data => {
    const json = csvTextToJson(data)

    importRuleset(ruleset, json).then(result => {
      getRules(ruleset.ruleType)
      setImportModalVisible(false)
      message.success(t('ruleset_imported_successfully'))
    })
  }

  return (
    <>
      <Button type='link' onClick={handleImport} disabled={loadingGet}>
        {t('import_csv')}
      </Button>
      <ImportModal
        visible={importModalVisible}
        onCancel={onCancelImport}
        fileType={TYPE_CSV}
        onOk={doImport}
        loading={loadingImport}
      />
    </>
  )
}

export default ImportButton
