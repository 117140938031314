import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'antd'

import { QuestionMarkIcon } from '@Components/Icons'
import CopyText from '@Components/CopyText'

import './index.less'

const DefinitionHeader = ({
  tooltip = false,
  label,
  actionLabel,
  onClick,
  showCopy
}) => {
  const { t } = useTranslation(['common'])
  const [text, setText] = useState()
  useEffect(() => {
    if (tooltip && typeof tooltip === 'object') {
      setText(tooltip)
    } else {
      setText(t(tooltip))
    }
  }, [tooltip, t])
  return (
    <dt className={`definition-header sl-${label}`}>
      <h3>
        {tooltip ? (
          <Tooltip title={text} placement='right'>
            {t(label)}
            <QuestionMarkIcon />
          </Tooltip>
        ) : (
          <>
            {t(label)}
            {showCopy && <CopyText text={label} />}
          </>
        )}
        {/* hidden for now
        {actionLabel && (
          <Button className='reset-sftp' type='link' onClick={onClick}>
            {t(actionLabel)}
          </Button>
        )} */}
      </h3>
    </dt>
  )
}
export default DefinitionHeader
