import React from 'react'
import { Button } from 'antd'

const PaginationNavigation = (
  current,
  type,
  originalElement,
  prevLabel,
  nextLabel
) => {
  if (type === 'prev') {
    return <Button>{prevLabel}</Button>
  }
  if (type === 'next') {
    return <Button>{nextLabel}</Button>
  }
  return originalElement
}

export default PaginationNavigation
