import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const FormMessage = ({ message, type = 'danger' }) => (
  <Text className='sl-form-message form-message' strong type={type}>
    {message}
  </Text>
)
export default FormMessage
