import React from 'react'

import { Card, Typography } from 'antd'

import { useTranslation } from 'react-i18next'
import { Template } from '@Templates/FullWidth'

import Icon from '@Images/icons/unavailable.svg'

import './index.less'

const { Title, Text } = Typography

const Unavailable = () => {
  const { t } = useTranslation(['common'])
  return (
    <Template classNames='unavailable'>
      <Card className='narrow'>
        <div className='icon-wrapper'>
          <Icon />
        </div>
        <Title level={1}>{t('website_unavailable')}</Title>
        <p>
          <Text>{t('try_later')}</Text>
        </p>
      </Card>
    </Template>
  )
}

export default Unavailable
