import React, { useState, useEffect, useRef } from 'react'

import { Col, Row } from 'antd'

import { useTranslation } from 'react-i18next'
import { isDnsActive, sanitiseDnsAnswers } from '@Lib/api/sites'

import DefinitionHeader from '@Components/DefinitionHeader'

import './index.less'

const CaaCheck = ({ title, domain }) => {
  const { t } = useTranslation(['common'])
  const [caaStatus, setCaaStatus] = useState(null)
  const [caaValues, setCaaValues] = useState([])

  const timer = useRef(null)
  const checkDNS = async () => {
    const results = await isDnsActive(domain, 'caa')
    // this makes sure we are only handling CAA records
    const answers = sanitiseDnsAnswers(results)
    if (answers?.length) {
      setCaaStatus(answers)
    } else {
      setCaaStatus(null)
    }
    timer.current = setTimeout(() => {
      checkDNS()
    }, 3 * 60 * 1000)
  }

  useEffect(() => {
    if (caaStatus) {
      const issueAmazon = !!caaStatus.filter(answer =>
        answer.data?.includes('issue "amazon.com"')
      ).length
      const issuewildAmazon = !!caaStatus.filter(answer =>
        answer.data?.includes('issuewild "amazon.com"')
      ).length
      const issueLetsencrypt = !!caaStatus.filter(answer =>
        answer.data?.includes('issue "letsencrypt.org"')
      ).length
      setCaaValues({
        issueAmazon,
        issuewildAmazon,
        issueLetsencrypt
      })
    }
  }, [caaStatus])

  useEffect(() => {
    if (domain) {
      checkDNS()
    }
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [domain])

  // there are no CAA do not display component
  if (!caaStatus) {
    return null
  }
  return (
    <>
      <br />
      {title || <h3 className='section-header'>{t('caa_record_check')}</h3>}
      <div className='data-list bordered'>
        <section
          className={Object.values(caaValues).includes(false) ? 'ERROR' : ''}
        >
          <dl>
            <Row>
              <Col span={8}>
                <DefinitionHeader label={t('caa_record')} />
              </Col>
              <Col span={14}>
                <DefinitionHeader label='STATUS' />
              </Col>
            </Row>
          </dl>
          <dl className={caaValues?.issueAmazon ? '' : 'caa-error'}>
            <Row>
              <Col span={8}>
                <DefinitionHeader label='0 issue "amazon.com"' showCopy />
              </Col>
              <Col span={14}>
                {caaValues?.issueAmazon ? (
                  <DefinitionHeader label={t('active')} />
                ) : (
                  <DefinitionHeader label={t('not_found')} />
                )}
              </Col>
            </Row>
          </dl>
          <dl className={caaValues?.issuewildAmazon ? '' : 'caa-error'}>
            <Row>
              <Col span={8}>
                <DefinitionHeader label='0 issuewild "amazon.com"' showCopy />
              </Col>
              <Col span={14}>
                {caaValues?.issuewildAmazon ? (
                  <DefinitionHeader label={t('active')} />
                ) : (
                  <DefinitionHeader label={t('not_found')} />
                )}
              </Col>
            </Row>
          </dl>
          <dl className={caaValues?.issueLetsencrypt ? '' : 'caa-error'}>
            <Row>
              <Col span={8}>
                <DefinitionHeader label='0 issue "letsencrypt.org"' showCopy />
              </Col>
              <Col span={14}>
                {caaValues?.issueLetsencrypt ? (
                  <DefinitionHeader label={t('active')} />
                ) : (
                  <DefinitionHeader label={t('not_found')} />
                )}
              </Col>
            </Row>
          </dl>
        </section>
      </div>
    </>
  )
}

export default CaaCheck
