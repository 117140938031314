import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import {
  getSubscriptionName,
  formatDate
} from '@Lib/helpers/subscriptionHelpers'
import useAccountPermissions from '@Hooks/useAccountPermissions'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import { formatPriceCurrency, pluralStringIfNeeded } from '@Lib/helpers'
import { BILLING_CYCLE_OPTIONS } from '@Constants'
import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'
import { Modal, Typography, Button } from 'antd'

import './index.less'

const { Text, Link } = Typography

const FutureSubNotice = ({
  futureSubscription,
  currentAccount,
  refreshSession,
  setFutureSubscription,
  planPageMessage
}) => {
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const [visible, setIsVisible] = useState(false)
  const {
    websitesAmount: futureWebsites,
    startDate: futureStartDate
  } = futureSubscription
  const {
    planFrequency: currentBillingCycle,
    planName: currentPlanName,
    planPrice
  } = currentAccount
  const { t } = useTranslation(['common'])
  const path = `accounts/${futureSubscription?.accountId}/subscriptions/${futureSubscription?.id}/cancel`
  const [
    { loading: cancelSubLoading, error: cancelSubError },
    cancelSub
  ] = useStratticApi(
    {
      url: path,
      method: 'POST'
    },
    { manual: true }
  )
  useErrorMessage(cancelSubError)
  const [futurePlanName] = useState(getSubscriptionName(futureSubscription))
  const [dateString] = useState(formatDate(futureStartDate))
  const [costString] = useState(
    formatPriceCurrency(
      futureSubscription?.priceTotal,
      futureSubscription?.currency
    )
  )
  const [cycle, setCycle] = useState()
  useEffect(() => {
    if (futureSubscription?.billingCycle === BILLING_CYCLE_OPTIONS.ANNUAL) {
      setCycle(t('per_year'))
    } else {
      setCycle(t('per_month'))
    }
    // eslint-disable-next-line
  }, [futureSubscription?.billingCycle])

  const [siteOrSites, setSiteOrSites] = useState(
    pluralStringIfNeeded('site', futureWebsites)
  )

  useEffect(() => {
    setSiteOrSites(pluralStringIfNeeded('site', futureWebsites))
  }, [futureWebsites])

  if (!futurePlanName || !cycle) {
    // Technically there should always be a plan name, don't display anything if you don't have one
    return null
  }
  return (
    <>
      <Modal
        className='sub-change-modal'
        title={t('sub_change')}
        width={825}
        open={visible}
        onCancel={() => setIsVisible(false)}
        footer={
          <div className='modal-footer'>
            <Button
              className='confirm-delete-btn sl-confirm-delete-btn'
              key='submit'
              type='primary'
              disabled={cancelSubLoading}
              onClick={() => setIsVisible(false)}
            >
              {t('close')}
            </Button>
            <Button
              className='sl-cancel-sub-btn'
              key='back'
              loading={cancelSubLoading}
              disabled={!hasPermissionToUpgrade}
              onClick={async () => {
                await cancelSub()
                AnalyticsManager.getInstance().trackEvent({
                  action: 'cancel_future_sub',
                  label: EVENT_CATEGORIES.FUTURE_SUB
                })
                refreshSession()
                setFutureSubscription(null)
                setIsVisible(false)
              }}
            >
              {t('cancel_sub_change')}
            </Button>
          </div>
        }
      >
        <div>
          <Text className='sl-current-plan-details'>
            {t('current_plan_details', {
              currentPlanName,
              aOrAn: currentBillingCycle === 'annual' ? 'an' : 'a',
              billingCycle: currentBillingCycle,
              planPrice: formatPriceCurrency(planPrice)
            })}
          </Text>
          <div className='future-sub-details sl-future-sub-details'>
            <Text className='sl-future-sub-details-1'>
              {t('future_sub_details_1', {
                dateString,
                futurePlanName,
                aOrAn:
                  futureSubscription?.billingCycle === 'annual' ? 'an' : 'a',
                billingCycle: futureSubscription?.billingCycle,
                costString
              })}
            </Text>
            <br />
            <Text className='sl-future-sub-details-2'>
              {t('future_sub_details_2', {
                futureWebsites,
                siteOrSites
              })}
            </Text>
          </div>
        </div>
      </Modal>
      <div className='future-sub-alert sl-future-sub-alert'>
        {planPageMessage
          ? t('future_sub_cancel_to_upgrade', { futurePlanName })
          : t('future_sub_plan_name', { futurePlanName })}{' '}
        <Link onClick={() => setIsVisible(true)}>{t('more_details')}</Link>
      </div>
    </>
  )
}

export default FutureSubNotice
