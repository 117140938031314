import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { Button, message, Tooltip } from 'antd'

import { RulesetContext } from '../../RulesetContext'

const RevertButton = ({ canManageRules }) => {
  const { t } = useTranslation(['common'])
  const { state: ruleset, api } = useContext(RulesetContext)

  const { loading: loadingRevert, doCall: revertChanges } = api.revertChanges
  const { loading: loadingGet, doCall: getRules } = api.getRules

  const handleRevert = () => {
    revertChanges(ruleset).then(result => {
      getRules(ruleset.ruleType)
      message.success(t('reverted_successfully'))
    })
  }
  if (!canManageRules) {
    return (
      <Tooltip title={t('insufficent_permissions_rules_engine')}>
        <Button
          disabled
          style={{ width: 100 }}
        >
          {t('revert')}
        </Button>
      </Tooltip>
    )
  }
  return (
    <Button
      onClick={handleRevert}
      disabled={loadingGet || !ruleset?.hasUndeployedChanges}
      loading={loadingRevert}
      style={{ width: 100 }}
    >
      {t('revert')}
    </Button>
  )
}

export default RevertButton
