import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({
  children,
  isAuthenticated = false,
  redirectLocation = '/signin',
  forwardQuery = true,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <>{React.cloneElement(children, { location })}</>
        ) : (
          <Redirect
            to={{
              pathname: redirectLocation,
              search: location.search,
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
