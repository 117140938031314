import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { Button, Divider } from 'antd'

import './index.less'
import DeleteTeamMember from '@Components/Team/DeleteTeamMember'
import EditTeamMemberForm from '@Components/Team/EditTeamMemberForm'

import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

const ManageTeamMember = ({
  sites,
  accountId,
  record,
  fetchTeamManagementData,
  canAddMemberCheck
}) => {
  const { t } = useTranslation(['common', 'errors'])
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const path = `accounts/${accountId}/invitations/${record?.key}`
  const [
    { loading: isEditLoading, error: editError },
    executeEditPut
  ] = useStratticApi(
    {
      url: path,
      method: 'PUT'
    },
    { manual: true }
  )
  const [
    { loading: isDeleteLoading, error: deleteError },
    executeDelete
  ] = useStratticApi(
    {
      url: path,
      method: 'DELETE'
    },
    { manual: true }
  )

  useErrorMessage(editError)
  useErrorMessage(deleteError)

  const onCreate = async values => {
    await executeEditPut({ data: values })
    AnalyticsManager.getInstance().trackEvent({
      action: 'edit_team_member_success',
      label: EVENT_CATEGORIES.TEAM_MANAGEMENT
    })
    fetchTeamManagementData()
    setIsEditModalVisible(false)
  }

  const onDelete = async values => {
    await executeDelete()
    AnalyticsManager.getInstance().trackEvent({
      action: 'delete_team_member_success',
      label: EVENT_CATEGORIES.TEAM_MANAGEMENT
    })
    fetchTeamManagementData()
    canAddMemberCheck()
    setIsDeleteModalVisible(false)
  }

  return (
    <>
      <Button
        onClick={() => {
          setIsEditModalVisible(true)
          AnalyticsManager.getInstance().trackClick({
            action: 'edit_team_member',
            label: EVENT_CATEGORIES.TEAM_MANAGEMENT
          })
        }}
        className='manage-button'
        type='link'
      >
        {t('manage_team_member')}
      </Button>
      <Divider type='vertical' className='manage-divider' />
      {/* delete will be dealt with correctly in the next task */}
      <Button
        onClick={() => {
          setIsDeleteModalVisible(true)
          AnalyticsManager.getInstance().trackClick({
            action: 'delete_team_member',
            label: EVENT_CATEGORIES.TEAM_MANAGEMENT
          })
        }}
        className='manage-button'
        type='link'
      >
        {t('delete')}
      </Button>
      <EditTeamMemberForm
        loading={isEditLoading}
        sites={sites}
        visible={isEditModalVisible}
        record={record}
        onCreate={onCreate}
        onCancel={() => {
          setIsEditModalVisible(false)
        }}
      />
      <DeleteTeamMember
        executeDelete
        loading={isDeleteLoading}
        sites={sites}
        visible={isDeleteModalVisible}
        record={record}
        onCreate={onDelete}
        onCancel={() => {
          setIsDeleteModalVisible(false)
        }}
      />
    </>
  )
}

export default ManageTeamMember
