import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Avatar, Button, Dropdown } from 'antd'
import { DownOutlined, UpOutlined, UserOutlined } from '@ant-design/icons'
import LogoutButton from '@Components/LogoutButton'
import useLocalStorage from '@Hooks/useLocalStorage'

import { useWindowDimensionsContext } from '@Context/WindowDimensionsContext'
import { NAV_STYLE } from '@Constants'

import { upperFirst } from '@Lib/helpers/text'

import './index.less'
import { useHistory } from 'react-router-dom'

const UserMenu = () => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  return (
    <Button type='link' onClick={() => history.push('/my-profile')}>
      {t('my_profile')}
    </Button>
  )
}

const ProfileMenu = () => {
  const [currentUser] = useLocalStorage('currentUser')
  const [initials, setInitials] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const { width } = useWindowDimensionsContext()
  useEffect(() => {
    if (!currentUser?.name) return
    const names = currentUser?.name?.split(' ')
    const firstInitial = upperFirst(names?.[0]?.charAt(0))
    const lastInitial = upperFirst(names?.[names?.length - 1]?.charAt(0))
    setInitials(firstInitial + lastInitial)
  }, [currentUser])

  const onDropdownVisibleChange = isVisible => {
    setIsVisible(isVisible)
  }

  const items = [
    {
      label: <UserMenu />,
      key: 'user-menu'
    },
    {
      type: 'divider'
    },
    {
      label: <LogoutButton />,
      key: 'logout'
    }
  ]

  const menuProps = {
    items
  }

  if (!currentUser?.id) return null

  return (
    <Dropdown
      className={`profile-menu ${width < NAV_STYLE.MOBILE && 'small'}`}
      menu={menuProps}
      placement={width < NAV_STYLE.MED ? 'topRight' : 'bottomRight'}
      trigger={['click']}
      onOpenChange={onDropdownVisibleChange}
    >
      <div className='dropdown'>
        <Avatar>{initials.length ? initials : <UserOutlined />}</Avatar>{' '}
        {isVisible ? <UpOutlined /> : <DownOutlined />}
      </div>
    </Dropdown>
  )
}
export default ProfileMenu
