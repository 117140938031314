import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons'

import { DNS_STATUS } from '@Constants'

import { EyeIcon } from '@Components/Icons'
import CopyText from '@Components/CopyText'

const DefinitionData = ({
  label,
  value,
  showHide = false,
  showCopy = true,
  dnsStatus,
  ssl,
  addHttps,
  isLink = false,
  isRootDomain
}) => {
  const { t } = useTranslation(['common'])
  const [isVisible, setIsVisible] = useState(false)

  return (
    <dd>
      {label && `${t(label)}: `}
      {showHide ? (
        <>
          {isVisible ? (
            <MakeLinkIfNeeded
              isLink={isLink}
              addHttps={addHttps}
              value={value}
            />
          ) : (
            t('hidden_password_value')
          )}
          <EyeIcon
            title={t('show_password')}
            className='reveal'
            onClick={() => setIsVisible(!isVisible)}
          />
        </>
      ) : (
        <MakeLinkIfNeeded isLink={isLink} addHttps={addHttps} value={value} />
      )}
      {showCopy && <CopyText text={addHttpsIfNeeded(addHttps, value)} />}
      {ssl && <span className='dns-status'>{t('issued')}</span>}
      {dnsStatus &&
        !isRootDomain &&
        (dnsStatus === DNS_STATUS.ACTIVE ? (
          <span className='dns-status'>
            <CheckOutlined />
            Active
          </span>
        ) : (
          <span className='dns-status'>
            <ClockCircleOutlined />
            Pending
          </span>
        ))}
    </dd>
  )
}

const MakeLinkIfNeeded = ({ isLink, value, addHttps }) => {
  if (!value) {
    return null
  }
  if (!isLink) {
    return value
  }

  return (
    <a
      href={addHttpsIfNeeded(addHttps, value)}
      target='_blank'
      rel='noopener noreferrer'
    >
      {value}
    </a>
  )
}

const addHttpsIfNeeded = (addHttps, value) => {
  if (!addHttps) {
    return value
  }

  return `https://${value}`
}

export default DefinitionData
