import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Typography,
  Row,
  Col,
  Divider,
  Space
} from 'antd'
import { useUserContext } from '@Context/UserContext'
import { submitHubspotForms } from '@Lib/api/hubspot'
import config from '@Lib/config'
import Success from '@Images/icons/success-tick.svg'
import { customPlanFormOnSubmitEvent } from '@Lib/helpers/gtmEventHelpers'

import { NUMBER_WP_USERS, PLAN_TYPES } from '@Constants'

import './index.less'

const { Text, Title } = Typography
const { Item } = Form
const { TextArea } = Input
const { Option } = Select

const WIDTH = 825
const WIDTH_SUCCESS = 543

const CustomPlanContactForm = ({
  visible,
  setIsModalVisible,
  onCancel,
  company
}) => {
  const { t } = useTranslation(['common'])
  const userContext = useUserContext()
  const currentUser = userContext?.currentUser
  const [submitting, setSubmitting] = useState(false)
  const [submitMessage, setSubmitMessage] = useState(false)
  const [isClosable, setIsClosable] = useState(true)
  const [width, setWidth] = useState(WIDTH)
  const [form] = Form.useForm()

  const onOk = async values => {
    const payload = {
      ...values,
      name: currentUser?.name,
      email: currentUser?.email,
      select_plan: PLAN_TYPES.ENTERPRISE
    }
    try {
      setSubmitting(true)

      await submitHubspotForms(config?.hubspot?.pricingContactForm, payload)
      showSuccessMsg()
    } catch (info) {
      setSubmitting(false)
      setSubmitMessage(
        <SomethingWentWrong t={t} setSubmitMessage={setSubmitMessage} />
      )
      form.resetFields()
      console.error(info)
    }
  }

  const onKeyPressNumber = e => {
    const re = /[0-9]/
    if (re.test(e.key)) {
      return true
    }
    e.preventDefault()
    return false
  }

  const showSuccessMsg = () => {
    customPlanFormOnSubmitEvent(
      userContext?.currentUser?.id,
      userContext?.accountDetails,
      config?.hubspot?.pricingContactForm
    )
    setSubmitMessage(<SuccessContent t={t} />)
    setIsClosable(false)
    setWidth(WIDTH_SUCCESS)
    setSubmitting(false)
    form.resetFields()
  }

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={width}
      footer={false}
      closable={isClosable}
      className='custom-contact-form'
    >
      {submitMessage || (
        <Row>
          <Col span={8} className='custom-plan-form-title'>
            <Title level={4}>{t('custom')}</Title>
            <Text type='secondary'>{t('custom_plan_title')}</Text>
          </Col>
          <Divider type='vertical' />
          <Col span={15} className='custom-plan-form-content'>
            <Form
              form={form}
              requiredMark={false}
              size='large'
              name='custom-plan-form'
              onFinish={onOk}
            >
              <Title level={5}>{t('how_many_sites_manage')}</Title>
              <Item
                name='number_of_sites'
                rules={[
                  {
                    required: true,
                    message: t('required')
                  }
                ]}
              >
                <Input
                  className='sl-numberSites'
                  type='number'
                  min='1'
                  onKeyPress={onKeyPressNumber}
                />
              </Item>

              <Title level={5}>{t('number_wp_users')}</Title>
              <Item
                name='number_of_wordpress_users'
                rules={[
                  {
                    required: true,
                    message: t('required')
                  }
                ]}
              >
                <Select className='sl-numberWpUsers' placeholder='Select'>
                  <Option value={NUMBER_WP_USERS.ONE} className='sl-one'>
                    1
                  </Option>
                  <Option
                    value={NUMBER_WP_USERS.TWO_TO_FIVE}
                    className='sl-twoToFive'
                  >
                    2-5
                  </Option>
                  <Option
                    value={NUMBER_WP_USERS.SIX_TO_TEN}
                    className='sl-sixToTen'
                  >
                    6-10
                  </Option>
                  <Option
                    value={NUMBER_WP_USERS.ELEVEN_PLUS}
                    className='sl-elevenPlus'
                  >
                    11+
                  </Option>
                </Select>
              </Item>
              <Title level={5}>{t('anything_else')}</Title>
              <Item name='anything_else'>
                <TextArea
                  rows={4}
                  className='sl-anythingElse'
                  type='text'
                  placeholder={t('type_here')}
                />
              </Item>
              <Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  // onClick={onOk}
                  loading={submitting}
                >
                  {t('send')}
                </Button>
              </Item>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  )
}

const SuccessContent = ({ t }) => (
  <div className='custom-success'>
    <Success />
    <Title level={4} className='custom-success-title sl-custom-success-title'>
      {t('Thank_you')}
    </Title>
    <div>
      <Text className='sl-custom-in-touch'>{t('in_touch')}</Text>
    </div>
    <Button
      className='custom-success-ok sl-custom-success-ok'
      href='/account'
      type='primary'
    >
      {t('ok')}
    </Button>
  </div>
)

const SomethingWentWrong = ({ t, setSubmitMessage }) => (
  <div className='custom-error'>
    <Space direction='vertical'>
      <Title level={3}>{t('error_occurred')}</Title>
      <Button
        className='custom-error-try'
        onClick={() => setSubmitMessage(false)}
        type='primary'
      >
        {t('try_again')}
      </Button>
    </Space>
  </div>
)

export default CustomPlanContactForm
