import { TagManager } from '../react-gtm-manager/TagManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import config from '@Lib/config'

const trackEventGoogle = (eventProps = {}) => {
  window.dataLayer.push(eventProps)
}

export default class GTMProvider {
  initialize = () => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))

    if (currentUser) {
      window.dataLayer = window.dataLayer || []
      trackEventGoogle({ user_id: currentUser?.id })
      trackEventGoogle({ user_email: currentUser?.email })
    }
    const tagManagerArgs = {
      gtmId: config.analytics.gtmId
    }
    if (config.analytics.gtmAuth) {
      tagManagerArgs.auth = config.analytics.gtmAuth
      tagManagerArgs.cookies = '&gtm_cookies_win=x'
    }
    if (config.analytics.gtmPreview) {
      tagManagerArgs.preview = config.analytics.gtmPreview
    }
    TagManager.initialize(tagManagerArgs)
  }

  // No need to enable or disable google tag manager. All event sending is based on cookiebot setup inside GTM.
  // enable = () => {
  // }

  // Note: No real way to disable tag manager after it has been enabled.
  // disable = () => {
  // }

  login = () => {
    trackEventGoogle({
      category: EVENT_CATEGORIES.USER_MANAGEMENT,
      action: 'login'
    })
  }

  logout = () => {
    trackEventGoogle({
      category: EVENT_CATEGORIES.USER_MANAGEMENT,
      action: 'logout'
    })
  }

  trackEvent = (eventProperties, category) => {
    trackEventGoogle({
      // category,
      ...eventProperties
    })
  }
}
