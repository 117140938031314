import axios from 'axios'

import config from '@Lib/config'
import { ApiError } from '@Packages/StratticApi/error'

export const submitHubspotForms = async (formId, fields) => {
  const { portalId } = config?.hubspot
  // from https://www.stephanieogaygarcia.com/hubspot-website-development/get-hubspot-cookie-hubspotutk-using-javascript
  const hutk = document.cookie.replace(
    /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  )
  const context = hutk ? { hutk } : {}

  const url = `${portalId}/${formId}`
  const formattedFields = []
  Object.keys(fields).forEach(i => {
    const value = fields[i]
    if (!value) {
      return
    }
    const newObj = {
      name: i.toLowerCase(),
      value
    }
    formattedFields.push(newObj)
  })

  const params = {
    fields: formattedFields,
    context
  }

  try {
    const response = await axios.post(
      `${config?.hubspot.apiUrl}/${url}`,
      params
    )
    return response
  } catch (error) {
    console.error(error)
    throw new ApiError(error?.response?.data?.message, error.code, error.status)
  }
}
