import React from 'react'

import { Card, Row, Col } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'

import { getFormattedSite } from '@Lib/helpers'
import { DetailsButton, WordPressButton } from '@Components/Buttons'
import SiteImage from '@Components/SiteImage'
import { RestoreInProgress } from '@Components//RestoreInProgress'
import { SITE_STATUS } from '@Constants'

import Mockup from '@Images/mockups/screenshot.jpeg'
import { useTranslation } from 'react-i18next'

import './index.less'

const { Meta } = Card

const SiteCard = ({ site, loading }) => {
  const {
    siteId,
    name,
    liveDistribution,
    screenshot,
    runningStatus
  } = getFormattedSite(site)
  const { t } = useTranslation(['common'])

  return (
    <Card
      hoverable
      className='sl-site-card site-card'
      cover={<SiteImage screenshot={screenshot} />}
      loading={loading}
    >
      <Meta title={name} description={liveDistribution?.domainName} />
      {(site.status === SITE_STATUS.ACTIVE ||
        site.status === SITE_STATUS.UPDATING) && (
        <>
          <Row gutter='10'>
            <Col span='12'>
              <DetailsButton siteId={siteId} />
            </Col>
            <Col span='12'>
              <WordPressButton siteId={siteId} runningStatus={runningStatus} />
            </Col>
          </Row>
        </>
      )}
      {site.status === SITE_STATUS.RESTORING && (
        <>
          <Row gutter='10'>
            <Col span='12'>
              <DetailsButton siteId={siteId} />
            </Col>
            <Col span='12'>
              <RestoreInProgress t={t} />
            </Col>
          </Row>
        </>
      )}
      {site.status === SITE_STATUS.CREATING && (
        <div className='site-being-created-msg'>
          <Loading3QuartersOutlined spin /> &nbsp;&nbsp;{' '}
          {t('site_is_being_created')}
        </div>
      )}
    </Card>
  )
}

export const LoadingCard = () => (
  <Card
    className='sl-site-card site-card loading'
    loading
    cover={<img alt='example' src={Mockup} />}
  />
)

export default SiteCard
