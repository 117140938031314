import { useTranslation } from 'react-i18next'
import React from 'react'
import { Button, Tooltip } from 'antd'

import RuleModal from '../../RuleModal'

const AddButton = ({
  addons,
  disabled,
  onSuccess,
  setRuleModalVisible,
  visible,
  onCancel,
  rule,
  ruleType,
  canManageRules
}) => {
  const { t } = useTranslation(['common'])
  if (!canManageRules) {
    return (
      <Tooltip title={t('insufficent_permissions_rules_engine')}>
        <Button
          disabled
          className='sl-addRule'
          type='primary'
        >
          {t('add_rule')}
        </Button>
      </Tooltip>
    )
  }
  return (
    <>
      <Button
        className='sl-addRule'
        onClick={() => setRuleModalVisible(true)}
        disabled={disabled}
        type='primary'
        style={{
          borderColor: 'hsl(187, 79%, 48%)',
          background: 'hsl(187, 79%, 48%)'
        }}
      >
        {t('add_rule')}
      </Button>

      <RuleModal
        addons={addons}
        onSuccess={onSuccess}
        visible={visible}
        onCancel={() => onCancel()}
        rule={rule}
        ruleType={ruleType}
      />
    </>
  )
}

export default AddButton
