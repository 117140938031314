import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useLocalStorage from '@Hooks/useLocalStorage'

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  Modal
} from 'antd'

import { SITE_STATUS, SUBSCRIPTION_STATUS } from '@Constants'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useAccountDetails from '@Hooks/useAccountDetails'
import useAccountPermissions from '@Hooks/useAccountPermissions'
import DashboardFooter from '@Components/DashboardFooter'
import { Template } from '@Templates/NewSite'

import MigrateSiteNav from './MigrateSiteNav'
import { getWPSiteHealth } from '@Lib/api/sites'
import useWindowDimensions from '@Hooks/useWindowDimensions'

import { useUserContext } from '@Context/UserContext'
import { returnOnlySiteName } from '@Lib/helpers/sites'

import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'
import MigrateGuru from '@Images/migrate-guru-plugin.png'
import EmailInput from '@Images/enter-email-migrate-guru.png'
import FtpArrows from '@Images/ftp-arrows.png'

import {
  InfoCircleOutlined,
  LoadingOutlined,
  CheckCircleTwoTone
} from '@ant-design/icons'
import Success from '@Images/icons/success-tick.svg'

import DefinitionData from '@Components/DefinitionData'
import config from '@Lib/config'

import './index.less'

const { Text, Title, Link } = Typography
const { Item } = Form

const SftpPageButtons = ({ setCurrentStep, setMenuSelectedKey, t }) => {
  return (
    <>
      <br />
      <Space className='buttons'>
        <Button
          onClick={() => {
            setCurrentStep(3)
            setMenuSelectedKey('add-email')
          }}
          id='back-btn'
        >
          {t('Back')}
        </Button>
        <MigrationSuccessModal />
      </Space>
      <br />
      <Text type='secondary'>{t('get_detailed_migration_instruc')}</Text>{' '}
      <Link href='https://link.strattic.com/migration-guide/' target='_blank'>
        {t('here')}
      </Link>
    </>
  )
}

const MigrateSitePage = () => {
  const { t } = useTranslation(['common', 'errors'])
  const [accountId] = useLocalStorage('accountId')
  const accountDetails = useAccountDetails()
  const [currentStep, setCurrentStep] = useState(1)
  const [siteName, setSiteName] = useState('')
  const [newSite, setNewSite] = useState(null)
  const [siteRunningStatus, setSiteRunningStatus] = useState('stopped')
  const [formattedSiteData, setFormattedSiteData] = useState(false)
  const [menuSelectedKey, setMenuSelectedKey] = useState('name-site')
  const hasPermissionToAddSite = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SITE_CREATE
  )
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const accountSubStatus = accountDetails?.value?.subscriptionStatus
  const [contentPadding] = useState(51)
  const [topPaddingSftp, setTopPaddingSftp] = useState(135)
  const userContext = useUserContext()
  const topPadding = userContext.expirationBarPadding ? 50 : 0
  const topIndent = 76 + topPadding
  const { height, width } = useWindowDimensions()

  const minMaxRule = {
    pattern: /^.{3,45}$/,
    message: t('min_max')
  }

  // Submit new site
  const [
    { loading: getIsNewSiteLoading, error: getNewSiteError },
    submitNewSite
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(getNewSiteError)

  // Get site info
  const [
    { data: newSiteData, error: getSiteInfoError },
    getSiteInfo
  ] = useStratticApi({ url: `/sites/${newSite?.id}` }, { manual: true })
  useErrorMessage(getSiteInfoError)
  useEffect(() => {
    if (newSiteData) {
      setFormattedSiteData({
        stagingDomain: newSiteData?.result?.stagingDomain || null,
        httpBasicUser: newSiteData?.result?.httpBasicUser || null,
        httpBasicPassword: newSiteData?.result?.httpBasicPassword || null,
        sftpAddress: config.siteConnectionInfo?.sftpAddress,
        sftpPort: 22,
        sftpUsername: newSiteData?.result?.sftpUser || null,
        sftpPassword: newSiteData?.result?.sftpPassword || null
      })
    }
  }, [newSiteData])

  useEffect(() => {
    if (
      accountDetails?.[0]?.value?.sitesUsage?.current >=
      accountDetails?.[0]?.value?.sitesUsage?.max
    ) {
      setCurrentStep(0)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (newSite?.id) {
      checkSite()
    }
    // eslint-disable-next-line
  }, [newSite])

  useEffect(() => {
    if (currentStep === 4) {
      if (width < 1386) {
        setTopPaddingSftp(0)
      } else {
        setTopPaddingSftp(135)
      }
    }
  }, [currentStep, width])

  const addSite = async () => {
    const response = await submitNewSite({
      url: `accounts/${accountId}/sites`,
      method: 'POST',
      data: {
        displayName: siteName
      }
    })
    const newSiteResponse = response?.data?.result
    if (newSiteResponse) {
      setNewSite(newSiteResponse)
      setCurrentStep(2)
      setMenuSelectedKey('install-plugin')
      setSiteRunningStatus(newSiteResponse?.runningStatus)
    }
  }

  const startSite = async newSiteInfo => {
    try {
      const siteHealth = await getWPSiteHealth(newSiteInfo)
      if (siteHealth === 1) {
        // setNewSiteUrl(makeNewSiteUrl(newSiteInfo))
        setSiteRunningStatus(SITE_STATUS.RUNNING)
      } else {
        setTimeout(() => {
          startSite(newSiteInfo)
        }, 5000)
      }
    } catch (error) {
      setTimeout(() => {
        startSite(newSiteInfo)
      }, 5000)
    }
  }

  const checkSite = async () => {
    const response = await getSiteInfo({ url: `/sites/${newSite?.id}` })
    const newSiteInfo = response?.data?.result
    if (newSiteInfo?.runningStatus === SITE_STATUS.RUNNING) {
      startSite(newSiteInfo)
    } else {
      setSiteRunningStatus(newSiteInfo?.runningStatus)
      setTimeout(() => {
        checkSite()
      }, 2500)
    }
  }

  if (
    !hasPermissionToAddSite ||
    accountSubStatus === SUBSCRIPTION_STATUS.EXPIRED
  ) {
    return <Redirect to='/' />
  }

  return (
    <Template title={t('migrate_site')} classNames='migrate-site'>
      <table>
        <tbody>
          <tr>
            <td style={{ height: height - topIndent }} className='nav-col'>
              <Title>{t('migrate_site')}</Title>
              <MigrateSiteNav selectedKey={menuSelectedKey} />
            </td>
            <td className='content-col'>
              <div
                className='content'
                style={{ height: height - topIndent - contentPadding }}
              >
                {currentStep === 1 ? (
                  <section className='site-name'>
                    <Spin tip={t('loading')} spinning={getIsNewSiteLoading}>
                      <label htmlFor='site_name'>{t('name_your_site')}</label>
                      <br />
                      <br />
                      <Form onFinish={addSite}>
                        <Item
                          name='site_name'
                          rules={[
                            {
                              required: true,
                              message: t('field_required')
                            },
                            minMaxRule
                          ]}
                          validateTrigger='onChange'
                        >
                          <Input
                            className='sl-siteNameInput'
                            id='site_name'
                            placeholder={t('enter_site_name')}
                            aria-label={t('site_name')}
                            size='large'
                            onChange={event => {
                              setSiteName(
                                returnOnlySiteName(event.target.value)
                              )
                            }}
                          />
                        </Item>
                        <Space>
                          <Button
                            disabled={
                              siteName?.trim().length < 3 ||
                              siteName?.trim().length > 45
                                ? 'disabled'
                                : ''
                            }
                            onClick={() => addSite()}
                            type='primary'
                          >
                            {t('next')}
                          </Button>
                        </Space>
                      </Form>
                    </Spin>
                  </section>
                ) : currentStep === 2 ? (
                  <section
                    className='install-plugin'
                    style={{ width: width - 680 }}
                  >
                    <Row>
                      <Col lg={24} xl={6} className='col-1'>
                        <Text strong className='title'>
                          {t('install_plugin')}
                        </Text>
                        <br />
                        <br />
                        <Text type='secondary'>
                          {t('lets_do_this_install')}
                        </Text>{' '}
                        <Link
                          href='https://link.strattic.com/migrate-guru/'
                          target='_blank'
                        >
                          {t('migrate_guru_plugin')}
                        </Link>
                        <br />
                        <Text type='secondary'>{t('on_existing_site')}</Text>
                        <br />
                      </Col>
                      <Col lg={24} xl={8} offset={1} className='col-2'>
                        <SiteRunningStatus
                          siteRunningStatus={siteRunningStatus}
                        />
                        <br />
                        <img
                          src={MigrateGuru}
                          alt='loading...'
                          className='migrate-img'
                        />
                      </Col>
                    </Row>
                    <Space className='buttons'>
                      <Button href='/' id='back-btn'>
                        {t('Back')}
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          setCurrentStep(3)
                          setMenuSelectedKey('add-email')
                        }}
                      >
                        {t('next')}
                      </Button>
                    </Space>
                    <br />
                    <Text type='secondary'>
                      {t('get_detailed_migration_instruc')}
                    </Text>{' '}
                    <Link
                      href='https://link.strattic.com/migration-guide/'
                      target='_blank'
                    >
                      {t('here')}
                    </Link>
                  </section>
                ) : currentStep === 3 ? (
                  <section className='add-email' style={{ width: width - 680 }}>
                    <Row>
                      <Col lg={24} xl={6} className='col-1'>
                        <Text strong className='title'>
                          {t('add_your_email')}
                        </Text>
                        <br />
                        <br />
                        <Text type='secondary'>
                          {t('add_email_instructions')}
                        </Text>
                        <br />
                      </Col>
                      <Col lg={24} xl={8} offset={1} className='col-2'>
                        <SiteRunningStatus
                          siteRunningStatus={siteRunningStatus}
                        />
                        <br />
                        <img
                          src={EmailInput}
                          alt='loading...'
                          className='email-input-img'
                        />
                      </Col>
                    </Row>
                    <Space className='buttons'>
                      <Button
                        onClick={() => {
                          setCurrentStep(2)
                          setMenuSelectedKey('install-plugin')
                        }}
                        id='back-btn'
                      >
                        {t('Back')}
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          setCurrentStep(4)
                          setMenuSelectedKey('add-sftp')
                        }}
                      >
                        {t('next')}
                      </Button>
                    </Space>
                    <br />
                    <Row className='info'>
                      <Col>
                        <InfoCircleOutlined />
                      </Col>
                      <Col className='info-content'>
                        <Text type='secondary'>{t('ooops_hit_back')}</Text>
                        <br />
                        <br />
                        <Text type='secondary'>
                          {t('get_detailed_migration_instruc')}
                        </Text>{' '}
                        <Link
                          href='https://link.strattic.com/migration-guide/'
                          target='_blank'
                        >
                          {t('here')}
                        </Link>
                      </Col>
                    </Row>
                  </section>
                ) : currentStep === 4 ? (
                  <section
                    className='add-sftp'
                    style={{ width: width - 680, paddingTop: topPaddingSftp }}
                  >
                    <Row>
                      <Col lg={24} xl={6} className='col-1'>
                        <Text strong className='title'>
                          {t('add_sftp')}
                        </Text>
                        <br />
                        <div className='content'>
                          <Text type='secondary'>
                            {t('select_other_hosts')}
                          </Text>
                          <br />
                          <Text type='secondary'>
                            {t('on_the_next_screen')}
                          </Text>
                          <br />
                          <Text type='secondary'>
                            {t('fill_out_the_fields')}
                          </Text>
                        </div>
                        {width > 1386 && (
                          <SftpPageButtons
                            t={t}
                            setCurrentStep={setCurrentStep}
                            setMenuSelectedKey={setMenuSelectedKey}
                          />
                        )}
                      </Col>
                      <Col lg={24} xl={10} offset={1} className='col-2'>
                        <SiteRunningStatus
                          siteRunningStatus={siteRunningStatus}
                        />
                        <br />
                        <br />
                        <Card className='ftp-details'>
                          <img
                            src={FtpArrows}
                            alt='loading...'
                            className='ftp-arrows'
                          />
                          <br />
                          <Text className='definition-title'>
                            {t('destination_url')}
                          </Text>
                          <br />
                          <DefinitionData
                            value={`https://${formattedSiteData?.stagingDomain}`}
                          />
                          <Divider />
                          <Text className='definition-title'>
                            {t('sftp_credentials')}
                          </Text>
                          <br />
                          <DefinitionData
                            label='sftp_address_host'
                            value={config.siteConnectionInfo?.sftpAddress}
                          />
                          <DefinitionData
                            label='port'
                            value={formattedSiteData?.sftpPort || 22}
                          />
                          <DefinitionData
                            label='directory_path'
                            value={
                              formattedSiteData?.directoryPath ||
                              '/public_html/'
                            }
                          />
                          <DefinitionData
                            label='user_name'
                            value={formattedSiteData?.sftpUsername}
                          />
                          <DefinitionData
                            label='password'
                            value={formattedSiteData?.sftpPassword}
                            showHide
                          />
                          <Divider />
                          <Text className='definition-title'>
                            {t('http_basic_auth_credentials')}
                          </Text>
                          <br />
                          <DefinitionData
                            label='user_name'
                            value={formattedSiteData?.httpBasicUser}
                          />
                          <DefinitionData
                            label='password'
                            value={formattedSiteData?.httpBasicPassword}
                            showHide
                          />
                        </Card>
                      </Col>
                    </Row>
                    {width < 1387 && (
                      <SftpPageButtons
                        t={t}
                        setCurrentStep={setCurrentStep}
                        setMenuSelectedKey={setMenuSelectedKey}
                      />
                    )}
                  </section>
                ) : (
                  <Upgrade
                    t={t}
                    accountId={accountId}
                    hasPermissionToUpgrade={hasPermissionToUpgrade}
                  />
                )}
              </div>

              <DashboardFooter />
            </td>
          </tr>
        </tbody>
      </table>
    </Template>
  )
}

const Upgrade = ({ t, accountId, hasPermissionToUpgrade }) => {
  return (
    <>
      <p>{t('exceeded_site_limit')}</p>
      {hasPermissionToUpgrade ? (
        <p>
          <Button className='sl-upgradeYourPlan' type='primary'>
            <Link to={`/account/${accountId}/available-plans`}>
              {t('upgrade_here')}
            </Link>
          </Button>
        </p>
      ) : (
        t('site_limit_contact_owner')
      )}
    </>
  )
}

const MigrationSuccessModal = () => {
  const { t } = useTranslation(['common'])
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button type='primary' onClick={() => setOpen(true)}>
        {t('done')}
      </Button>
      <Modal
        open={open}
        closable={false}
        width={825}
        className='migration-confirm-modal'
        footer={[
          <Button key='back' href='/' className='back-to-dash-btn'>
            {t('go_to_dash')}
          </Button>
        ]}
      >
        <Success />
        <div className='modal-text'>
          <Title level={4}>{t('job_well_done')}</Title>
          <Text type='secondary'>{t('migration_success_line_one')}</Text>
          <br />
          <Text type='secondary'>{t('migration_success_line_two')}</Text>
          <br />
        </div>
      </Modal>
    </>
  )
}

const SiteRunningStatus = ({ siteRunningStatus }) => {
  const { t } = useTranslation(['common', 'errors'])
  if (siteRunningStatus !== SITE_STATUS.RUNNING) {
    return (
      <Text className='container-spinner' type='secondary'>
        <LoadingOutlined />
        {`${t('dedicated_server')}${t('spinning_up')}`}
      </Text>
    )
  } else {
    return (
      <Text className='container-spinner' type='secondary'>
        <CheckCircleTwoTone twoToneColor='#19c3da' />
        {`${t('dedicated_server')}${t('up_and_ready')}`}
      </Text>
    )
  }
}

export default MigrateSitePage
