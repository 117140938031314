import React, { useState } from 'react'

import { Button, Modal, Typography, Form, Select, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@Context/UserContext'
import { submitHubspotForms } from '@Lib/api/hubspot'
import { getDate } from '@Lib/dates'
import config from '@Lib/config'

import './index.less'

const { Title, Text, Link } = Typography
const { Option } = Select

const ExtendTrialModal = ({ canViewUpgrade, daysLeft }) => {
  const { t } = useTranslation(['common', 'errors'])
  const userContext = useUserContext()
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [specify, setSpecify] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const accountName = userContext.accountDetails?.accountName
  const accountId = userContext.accountId
  const subscriptionStartDate = userContext.accountDetails?.planStartDate
  const show = () => window.Kustomer?.createConversation()

  const onFinish = async values => {
    setSubmitting(true)
    const hubspotFields = {
      ...values,
      strattic_account_id: accountId,
      email: userContext.currentUser?.email,
      trial_start_date: getDate(subscriptionStartDate)
    }
    try {
      const response = await submitHubspotForms(
        config?.hubspot?.extendTrialForm,
        hubspotFields
      )
      message.success(response?.data?.inlineMessage)
      setSubmitting(false)
      onClose()
    } catch {
      message.error('Something went wrong')
      setSubmitting(false)
    }
  }

  const onSelectChange = val => {
    if (val === 'other') {
      setSpecify(true)
    } else {
      setSpecify(false)
    }
  }

  const onClose = () => {
    setOpen(false)
    form.resetFields()
    setSpecify(false)
  }
  if (daysLeft >= 5) return null
  return (
    <>
      <Button type='link' onClick={() => setOpen(true)}>
        {t('i_need_more_time')}
      </Button>
      <Modal
        destroyOnClose
        open={open}
        width={825}
        className='extend-trial-modal'
        footer={false}
        onCancel={onClose}
      >
        <Form name='extend-trial-form' onFinish={onFinish} form={form}>
          <Title level={4} style={{ textTransform: 'capitalize' }}>
            {userContext.accountDetails?.planName}
          </Title>
          {t('trial_period_for', { accountName })}
          <Title level={4}>{getDate(subscriptionStartDate)}</Title>
          <Text type='secondary'>{t('during_this_period')}</Text>
          <Form.Item
            name='extension_reason'
            rules={[
              {
                required: true,
                message: t('field_required')
              }
            ]}
          >
            <Select
              placeholder={t('let_us_know_why_extend')}
              onChange={onSelectChange}
            >
              <Option value='time_to_eval'>{t('time_to_eval')}</Option>
              <Option value='time_to_comple'>{t('time_to_comple')}</Option>
              <Option value='comparing_to_others'>
                {t('comparing_to_others')}
              </Option>
              <Option value='lack_of_urgency'>{t('lack_of_urgency')}</Option>
              <Option value='other'>{t('other')}</Option>
            </Select>
          </Form.Item>
          {specify && (
            <Form.Item
              name='extention_reason_other'
              rules={[
                {
                  required: true,
                  message: t('field_required')
                }
              ]}
            >
              <Input.TextArea
                placeholder={t('please_explain_extension')}
                id='explain-please'
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button htmlType='submit' id='extend-trial' loading={submitting}>
              {t('extend_trial')}
            </Button>
          </Form.Item>
          <Form.Item className='upgrade-section'>
            <Title level={5}>{t('ready_to_upgrade')}</Title>
            {canViewUpgrade && (
              <Text type='secondary'>
                {t('upgrading_is_easy')} {t('our_plans_start')}
              </Text>
            )}
            {!canViewUpgrade && (
              <Text type='secondary'>
                {t('upgrade_trial_account_contact_owner')}
              </Text>
            )}
            <br />
            <br />
            <Link onClick={() => show()}>{t('were_here')}</Link>
            <Text type='secondary'>{t('if_you_have_q')}</Text>
          </Form.Item>
          {canViewUpgrade && (
            <Button
              disabled={submitting}
              type='primary'
              href={`/account/${accountId}/available-plans`}
              id='upgrade'
            >
              {t('upgrade_now')}
            </Button>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default ExtendTrialModal
