import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, Input, Row, Col, Form, message } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useAccountDetails from '@Hooks/useAccountDetails'
import useErrorMessage from '@Hooks/useErrorMessage'

const { Text } = Typography

const BillingEmail = ({ canUpdate }) => {
  const { t } = useTranslation(['common', 'errors'])
  const [{ value: accountDetails }, setAccountDetails] = useAccountDetails()
  const [isEditing, setIsEditing] = useState(false)
  const [email, setEmail] = useState()

  const path = `accounts/${accountDetails?.accountId}`
  const [
    { loading: isApiLoading, error: apiError },
    executePut
  ] = useStratticApi(
    {
      url: path,
      method: 'PUT'
    },
    { manual: true }
  )

  useErrorMessage(apiError)

  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        isEditing
      ) {
        cancelEditing()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, isEditing])

  useEffect(() => {
    setEmail(accountDetails?.contactEmail)
    // eslint-disable-next-line
  }, [accountDetails?.contactEmail])

  const startEditing = () => {
    setIsEditing(true)
  }

  const cancelEditing = () => {
    setIsEditing(false)
  }

  const onFinish = async values => {
    const result = await executePut({ data: { contactEmail: values.email } })
    if (result) {
      message.success(t('billing_email_update_success'))
      setEmail(values.email)
      accountDetails.contactEmail = email
      setAccountDetails(accountDetails)
      cancelEditing()
    }
  }

  return (
    <div ref={wrapperRef}>
      {isEditing ? (
        <EditForm
          t={t}
          email={email}
          isApiLoading={isApiLoading}
          onFinish={onFinish}
        />
      ) : (
        <Data
          t={t}
          email={email}
          canUpdate={canUpdate}
          startEditing={startEditing}
        />
      )}
    </div>
  )
}

const EditForm = ({ onFinish, email, isApiLoading, t }) => (
  <Form layout='inline' onFinish={onFinish}>
    <Input.Group>
      <Row>
        <Col>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: t('please_enter_valid_email')
              },
              {
                type: 'email',
                message: t('please_enter_valid_email')
              }
            ]}
            initialValue={email}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Button
            type='default'
            loading={isApiLoading}
            block
            htmlType='submit'
            style={{ margin: 0, minWidth: 'auto' }}
            className='submit-billing-email'
          >
            <SendOutlined />
          </Button>
        </Col>
      </Row>
    </Input.Group>
  </Form>
)
const Data = ({ t, email, canUpdate, startEditing }) => (
  <Text type='secondary'>
    {email}
    {email && canUpdate && (
      <Button type='link' className='edit-link' onClick={startEditing}>
        {t('edit')}
      </Button>
    )}
  </Text>
)

export default BillingEmail
