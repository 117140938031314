import { useParams } from 'react-router-dom'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

const ApiFactory = distributionType => {
  return {
    useGetRules: () => {
      const { siteId } = useParams()
      const [{ data, loading, error }, getRules] = useStratticApi(
        {
          method: 'GET'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: ruleType => {
          return getRules({
            url: `sites/${siteId}/ruleset?ruleType=${ruleType}&distributionType=${distributionType}`
          })
        }
      }
    },
    useDeployRuleset: () => {
      const { siteId } = useParams()
      const [{ data, loading, error }, deployRuleset] = useStratticApi(
        {
          method: 'POST'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: ruleset => {
          return deployRuleset({
            url: `sites/${siteId}/ruleset/${ruleset.id}/deploy?optimisticLockVersion=${ruleset.optimisticLockVersion}`
          })
        }
      }
    },
    useRevertChanges: () => {
      const { siteId } = useParams()
      const [{ data, loading, error }, revertChanges] = useStratticApi(
        {
          method: 'POST'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: ruleset => {
          return revertChanges({
            url: `sites/${siteId}/ruleset/${ruleset.id}/revert-changes?optimisticLockVersion=${ruleset.optimisticLockVersion}`
          })
        }
      }
    },
    useCreateRuleset: () => {
      const { siteId } = useParams()
      const [{ data, loading, error }, createRuleset] = useStratticApi(
        {
          url: `sites/${siteId}/ruleset`,
          method: 'POST'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: ruleType => {
          return createRuleset({
            data: { distributionType, ruleType }
          })
        }
      }
    },
    useDeleteRule: () => {
      const { siteId } = useParams()
      const [{ data, loading, error }, deleteRule] = useStratticApi(
        {
          method: 'DELETE'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: (ruleset, ruleId) => {
          return deleteRule({
            url: `sites/${siteId}/ruleset/${ruleset.id}/rule/${ruleId}?optimisticLockVersion=${ruleset.optimisticLockVersion}`
          })
        }
      }
    },
    useReorderRuleset: () => {
      const { siteId } = useParams()
      const [{ data, loading, error }, reorderRuleset] = useStratticApi(
        {
          method: 'POST'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: (ruleset, ruleId, newIndex) => {
          return reorderRuleset({
            url: `sites/${siteId}/ruleset/${ruleset.id}/reorder`,
            data: { ruleId, newIndex }
          })
        }
      }
    },
    useImportRuleset: () => {
      const { siteId } = useParams()
      const [{ data, loading, error }, importRuleset] = useStratticApi(
        {
          method: 'POST'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: (ruleset, data) => {
          return importRuleset({
            url: `sites/${siteId}/ruleset/${ruleset.id}/import?optimisticLockVersion=${ruleset.optimisticLockVersion}`,
            data
          })
        }
      }
    },
    useAddRule: () => {
      const { siteId } = useParams()

      const [{ data, loading, error }, addRule] = useStratticApi(
        {
          method: 'POST'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: (ruleset, data) => {
          return addRule({
            url: `sites/${siteId}/ruleset/${ruleset.id}/rule?optimisticLockVersion=${ruleset.optimisticLockVersion}`,
            data
          })
        }
      }
    },
    useUpdateRule: () => {
      const { siteId } = useParams()

      const [{ data, loading, error }, updateRule] = useStratticApi(
        {
          method: 'PUT'
        },
        { manual: true }
      )
      useErrorMessage(error)

      return {
        data,
        loading,
        error,
        doCall: (ruleset, rule, data) => {
          return updateRule({
            url: `sites/${siteId}/ruleset/${ruleset.id}/rule/${rule.id}?optimisticLockVersion=${ruleset.optimisticLockVersion}`,
            data
          })
        }
      }
    }
  }
}

export default ApiFactory
