import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Col, Typography, Card, Skeleton } from 'antd'
import { useUserContext } from '@Context/UserContext'
import Session from '@Lib/session'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import useAccountPermissions from '@Hooks/useAccountPermissions'
import { Template } from '@Templates/FullWidth'
import PlanFeatureList from '@Components/PlanFeatureList'
import PlanCard from '@Components/PlanCard'

import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'
import FutureSubNotice from '@Components/FutureSubNotice'

import './index.less'

const { Title, Text, Link } = Typography

const PlansPage = () => {
  const { t } = useTranslation(['common', 'errors'])
  const userContext = useUserContext()
  const [isValidatingPlan, setIsValidatingPlan] = useState(false)
  const [showFullComparison] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [numberAvailPlans, setNumberAvailPlans] = useState(null)
  const [futureSubscription, setFutureSubscription] = useState(
    userContext?.accountDetails?.futureSubscription
  )
  const history = useHistory()
  const { accountId } = useParams()
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const [getPlansPath] = useState(`accounts/${accountId}/available-plans`)
  const [
    {
      data: getPlanData,
      loading: isGetPlanDataLoading,
      error: getPlanDataError
    }
  ] = useStratticApi(getPlansPath)

  useErrorMessage(getPlanDataError)

  const getCanAddMemberCheckPath = `accounts/${accountId}/permissions/create-invitation`
  const [
    {
      data: getCanAddMember,
      loading: isGetCanAddMemberLoading,
      error: getCanAddMemberError
    }
  ] = useStratticApi({ url: getCanAddMemberCheckPath })

  useErrorMessage(getCanAddMemberError)

  // fixed enough to load the page but requires reworking
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plans = getPlanData?.result || []
        // if the thrid plan is null remove it from array
        const filterPlansArr = plans?.filter(plans => {
          return plans !== null
        })
        setNumberAvailPlans(filterPlansArr.length)
        userContext.setPlans(filterPlansArr)
      } catch (error) {}
    }

    fetchPlans()

    // eslint-disable-next-line
  }, [getPlanData])
  // canManageSubscriptions else redirect to be added
  useEffect(() => {
    if (parseInt(accountId, 0) !== Session.get('accountDetails')?.accountId) {
      history.push('/account')
    }
    if (!hasPermissionToUpgrade) {
      history.push('/')
    }
  }, [history, accountId, userContext, hasPermissionToUpgrade])

  return (
    <Template title={t('plans')} showTitle={false}>
      {/* <CustomPlanContactForm
        visible={isModalVisible}
        setIsModalVisible={() => {
          setIsModalVisible(false)
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        // company={accountDetails?.accountName}
      /> */}
      {futureSubscription && (
        <FutureSubNotice
          futureSubscription={futureSubscription}
          setFutureSubscription={setFutureSubscription}
          currentAccount={userContext?.accountDetails}
          refreshSession={userContext?.login}
          planPageMessage
        />
      )}

      <Row gutter={[20, 20]} style={{ minWidth: 1100 }}>
        <Col span={6} key='plans-overview' className='plans-overview'>
          <div
            className={`plan-page-title ${
              numberAvailPlans === 3 ? 'custom-top' : null
            }`}
          >
            <Title>{t('select_new_plan')}</Title>
            <Text className='select-plan-description'>
              {t('select_new_plan_description')}
            </Text>
          </div>

          <PlanFeatureList
            classNames='label-only'
            showFullComparison={showFullComparison}
          />
          <p className='full-comparison'>
            <Link
              href='https://www.strattic.com/pricing/#feature-comparison'
              target='_blank'
              rel='noreferrer'
            >
              {t('see_full_comparison')}
            </Link>
          </p>

          {/* Functionality for v2.1
          <Button
            onClick={toggleFullComparison}
            id='toggle-full-comparison'
            type='link'
          >
            {showFullComparison
              ? t('hide_full_comparison')
              : t('see_full_comparison')}
          </Button> */}
        </Col>
        <Col sm={18} key='plans-listing-wrapper'>
          <Row gutter={[16, 16]}>
            {userContext.plans && !isGetCanAddMemberLoading ? (
              userContext.plans.map(plan => (
                <Col sm={12} key={plan.id}>
                  <PlanCard
                    isModalVisible={isModalVisible}
                    setIsModalVisible={value => setIsModalVisible(value)}
                    plan={plan}
                    isAnnual={false}
                    showFullComparison={showFullComparison}
                    classNames='icons-only'
                    isValidatingPlan={isValidatingPlan}
                    setIsValidatingPlan={setIsValidatingPlan}
                    hasFutureSubscription={!!futureSubscription}
                    currentUsersOnAcc={getCanAddMember?.result?.current}
                  />
                </Col>
              ))
            ) : (
              <Col span={24}>
                <Card>
                  <Skeleton
                    loading={isGetPlanDataLoading || isGetCanAddMemberLoading}
                    active
                    paragraph={{ rows: 25 }}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Template>
  )
}

export default PlansPage
