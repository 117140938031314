import React from 'react'
import { Redirect } from 'react-router-dom'

import useCurrentSite from '@Hooks/useCurrentSite'
import SiteRestoreFullPage from '@Components/SiteRestoreFullPage'
import { SITE_STATUS } from '@Constants'

const RestoringPage = () => {
  const [site] = useCurrentSite()
  if (!site?.value) return 'Loading...'
  if (site?.value?.status !== SITE_STATUS.RESTORING) {
    return <Redirect to={`/site/${site?.value?.siteId}`} />
  }
  return <SiteRestoreFullPage />
}

export default RestoringPage
