import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Auth } from 'aws-amplify'
import useErrorMessage from '@Hooks/useErrorMessage'

import { Button, Modal, Form, Input, Typography, message } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

import PasswordStrengthTooltip from '@Components/PasswordStrengthTooltip'

import './index.less'

const { Text } = Typography

const ResetPasswordForm = ({ visible, onCreate, onCancel, t, loading }) => {
  const [password, setPassword] = useState('')
  const [form] = Form.useForm()
  // antd takes the message as the key and a none unique error message throws console error
  const passwdMinLengthRule = {
    pattern: /^.{8,}$/,
    message: ''
  }

  const passwdContainsLowercaseRule = {
    pattern: /^.*[a-z].*$/,
    message: ' '
  }

  const passwdContainsUppercaseRule = {
    pattern: /^.*[A-Z].*$/,
    message: '  '
  }

  const passwdContainsNumberRule = {
    pattern: /^.*[0-9].*$/,
    message: '   '
  }
  const handleOk = () => {
    form.validateFields().then(values => {
      onCreate(values)
    })
  }
  return (
    <Modal
      open={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      title={<Text strong>{t('update_password')}</Text>}
      className='reset-password-modal'
      onOk={handleOk}
      footer={[
        <Button
          key='submit'
          onClick={handleOk}
          className='reset-password sl-ok'
          loading={loading}
        >
          {t('update_password')}
        </Button>,
        <Button
          key='back'
          onClick={() => {
            onCancel()
            form.resetFields()
          }}
          className='reset-password-cancel sl-cancel'
          disabled={loading}
        >
          {t('cancel')}
        </Button>
      ]}
    >
      <>
        {/* <Title level={4}></Title> */}
        <Text>{t('change_password_text')}</Text>
        <Form
          form={form}
          layout='vertical'
          name='form_in_modal'
          initialValues={{
            modifier: 'public'
          }}
          style={{ paddingTop: 24 }}
        >
          <Form.Item
            name='oldPassword'
            rules={[
              {
                required: true,
                message: t('password_please')
              },
              passwdMinLengthRule,
              passwdContainsLowercaseRule,
              passwdContainsUppercaseRule,
              passwdContainsNumberRule
            ]}
          >
            <Input.Password
              placeholder={t('current_password')}
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <PasswordStrengthTooltip password={password}>
            <Form.Item
              name='newPassword'
              rules={[
                {
                  required: true,
                  message: t('password_please')
                },
                passwdMinLengthRule,
                passwdContainsLowercaseRule,
                passwdContainsUppercaseRule,
                passwdContainsNumberRule
              ]}
            >
              <Input.Password
                placeholder={t('new_password')}
                onChange={e => setPassword(e.target.value)}
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
          </PasswordStrengthTooltip>
          <Form.Item
            name='confirmPassword'
            rules={[
              {
                required: true,
                message: t('password_confirm_please')
              },
              passwdMinLengthRule,
              passwdContainsLowercaseRule,
              passwdContainsUppercaseRule,
              passwdContainsNumberRule,
              ({ getFieldValue }) => ({
                validator (_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('passwords_dont_match')))
                }
              })
            ]}
          >
            <Input.Password
              placeholder={t('confirm_password')}
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </Form>
      </>
    </Modal>
  )
}

const ResetPassword = () => {
  const { t } = useTranslation(['common'])
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useErrorMessage(error)

  const onCreate = async values => {
    setLoading(true)
    try {
      const oldPassword = values.oldPassword
      const newPassword = values.newPassword
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(currentUser, oldPassword, newPassword)
      setLoading(false)
      setVisible(false)
      message.success(t('Your_password_updated'))
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  return (
    <>
      <ResetPasswordForm
        t={t}
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false)
        }}
        loading={loading}
      />
      <Button
        type='link'
        className='edit-link'
        onClick={() => {
          setVisible(true)
        }}
      >
        {t('update')}
      </Button>
    </>
  )
}

export default ResetPassword
