import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Row } from 'antd'
import Logo from '@Images/logos/strattic-logo-color.svg'
import LoginCircle from '@Images/login-circle.svg'

import './index.less'

export const Col1 = ({ children }) => (
  <Col className='col-1' xs={24} lg={12}>
    <Logo className='logo' />
    {children}
  </Col>
)

export const Col2 = ({ featuredImage, children }) => (
  <Col className='col-2' xs={0} sm={0} md={0} lg={12}>
    <LoginCircle className='icon' />
    <img
      className='feature'
      src={featuredImage}
      alt='Illustrated screenshot of the Strattic admin interface'
    />
    {children}
  </Col>
)

export const Template = ({ title, children, classNames }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Row className={`login-row ${classNames}`}>{children}</Row>
    </>
  )
}
