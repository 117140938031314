import React, { useState, useEffect, useRef } from 'react'

import { Col, Row } from 'antd'

import { isDnsActive } from '@Lib/api/sites'
import { DNS_STATUS } from '@Constants'

import DefinitionHeader from '@Components/DefinitionHeader'
import DefinitionData from '@Components/DefinitionData'

import './index.less'

const DnsData = ({ record, final, setParentStatus, ssl, rootDomain }) => {
  const [dnsStatus, setDnsStatus] = useState()
  const [dnsValue, setDnsValue] = useState()

  const timer = useRef(null)

  const checkDNS = async () => {
    const results = await isDnsActive(record?.name, record?.type)
    const answer = results?.Answer?.find(i => i.data === dnsValue)
    // not all parent components provide setParentStatus hence the check
    if (answer) {
      setDnsStatus(DNS_STATUS.ACTIVE)
      setParentStatus && setParentStatus(DNS_STATUS.ACTIVE)
    } else {
      setDnsStatus(DNS_STATUS.PENDING)
      setParentStatus && setParentStatus(DNS_STATUS.PENDING)
    }

    timer.current = setTimeout(() => {
      checkDNS()
    }, 3 * 60 * 1000)
  }

  useEffect(() => {
    if (record?.type === 'CNAME') {
      setDnsValue(`${record?.value}.`)
    } else {
      setDnsValue(record?.value)
    }
  }, [record])

  useEffect(() => {
    if (record && !ssl && !rootDomain) {
      checkDNS()
    }
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [record, ssl])

  const removePeriodFromEnd = val => {
    if (val.endsWith('.')) {
      return val.slice(0, -1)
    }
    return val
  }
  return (
    <section className={dnsStatus}>
      <dl>
        <Row>
          <Col span={5}>
            <DefinitionHeader label='type' />
          </Col>
          <Col span={17}>
            <strong>
              <DefinitionData value={record?.type} showCopy={false} />
            </strong>
          </Col>
        </Row>
      </dl>
      <dl>
        <Row>
          <Col span={5}>
            <DefinitionHeader label='name_host' />
          </Col>
          <Col span={17}>
            <DefinitionData
              value={removePeriodFromEnd(record?.name)}
              dnsStatus={final && dnsStatus}
              rootDomain={rootDomain}
              ssl={ssl}
            />
          </Col>
        </Row>
      </dl>
      <dl>
        <Row>
          <Col span={5}>
            <DefinitionHeader label='value_answer' />
          </Col>
          <Col span={17}>
            <DefinitionData value={removePeriodFromEnd(record?.value)} />
          </Col>
        </Row>
      </dl>
    </section>
  )
}
export default React.memo(DnsData)
