import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Modal, Button, Form, Input, Select, Skeleton, Typography } from 'antd'

import { ROLE_TYPE, MODAL_WIDTH } from '@Constants'
import TeamRoleTooltip from '@Components/TeamRoleTooltip'
import useLocalStorage from '@Hooks/useLocalStorage'
import { useUserContext } from '@Context/UserContext'

import {
  roleTypeByAccount,
  hasHigherRoleHierarchy
} from '@Lib/helpers/permissionsHelpers'

const { Item } = Form
const { Option } = Select
const { Text } = Typography

const ownerOrAdmin = [ROLE_TYPE.OWNER, ROLE_TYPE.ADMIN]

const EditTeamMemberForm = ({
  visible,
  onCreate,
  onCancel,
  sites,
  loading,
  record
}) => {
  const { t } = useTranslation(['common'])
  const [selectedSites, setSelectedSites] = useState([])
  const [roleType, setRoleType] = useState()
  const [allSites, setAllSites] = useState(0)
  const [form] = Form.useForm()

  const userContext = useUserContext()
  const currentAccountId = userContext?.accountId

  const [currentUser] = useLocalStorage('currentUser')
  const [currentRole] = useState(
    roleTypeByAccount(currentUser, currentAccountId)
  )

  useEffect(() => {
    setRoleType(record?.roleType)
    const initialValues = Array.isArray(record?.permissions)
      ? record?.permissions?.map(i => i.id?.toString())
      : ['all']
    setSelectedSites(initialValues)
  }, [record])

  const handleSitesSelection = value => {
    // if  'all' is selected set the display in the dropdown to show only all sites
    // in the option box
    if (!value || value.length === 0) {
      setSelectedSites(value)
    } else if (value.includes('all')) {
      form.setFieldsValue({ permissions: ['all'] })
      setSelectedSites(['all'])
      setAllSites(1)
    }
  }

  const handleCancel = () => {
    onCancel()
    form.resetFields()
  }

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        let payload
        if (allSites === 1) {
          delete values.permissions
          payload = { ...values, allSites }
        } else {
          values.permissions = values.permissions.map(siteId => {
            return { siteId: parseInt(siteId, 10), roleType }
          })
          payload = { ...values, allSites }
        }
        onCreate(payload)
        form.resetFields()
      })
      .catch(info => {
        console.error('Validate Failed:', info)
      })
  }

  return (
    <Modal
      open={visible}
      title={<Text strong>{t('edit_team_member')}</Text>}
      onCancel={handleCancel}
      onOk={handleOk}
      className='add-member-modal'
      width={MODAL_WIDTH}
      footer={[
        <Button
          key='submit'
          onClick={handleOk}
          className='add-member-ok'
          loading={loading}
        >
          {t('save')}
        </Button>,
        <Button key='back' onClick={handleCancel} className='add-member-cancel'>
          {t('cancel')}
        </Button>
      ]}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <Form
          form={form}
          requiredMark={false}
          size='large'
          labelCol={{
            xs: {
              span: 8
            }
          }}
          wrapperCol={{
            xs: {
              span: 16
            }
          }}
          name='edit-team-member'
          initialValues={{
            email: record?.userEmail,
            roleType: record.roleType,
            permissions: selectedSites
          }}
        >
          <Item
            name='email'
            labelAlign='left'
            label={<Text strong>{t('email')}</Text>}
            rules={[
              {
                required: true,
                message: t('required')
              }
            ]}
          >
            <Input
              disabled
              autoComplete='username'
              className='sl-team-email-input'
              aria-label={t('email')}
            />
          </Item>
          <Item
            name='roleType'
            labelAlign='left'
            label={<Text strong>{t('role')}</Text>}
            rules={[
              {
                required: true,
                message: t('required')
              }
            ]}
          >
            <Select
              onChange={roleType => {
                setRoleType(roleType)
                if (ownerOrAdmin.includes(roleType)) {
                  form.setFieldsValue({ permissions: ['all'] })
                  setAllSites(1)
                } else {
                  form.setFieldsValue({ permissions: [] })
                  setSelectedSites([])
                  setAllSites(0)
                }
              }}
              dropdownStyle={{ textTransform: 'capitalize' }}
              style={{ textTransform: 'capitalize' }}
              allowClear
            >
              {!hasHigherRoleHierarchy(ROLE_TYPE.OWNER, currentRole) && (
                <Option value={ROLE_TYPE.OWNER}>
                  <TeamRoleTooltip roleType={ROLE_TYPE.OWNER} />
                </Option>
              )}

              {!hasHigherRoleHierarchy(ROLE_TYPE.ADMIN, currentRole) && (
                <Option value={ROLE_TYPE.ADMIN}>
                  <TeamRoleTooltip roleType={ROLE_TYPE.ADMIN} />
                </Option>
              )}

              {!hasHigherRoleHierarchy(ROLE_TYPE.MANAGER, currentRole) && (
                <Option value={ROLE_TYPE.MANAGER}>
                  <TeamRoleTooltip roleType={ROLE_TYPE.MANAGER} />
                </Option>
              )}

              <Option value={ROLE_TYPE.COLLABORATOR}>
                <TeamRoleTooltip roleType={ROLE_TYPE.COLLABORATOR} />
              </Option>
            </Select>
          </Item>
          <Item
            name='permissions'
            labelAlign='left'
            label={<Text strong>{t('site_access')}</Text>}
            rules={[
              {
                required: true,
                message: t('required')
              }
            ]}
          >
            <Select
              mode='multiple'
              allowClear
              showArrow
              onChange={handleSitesSelection}
              disabled={ownerOrAdmin.includes(roleType)}
            >
              <Option value='all'>{t('all_sites')}</Option>
              {selectedSites.includes('all') ||
                sites?.value?.map(site => (
                  <Option key={site.id}>{site.displayName}</Option>
                ))}
            </Select>
          </Item>
        </Form>
      )}
    </Modal>
  )
}

export default EditTeamMemberForm
