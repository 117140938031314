import axios from 'axios'
import { ApiError } from '@Packages/StratticApi/error'

export const getWPSiteHealth = async site => {
  try {
    const isSiteUp = await axios.get(
      `https://${site?.stagingDomain}/health.php`,
      {},
      {
        auth: {
          username: site?.httpBasicUser,
          password: site?.httpBasicPassword
        }
      }
    )
    return isSiteUp?.data
  } catch (error) {
    throw new ApiError(error?.message, error?.code, error?.status)
  }
}

export const isDnsActive = async (name, type) => {
  try {
    const dnsCheck = await axios.get(
      `https://dns.google/resolve?name=${name}&type=${type}`
    )
    return dnsCheck.data
  } catch (error) {
    throw new ApiError(error?.message, error?.code, error?.status)
  }
}

export const sanitiseDnsAnswers = data => {
  const questions = data?.Question
  const answers = data?.Answer
  const questionArr = []
  questions.forEach(element => {
    if (!questionArr.includes(element.type)) {
      return questionArr.push(element.type)
    }
    return questionArr
  })
  let answersArr = []
  // take type id from question and filter answer for matching type
  if (answers) {
    questionArr.forEach(query => {
      answersArr = [
        ...answersArr,
        ...answers?.filter(answer => answer.type === query)
      ]
    })
  }
  return answersArr
}

export const latestWpVersion = async () => {
  try {
    const wpVersions = await axios.get(
      'https://api.wordpress.org/core/version-check/1.7/'
    )
    return wpVersions?.data?.offers?.[0]?.version
  } catch (error) {
    throw new ApiError(error?.message, error?.code, error?.status)
  }
}
