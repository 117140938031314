import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { Button, Card, Table, Pagination, Typography } from 'antd'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useCurrentSite from '@Hooks/useCurrentSite'
import { permissionGranted } from '@Lib/helpers/permissionsHelpers'
import { useMessageContext } from '@Context/MessageContext'
import {
  convertOrderByToAnt,
  getFormattedBackupHistory,
  downloadResponse
} from '@Lib/helpers'
import { SITE_PERMISSIONS, MAX_BACKUPS } from '@Constants/permissionsConstants'
import { BACKUP_STATUSES, TRIGGERED_BY } from '@Constants/siteConstants'
import AnalyticsManager from '@Managers/analyticsManager'

import { Template } from '@Templates/Site'
import TriggerBackup from '@Components/TriggerBackups'
import DeleteBackupModal from '@Components/DeleteBackupModal'
import DatabaseIcon from '@Images/icons/db.png'

import {
  CheckOutlined,
  LoadingOutlined,
  CloseOutlined,
  CheckSquareFilled,
  BorderOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { upperFirst } from '@Lib/helpers/text'
import TriggerRestore from '@Components/TriggerRestore'
import { FORM_STATUS, SORT_ORDER, SITE_STATUS } from '@Constants'

import './index.less'

const { Link, Text } = Typography

const defaultSortBy = 'id'
const defaultOrderBy = SORT_ORDER.DESC

const useQuery = () => new URLSearchParams(useLocation().search)

const SiteBackupHistoryPage = () => {
  const { t } = useTranslation(['common'])
  const messageContext = useMessageContext()
  const { siteId } = useParams()
  const query = useQuery()
  const timer = useRef(null)

  const [initiallyLoading, setInitiallyLoading] = useState(true)
  const [path] = useState(`sites/${siteId}/backups`)

  const DEFAULT_PAGE_SIZE = 10

  const [params, setParams] = useState({
    page: parseInt(query.get('page')) || 1,
    byColumn: query.get('sortBy') || defaultSortBy,
    order: query.get('orderBy') || defaultOrderBy,
    records: parseInt(query.get('records')) || DEFAULT_PAGE_SIZE
  })

  const [
    { data: getData, loading: getIsLoading, error: getError }
  ] = useStratticApi({ url: path, params })
  useErrorMessage(getError)

  const [{ error: deleteError }, deleteBackup] = useStratticApi(
    {},
    { manual: true }
  )
  useErrorMessage(deleteError)

  const [{ error: getBackupUrlError }, getBackupUrl] = useStratticApi(
    {},
    { manual: true }
  )
  useErrorMessage(getBackupUrlError)

  const [backupHistory, setBackupHistory] = useState()

  const [isBackupsAllowed, setIsBackupsAllowed] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedForRestore, setSelectedForRestore] = useState([])
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [currentSite, refetchCurrentSite] = useCurrentSite()
  const [siteStatus, setSiteStatus] = useState(currentSite?.value?.status)
  // permissions
  const [
    hasPermissionToDeleteBackup,
    setHasPermissionToDeleteBackup
  ] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_BACKUP_DELETE
    )
  )
  const [
    hasPermissionToTriggerBackup,
    setHasPermissionToTriggerBackup
  ] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_BACKUP_TRIGGER
    )
  )
  const [
    hasPermissionToDownloadBackup,
    setHasPermissionToDownloadBackup
  ] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_VIEW_BACKUP_URL
    )
  )
  const [
    hasPermissionToRestoreBackup,
    setHasPermissionToRestoreBackup
  ] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_RESTORE_FROM_ZIP
    )
  )

  useEffect(() => {
    setHasPermissionToDeleteBackup(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_BACKUP_DELETE
      )
    )
    setHasPermissionToTriggerBackup(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_BACKUP_TRIGGER
      )
    )
    setHasPermissionToDownloadBackup(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_VIEW_BACKUP_URL
      )
    )
    setHasPermissionToRestoreBackup(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_RESTORE_FROM_ZIP
      )
    )
  }, [currentSite])
  useEffect(() => {
    setSiteStatus(currentSite?.value?.status)
  }, [currentSite])
  const sortOrderByQuery = val => {
    return query.get('sortBy') === val
      ? convertOrderByToAnt(query.get('orderBy'))
      : ''
  }

  const cols = [
    {
      width: '6%',
      key: 'checkbox',
      render: (text, record) => {
        if (
          hasPermissionToDeleteBackup ||
          hasPermissionToTriggerBackup ||
          hasPermissionToDownloadBackup ||
          hasPermissionToRestoreBackup
        ) {
          return (
            <>
              {selectedRowKeys?.includes(record.key) ? (
                <CheckSquareFilled />
              ) : (
                <>
                  <div className='spacer' />
                  <BorderOutlined />
                </>
              )}
            </>
          )
        }
        return null
      }
    },
    {
      width: '34%',
      title: t('date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: sortOrderByQuery('createdAt'),
      render: (text, record) => {
        // If you have a lastCreated property on the record, show that
        // otherwise show when the backup record was added to the database
        if (record.lastCreated) {
          return record.lastCreated
        }
        return record.createdAt
      },
      sorter: true
    },
    {
      width: '15%',
      title: t('name'),
      dataIndex: 'description',
      key: 'description',
      defaultSortOrder: sortOrderByQuery('description'),
      sorter: true
    },
    {
      width: '15%',
      title: t('backup_size'),
      dataIndex: 'fileZipSize',
      key: 'fileZipSize',
      defaultSortOrder: sortOrderByQuery('fileZipSize'),
      sorter: true,
      render: (text, record) => {
        if (
          record.status === BACKUP_STATUSES.BUSY ||
          record.status === BACKUP_STATUSES.PENDING
        ) {
          return 'N/A'
        }
        return text
      }
    },
    {
      width: '15%',
      title: t('type'),
      dataIndex: 'triggerBy',
      key: 'triggerBy',
      defaultSortOrder: sortOrderByQuery('triggerBy'),
      sorter: true,
      render: text => {
        if (text === TRIGGERED_BY.CUSTOMER) {
          return 'Manual'
        }
        return upperFirst(text)
      }
    },
    {
      width: '15%',
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder: sortOrderByQuery('status'),
      sorter: true,
      render: text => {
        switch (text) {
          case BACKUP_STATUSES.COMPLETED:
            return (
              <div className='ready'>
                <CheckOutlined /> {t('complete')}
              </div>
            )
          case BACKUP_STATUSES.BUSY:
          case BACKUP_STATUSES.PENDING:
            return (
              <div className='in-progress'>
                <LoadingOutlined /> {t('in_progress')}
              </div>
            )
          case BACKUP_STATUSES.FAILED:
            return (
              <div className='failed'>
                <CloseOutlined /> {t('failed')}
              </div>
            )
          default:
            return text
        }
      }
    }
  ]
  const [columns, setColumns] = useState(cols)

  const selectRow = record => {
    if (selectedRowKeys?.includes(record.key)) {
      const updateSelectedRows = selectedRowKeys.filter(
        rowKey => rowKey !== record.key
      )
      const updateSelectedForRestore = selectedForRestore.filter(
        row => row.key !== record.key
      )
      setSelectedRowKeys(updateSelectedRows)
      setSelectedForRestore(updateSelectedForRestore)
    } else {
      setSelectedRowKeys(selectedRowKeys => [...selectedRowKeys, record.key])
      setSelectedForRestore(selectedForRestore => [
        ...selectedForRestore,
        record
      ])
    }
  }

  useEffect(() => {
    setColumns(cols)
    // eslint-disable-next-line
  }, [
    selectedRowKeys,
    hasPermissionToDeleteBackup,
    hasPermissionToTriggerBackup,
    hasPermissionToDownloadBackup,
    hasPermissionToRestoreBackup
  ])

  const onTableChange = (pagination, filters, sorter) => {
    if (!sorter.order) {
      fetchBackups({
        byColumn: null,
        order: null
      })
      return
    }

    let order

    switch (sorter.order) {
      case convertOrderByToAnt(SORT_ORDER.ASC):
        order = SORT_ORDER.ASC
        break
      case convertOrderByToAnt(SORT_ORDER.DESC):
        order = SORT_ORDER.DESC
        break
      case '':
      default:
        order = ''
        break
    }

    fetchBackups({
      byColumn: sorter.field,
      order
    })
  }

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button>{t('prev')}</Button>
    }
    if (type === 'next') {
      return <Button>{t('next')}</Button>
    }
    return originalElement
  }

  useEffect(() => {
    fetchBackups()
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!getData?.result && !getError) return
    setInitiallyLoading(false)
    setBackupHistory({
      isLoading: getIsLoading,
      value: getFormattedBackupHistory(getData?.result),
      paginationTotal: getData?.count,
      error: getError
    })
    checkIfBackupsAllowed(getData?.result)

    const newBackups = getData?.result
    let duration = 30 * 1000
    if (
      newBackups?.find(backup =>
        [BACKUP_STATUSES.PENDING, BACKUP_STATUSES.BUSY].includes(backup.status)
      )
    ) {
      duration = 5 * 1000
    } else {
      duration = 30 * 1000
    }
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      fetchBackups()
    }, duration)
    // eslint-disable-next-line
  }, [getData?.result])

  const fetchBackups = newParams => {
    if (newParams) {
      setBackupHistory({ isLoading: true, value: null, error: null })
    }

    const paramsObj = {
      ...params,
      ...newParams,
      ts: new Date().getTime()
    }

    setParams(paramsObj)

    const pageFragment =
      paramsObj.page === 'null' ? '' : `page=${paramsObj.page}`
    const sortFragment = paramsObj.byColumn
      ? `&sortBy=${paramsObj.byColumn}`
      : ''
    const orderFragment = paramsObj.order ? `&orderBy=${paramsObj.order}` : ''
    const recordsFragment = `&records=${paramsObj.records}`
    window.history.pushState(
      null,
      t('backups'),
      `/site/${siteId}/backups?${pageFragment}${sortFragment}${orderFragment}${recordsFragment}`
    )
  }

  // TODO for now usage is being handled by the front, this should be moved to the back in the future
  const checkIfBackupsAllowed = backups => {
    const userTriggered = backups?.filter(
      backup =>
        backup.triggerBy === TRIGGERED_BY.CUSTOMER &&
        (backup.status === BACKUP_STATUSES.COMPLETED ||
          backup.status === BACKUP_STATUSES.PENDING ||
          backup.status === BACKUP_STATUSES.BUSY)
    )
    if (userTriggered && userTriggered.length < MAX_BACKUPS) {
      setIsBackupsAllowed(true)
      return
    }
    setIsBackupsAllowed(false)
  }

  const showDeleteConfirm = () => {
    setConfirmDeleteVisible(true)
  }

  const deleteBackups = async () => {
    AnalyticsManager.getInstance().trackClick('delete_backup')
    setDeleting(true)
    for (const backupId of selectedRowKeys) {
      await deleteBackup({
        url: `sites/${siteId}/backups/${backupId}`,
        method: 'DELETE'
      })
    }
    setSelectedRowKeys([])
    setConfirmDeleteVisible(false)
    setDeleting(false)
  }

  useEffect(() => {
    if (deleting === false) {
      fetchBackups()
    }
    // eslint-disable-next-line
  }, [deleting])

  const downloadBackups = async () => {
    AnalyticsManager.getInstance().trackClick('download_backups')
    for (const backupId of selectedRowKeys) {
      messageContext.setMessage({
        value: t('download_backup_queued'),
        type: FORM_STATUS.SUCCESS
      })
      const backupUrl = await getBackupUrl({
        url: `sites/${siteId}/backups/${backupId}/url`
      })
      downloadResponse(backupUrl?.data?.result)
    }
    setSelectedRowKeys([])
  }

  const onPaginationChange = (page, pageSize) => {
    fetchBackups({
      page,
      records: pageSize
    })
  }

  return (
    <Template
      currentSite={currentSite}
      siteStatus={siteStatus}
      classNames='backups'
      title={t('backups')}
      isLoading={initiallyLoading}
      titleButton={
        !initiallyLoading && (
          <ActionButtons
            refetchCurrentSite={refetchCurrentSite}
            showDeleteConfirm={showDeleteConfirm}
            selectedRowKeys={selectedRowKeys}
            isDeleting={deleting}
            downloadBackups={downloadBackups}
            currentSite={currentSite}
            fetchBackups={fetchBackups}
            isBackupsAllowed={isBackupsAllowed}
            resetSelectedRows={() => setSelectedRowKeys([])}
            selectedForRestore={selectedForRestore?.[0]}
            t={t}
            hasPermissionToDeleteBackup={hasPermissionToDeleteBackup}
            hasPermissionToTriggerBackup={hasPermissionToTriggerBackup}
            hasPermissionToDownloadBackup={hasPermissionToDownloadBackup}
            hasPermissionToRestoreBackup={hasPermissionToRestoreBackup}
          />
        )
      }
    >
      {backupHistory?.value?.length === 0 ? (
        <Card className='empty'>
          <img src={DatabaseIcon} alt='' />
          <h2>{t('no_backups')}</h2>
        </Card>
      ) : (
        <>
          <Table
            loading={backupHistory?.isLoading || deleting}
            pagination={false}
            dataSource={backupHistory?.value}
            columns={columns}
            onChange={onTableChange}
            locale={{
              emptyText: backupHistory?.isLoading ? t('loading') : t('no_data')
            }}
            onRow={record => ({
              onClick: () => {
                selectRow(record)
              }
            })}
          />
          {!backupHistory?.isLoading && backupHistory?.paginationTotal > 10 && (
            <div className='pagination-wrapper'>
              <Pagination
                onChange={onPaginationChange}
                defaultCurrent={parseInt(query.get('page')) || 1}
                total={backupHistory?.paginationTotal}
                itemRender={itemRender}
                defaultPageSize={params.records}
                current={params.page}
                showSizeChanger
              />
            </div>
          )}
          <div style={{ margin: 30, textAlign: 'center' }}>
            <Text type='secondary'>
              {t('backups_tooltip')}{' '}
              <Link
                href='https://support.strattic.com/en/articles/5443053-how-can-i-backup-and-restore-my-strattic-website'
                target='_blank'
                rel='noreferrer'
              >
                {t('see_more')}
              </Link>
            </Text>
          </div>
          <DeleteBackupModal
            isVisible={confirmDeleteVisible}
            processing={deleting}
            handleDelete={deleteBackups}
            handleCancel={() => {
              setConfirmDeleteVisible(false)
            }}
            num={selectedRowKeys.length}
          />
        </>
      )}
    </Template>
  )
}

const ActionButtons = ({
  refetchCurrentSite,
  showDeleteConfirm,
  selectedRowKeys,
  isDeleting,
  downloadBackups,
  currentSite,
  fetchBackups,
  isBackupsAllowed,
  resetSelectedRows,
  selectedForRestore,
  t,
  hasPermissionToDeleteBackup,
  hasPermissionToTriggerBackup,
  hasPermissionToDownloadBackup,
  hasPermissionToRestoreBackup
}) => {
  return (
    <div className='primary-buttons'>
      {hasPermissionToDeleteBackup && (
        <Button
          onClick={showDeleteConfirm}
          className='secondary delete-backup'
          disabled={
            !selectedRowKeys.length ||
            isDeleting ||
            currentSite?.value?.status === SITE_STATUS.RESTORING
          }
          icon={<DeleteOutlined />}
        >
          {t('delete')}
        </Button>
      )}

      {hasPermissionToDownloadBackup && (
        <Button
          onClick={downloadBackups}
          className='secondary'
          disabled={!selectedRowKeys.length}
        >
          {t('download')}
        </Button>
      )}

      {hasPermissionToRestoreBackup && (
        <TriggerRestore
          selectedForRestore={selectedForRestore}
          currentSite={currentSite}
          fetchBackups={fetchBackups}
          selectedRowKeys={selectedRowKeys}
          refetchCurrentSite={refetchCurrentSite}
          resetSelectedRows={resetSelectedRows}
        />
      )}

      {hasPermissionToTriggerBackup && (
        <TriggerBackup
          currentSite={currentSite}
          fetchBackups={fetchBackups}
          isBackupsAllowed={isBackupsAllowed}
        />
      )}
    </div>
  )
}

export default SiteBackupHistoryPage
