import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, message } from 'antd'

import { RulesetContext } from '../../RulesetContext'
import {
  ACTIONS as CONFIRMATION_MODAL_ACTIONS,
  ConfirmationModalContext
} from '../../../ConfirmationModal/ConfirmationModalContext'

const DeleteButton = ({ record }) => {
  const { t } = useTranslation(['common'])
  const { state: ruleset, api } = useContext(RulesetContext)
  const { doCall: deleteRule } = api.deleteRule
  const { dispatch: dispatchConfirmationModal } = useContext(
    ConfirmationModalContext
  )

  const doDelete = ruleId => {
    dispatchConfirmationModal({
      type: CONFIRMATION_MODAL_ACTIONS.START_LOADING
    })

    deleteRule(ruleset, ruleId)
      .then(response => {
        if (response) {
          message.success(t('rule_deleted_successfully'))
          dispatchConfirmationModal({ type: CONFIRMATION_MODAL_ACTIONS.HIDE })
        } else {
          dispatchConfirmationModal({
            type: CONFIRMATION_MODAL_ACTIONS.STOP_LOADING
          })
        }
      })
  }

  const openDeleteConfirmModal = rule => {
    dispatchConfirmationModal({
      type: CONFIRMATION_MODAL_ACTIONS.SHOW,
      data: {
        title: t('delete_rule'),
        text: t('delete_rule_confirm_text'),
        okText: t('delete'),
        onConfirm: () => {
          doDelete(rule.id)
        }
      }
    })
  }

  return (
    <Button
      type='link'
      onClick={() => {
        openDeleteConfirmModal(record)
      }}
    >
      {t('delete')}
    </Button>
  )
}

export default DeleteButton
