import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Layout, Skeleton, Typography, Row } from 'antd'
import Scrollbars from 'react-custom-scrollbars'

import { useUserContext } from '@Context/UserContext'

import MainMenu from '@Components/MainMenu'
import TopBar from '@Components/TopBar'
import DashboardFooter from '@Components/DashboardFooter'
import { TopWarningBar } from '@Components/TopWarningBar'
import { useWindowDimensionsContext } from '@Context/WindowDimensionsContext'
import { NAV_STYLE } from '@Constants'

import './index.less'

const { Title } = Typography
const { Content, Footer } = Layout
const FOOTER_HEIGHT = 150

export const Template = ({
  title,
  classNames,
  children,
  loading,
  titleButton,
  hideFooter
}) => {
  const userContext = useUserContext()
  const [topPadding, setTopPadding] = useState(0)

  const { width, height } = useWindowDimensionsContext()
  const [topIndent, setTopIndent] = useState(0)

  useEffect(() => {
    userContext.expirationBarPadding ? setTopPadding(56) : setTopPadding(0)
  }, [userContext.expirationBarPadding])

  useEffect(() => {
    setTopIndent(76 + topPadding)
  }, [topPadding])

  return (
    <>
      <TopWarningBar />
      <Layout style={{ paddingTop: topPadding }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <MainMenu />
        <Layout className='page-wrapper'>
          <TopBar />
          <Scrollbars style={{ height: height - topIndent }}>
            <Content style={{ minHeight: height - topIndent - FOOTER_HEIGHT }}>
              <div
                className={`site-layout-content ${classNames} ${
                  width < NAV_STYLE.MOBILE ? 'dashboard-mobile' : ''
                }`}
              >
                <Row justify='space-between'>
                  <Title
                    className={`site-layout-title sl-account-name sl-account-name-${title}`}
                  >
                    {title}
                  </Title>
                  {titleButton}
                </Row>
                <Skeleton loading={loading}>{children}</Skeleton>
              </div>
            </Content>
            {hideFooter ? null : (
              <Footer>
                <DashboardFooter />
              </Footer>
            )}
          </Scrollbars>
        </Layout>
      </Layout>
    </>
  )
}
