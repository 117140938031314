import React from 'react'
import { useTranslation } from 'react-i18next'

import Mockup from '@Images/mockups/screenshot.png'

import './index.less'

const SiteImage = ({ screenshot }) => {
  const { t } = useTranslation(['common'])
  return (
    <div className='site-image'>
      <img src={screenshot || Mockup} alt={t('screenshot')} />
    </div>
  )
}
export default SiteImage
