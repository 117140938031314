import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import { Layout, Skeleton } from 'antd'

import MainMenu from '@Components/MainMenu'
import TopBar from '@Components/TopBar'
import DashboardFooter from '@Components/DashboardFooter'
import { useUserContext } from '@Context/UserContext'
import { TopWarningBar } from '@Components/TopWarningBar'
import { Scrollbars } from 'react-custom-scrollbars'
import useWindowDimensions from '@Hooks/useWindowDimensions'
import { SITE_STATUS } from '@Constants'

import './index.less'

const { Content, Sider } = Layout

const LAYOUT_CONSTS = {
  FOOTER_HEIGHT: 72,
  CONTENT_PADDING: 45,
  TOP_PADDING: 56,
  TOP_INDENT: 76,
  RESTORE_PADDING: 40
}

export const Template = ({
  title,
  classNames,
  children,
  isLoading,
  siteStatus,
  siderContent
}) => {
  const userContext = useUserContext()
  const [footerHeight] = useState(LAYOUT_CONSTS.FOOTER_HEIGHT)
  const [contentPaddings] = useState(LAYOUT_CONSTS.CONTENT_PADDING)
  const [topPadding, setTopPadding] = useState(
    userContext.expirationBarPadding ? LAYOUT_CONSTS.TOP_PADDING : 0
  )
  const [topIndent, setTopIndent] = useState(
    LAYOUT_CONSTS.TOP_INDENT + topPadding
  )
  const [restorePadding, setRestorePadding] = useState(0)
  const { height } = useWindowDimensions()

  useEffect(() => {
    if (userContext.expirationBarPadding) {
      setTopPadding(LAYOUT_CONSTS.TOP_PADDING)
    } else {
      setTopPadding(0)
    }
  }, [userContext.expirationBarPadding])

  useEffect(() => {
    setTopIndent(LAYOUT_CONSTS.TOP_INDENT + topPadding)
  }, [topPadding])

  useEffect(() => {
    if (siteStatus === SITE_STATUS.RESTORING) {
      setRestorePadding(LAYOUT_CONSTS.RESTORE_PADDING)
    } else {
      setRestorePadding(0)
    }
  }, [siteStatus])

  return (
    <>
      <TopWarningBar />
      <Layout style={{ paddingTop: topPadding }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <MainMenu />
        <Layout className={`page-wrapper ${classNames}`}>
          <TopBar />
          <Layout>
            <Sider className='migrate-site-nav' width='444px' theme='light'>
              {siderContent}
            </Sider>
            <Content>
              <Scrollbars
                style={{ height: height - topIndent - restorePadding }}
                autoHide
              >
                <div
                  className='migrate-site-layout-content'
                  style={{
                    minHeight:
                      height - footerHeight - contentPaddings - topIndent
                  }}
                >
                  <Skeleton loading={isLoading} active>
                    {children}
                  </Skeleton>
                </div>
                <DashboardFooter />
              </Scrollbars>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}
