export const BACKUP_STATUSES = {
  PENDING: 'pending',
  BUSY: 'busy',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELED: 'canceled',
  DUPLICATE: 'duplicate'
}

export const TRIGGERED_BY = {
  CUSTOMER: 'customer',
  ADMIN: 'admin',
  AUTO: 'auto'
}

export const PUBLISH_STATUSES = {
  FAILED: 'failed',
  COMPLETED: 'completed',
  BUSY: 'busy',
  CANCELLED: 'cancelled',
  ABORTED: 'aborted'
}

export const INITIATOR_TIMING = {
  SCHEDULED: 'scheduled',
  IMMEDIATE: 'immediate'
}

export const PUBLISH_INITIATOR_SOURCES = {
  WORDPRESS: 'wordpress',
  CUSTOMER_DASH: 'customer_dash',
  ADMIN: 'Strattic Support',
  API: 'api',
  CUSTOM_DOMAIN: 'custom_domain'
}

export const TIMINGS = {
  LAUNCH_TIME: 40
}
export const SLASH_REDIRECT_CODES = {
  301: '301',
  302: '302',
  307: '307',
  308: '308'
}
