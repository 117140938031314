import axios from 'axios'
import { ERROR_CODES } from '@Constants'
import { ApiError } from '@Packages/StratticApi/error'

const API_ENDPOINT = process.env.API_ENDPOINT

export const getRequestParams = async ({
  path,
  cancelToken,
  method = 'get',
  params,
  data,
  authenticated = true
}) => {
  const requestParams = {
    method,
    url: `${API_ENDPOINT}/${path}`,
    data,
    params,
    cancelToken,
    headers: {}
  }
  return requestParams
}

export const makeApiRequest = async requestParams => {
  try {
    return await axios(requestParams)
  } catch (error) {
    console.error(error)
    if (axios.isCancel(error)) {
      throw new ApiError('', ERROR_CODES.CANCELLED_API_REQUEST, '')
    } else {
      throw new ApiError(error.response.data.message, error.response.data.code)
    }
  }
}
