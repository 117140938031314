import React from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, Typography } from 'antd'

import './index.less'

const { Text } = Typography

const MigrateSiteNav = ({ selectedKey }) => {
  const { t } = useTranslation(['common', 'errors'])

  return (
    <Menu
      mode='vertical'
      className='new-site-nav'
      selectedKeys={[selectedKey]}
      items={[
        {
          key: 'name-site',
          label: <Text>{t('name_site_1')}</Text>,
          title: null,
          disabled: true
        },
        {
          key: 'install-plugin',
          label: <Text>{t('install_migration_plugin_2')}</Text>,
          title: null,
          disabled: true
        },
        {
          key: 'add-email',
          label: <Text>{t('add_email_3')}</Text>,
          title: null,
          disabled: true
        },
        {
          key: 'add-sftp',
          label: <Text>{t('add_sftp_4')}</Text>,
          title: null,
          disabled: true
        }
      ]}
    />
  )
}

export default MigrateSiteNav
