import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { Template, Col1, Col2 } from '@Templates/Login'
import SignUpConfirmForm from '@Components/SignUpConfirmForm'
import AnalyticsManager from '@Managers/analyticsManager'
import featuredImage from '@Images/strattic-illustration.png'

import './index.less'
import qs from 'qs'

const { Text, Title } = Typography

const SignUpConfirm = () => {
  const { t } = useTranslation(['common'])

  const location = useLocation()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [email] = useState(params.email)

  return (
    <Template title={t('confirm_account')} classNames='signup'>
      <Col1>
        <Title>{t('confirm_account')}</Title>
        <Text id='confirm_acc_txt' type='secondary'>
          {t('check_email_to_confirm_1', { email })}
        </Text>
        <Text id='confirm_acc_txt'>{t('check_spam')}</Text>
        <SignUpConfirmForm />
        <Text className='footer'>
          {t('have_account')}
          <Link
            onClick={() =>
              AnalyticsManager.getInstance().trackClick({
                action: 'back_to_login',
                label: 'sign_up_confirm'
              })
            }
            className='login-next-step'
            to='/signin'
          >
            {t('login')}
          </Link>
        </Text>
        <footer />
      </Col1>
      <Col2 featuredImage={featuredImage} />
    </Template>
  )
}

export default SignUpConfirm
