import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useUserContext } from '@Context/UserContext'

import { Template } from '@Templates/Dashboard'
import { Card, Row, Col, Typography, Divider } from 'antd'
import Name from './Name'
import ResetPassword from '@Components/ResetPassword'
import { SetMfa } from '@Components/SetMfa'
import { getFromLocalStorage } from '@Lib/helpers'

import './index.less'

const { Text } = Typography

const MyProfile = () => {
  const { t } = useTranslation(['common', 'errors'])
  const userContext = useUserContext()
  const [ssoManaged] = useState(getFromLocalStorage('externally-managed'))
  return (
    <Template title={t('my_profile')} classNames='dashboard my-profile'>
      <Card
        title={t('general')}
        headStyle={{
          fontWeight: 600,
          background: 'linear-gradient(180deg, #FFFFFF 0%, #FBFBFD 100%)'
        }}
        style={{ width: 540, height: 220 }}
        bodyStyle={{ paddingTop: 34 }}
      >
        <UserEmail t={t} email={userContext.currentUser?.email} />
        <Divider />
        <Name
          name={userContext.currentUser?.name}
          refetchCurrentUser={userContext.login}
          cantEditName={ssoManaged}
        />
      </Card>
      <Card
        title={t('security')}
        headStyle={{
          fontWeight: 600,
          background: 'linear-gradient(180deg, #FFFFFF 0%, #FBFBFD 100%)'
        }}
        style={{ width: 540, marginTop: 40 }}
        bodyStyle={{ paddingTop: 34 }}
      >
        <Password ssoManaged={ssoManaged} t={t} />
        <Divider />
        <Mfa
          t={t}
          mfaActive={userContext.currentUser?.mfaSetUp}
          ssoManaged={ssoManaged}
        />
      </Card>
    </Template>
  )
}

function Password ({ ssoManaged, t }) {
  return (
    <Row justify='space-between' style={{ marginBottom: -14 }}>
      {ssoManaged ? (
        <Col span={14}>
          <Text disabled>{t('login_with_company_sso')}</Text>
        </Col>
      ) : (
        <>
          <Col span={7}>
            <Text type='secondary'>{t('password')}</Text>
          </Col>
          <Col span={13} style={{ paddingLeft: 12 }}>
            <Text type='secondary'>********</Text>
          </Col>
          <Col>
            <ResetPassword />
          </Col>
        </>
      )}
    </Row>
  )
}

function UserEmail ({ t, email }) {
  return (
    <Row>
      <Col span={7}>
        <Text type='secondary'>{t('email')}</Text>
      </Col>
      <Col span={14} style={{ paddingLeft: 14 }}>
        <Text type='secondary'>{email}</Text>
      </Col>
    </Row>
  )
}

function Mfa ({ t, mfaActive, ssoManaged }) {
  return (
    <Row justify='space-between'>
      <Col span={7}>
        <Text disabled={ssoManaged}>{t('two_step_val')}</Text>
      </Col>
      <SetMfa t={t} mfaActive={mfaActive} ssoManaged={ssoManaged} />
    </Row>
  )
}
export default MyProfile
