const config = {
  apiAccount: {
    // This needs to be added back in, envs where not working
    // URL: process.env.API_URL.endsWith('/') ? process.env.API_URL : process.env.API_URL + '/'
  },
  cognito: {
    REGION: process.env.REGION,
    USER_POOL_ID: process.env.USER_POOL_ID,
    APP_CLIENT_ID: process.env.APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.IDENTITY_POOL_ID,
    DOMAIN_NAME: process.env.AUTH_DOMAIN,
    OAUTH_CALLBACK_URL:
      (/localhost/.test(process.env.FRONTEND_DOMAIN) ? 'http://' : 'https://') +
      process.env.FRONTEND_DOMAIN +
      '/callback',
    OAUTH_SIGNOUT_URL:
      (/localhost/.test(process.env.FRONTEND_DOMAIN) ? 'http://' : 'https://') +
      process.env.FRONTEND_DOMAIN +
      '/signout'
  },
  itemsPerTable: 10,
  //   showBackups: process.env.SHOW_BACKUPS,
  awsAnalyticsDisabled: true, // TODO: consider turning this on
  siteConnectionInfo: {
    sftpPort: 22,
    sftpAddress: 'sftp.strattic.com',
    sqlHost: 'mysql.strattic.com'
  },
  registrars: [
    {
      name: 'godaddy',
      aaaaSupport: false
    },
    {
      name: 'amazon_route_53',
      aaaaSupport: true
    },
    {
      name: 'cloudflare',
      aaaaSupport: true
    },
    {
      name: 'namedotcom',
      aaaaSupport: false
    },
    {
      name: 'gandi',
      aaaaSupport: false
    },
    {
      name: 'google',
      aaaaSupport: false
    },
    {
      name: 'registerdotcom',
      aaaaSupport: false
    },
    {
      name: 'network_solutions',
      aaaaSupport: false
    },
    {
      name: 'other',
      aaaaSupport: 'maybe'
    }
  ],
  maintenanceMode: process.env.MAINTENANCE_MODE,
  disabledPayments: process.env.DISABLE_PAYMENTS === 'true',
  stripePublicApi: process.env.STRIPE_API,
  intercomAppId: process.env.INTERCOM_APP_ID,
  kustomerApiKey: process.env.KUSTOMER_API_KEY,
  kustomerBrandId: process.env.KUSTOMER_BRAND_ID,
  //   maintenanceMode: parseInt(process.env.MAINTENANCE_MODE, 10),
  hubspot: {
    apiUrl: 'https://api.hsforms.com/submissions/v3/integration/submit/',
    signUpForm: process.env.CUSTOMER_SIGNUP_FORM,
    pricingContactForm: process.env.HUBSPOT_PRICING_FORM,
    portalId: process.env.REACT_APP_HUBSPOT,
    extendTrialForm: process.env.EXTEND_TRIAL_FORM
  },
  analytics: {
    disabled: process.env.DISABLE_ANALYTICS === 'true',
    gtmId: process.env.GTM_ID,
    gtmAuth: process.env.GTM_AUTH || null,
    gtmPreview: process.env.GTM_PREVIEW || null,
    amplitudeId: process.env.AMPLITUDE_ID
  }
}
export default config
