import { DateTime, Duration } from 'luxon'

export const getDate = (date, format = 'DATE_MED', type) => {
  let dt = ''
  switch (type) {
    case 'iso':
      dt = DateTime.fromISO(date)
      break
    default:
      dt = DateTime.fromMillis(date * 1000)
  }
  return dt.toLocaleString(DateTime[format])
}

export const getSiteCardDate = (date, type) => {
  let dt = ''
  switch (type) {
    case 'iso':
      dt = DateTime.fromISO(date)
      break
    default:
      dt = DateTime.fromSeconds(date)
  }
  return {
    day: dt.day,
    month: dt.monthShort,
    year: dt.year
  }
}

export const getDuration = (start, end) => {
  const startDt = DateTime.fromISO(start)
  const endDt = DateTime.fromISO(end)

  const diffInMonths = endDt.diff(startDt)
  return diffInMonths.toFormat('hh:mm:ss')
}

export const formatDuration = durationInMs => {
  return Duration.fromMillis(durationInMs, {}).toFormat('hh:mm:ss')
}

export const getCurrentYear = () => {
  return DateTime.local().year
}

export const getCurrentMonth = () => {
  return DateTime.local().month
}

const sevenDaysInSeconds = 60 * 60 * 24 * 7

export const publishedLessThanWeek = date => {
  // if the publish date is less than 7 days format as string '### days/hours ago'
  if (
    Math.abs(DateTime.fromISO(date).diffNow(['seconds']).seconds) <
    sevenDaysInSeconds
  ) {
    return luxonFromNow(date).split(' ')
  }
  return null
}

export const luxonFromNow = timeStamp => {
  if (!timeStamp) return null
  if (typeof timeStamp === 'number') {
    return DateTime.fromSeconds(timeStamp).toRelative()
  } else {
    return DateTime.fromISO(timeStamp).toRelative()
  }
}
