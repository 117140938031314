import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Modal, Button, Form, Typography, Input, Tooltip } from 'antd'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { useMessageContext } from '@Context/MessageContext'
import { TrashIcon } from '@Components/Icons'
import AnalyticsManager from '@Managers/analyticsManager'

import { FORM_STATUS } from '@Constants'

import './index.less'
import { SITE_STATUS } from '../../constants'

const { Text, Title } = Typography

const ConfirmDelete = ({
  isModalVisible,
  handleDelete,
  handleCancel,
  isDeleting,
  siteId
}) => {
  const { t } = useTranslation(['common'])
  const [form] = Form.useForm()
  return (
    <Modal
      width={825}
      height={478}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={
        <div className='modal-footer'>
          <Button
            className='confirm-delete-btn'
            key='submit'
            type='primary'
            loading={isDeleting}
            onClick={() => {
              AnalyticsManager.getInstance().trackEvent({
                action: `delete-site-${siteId}`,
                label: 'DELETE_SITE'
              })
              form
                .validateFields()
                .then(values => {
                  handleDelete(values)
                  form.resetFields()
                })
                .catch(info => {
                  console.error('Validate Failed:', info)
                })
            }}
          >
            {t('delete_site_header')}
          </Button>
          <Button key='back' onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </div>
      }
      bodyStyle={{ textAlign: 'center', paddingBottom: 0 }}
      className='delete-confirm'
    >
      <Title level={3}>{t('delete_site_header')}</Title>
      <Text className='warning-text'>{t('delete_warning')}</Text>
      <br />
      <div className='form-section'>
        <Text strong>{t('type_delete')}</Text>
        <Form
          form={form}
          layout='vertical'
          name='form_in_modal'
          requiredMark={false}
        >
          <Form.Item
            name='confirm'
            rules={[
              { required: true, message: t('incorrect_input') },
              {
                pattern: /DELETE$/,
                message: t('incorrect_input')
              }
            ]}
          >
            <Input width={256} disabled={isDeleting} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

const DeleteSite = ({ currentSite, siteStatus }) => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const messageContext = useMessageContext()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }

  const [
    { loading: isDeleting, error: deleteError },
    deleteSite
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(deleteError)
  const handleDelete = async value => {
    try {
      await deleteSite({
        url: `sites/${currentSite?.value?.siteId}`,
        method: 'DELETE',
        data: value
      })
      messageContext.setMessage({
        value: t('site_deleted'),
        type: FORM_STATUS.SUCCESS
      })
      history.push('/')
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    AnalyticsManager.getInstance().trackEvent({
      action: 'cancelled',
      label: 'DELETE_SITE'
    })
    setIsModalVisible(false)
  }

  return (
    <>
      {siteStatus === SITE_STATUS.RESTORING ? (
        <Tooltip title={t('restore_in_progress_tooltip')}>
          <Button
            block
            disabled
            className='sl-delete-site delete-site-restoring'
          >
            {t('delete_site')} <TrashIcon />
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={showModal}
          disabled={isDeleting}
          className='sl-delete-site delete-site delete-site-btn'
        >
          {t('delete_site')} <TrashIcon />
        </Button>
      )}

      <ConfirmDelete
        siteId={currentSite?.value?.siteId}
        isModalVisible={isModalVisible}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
        handleCancel={handleCancel}
      />
    </>
  )
}

export default DeleteSite
