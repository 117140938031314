import Axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

import { getJwt } from './amplify'
import { ApiError } from './error'

const API_ENDPOINT = process.env.API_ENDPOINT

// based on https://github.com/simoneb/axios-hooks

const axios = Axios.create({
  baseURL: `${API_ENDPOINT}/`
})

axios.interceptors.request.use(
  async config => {
    const token = await getJwt()
    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`
      }
    }
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  response => response,
  async error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.data?.message) {
      throw new ApiError(
        error.response?.data?.message,
        error.response?.data?.code,
        error.response?.status
      )
    }
    throw error
  }
)

// Thanks @Amitay adding the catch in here rather than on every instance of api call

const useStratticApi = (config, options) => {
  const [state, refetch, cancelOutstandingRequest] = makeUseAxios({
    axios
  })(config, options)
  const execute = async (configOverride, options) => {
    return refetch(configOverride, options).catch(() => {})
  }
  return [state, execute, cancelOutstandingRequest]
}
export default useStratticApi
