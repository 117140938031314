import React from 'react'

import { useTranslation } from 'react-i18next'

import { ROLE_TYPE } from '@Constants'
import MyTooltip from '@Components/MyTooltip'

import './index.less'

const TeamRoleTooltip = ({ roleType }) => {
  const { t } = useTranslation(['common'])

  switch (roleType) {
    case ROLE_TYPE.ADMIN:
      return <MyTooltip roleType={roleType} title={<AdminText t={t} />} />
    case ROLE_TYPE.MANAGER:
      return <MyTooltip roleType={roleType} title={<ManagerText t={t} />} />
    case ROLE_TYPE.COLLABORATOR:
      return (
        <MyTooltip roleType={roleType} title={<CollaboratorText t={t} />} />
      )
    default:
      return roleType
  }
}

const AdminText = ({ t }) => (
  <>
    {t('admin_desc_title')}
    <ul className='role-info'>
      <li>{t('view_billing')}</li>
      <li>{t('access_manage_sites')}</li>
      <li>{t('add_members_all_sites')}</li>
      <li>{t('access_credentials')}</li>
      <li>{t('connect_domain')}</li>
    </ul>
  </>
)

const ManagerText = ({ t }) => (
  <>
    {t('manager_desc_title')}
    <ul className='role-info'>
      <li>{t('add_new_team_members')}</li>
      <li>{t('access_credentials')}</li>
      <li>{t('connect_domain')}</li>
    </ul>
  </>
)

const CollaboratorText = ({ t }) => (
  <>
    {t('collaborator_desc_title')}
    <ul className='role-info'>
      <li>{t('access_wp')}</li>
    </ul>
  </>
)

export default TeamRoleTooltip
