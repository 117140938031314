import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Typography } from 'antd'
import Clock from '@Images/icons/clock.svg'
import useAccountPermissions from '@Hooks/useAccountPermissions'
import useCurrentSite from '@Hooks/useCurrentSite'
import {
  ACCOUNT_PERMISSIONS,
  SITE_PERMISSIONS
} from '@Constants/permissionsConstants'
import { permissionGranted } from '@Lib/helpers/permissionsHelpers'
import './index.less'

const { Text } = Typography

const AccountExpiredCard = ({ context }) => {
  const { t } = useTranslation(['common'])
  const [firstLine, setFirstLine] = useState()

  useEffect(() => {
    if (!context) {
      setFirstLine(<GeneralText />)
    }
    if (context === 'domain') {
      setFirstLine(<DomainText />)
    }
  }, [context])

  return (
    <div className='account-expired-card'>
      <div className='back-shadow' />
      <Card className='narrow account-expired-card'>
        <div className='icon-wrapper'>
          <Clock />
        </div>
        <h2>{t('hey_there')}</h2>
        <main>
          <p>{firstLine}</p>
          <p>
            <Text>{t('acc_expired_content_2')}</Text>
          </p>
          <p>
            <Text>{t('the_strattic_team')}</Text>
          </p>
        </main>
      </Card>
    </div>
  )
}

const DomainText = () => {
  const { t } = useTranslation(['common'])
  const [currentSite] = useCurrentSite()
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const hasPermissionToViewAccount = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_VIEW_SUBSCRIPTIONS
  )
  const [text, setText] = useState()
  const [
    hasPermissionToConnectDomain,
    setHasPermissionToConnectDomain
  ] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_CREATE_CUSTOM_DOMAIN
    )
  )

  useEffect(() => {
    setHasPermissionToConnectDomain(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_CREATE_CUSTOM_DOMAIN
      )
    )
  }, [currentSite])

  useEffect(() => {
    if (hasPermissionToUpgrade) {
      setText(t('acc_expired_content_1'))
      return
    }

    if (hasPermissionToViewAccount || hasPermissionToConnectDomain) {
      setText(t('paid_acct_expired_contact_support_domain_context'))
    }
  }, [
    hasPermissionToViewAccount,
    hasPermissionToUpgrade,
    hasPermissionToConnectDomain,
    t
  ])

  return <Text>{text}</Text>
}

const GeneralText = () => {
  const { t } = useTranslation(['common'])

  return <Text>{t('acc_expired_content_1')}</Text>
}

export default AccountExpiredCard
