import React, { useContext, useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams } from 'react-router-dom'

import { Button, Form, Input, message, Tabs, Typography } from 'antd'

import { Template } from '@Templates/Site'
import useCurrentSite from '@Hooks/useCurrentSite'
import { useUserContext } from '@Context/UserContext'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import { DIST_TYPES } from '@Constants'

import {
  ACTIONS as CONFIRMATION_MODAL_ACTIONS,
  ConfirmationModalContext,
  ConfirmationModalProvider
} from '@Packages/ConfirmationModal/ConfirmationModalContext'
import WrappingCol from '@Packages/RulesEngine/RulesTable/WrappingCol'

import {
  RulesetContext,
  RulesetProvider
} from '@Packages/RulesEngine/RulesetContext'
import RulesTable from '@Packages/RulesEngine/RulesTable'
import ApiFactory from './ApiFactory'
import { mapStratticPermissionsToRulesPermissions } from '../../lib/helpers/permissionsHelpers'
import { SiteProvider } from '../../packages/SiteContext'

const { Text } = Typography
const { Item } = Form

const LiveAPI = ApiFactory('live')
const PreviewAPI = ApiFactory('preview')

const getOtherDistributionType = distributionType => {
  if (distributionType === DIST_TYPES.LIVE) {
    return DIST_TYPES.PREVIEW
  }

  return DIST_TYPES.LIVE
}

const CopyFromButton = ({ distributionType }) => {
  const { t } = useTranslation(['common'])
  const { siteId } = useParams()
  const { state: ruleset, api } = useContext(RulesetContext)
  const { doCall: getRules } = api.getRules

  const { dispatch: dispatchConfirmationModal } = useContext(
    ConfirmationModalContext
  )

  const [{ error: errorCopy }, copyRuleset] = useStratticApi(
    {
      method: 'POST'
    },
    { manual: true }
  )
  useErrorMessage(errorCopy)

  const handleCopyFrom = () => {
    dispatchConfirmationModal({
      type: CONFIRMATION_MODAL_ACTIONS.SHOW,
      data: {
        title: t('are_you_sure'),
        text: t('copy_rules_confirmation_text', {
          distribution: distributionType
        }),
        okText: t('yes_proceed'),
        onConfirm: () => {
          dispatchConfirmationModal({
            type: CONFIRMATION_MODAL_ACTIONS.START_LOADING
          })

          copyRuleset({
            url: `sites/${siteId}/ruleset/${ruleset.id}/copy?optimisticLockVersion=${ruleset.optimisticLockVersion}`,
            data: { direction: 'to' }
          }).then(response => {
            if (response) {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.HIDE
              })
              message.success(t('ruleset_copied_successfully'))
              getRules('headers')
            } else {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.STOP_LOADING
              })
            }
          })
        }
      }
    })
  }

  return (
    <Button type='link' onClick={handleCopyFrom}>
      {t('sync_from') + ' ' + t(getOtherDistributionType(distributionType))}
    </Button>
  )
}

const CopyToButton = ({ distributionType }) => {
  const { t } = useTranslation(['common'])
  const { siteId } = useParams()
  const { state: ruleset } = useContext(RulesetContext)

  const { dispatch: dispatchConfirmationModal } = useContext(
    ConfirmationModalContext
  )

  const [{ error: errorCopy }, copyRuleset] = useStratticApi(
    {
      method: 'POST'
    },
    { manual: true }
  )
  useErrorMessage(errorCopy)

  const handleCopyTo = () => {
    dispatchConfirmationModal({
      type: CONFIRMATION_MODAL_ACTIONS.SHOW,
      data: {
        title: t('are_you_sure'),
        text: t('copy_rules_confirmation_text', {
          distribution: distributionType === 'live' ? 'preview' : 'live'
        }),
        okText: t('yes_proceed'),
        onConfirm: () => {
          dispatchConfirmationModal({
            type: CONFIRMATION_MODAL_ACTIONS.START_LOADING
          })

          copyRuleset({
            url: `sites/${siteId}/ruleset/${ruleset.id}/copy?optimisticLockVersion=${ruleset.optimisticLockVersion}`,
            data: { direction: 'from' }
          }).then(response => {
            if (response) {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.HIDE
              })
              message.success(t('ruleset_copied_successfully'))
            } else {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.STOP_LOADING
              })
            }
          })
        }
      }
    })
  }

  return (
    <Button type='link' onClick={handleCopyTo}>
      {t('sync_to') + ' ' + t(getOtherDistributionType(distributionType))}
    </Button>
  )
}

const SiteHeadersRulesPage = () => {
  const { t } = useTranslation(['common'])
  const [currentSite, refetchCurrentSite] = useCurrentSite()
  const userContext = useUserContext()

  const sitePermissions = currentSite?.value?.allowedPermissions
  const [permissionsArr, setPermissionsArr] = useState([])

  useEffect(() => {
    const sitePermissionsArr = mapStratticPermissionsToRulesPermissions(
      sitePermissions
    )
    setPermissionsArr(sitePermissionsArr)
  }, [sitePermissions])

  const addons = useMemo(() => {
    const additionalColumns = [
      {
        title: t('header'),
        dataIndex: 'header',
        width: '15%',
        render: data => {
          if (data === undefined) {
            return ''
          }

          return <WrappingCol text={data.name + ': ' + data.value} />
        }
      }
    ]

    const additionalFields = (
      <>
        <Item
          name='header_name'
          labelAlign='left'
          labelCol={{ span: 6 }}
          label={<Text strong>{t('header_name')}</Text>}
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              message: t('required')
            }
          ]}
        >
          <Input placeholder='Content-Security-Policy' />
        </Item>

        <Item
          name='header_value'
          labelAlign='left'
          labelCol={{ span: 6 }}
          label={<Text strong>{t('header_value')}</Text>}
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              message: t('required')
            }
          ]}
        >
          <Input placeholder='default-src "self"' />
        </Item>
      </>
    )

    return {
      columns: additionalColumns,
      fields: additionalFields,
      fieldsValuesToObj: (obj, fieldsValues) => {
        obj.header = {
          name: fieldsValues.header_name,
          value: fieldsValues.header_value
        }
      },
      objToFieldsValues: (obj, fieldsValues) => {
        fieldsValues.header_name = obj.header.name
        fieldsValues.header_value = obj.header.value
      },
      csvColumnsNames: [
        'uri',
        'header name',
        'header value',
        'description',
        'conditions',
        'status'
      ],
      ruleToCsvArray: rule => [
        rule.uri,
        rule.header?.name,
        rule.header?.value,
        rule.description,
        rule.conditions,
        rule.status
      ],
      csvObjToRule: csvObj => ({
        uri: csvObj.uri,
        description: csvObj.description,
        status: csvObj.status,
        conditions: JSON.parse(csvObj.conditions),
        header: {
          name: csvObj.headername,
          value: csvObj.headervalue
        }
      })
    }
  }, [t])
  const liveAddons = useMemo(() => {
    return {
      ...addons,
      customMenuButtons: [
        <CopyFromButton distributionType='live' key='1' />,
        <CopyToButton distributionType='live' key='2' />
      ]
    }
  }, [addons])
  const previewAddons = useMemo(() => {
    return {
      ...addons,
      customMenuButtons: [
        <CopyFromButton distributionType='preview' key='1' />,
        <CopyToButton distributionType='preview' key='2' />
      ]
    }
  }, [addons])
  const hiddenColumnsArr = [t('operation')]
  if (
    userContext?.accountDetails &&
    !userContext?.accountDetails?.allowRulesEngine
  ) {
    return <Redirect to='/' />
  }
  return (
    <Template
      currentSite={currentSite}
      siteStatus={currentSite?.value?.status}
      title={t('header_rules')}
      classNames='headers-rules'
      beta
    >
      <SiteProvider
        siteData={currentSite?.value}
        refetchCurrentSite={refetchCurrentSite}
      >
        <ConfirmationModalProvider>
          <Tabs
            defaultActiveKey='1'
            destroyInactiveTabPane
            items={[
              {
                key: '1',
                label: t('live_site'),
                children: (
                  <RulesetProvider API={LiveAPI}>
                    <RulesTable
                      permissionsArr={permissionsArr}
                      ruleType='headers'
                      addons={liveAddons}
                      hiddenColumnsArr={hiddenColumnsArr}
                    />
                  </RulesetProvider>
                )
              },
              {
                key: '2',
                label: t('preview_site'),
                children: (
                  <RulesetProvider API={PreviewAPI}>
                    <RulesTable
                      permissionsArr={permissionsArr}
                      ruleType='headers'
                      addons={previewAddons}
                      hiddenColumnsArr={hiddenColumnsArr}
                    />
                  </RulesetProvider>
                )
              }
            ]}
          />
        </ConfirmationModalProvider>
      </SiteProvider>
    </Template>
  )
}

export default SiteHeadersRulesPage
