import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Button, message, Tooltip } from 'antd'

import { RulesetContext } from '../../RulesetContext'
import { SiteContext } from '../../../SiteContext'
import { SITE_STATUSES } from '../../../constants/SiteConstants'

const DeployButton = ({ disabled, ruleType, canDeployRules }) => {
  const { t } = useTranslation(['common'])
  const [title, setTitle] = useState('')
  const { state: ruleset, api } = useContext(RulesetContext)
  const { status: siteStatus, refetchCurrentSite } = useContext(SiteContext)

  const { doCall: getRules } = api.getRules

  const { loading: loadingDeploy, doCall: deployRuleset } = api.deployRuleset

  useEffect(() => {
    if (siteStatus === SITE_STATUSES.UPDATING) {
      setTitle('The site is currently updating')
    } else {
      setTitle('')
    }
  }, [siteStatus])

  const doDeploy = async () => {
    deployRuleset(ruleset)
      .then(result => {
        if (result) {
          getRules(ruleType)
          refetchCurrentSite && refetchCurrentSite()
          message.success(t('ruleset_deploying_successfully'))
        }
      })
  }
  if (!canDeployRules) {
    return (
      <Tooltip title={t('insufficent_permissions_rules_engine')}>
        <Button
          className='sl-deploy'
          disabled
          type='danger'
          style={{ width: 100 }}
        >
          {t('deploy_ruleset')}
        </Button>
      </Tooltip>
    )
  }
  return (
    <Tooltip title={siteStatus === SITE_STATUSES.UPDATING ? t('site_details_updating') : t('deploy_changes_to_site', { distribution: ruleset.distributionType })}>
      <Button
        className='sl-deploy'
        onClick={() => doDeploy()}
        loading={loadingDeploy || siteStatus === SITE_STATUSES.UPDATING}
        disabled={disabled || siteStatus !== SITE_STATUSES.ACTIVE}
        title={title}
        type='danger'
        style={{ width: 100 }}
      >
        {t('deploy_ruleset')}
      </Button>
    </Tooltip>
  )
}

export default DeployButton
