import { useEffect, useState } from 'react'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import useLocalStorage from '@Hooks/useLocalStorage'
import { useUserContext } from '@Context/UserContext'
import { getFormattedAccountDetails } from '@Lib/helpers'

const useAccountDetails = () => {
  const userContext = useUserContext()
  const accountId = userContext?.accountId
  const [currentAccountDetails, setCurrentAccountDetails] = useLocalStorage(
    'accountDetails'
  )

  const path = `accounts/${accountId}`
  const [
    { data: getData, loading: getIsLoading, error: getError }
  ] = useStratticApi(path)
  useErrorMessage(getError)

  const [accountDetails, setAccountDetails] = useState({
    isLoading: !currentAccountDetails,
    value: currentAccountDetails,
    error: null
  })

  useEffect(() => {
    if (!getData?.result && !getError) return
    setAccountDetails({
      isLoading: getIsLoading,
      value: getFormattedAccountDetails(getData?.result),
      error: getError
    })
    setCurrentAccountDetails(getFormattedAccountDetails(getData?.result))
    // eslint-disable-next-line
  }, [getData?.result, getIsLoading])

  return [accountDetails, setCurrentAccountDetails]
}

export default useAccountDetails
