import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Row, Space, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useUserContext } from '@Context/UserContext'
import useSites from '@Hooks/useSites'
import useAccountPermissions from '@Hooks/useAccountPermissions'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import AnalyticsManager from '@Managers/analyticsManager'

import { Template } from '@Templates/Dashboard'

import SiteCard, { LoadingCard } from '@Components/SiteCard'
import OwnerTrialExpired from '@Components/OwnerTrialExpired'
import AddSiteCard from '@Components/AddSiteCard'
import NoActiveSites from '@Components/NoActiveSites'
import EmptyDashWithFAQ from '@Components/EmptyDashWithFaq'
import {
  hasSubscriptionExpired,
  isFreeTrial
} from '@Lib/helpers/subscriptionHelpers'

import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'

import './index.less'

const Dashboard = () => {
  const { t } = useTranslation(['common', 'errors'])
  const history = useHistory()
  const [acceptedAccountId, setAcceptedAccountId] = useState()
  const [addSiteBtnTitle, setAddSiteBtnTitle] = useState('')
  const [sites, initialLoad] = useSites()
  const userContext = useUserContext()
  const accountId = userContext?.accountId
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const hasPermissionToAddSite = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SITE_CREATE
  )
  const accountDetails = userContext?.accountDetails

  const [isExpired, setIsExpired] = useState(
    hasSubscriptionExpired(accountDetails)
  )
  const [canAddMoreSites, setCanAddMoreSites] = useState(
    accountDetails?.sitesUsage?.current < accountDetails?.sitesUsage?.max
  )
  const [
    { loading: acceptInviteLoading, error: acceptInviteError },
    acceptInviteCall
  ] = useStratticApi({}, { manual: true })

  useErrorMessage(acceptInviteError)

  const acceptInv = async () => {
    if (!userContext?.pendingInvites) return
    let accepted = false
    for (const invite of userContext?.pendingInvites) {
      const result = await acceptInviteCall({
        url: `invitations/${invite?.id}/accept`,
        method: 'POST'
      })
      if (result) {
        setAcceptedAccountId(invite?.accountId)
        accepted = true
      }
    }
    if (accepted) {
      userContext.login(true)
    }
  }

  const setCurrentAccountId = () =>
    userContext?.setCurrentAccountId(acceptedAccountId)
  useEffect(() => {
    if (acceptedAccountId) {
      history.push('/')
      setCurrentAccountId()
    }
    // eslint-disable-next-line
  }, [acceptedAccountId])

  useEffect(() => {
    acceptInv()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isExpired) {
      if (hasPermissionToUpgrade) {
        setAddSiteBtnTitle(t('owner_acc_expired_add_site'))
      } else {
        setAddSiteBtnTitle(t('acc_expired_add_site'))
      }
      return
    }
    if (canAddMoreSites) {
      setAddSiteBtnTitle('')
      return
    }

    if (hasPermissionToUpgrade) {
      setAddSiteBtnTitle(t('no_more_sites_allowed_owner'))
    } else {
      setAddSiteBtnTitle(t('no_more_sites_allowed'))
    }
  }, [
    hasPermissionToUpgrade,
    t,
    hasPermissionToAddSite,
    canAddMoreSites,
    isExpired
  ])

  useEffect(() => {
    setCanAddMoreSites(
      accountDetails?.sitesUsage?.current < accountDetails?.sitesUsage?.max
    )
    // eslint-disable-next-line
  }, [accountDetails?.sitesUsage])

  useEffect(() => {
    if (hasSubscriptionExpired(accountDetails)) {
      setIsExpired(true)
    } else {
      setIsExpired(false)
    }
    // eslint-disable-next-line
  }, [accountDetails])

  if (
    (sites?.isLoading && sites?.error === null && !isExpired) ||
    acceptInviteLoading ||
    initialLoad
  ) {
    return (
      <Template title={t('dashboard')} classNames='dashboard'>
        <LoadingCard />
      </Template>
    )
  }
  if (!accountId) {
    return (
      <Template title={!isExpired && t('dashboard')} classNames='dashboard'>
        <EmptyDashWithFAQ />
      </Template>
    )
  }
  return (
    <Template
      title={!isExpired && t('dashboard')}
      classNames='dashboard'
      titleButton={
        hasPermissionToAddSite && (
          <div className='primary-buttons'>
            <AddSiteButton
              isExpired={isExpired}
              accountId={accountId}
              title={addSiteBtnTitle}
              t={t}
              disabled={!canAddMoreSites}
              isFreeTrial={isFreeTrial(accountDetails?.planName)}
            />
          </div>
        )
      }
    >
      <SitesContent
        sites={sites}
        hasPermissionToAddSite={hasPermissionToAddSite}
        canAddMoreSites={canAddMoreSites}
        accountDetails={accountDetails}
        isExpired={isExpired}
        accountId={accountId}
      />
    </Template>
  )
}

const SitesContent = ({
  sites,
  hasPermissionToAddSite,
  accountDetails,
  isExpired,
  accountId,
  canAddMoreSites
}) => {
  if (sites?.isLoading) {
    return null
  }
  // if there are sites present
  if (sites?.value?.length) {
    return (
      <StandardSitesView
        sites={sites}
        accountId={accountId}
        canAddMoreSites={canAddMoreSites}
        hasPermissionToAddSite={hasPermissionToAddSite}
        isExpired={isExpired}
      />
    )
  }

  // if there are no sites and has permissions to add site check expiration status
  if (hasPermissionToAddSite && accountId) {
    if (sites?.error) {
      return <NoActiveSites />
    }
    if (isExpired) {
      return <OwnerTrialExpired accountDetails={accountDetails} />
    }
    return <EmptyDashWithFAQ accountId={accountId} />
  }
  return <NoActiveSites />
}

const StandardSitesView = ({
  sites,
  accountId,
  canAddMoreSites,
  hasPermissionToAddSite,
  isExpired
}) => (
  <Row>
    {sites?.value?.map(site => (
      <SiteCard site={site} key={site.id} />
    ))}
    {canAddMoreSites && hasPermissionToAddSite && !isExpired && (
      <AddSiteCard accountId={accountId} />
    )}
  </Row>
)

const AddSiteButton = ({
  isExpired,
  accountId,
  t,
  disabled,
  title,
  isFreeTrial
}) => {
  if (disabled || isExpired) {
    return (
      <Space>
        <Tooltip title={title} placement='bottom'>
          <Button
            className='primary-add sl-addSiteBtn'
            icon={<PlusOutlined />}
            type='primary'
            disabled={disabled || isExpired}
            danger
          >
            {t('add_site')}
          </Button>
        </Tooltip>
      </Space>
    )
  }
  return (
    <Space>
      <Link
        onClick={() =>
          AnalyticsManager.getInstance().trackClick('dashboard_add_site')
        }
        to={`/account/${accountId}/site-welcome`}
      >
        <Button
          className='primary-add sl-addSiteBtn'
          icon={<PlusOutlined />}
          type='primary'
          title={title}
          disabled={disabled}
          danger
        >
          {t('add_site')}
        </Button>
      </Link>
    </Space>
  )
}

export default Dashboard
