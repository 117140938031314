import React, { useReducer } from 'react'
import ConfirmationModal from './index'

const ConfirmationModalContext = React.createContext()

const ACTIONS = {
  SHOW: 'show',
  HIDE: 'hide',
  START_LOADING: 'start_loading',
  STOP_LOADING: 'stop_loading'
}

const deepClone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

const defaultState = {
  isVisible: false,
  onConfirm: () => {},
  processing: false,
  okText: 'ok',
  cancelText: 'cancel',
  title: 'Are you sure?',
  text: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SHOW: {
      const data = action.data
      const newState = { ...state, ...data }
      newState.isVisible = true
      return newState
    }
    case ACTIONS.HIDE: {
      return deepClone(defaultState)
    }
    case ACTIONS.START_LOADING: {
      return { ...state, ...{ processing: true } }
    }
    case ACTIONS.STOP_LOADING: {
      return { ...state, ...{ processing: false } }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const ConfirmationModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const value = { state, dispatch }

  const hide = () => {
    dispatch({ type: ACTIONS.HIDE })
  }

  return (
    <ConfirmationModalContext.Provider value={value}>
      {children}
      <ConfirmationModal
        isVisible={state.isVisible}
        onCancel={state.onCancel || hide}
        onConfirm={() => {
          state.onConfirm()
        }}
        processing={state.processing}
        okText={state.okText}
        cancelText={state.cancelText}
        title={state.title}
        text={state.text}
      />
    </ConfirmationModalContext.Provider>
  )
}

export { ConfirmationModalContext, ConfirmationModalProvider, ACTIONS }
