import React, { useState } from 'react'

import { Typography, Modal, Card } from 'antd'

import PlayIcon from '@Images/icons/playplay.png'

import './index.less'

const { Text } = Typography

const VideoModalCard = ({
  imageSrc,
  altTxt,
  videoLength,
  videoDescription,
  videoUrl
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Card onClick={() => setVisible(true)} className='video-card'>
        <img className='space-filler' alt={altTxt} src={imageSrc} />
        <img className='play-icon' alt='play icon' src={PlayIcon} />
        <Text>{videoDescription}</Text>
        <br />
        <Text type='secondary'>{videoLength}</Text>
      </Card>
      <Modal
        centered
        open={visible}
        footer={false}
        onCancel={() => setVisible(false)}
        width={560}
        className='video-in-modal'
      >
        <iframe
          width='560'
          height='315'
          src={videoUrl}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </Modal>
    </>
  )
}

export default VideoModalCard
