import React, { useState } from 'react'
import { Button, Form, Input, Modal, Typography } from 'antd'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useCurrentSite from '@Hooks/useCurrentSite'
import { useMessageContext } from '@Context/MessageContext'
import { useTranslation } from 'react-i18next'
import { FORM_STATUS } from '@Constants'
import AnalyticsManager from '@Managers/analyticsManager'
import './index.less'

const { Text, Title } = Typography

const ConfirmModal = ({ isVisible, handleDetach, handleCancel }) => {
  const { t } = useTranslation(['common'])
  const [form] = Form.useForm()
  const [processing, setProcessing] = useState(false)

  const footer = (
    <div className='modal-footer'>
      <Button
        className='confirm-delete-btn'
        key='submit'
        type='primary'
        loading={processing}
        onClick={() => {
          AnalyticsManager.getInstance().trackEvent({
            action: 'detach_custom_domain',
            label: 'connect_domain'
          })
          form
            .validateFields()
            .then(values => {
              setProcessing(true)
              handleDetach(values)
            })
            .catch(info => {
              console.error('Validate Failed:', info)
            })
        }}
      >
        {t('detach_domain')}
      </Button>
      <Button
        key='back'
        onClick={() => {
          if (!processing) {
            handleCancel()
          }
        }}
      >
        {t('cancel')}
      </Button>
    </div>
  )

  return (
    <Modal
      className='detach-domain-modal'
      open={isVisible}
      footer={footer}
      width={825}
      height={478}
      onCancel={() => {
        if (!processing) {
          handleCancel()
        }
      }}
    >
      <div className='heading'>
        <Title level={3}>{t('detach_custom_domain')}</Title>
        <Text className='warning-text'>{t('detach_confirm_text')}</Text>
      </div>

      <hr />

      <div className='form-section'>
        <Text strong>{t('type_detach')}</Text>
        <Form
          form={form}
          layout='vertical'
          name='form_in_modal'
          requiredMark={false}
        >
          <Form.Item
            name='confirm'
            rules={[
              { required: true, message: t('incorrect_input') },
              {
                pattern: /^DETACH$/,
                message: t('incorrect_input')
              }
            ]}
          >
            <Input
              width={256}
              placeholder={t('type_here')}
              disabled={processing}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

const DetachDomain = ({ refetch }) => {
  const { t } = useTranslation(['common'])
  const [currentSite] = useCurrentSite()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const messageContext = useMessageContext()

  const [
    { error: deleteCustomDomainError },
    deleteCustomDomain
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(deleteCustomDomainError)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const hideModal = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    hideModal()
  }

  const handleDetach = async () => {
    const result = await deleteCustomDomain({
      url: `/distributions/${currentSite?.value?.liveDistribution?.id}/domain`,
      method: 'DELETE'
    })
    if (result) {
      messageContext.setMessage({
        value: t('domain_detached'),
        type: FORM_STATUS.SUCCESS
      })
    }
    hideModal()
    await refetch()
  }

  return (
    <>
      <Button onClick={showModal}>{t('detach_domain')}</Button>
      <ConfirmModal
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        handleDetach={handleDetach}
      />
    </>
  )
}

export default DetachDomain
