import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useAccountPermissions from '@Hooks/useAccountPermissions'
import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'

import {
  isFreeTrial,
  hasSubscriptionExpired
} from '@Lib/helpers/subscriptionHelpers'
import { useUserContext } from '@Context/UserContext'

const useExpiredSubscriptionTooltip = () => {
  const { t } = useTranslation(['common'])

  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )

  const userContext = useUserContext()

  const [isSubExpired, setIsSubExpired] = useState(
    hasSubscriptionExpired(userContext?.accountDetails)
  )

  const [expiredButtonTitle, setExpiredButtonTitle] = useState(null)

  const expired = userContext?.accountDetails

  useEffect(() => {
    setIsSubExpired(hasSubscriptionExpired(expired))
  }, [expired])

  useEffect(() => {
    if (!isSubExpired) {
      return
    }
    if (isFreeTrial(userContext.accountDetails?.planName)) {
      setExpiredButtonTitle(
        hasPermissionToUpgrade
          ? t('free_trial_expired_contact_support_for_owner')
          : t('free_trial_expired_contact_support')
      )
    } else {
      setExpiredButtonTitle(
        hasPermissionToUpgrade
          ? t('paid_acct_expired_contact_support_for_owner')
          : t('paid_acct_expired_contact_support')
      )
    }
  }, [hasPermissionToUpgrade, userContext.accountDetails, t, isSubExpired])

  return expiredButtonTitle
}

export default useExpiredSubscriptionTooltip
