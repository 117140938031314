import React, { useState, useEffect } from 'react'
import './index.less'

const Progress = ({ max, current }) => {
  const [fillPercent, setFillPercent] = useState(
    (parseInt(current, 10) / parseInt(max, 10)) * 100
  )
  const [overMaxClass, setOverMaxClass] = useState('')

  useEffect(() => {
    setFillPercent((parseInt(current, 10) / parseInt(max, 10)) * 100)
  }, [max, current])

  useEffect(() => {
    if (fillPercent > 100) {
      setFillPercent(100)
      setOverMaxClass('over-max')
    }
  }, [fillPercent])

  return (
    <span className='progress'>
      <span
        className={`fill ${overMaxClass}`}
        style={{
          width: `${fillPercent}%`,
          background: 'linear-gradient(90deg, #0eb8cf 0%, #fb4246 117.72%)'
        }}
      />
    </span>
  )
}

export default Progress
