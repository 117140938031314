import React from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Layout, Typography } from 'antd'
import { StratticIcon } from '@Components/Icons'
import './index.less'

const { Text } = Typography
const { Footer } = Layout

const DashboardFooter = () => {
  const { t } = useTranslation(['common'])
  return (
    <Footer className='dashboard-footer'>
      <span className='logo'>
        <StratticIcon />
      </span>
      <Text className='copyright'>
        {t('copyright_message', { year: DateTime.local().year })}
      </Text>
      <nav className='legal-links'>
        <a
          href='https://www.strattic.com/dmca-policy/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('dmca_policy')}
        </a>
        <span className='spacer'>•</span>
        <a
          href='https://www.strattic.com/privacy-policy/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('privacy_policy')}
        </a>
        <span className='spacer'>•</span>
        <a
          href='https://www.strattic.com/cookie-policy/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('mmmm_cookies')}
        </a>
        <span className='spacer'>•</span>
        <a
          href='https://www.strattic.com/terms-of-service/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('terms_and_services')}
        </a>
      </nav>
    </Footer>
  )
}

export default DashboardFooter
