import { Auth } from 'aws-amplify'

const INVALIDATE_SESSION_EARLY_SECONDS = 30

const refreshSession = async session => {
  const user = await Auth.currentUserPoolUser()
  return new Promise((resolve, reject) => {
    const refreshToken = session.getRefreshToken()
    user.refreshSession(refreshToken, (err, newSession) => {
      if (err) {
        return reject(err)
      }
      resolve(newSession)
    })
  })
}

export const getJwt = async () => {
  let session = await Auth.currentSession()
  const idExpiration = session.getIdToken().getExpiration()
  const now = Math.floor(new Date() / 1000)
  const adjusted = now + INVALIDATE_SESSION_EARLY_SECONDS

  if (idExpiration < adjusted) {
    session = await refreshSession(session)
  }
  return session?.idToken?.jwtToken
}
