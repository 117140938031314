// import Session from '@Lib/session'
import config from '@Lib/config'

const amplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH', // required because we are migrating users to new user pool
    oauth: {
      domain: config.cognito.DOMAIN_NAME,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: config.cognito.OAUTH_CALLBACK_URL,
      redirectSignOut: config.cognito.OAUTH_SIGNOUT_URL,
      responseType: 'code'
    }
  },
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: config.awsAnalyticsDisabled
  },
  API: {
    endpoints: [
      {
        name: 'HSFormsAPI',
        endpoint: 'https://api.hsforms.com/submissions/v3/integration/submit/'
      }
    ]
  }
}
export default amplifyConfig
