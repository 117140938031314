import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, Redirect } from 'react-router-dom'
import qs from 'qs'
import wdtLoading from 'wdt-loading'

import { Button, Card, Typography } from 'antd'

import useCurrentSite from '@Hooks/useCurrentSite'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import { useUserContext } from '@Context/UserContext'
import { hasSubscriptionExpired } from '@Lib/helpers/subscriptionHelpers'

import { TIMINGS } from '@Constants/siteConstants'
import { SITE_STATUS } from '@Constants'

import { getWPSiteHealth } from '@Lib/api/sites'

import { Template } from '@Templates/FullWidth'
import Progress from '@Components/Progress'
import { loadingPhrases } from '@Lib/config/loading-phrases'

import 'wdt-loading/wdtLoading.css'
import './index.less'

const { Title, Text } = Typography

const checkWPSiteHealth = async stagingURL => {
  try {
    const site = await getWPSiteHealth(stagingURL)
    return site
  } catch (error) {
    console.error(error)
  }
}

const redirectToSite = (site, redirectTo) => {
  const fromDashParam = 'fromdash'
  let redirectFullPath = redirectTo
    ? `${decodeURIComponent(redirectTo)}`
    : '/wp-admin/'
  if (!redirectFullPath?.startsWith('/')) {
    redirectFullPath = `/${redirectFullPath}`
  }
  const locationPath = `https://${site?.httpBasicUser}:${site?.httpBasicPassword}@${site?.stagingDomain}${redirectFullPath}`
  const urlObject = new URL(locationPath)
  if (!redirectFullPath?.includes(fromDashParam)) {
    urlObject.searchParams.append(fromDashParam, true)
  }
  window.location.href = urlObject.toString()
}

const LaunchWPPage = () => {
  const userContext = useUserContext()
  const expired = hasSubscriptionExpired(userContext?.accountDetails)
  const { t } = useTranslation(['common'])
  const { siteId } = useParams()
  const [site, refetchCurrentSite] = useCurrentSite()
  const siteStatus = site?.value?.status
  const { s_r: redirectTo, w_c: windowClose } = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true
  })
  const [needsRefresh, setNeedsRefresh] = useState(false)
  const [percentLoaded, setPercentLoaded] = useState(0)
  const [countTime] = useState(TIMINGS.LAUNCH_TIME)

  const [{ loading: startIsLoading }, startSiteCall] = useStratticApi({
    url: `sites/${siteId}/start`,
    method: 'post'
  })

  const checkSite = async () => {
    const results = await refetchCurrentSite()
    const site = results?.data?.result
    switch (site?.runningStatus) {
      case 'running':
        {
          const siteHealth = await checkWPSiteHealth(site)
          if (siteHealth === 1) {
            if (windowClose) {
              window.close()
            }
            redirectToSite(site, redirectTo)
          } else {
            setTimeout(() => {
              checkSite()
            }, 2000)
          }
        }
        break
      case 'stopped':
        if (!startIsLoading) {
          await startSiteCall()
        }
        setTimeout(() => {
          checkSite()
        }, 2500)
        break
      default:
    }
  }

  useEffect(() => {
    if (!expired) {
      wdtLoading.start({ speed: 2000 })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (site?.value?.siteId && !expired) {
      checkSite(site, redirectTo)
    }
    // eslint-disable-next-line
  }, [site?.value?.siteId])

  useEffect(() => {
    if (percentLoaded === countTime) {
      wdtLoading.done()
      setNeedsRefresh(true)
      return
    }
    const percentCounter = setInterval(() => {
      setPercentLoaded(percentLoaded + 1)
    }, 1000)

    return () => clearInterval(percentCounter)
  }, [percentLoaded, countTime])

  if (expired) {
    return <Redirect to='/' />
  }
  if (siteStatus === SITE_STATUS.RESTORING) {
    return <Redirect to={`/site/${site?.value?.siteId}/restoring`} />
  }
  return (
    <Template
      title={t('loading_wp')}
      showTitle={false}
      classNames='launch-wp-page sl-launchPage'
    >
      <section className='narrow'>
        <Title className='sl-launchPageTitle' level={1}>
          {t('loading_server')}
        </Title>
        <Card className='time-remaining sl-timeRemaining'>
          <Progress max={countTime} current={percentLoaded} />
          {needsRefresh ? (
            <>
              <p>
                <Text>{t('taking_longer_than_usual')}</Text>
              </p>
              <Button
                type='primary'
                onClick={() => window.location.reload(false)}
              >
                {t('refresh')}
              </Button>
            </>
          ) : (
            <p>
              <Text>{t('est_time', { time: countTime - percentLoaded })}</Text>
            </p>
          )}
        </Card>
      </section>
      <div className='funny-texts-loader sl-funnyTextLoader'>
        <div className='wdt-loading-screen'>
          <div className='wdt-loading-phrases'>
            <div
              className='wdt-loading-phrase-category'
              data-category='default'
            >
              {loadingPhrases.map((phrase, index) => (
                <div key={index} className='wdt-loading-phrase'>
                  {t(phrase)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default LaunchWPPage
