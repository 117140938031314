import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { forgotPassword } from '@Lib/api/auth'
import { Form, Input, Button, Typography, Spin } from 'antd'
import { FORM_STATUS } from '@Constants'

import AnalyticsManager from '@Managers/analyticsManager'

import './index.less'

const { Item } = Form
const { Text } = Typography

const ForgotPasswordForm = () => {
  const { t } = useTranslation(['common'])
  const [email, setEmail] = useState('')
  const [formStatus, setFormStatus] = useState('')
  const [formMessage, setFormMessage] = useState('')
  const [form] = Form.useForm()

  const onFinish = async values => {
    setFormStatus(FORM_STATUS.SUBMITTING)
    try {
      const results = await forgotPassword(email)
      AnalyticsManager.getInstance().trackEvent(
        {
          action: 'forgot_password_submit'
        },
        'forgot_password'
      )
      setFormStatus(FORM_STATUS.SUCCESS)
      setFormMessage('Success! Please check your email.')
      form.resetFields()
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'forgot_password_success',
        action_type: 'forgot_password_success'
      })
      return results
    } catch (error) {
      AnalyticsManager.getInstance().trackEvent(
        {
          action: 'forgot_password_error'
        },
        'forgot_password'
      )
      setFormStatus(FORM_STATUS.DANGER)
      setFormMessage(error.message)
    }
  }

  return (
    <Spin
      spinning={formStatus === FORM_STATUS.SUBMITTING}
      tip={t(FORM_STATUS.SUBMITTING)}
    >
      <div className='wrapper'>
        <Form
          form={form}
          name='login'
          className='sl-forgot-password-form forgot-password-form'
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
        >
          {formMessage && (
            <Text
              className='sl-form-message form-message'
              strong
              type={
                formStatus === FORM_STATUS.SUCCESS
                  ? formStatus
                  : FORM_STATUS.DANGER
              }
            >
              {formMessage}
            </Text>
          )}

          <Item
            name='email'
            rules={[
              {
                required: true,
                message: t('email_please')
              },
              {
                type: 'email',
                message: t('email_invalid')
              }
            ]}
            className='sl-forgot-password-form forgot-password-form'
          >
            <Input
              type='email'
              placeholder={t('email')}
              aria-label={t('email')}
              onChange={e => setEmail(e.target.value)}
              value={email}
              className='sl-forgot-password-email-input'
            />
          </Item>

          <Item
            wrapperCol={{
              offset: 2,
              span: 20
            }}
          >
            <Button
              size='large'
              type='primary'
              htmlType='submit'
              className='sl-forgot-password-submit-button'
              disabled={formStatus === FORM_STATUS.SUBMITTING}
              onClick={() =>
                window.dataLayer.push({
                  event: 'fireEvent',
                  event_name: 'element_click',
                  action_type: 'forgot_password_attempt'
                })
              }
            >
              {t('submit')}
            </Button>
          </Item>
        </Form>
      </div>
    </Spin>
  )
}

export default ForgotPasswordForm
