import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { Template, Col1, Col2 } from '@Templates/Login'
import ResetPasswordForm from '@Components/ResetPasswordForm'

import AnalyticsManager from '@Managers/analyticsManager'

import featuredImage from '@Images/dashboard-browser.png'
const { Text, Title } = Typography

const ResetPasswordPage = () => {
  const { t } = useTranslation(['common'])
  return (
    <Template title={t('reset_password')}>
      <Col1>
        <Title>{t('reset_password')}</Title>
        <Text>{t('verification_code_text')}</Text>
        <ResetPasswordForm />
        <Text>
          <Link
            to='/forgot-password'
            onClick={() => {
              window.dataLayer.push({
                event: 'fireEvent',
                event_name: 'element_click',
                action_type: 'forgot_password_link'
              })
              AnalyticsManager.getInstance().trackClick({
                action: 'reset_password',
                label: 'need_new_code'
              })
            }}
          >
            {t('need_new_code')}
          </Link>
          <br />
          <br />
          <Link
            to='/signin'
            onClick={() => {
              window.dataLayer.push({
                event: 'fireEvent',
                event_name: 'element_click',
                action_type: 'login_link'
              })
              AnalyticsManager.getInstance().trackClick({
                action: 'reset_password',
                label: 'back_to_login'
              })
            }}
          >
            {t('back_to_login')}
          </Link>
        </Text>
      </Col1>
      <Col2 featuredImage={featuredImage} />
    </Template>
  )
}

export default ResetPasswordPage
