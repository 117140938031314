import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useSites from '@Hooks/useSites'

import {
  getFormattedSiteAccessData,
  getAccountContactEmail
} from '@Lib/helpers'
import { Template } from '@Templates/Dashboard'

import { Table, Typography, Tooltip, Divider, Button, message } from 'antd'

import AddTeamMember from '@Components/Team/AddTeamMember'
import TeamRoleTooltip from '@Components/TeamRoleTooltip'
import PaginationNavigation from '@Components/PaginationNav'
import ManageTeamMember from '@Components/Team/ManageTeamMember'

import { useUserContext } from '@Context/UserContext'
import useLocalStorage from '@Hooks/useLocalStorage'
import useAccountPermissions from '@Hooks/useAccountPermissions'
import {
  canCurrentUserManage,
  roleTypeByAccount,
  userHasAllSites
} from '@Lib/helpers/permissionsHelpers'
import { getDaysLeftOnSubscription } from '@Lib/helpers/subscriptionHelpers'
import { CheckCircleTwoTone } from '@ant-design/icons'

import './index.less'
import { INVITATION_STATUS, PAGINATION_POSITIONS } from '@Constants'
import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'

const { Text } = Typography

const TeamPage = () => {
  const { t } = useTranslation(['common', 'errors'])
  const userContext = useUserContext()
  const [currentUser] = useLocalStorage('currentUser')
  const accountDetails = userContext?.accountDetails
  const currentAccountId = userContext?.accountId
  const [sites] = useSites()
  const [columns, setColumns] = useState([])
  const [resentList, setResentList] = useState([])
  const [daysLeft, setDaysLeft] = useState(
    getDaysLeftOnSubscription(userContext.accountDetails?.planEndDate)
  )
  useEffect(() => {
    setDaysLeft(
      getDaysLeftOnSubscription(userContext.accountDetails?.planEndDate)
    )
  }, [userContext.accountDetails])

  const [currentUserRoleType] = useState(
    roleTypeByAccount(currentUser, currentAccountId)
  )
  const hasPermissionToManageTeam = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_USER_INVITATION_MANAGE
  )
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const hasPermissionsToViewMfaStatus = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_USER_INVITATION_VIEW_MFA
  )
  const canManageAllSites = userHasAllSites(currentUser, currentAccountId)

  const [teamManagementData, setTeamManagementData] = useState({
    status: false,
    value: [],
    error: null
  })

  const getTeamDataPath = `accounts/${currentAccountId}/invitations`
  const [
    {
      data: getTeamData,
      loading: isGetTeamDataLoading,
      error: getTeamDataError
    },
    refetchGetTeamData
  ] = useStratticApi(getTeamDataPath)

  useErrorMessage(getTeamDataError)

  const getCanAddMemberCheckPath = `accounts/${currentAccountId}/permissions/create-invitation`
  const [
    {
      data: getCanAddMember,
      loading: isGetCanAddMemberLoading,
      error: getCanAddMemberError
    },
    refetchGetCanAddMemberCheck
  ] = useStratticApi({ url: getCanAddMemberCheckPath }, { manual: true })

  const [
    { error: resendTeamInvitationError },
    resendTeamInvitation
  ] = useStratticApi({}, { manual: true })

  useErrorMessage(resendTeamInvitationError)

  useErrorMessage(getCanAddMemberError)

  useEffect(() => {
    let table = [
      {
        title: t('user'),
        dataIndex: 'userName',
        key: 'user',
        render: (text, record) => {
          if (record.status === INVITATION_STATUS.ACTIVE) {
            return (
              <>
                <Text strong>{record?.userName}</Text> <br />
                <Text type='secondary'>{record?.userEmail}</Text>
              </>
            )
          }
          return (
            <>
              <Text strong>
                {record?.userEmail} - {t('pending')}
              </Text>{' '}
              <br />
              {!resentList?.includes(record.key) &&
                canCurrentUserManage(currentUser, record, currentAccountId) && (
                  <Button
                    onClick={() => {
                      resendTeamInvitation({
                        url: `accounts/${currentAccountId}/invitations/${record.key}/resend`,
                        method: 'POST'
                      })
                      setResentList([...resentList, record.key])
                      message.success(t('invite_resent'))
                    }}
                    type='link'
                    className='resend-btn'
                  >
                    {t('resend_invite')}
                  </Button>
                )}
            </>
          )
        },
        width: '30%'
      },
      {
        title: t('sso'),
        dataIndex: 'managedType',
        render: text => {
          if (text !== 'none') {
            return <CheckCircleTwoTone twoToneColor='#52c41a' />
          } else {
            return null
          }
        }
      },
      {
        title: t('role'),
        dataIndex: 'roleType',
        key: 'roleType',
        className: 'role-type',
        align: 'center',
        render: text => <TeamRoleTooltip roleType={text} />,
        width: '25%'
      },
      {
        title: t('site_access'),
        dataIndex: 'permissions',
        key: 'permissions',
        align: 'center',
        render: (text, record) => {
          if (!Array.isArray(record?.permissions)) {
            return record.permissions
          }
          if (record.permissions?.length > 3) {
            const items = record.permissions?.slice(0, 3)
            return (
              <ul className='no-bullets'>
                <SiteList sitesForList={items} />
                <Tooltip title={<SiteList sitesForList={record.permissions} />}>
                  <li>+{record.permissions?.length - 3} more</li>
                </Tooltip>
              </ul>
            )
          }
          return (
            <ul className='no-bullets'>
              <SiteList sitesForList={record.permissions} />
            </ul>
          )
        },
        width: '25%'
      },
      {
        title: t('two_fa_col'),
        dataIndex: 'mfaSetup',
        key: 'mfaSetup',
        align: 'center',
        width: 50,
        render: (text, record) => {
          if (record.mfaSetup === true) {
            return t('enabled')
          } else if (record.mfaSetup === false) {
            return t('disabled')
          }
        }
      },
      {
        title: '',
        dataIndex: 'operation',
        key: 'operation',
        align: 'center',
        width: 210,
        render: (text, record) => {
          if (
            record.userEmail === getAccountContactEmail(accountDetails) &&
            canCurrentUserManage(currentUser, record, currentAccountId)
          ) {
            return <BillingContactEmail t={t} accountDetails={accountDetails} />
          }
          if (
            record.userId !== currentUser?.id &&
            canCurrentUserManage(currentUser, record, currentAccountId)
          ) {
            return (
              <ManageTeamMember
                canManageAllSites={canManageAllSites}
                sites={sites}
                accountId={currentAccountId}
                record={record}
                fetchTeamManagementData={refetchGetTeamData}
                canAddMemberCheck={refetchGetCanAddMemberCheck}
                currentUserRoleType={currentUserRoleType}
              />
            )
          }
        }
      }
    ]
    if (accountDetails?.ssoIntegrations?.length < 1) {
      table = table.filter(col => col.dataIndex !== 'managedType')
    }
    if (!hasPermissionsToViewMfaStatus) {
      table = table.filter(col => col.dataIndex !== 'mfaSetup')
    }
    setColumns(table)
    // eslint-disable-next-line
  }, [
    isGetTeamDataLoading,
    accountDetails,
    resentList,
    hasPermissionsToViewMfaStatus
  ])

  const fetchTeamManagementData = () => {
    setTeamManagementData({
      status: isGetTeamDataLoading,
      value: getFormattedTeamManagementData(getTeamData?.result),
      error: getCanAddMemberError
    })
  }

  const getFormattedTeamManagementData = history => {
    const formattedManagementData = history?.map(item => {
      return {
        key: item.id,
        userId: item.userId,
        userName: item.user?.name,
        userEmail: item.user?.email,
        roleType: item.roleType,
        permissions: getFormattedSiteAccessData(item.permissions, sites?.value),
        status: item.status,
        managedType: item.managedType,
        mfaSetup: item?.user?.mfaSetUp
      }
    })
    return formattedManagementData
  }

  useEffect(() => {
    fetchTeamManagementData()
    if (hasPermissionToManageTeam && isGetTeamDataLoading) {
      refetchGetCanAddMemberCheck({
        url: `accounts/${currentAccountId}/permissions/create-invitation`
      })
    }
    // eslint-disable-next-line
  }, [isGetTeamDataLoading, hasPermissionToManageTeam])

  const prevButtonText = t('prev')
  const nextButtonText = t('next')
  const locale = {
    emptyText: 'Loading...'
  }
  return (
    <Template
      className='team-management'
      title={t('team_management')}
      loading={isGetTeamDataLoading || isGetCanAddMemberLoading}
      titleButton={
        hasPermissionToManageTeam &&
        teamManagementData.value !== null &&
        !isGetCanAddMemberLoading && (
          <AddTeamMember
            loading={isGetCanAddMemberLoading}
            disabled={!getCanAddMember?.result?.allowed || daysLeft < 0}
            sites={sites}
            accountId={currentAccountId}
            fetchTeamManagementData={refetchGetTeamData}
            canAddMemberCheck={refetchGetCanAddMemberCheck}
            currentUserRoleType={currentUserRoleType}
            canManageAllSites={canManageAllSites}
            canAddMemberData={getCanAddMember?.result}
            accountSubStatus={accountDetails?.subscriptionStatus}
            hasPermissionToUpgrade={hasPermissionToUpgrade}
          />
        )
      }
    >
      <div className='team-table-center'>
        <Table
          locale={locale}
          loading={isGetTeamDataLoading || teamManagementData.value === null}
          dataSource={teamManagementData.value}
          columns={columns}
          className='team-table'
          pagination={
            teamManagementData?.value?.length > 10 && {
              position: [PAGINATION_POSITIONS.BOTTOM_LEFT],
              itemRender: (page, type, originalElement) =>
                PaginationNavigation(
                  page,
                  type,
                  originalElement,
                  prevButtonText,
                  nextButtonText
                )
            }
          }
        />
      </div>
    </Template>
  )
}

const SiteList = ({ sitesForList }) =>
  sitesForList?.map(site => <li key={site.id}>{site.name}</li>)

const BillingContactEmail = ({ t, accountDetails }) => {
  return (
    <Tooltip
      title={
        accountDetails?.planGroupId === 1
          ? t('billing_email_warn_trial')
          : t('billing_email_warn')
      }
      placement='left'
    >
      <div className='contact-email'>
        {t('manage_team_member')}
        <Divider type='vertical' />
        {t('delete')}
      </div>
    </Tooltip>
  )
}

export default TeamPage
