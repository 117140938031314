import React from 'react'

import { Link } from 'react-router-dom'
import { Layout, Drawer, Row, Col } from 'antd'

import useAccountPermissions from '@Hooks/useAccountPermissions'
import { useWindowDimensionsContext } from '@Context/WindowDimensionsContext'
import { useUserContext } from '@Context/UserContext'

import { NAV_STYLE } from '@Constants'
import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'

import Logo from '@Images/logos/strattic-logo-mark-white.svg'
import ProfileMenu from '@Components/ProfileMenu'
import AccountMenu from '@Components/AccountMenu'

import './index.less'
import FullScreenMenu from './FullScreenMenu'

const { Sider } = Layout

const MainMenu = () => {
  const userContext = useUserContext()
  const currentAccountId = userContext?.accountId
  const hasPermissionToViewAccount = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_VIEW_SUBSCRIPTIONS
  )
  const {
    width,
    height,
    menuVisible,
    toggleMenuVisible
  } = useWindowDimensionsContext()

  if (width < NAV_STYLE.MED) {
    return (
      <Drawer
        onClose={toggleMenuVisible}
        open={menuVisible}
        width={width < NAV_STYLE.MOBILE ? '100%' : 350}
        placement='left'
        closeIcon={false}
        style={{ marginTop: 75, height: height - 75 }}
        className={`mobile-menu ${width < NAV_STYLE.MOBILE && 'small'}`}
        headerStyle={{ padding: 0, borderBottom: 'none' }}
        maskStyle={{
          opacity: 1,
          animation: 'none'
        }}
        drawerStyle={{
          background: 'linear-gradient(180deg, #2e3345 0%, #3a4157 100%)'
        }}
        footer={
          <Row justify='space-between'>
            <Col>{width < NAV_STYLE.MOBILE && <AccountMenu />}</Col>
            <Col>
              <ProfileMenu />
            </Col>
          </Row>
        }
        footerStyle={{
          border: '1px solid #5D637B',
          height: '15%',
          padding: 25
        }}
      >
        <FullScreenMenu
          currentAccountId={currentAccountId}
          hasPermissionToViewAccount={hasPermissionToViewAccount}
          mobile
        />
      </Drawer>
    )
  }

  return (
    <>
      <Sider collapsed className='main-sidebar'>
        <Link to='/'>
          <div className='logo'>
            <Logo />
          </div>
        </Link>
        <FullScreenMenu
          currentAccountId={currentAccountId}
          hasPermissionToViewAccount={hasPermissionToViewAccount}
        />
      </Sider>
    </>
  )
}

export default MainMenu
