import { useCallback, useEffect, useState } from 'react'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import useLocalStorage from '@Hooks/useLocalStorage'

import { getFormattedAccountDetails } from '@Lib/helpers'
import { useHistory } from 'react-router-dom'

const useStratticAccount = () => {
  const [lastUsedAccountId, setLastUsedAccountId] = useLocalStorage(
    'lastUsedAccountId',
    null,
    true
  )
  const [currentAccountId, setAccountId] = useLocalStorage('accountId')
  const [storeAccountDetails, setStoreAccountDetails] = useLocalStorage(
    'accountDetails'
  )
  const [, setSitesStorage] = useLocalStorage('sites')
  const [, setStoreAccountPermissions] = useLocalStorage('accountPermissions')
  const [accountPermissions, setAccountPermissions] = useState()
  const [accountDetails, setAccountDetails] = useState(storeAccountDetails)
  const history = useHistory()
  const [
    { loading: currentAccountLoading, error: currentAccountError },
    getAccountDetails
  ] = useStratticApi(
    {
      url: `accounts/${currentAccountId}`,
      method: 'GET'
    },
    { manual: true }
  )
  useErrorMessage(currentAccountError)

  const reloadCurrentAccount = async () => {
    if (currentAccountId) {
      const currentAccount = await getAccountDetails({
        url: `accounts/${currentAccountId}`
      })

      if (!currentAccount?.data?.result) return
      setStoreAccountDetails(
        getFormattedAccountDetails(currentAccount?.data?.result)
      )
      setAccountDetails(
        getFormattedAccountDetails(currentAccount?.data?.result)
      )
      setAccountPermissions(currentAccount?.data?.result?.allowedPermissions)
      setStoreAccountPermissions(
        currentAccount?.data?.result?.allowedPermissions
      )
    }
  }

  useEffect(() => {
    reloadCurrentAccount()
    // eslint-disable-next-line
  }, [currentAccountId])

  const setCurrentAccountId = useCallback(async accountId => {
    setAccountId(accountId)
    setLastUsedAccountId(accountId || null)
    setSitesStorage(null)
    history.push('/')
    // eslint-disable-next-line
  }, [])

  const callCurrentAccount = useCallback(async () => {
    reloadCurrentAccount()
    // eslint-disable-next-line
  }, [currentAccountId])

  return [
    {
      currentAccountId,
      currentAccountLoading,
      lastUsedAccountId,
      accountPermissions,
      accountDetails
    },
    setCurrentAccountId,
    callCurrentAccount
  ]
}

export default useStratticAccount
