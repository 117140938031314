import React from 'react'

const WrappingCol = ({ text }) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
      {text}
    </div>
  )
}

export default WrappingCol