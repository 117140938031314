import React from 'react'
import { Typography, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import numeral from 'numeral'

import { PLAN_NAMES } from '@Constants'

import './index.less'

const { Text } = Typography

// const CheckIfCustom = ({ plan, t }) =>
//   plan?.id === 0 ? <CheckOutlined /> : t('enterprise_feat')

// const CheckIfCustomOrBusiness = ({ plan }) => {
//   const { t } = useTranslation(['common'])
//   if (plan?.id === 0) {
//     return <>{t('custom')}</>
//   }
//   return (
//     <>
//       {plan?.name !== 'Business'
//         ? (
//           <CloseOutlined />
//           )
//         : (
//           <Text>{t('staging_site_number')}</Text>
//           )}
//     </>
//   )
// }

const PlanFeatureList = ({ plan, showFullComparison, classNames = '' }) => {
  const { t } = useTranslation(['common'])
  return (
    <ul className={`plan-feature-list ${classNames}`}>
      <li>
        <ul className='sl-feature-list'>
          {' '}
          <>
            <li key='websites' className='feature-sites'>
              <Text className='label'>{t('websites')}</Text>
              {plan?.id !== 0 ? (
                <Text className='sl-item-2'>
                  {plan?.websitesAmount > 1 ? (
                    <>
                      {t('up_to')}
                      {plan?.websitesAmount}
                    </>
                  ) : (
                    plan?.websitesAmount
                  )}
                </Text>
              ) : (
                <Text className='sl-item-2'>{t('custom')}</Text>
              )}
            </li>
            <li key='seats' className='feature-seats'>
              <Text className='label'>
                {t('seats')}
                <Tooltip title={t('seats_tooltip')} className='label'>
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </Text>
              {plan?.id !== 0 ? (
                <Text className='sl-item-1'>
                  {' '}
                  {plan?.usersAmount ? plan?.usersAmount : t('unlimited')}
                </Text>
              ) : (
                <Text className='sl-item-1'>{t('custom')}</Text>
              )}
            </li>
            <li key='visits' className='feature-sites'>
              <Text className='label'>{t('visits')}</Text>
              <Text className='sl-item-3'>
                {plan?.visitsAmount
                  ? numeral(plan?.visitsAmount).format('0,0')
                  : t('unlimited')}
              </Text>
            </li>
            <li key='storage' className='feature-sites'>
              <Text className='label'>{t('storage')}</Text>
              {plan?.id !== 0 ? (
                <Text className='sl-item-4'>{plan?.storageGb}GB</Text>
              ) : (
                <Text className='sl-item-4'>{t('custom')}</Text>
              )}
            </li>
            <li key='bandwidth' className='feature-bandwidth'>
              <Text className='label'>{t('cdn_bandwidth')}</Text>
              {plan?.id !== 0 ? (
                <Text className='sl-item-14'>
                  {plan &&
                    numeral(plan?.bandwidthGb * 1000 * 1000 * 1000).format(
                      '0b'
                    )}
                </Text>
              ) : (
                <Text className='sl-item-14'>{t('custom')}</Text>
              )}
            </li>
            {/* <li key='no_posts_page' className='feature-sites'>
              <Text className='label'>{t('no_posts_page')}</Text>
              {plan?.name === PLAN_NAMES.PLAN_ONE && <Text className='sl-item-5'>{t('upto_hundred')}</Text>}
              {plan?.name === PLAN_NAMES.PLAN_TWO && <Text className='sl-item-5'>{t('upto_fivehundred')}</Text>}
              {plan?.name === PLAN_NAMES.PLAN_THREE && <Text className='sl-item-5'>{t('upto_onek')}</Text>}
              {plan?.name === 'Enterprise' && <Text className='sl-item-5'>{t('custom')}</Text>}
            </li> */}
            <li key='full_site_pub' className='feature-sites'>
              <Text className='label'>{t('full_site_pub')}</Text>
              {plan?.name === PLAN_NAMES.PLAN_ONE && (
                <Text className='sl-item-5'>{t('upto_ten_month')}</Text>
              )}
              {plan?.name === PLAN_NAMES.PLAN_TWO && (
                <Text className='sl-item-5'>{t('upto_twenty_month')}</Text>
              )}
              {plan?.name === PLAN_NAMES.PLAN_THREE && (
                <Text className='sl-item-5'>{t('upto_fifty_month')}</Text>
              )}
              {plan?.name === 'Enterprise' && (
                <Text className='sl-item-5'>{t('custom')}</Text>
              )}
            </li>
            <li key='selective_quick_process' className='feature-sites'>
              <Text className='label'>{t('selective_quick_process')}</Text>
              <Text className='sl-item-5'>{t('unlimited')}</Text>
            </li>
            <li key='backup_freq' className='feature-sites'>
              <Text className='label'>{t('backup_freq')}</Text>
              {plan?.name === PLAN_NAMES.PLAN_ONE && (
                <Text className='sl-item-15'>{t('daily_fourteen')}</Text>
              )}
              {plan?.name === PLAN_NAMES.PLAN_TWO && (
                <Text className='sl-item-15'>{t('daily_thirty')}</Text>
              )}
              {plan?.name === PLAN_NAMES.PLAN_THREE && (
                <Text className='sl-item-15'>{t('daily_thirty')}</Text>
              )}
              {plan?.name === 'Enterprise' && (
                <Text className='sl-item-15'>{t('enterprise_backups')}</Text>
              )}
            </li>
            {/* <li key='cdn_limits' className='feature-cdn'>
              <Text className='label'>
                {t('cdn_limits')}
                <Tooltip title={t('cdn_limits_tooltip')} className='label'>
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </Text>
              {plan?.id !== 0
                ? (
                  <Text className='sl-item-6'>{t('limited_regions')}</Text>
                  )
                : (
                  <Text className='sl-item-6'>{t('global_cdn')}</Text>
                  )}
            </li>
            <li key='preview' className='feature-preview'>
              <Text className='label'>
                {t('preview_staging_site')}
                <Tooltip title={t('preview_tooltip')} className='label'>
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </Text>
              <Text className='sl-item-7'>
                <CheckOutlined />
              </Text>
            </li>
            <li key='ddos' className='feature-ddos'>
              <Text className='label'>{t('ddos_mitigation')}</Text>
              <Text className='sl-item-8'>
                <CheckIfCustom t={t} plan={plan} />
              </Text>
            </li>
            <li key='high-concurrency' className='feature-high-concurrency'>
              <Text className='label'>
                {t('high_concurrency_build')}
                <Tooltip
                  title={t('high_concurrency_build_tooltip')}
                  className='label'
                >
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </Text>
              <Text className='sl-item-9'>
                <CheckIfCustom t={t} plan={plan} />
              </Text>
            </li>
            <li key='s3-replication' className='feature-s3-replication'>
              <Text className='label'>
                {t('feature_s3_replication')}
                <Tooltip
                  title={t('feature_s3_replication_tooltip')}
                  className='label'
                >
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </Text>
              <Text className='sl-item-10'>
                <CheckIfCustom t={t} plan={plan} />
              </Text>
            </li>
            <li key='uptime' className='feature-uptime'>
              <Text className='label'>{t('uptime')}</Text>
              <Text className='sl-item-11'>
                {plan?.id === 0 ? '99.99%' : '99%'}
              </Text>
            </li>
            <li key='additional-staging' className='feature-additional-staging'>
              <Text className='label'>
                {t('additional_staging')}
                <Tooltip
                  title={t('additional_staging_tooltip')}
                  className='label'
                >
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </Text>
              <Text className='sl-item-12'>
                <CheckIfCustomOrBusiness plan={plan} />
              </Text>
            </li>
            <li key='aws-ssl' className='feature-aws-ssl'>
              <Text className='label'>
                {t('aws_ssl')}
                <Tooltip title={t('aws_ssl_tooltip')} className='label'>
                  {' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              </Text>
              <Text className='sl-item-13'>
                <CheckOutlined />
              </Text>
            </li>
             */}
            {/* move to bottom
              <li key='visits' className='feature-visits'>
                <Text className='label'>{t('visits_storage')}</Text>
                <Text>
                  {plan?.visitsAmount &&
                    numeral(plan.visitsAmount).format('0 ib')}
                </Text>
              </li> */}
          </>
        </ul>
      </li>
      {/* prep for v2.1
      {showFullComparison && (
        <>
          <li key='full_comparison' className='title'>
            <Title level={3} className='label'>
              {t('full_comparison')}
            </Title>
          </li>
          <li>
            <ul>
              <li key='custom_domains'>
                <Text className='label'>{t('custom_domains')}</Text>
                <CheckOutlined />
              </li>
            </ul>
          </li>
          <li key='performance' className='title'>
            <Title level={3} className='label'>
              {t('performance')}
            </Title>
          </li>
          <li>
            <ul>
              <li key='serverless_architecture'>
                <Text className='label'>{t('serverless_architecture')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='fullsite_cdn'>
                <Text className='label'>{t('fullsite_cdn')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='gzip_compression'>
                <Text className='label'>{t('gzip_compression')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='custom_publishing_optimization'>
                <Text className='label'>
                  {t('custom_publishing_optimization')}
                </Text>
                <Text>
                  <CheckIfCustom t={t} plan={plan} />
                </Text>
              </li>
            </ul>
          </li>
          <li key='workflows' className='title'>
            <Title level={3} className='label'>
              {t('workflows')}
            </Title>
          </li>
          <li>
            <ul>
              <li key='wp_staging_site'>
                <Text className='label'>{t('wp_staging_site')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='static_preview_site'>
                <Text className='label'>{t('static_preview_site')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='custom_domains_static_preview'>
                <Text className='label'>
                  {t('custom_domains_static_preview')}
                </Text>
                <Text>
                  <CheckIfCustom t={t} plan={plan} />
                </Text>
              </li>
              <li key='test_sites'>
                <Text className='label'>{t('test_sites')} ??</Text>
                <Text>{plan?.testSites}</Text>
              </li>
            </ul>
          </li>
          <li key='security' className='title'>
            <Title level={3} className='label'>
              {t('security')}
            </Title>
          </li>
          <li>
            <ul>
              <li key='free_SSL'>
                <Text className='label'>{t('free_SSL')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='sftp'>
                <Text className='label'>{t('sftp')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='containerized_wp'>
                <Text className='label'>{t('containerized_wp')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='auto_container_shutdown'>
                <Text className='label'>{t('auto_container_shutdown')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
            </ul>
          </li>
          <li key='backups' className='title'>
            <Title level={3} className='label'>
              {t('backups')}
            </Title>
          </li>
          <li>
            <ul>
              <li key='auto_backup_retention'>
                <Text className='label'>{t('auto_backup_retention')}</Text>
                <Text>{t('one_month')}</Text>
              </li>
              <li key='manual_backups'>
                <Text className='label'>{t('manual_backups')} ??</Text>
                <Text>
                  {plan?.manualBackups} {t('per_site')}
                </Text>
              </li>
            </ul>
          </li>
          <li key='support' className='title'>
            <Title level={3} className='label'>
              {t('support')}
            </Title>
          </li>
          <li>
            <ul>
              <li key='email_chat_support'>
                <Text className='label'>{t('email_chat_support')}</Text>
                <Text>
                  <CheckOutlined />
                </Text>
              </li>
              <li key='dedicated_account_manager'>
                <Text className='label'>{t('dedicated_account_manager')}</Text>
                <Text>
                  <CheckIfCustom t={t} plan={plan} />
                </Text>
              </li>
            </ul>
          </li>
        </>
      )} */}
    </ul>
  )
}

export default PlanFeatureList
