import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import AmplitudeProvider from './analyticsProviders/amplitudeProvider'
import AwsProvider from './analyticsProviders/awsProvider'
import GTMProvider from './analyticsProviders/googleTagManagerProvider'

import config from '@Lib/config'

const ANALYTICS_DISABLED = config.analytics.disabled

const ANALTYICS_ACTIVATED_KEY = 'analytics_activated'

class TheManager {
  initialized = false

  activated = false

  initialize = () => {
    if (ANALYTICS_DISABLED || this.initialized) {
      return
    }

    this.initialized = true

    this.activated = window.localStorage.getItem(ANALTYICS_ACTIVATED_KEY)

    // Set up all providers
    this.providers = [
      new GTMProvider(),
      new AmplitudeProvider(),
      new AwsProvider()
    ]

    this.providers.forEach(provider => {
      provider.initialize && provider.initialize()
    })

    if (this.activated) {
      this.enable()
    } else {
      this.disable()
    }
  }

  updateActivated = activated => {
    this.activated = activated
    window.localStorage.setItem(ANALTYICS_ACTIVATED_KEY, activated)
  }

  enable = () => {
    if (ANALYTICS_DISABLED) {
      return
    }

    this.updateActivated(true)

    this.providers.forEach(provider => {
      provider.enable && provider.enable()
    })
  }

  disable = () => {
    if (ANALYTICS_DISABLED) {
      return
    }

    this.updateActivated(false)

    this.providers.forEach(provider => {
      provider.disable && provider.disable()
    })
  }

  login = userInfo => {
    if (!userInfo) {
      return
    }

    // After logging in, all cookies are automatically accepted by the TOS.
    if (!this.activated) {
      this.enable()
    }

    this.providers.forEach(provider => {
      provider.login && provider.login(userInfo)
    })
  }

  logout = () => {
    if (!this.activated) {
      return
    }

    this.providers.forEach(provider => {
      provider.logout && provider.logout()
    })
  }

  trackEvent = (
    eventProperties,
    category = EVENT_CATEGORIES.UI_INTERACTION
  ) => {
    if (!this.activated) {
      return
    }

    this.providers.forEach(provider => {
      provider.trackEvent && provider.trackEvent(eventProperties, category)
    })
  }

  trackClick = name => {
    this.trackEvent(
      {
        action: 'click',
        label: name
      },
      EVENT_CATEGORIES.UI_INTERACTION
    )
  }
}

const AnalyticsManager = (() => {
  let instance
  return {
    getInstance: function () {
      if (!instance) {
        instance = new TheManager()
      }
      return instance
    }
  }
})()

export default AnalyticsManager
