import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Layout } from 'antd'

import { useUserContext } from '@Context/UserContext'

import MainMenu from '@Components/MainMenu'
import TopBar from '@Components/TopBar'

import './index.less'
import { TopWarningBar } from '@Components/TopWarningBar'

const { Content } = Layout

export const Template = ({ title, children, classNames }) => {
  const userContext = useUserContext()
  const [topPadding, setTopPadding] = useState(0)

  useEffect(() => {
    if (userContext.expirationBarPadding) {
      setTopPadding(56)
    } else {
      setTopPadding(0)
    }
    return () => {
      setTopPadding(0)
    }
  }, [userContext.expirationBarPadding])

  return (
    <>
      <TopWarningBar />
      <Layout style={{ paddingTop: topPadding }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <MainMenu />
        <Layout className={`page-wrapper ${classNames}`}>
          <TopBar />
          <Content>
            <div className='site-layout-content'>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
