import React, { useState, useEffect, useRef, Suspense } from 'react'
import Routes from '@Pages/Routes'

import { useLocation, useHistory } from 'react-router-dom'

import { Amplify, Hub, Auth } from 'aws-amplify'
import amplifyConfig from '@Lib/config/amplify'

import useStratticSession from '@Hooks/useStratticSession'
import useStratticAccount from '@Hooks/useStratticAccount'
import usePrevious from '@Hooks/usePrevious'
import useToggle from '@Hooks/useToggle'
import { IntercomProvider } from 'react-use-intercom'

import config from '@Lib/config'
import Session from '@Lib/session'
import { UserContext } from '@Context/UserContext'
import { MessageContext } from '@Context/MessageContext'
import { WindowDimensionsContext } from '@Context/WindowDimensionsContext'
import { ConfigProvider, message as AntMessage } from 'antd'
import AnalyticsManager from '@Managers/analyticsManager'
import useWindowDimensions from '@Hooks/useWindowDimensions'
import { submitHubspotForms } from '@Lib/api/hubspot'
import { isMethodEmailOrGoogle } from '@Lib/helpers/gtmEventHelpers'

import useAccountPaymentDetails from '@Hooks/useAccountPaymentDetails'

import './index.less'

// having 'code' in the params is breaking amplify
// https://github.com/aws-amplify/amplify-js/issues/3494
// https://github.com/aws-amplify/amplify-js/issues/9208
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth)

Auth._handleAuthResponse = url => {
  const configuration = Auth.configure()
  if (!url.includes(configuration.oauth.redirectSignIn)) return
  return _handleAuthResponse(url)
}

Amplify.configure(amplifyConfig)

const App = ({ kustomerIsStarted }) => {
  const location = useLocation()
  const history = useHistory()
  const isInitialMount = useRef(true)
  const [menuVisible, toggleMenuVisible] = useToggle()
  const [plans, setPlans] = useState(Session.get('plans'))
  // this was set to use message from local storage but I cannot find where its set
  // for now I am setting it as empty
  const [message, setMessage] = useState()
  const [cart, setCart] = useState(Session.get('cart'))
  const [language, setLanguage] = useState('en_US')
  const [expirationBarPadding, setExpirationBarPadding] = useState(false)
  const [
    { isAuthenticated, currentUserLoading, currentUser },
    login,
    clearSession
  ] = useStratticSession()
  const { height, width } = useWindowDimensions()
  const prevWidth = usePrevious(width)
  const [currentUserEmail, setCurrentUserEmail] = useState(currentUser?.email)
  const [pendingInvites, setPendingInvites] = useState(
    currentUser?.pendingInvitations
  )
  const [
    {
      currentAccountId,
      currentAccountLoading,
      lastUsedAccountId,
      accountPermissions,
      accountDetails
    },
    setCurrentAccountId,
    callCurrentAccount
  ] = useStratticAccount()
  const [accountId, setAccountId] = useState(currentAccountId)
  const [signinSuccess, setSigninSuccess] = useState(false)
  const [initialGoogleSignin, setInitialGoogleSignin] = useState(false)
  const [hostedUiLogin, setHostedUiLogin] = useState(false)
  const [autoSignIn, setAutoSignIn] = useState(false)
  const [{ paymentCardExpired }] = useAccountPaymentDetails(
    currentAccountId,
    accountDetails
  )
  useEffect(() => {
    const queryFromStorage = window.localStorage.getItem('queryParams')
    if (location?.search?.length && location.search !== queryFromStorage) {
      window.localStorage.setItem('queryParams', location.search)
    }
  }, [location])
  useEffect(() => {
    if (width !== prevWidth) {
      menuVisible && toggleMenuVisible()
    }
  }, [width, prevWidth, menuVisible, toggleMenuVisible])

  useEffect(() => {
    currentAccountId && setAccountId(currentAccountId)
  }, [currentAccountId, currentUser])

  const handleLogout = () => {
    clearSession()
    setAccountId(null)
  }
  useEffect(() => {
    if (currentUser) {
      setCurrentUserEmail(currentUser?.email)
      setPendingInvites(currentUser?.pendingInvitations)
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser && isAuthenticated) {
      let newAccountId = currentAccountId || lastUsedAccountId
      newAccountId = currentUser?.accounts?.find(i => i.id === newAccountId)?.id
      if (!newAccountId) {
        newAccountId =
          currentUser?.accounts?.length > 0
            ? currentUser?.accounts[0]?.id
            : null
      }

      if (newAccountId && newAccountId !== currentAccountId) {
        setCurrentAccountId(newAccountId)
      } else {
        callCurrentAccount()
      }
    }

    // eslint-disable-next-line
  }, [currentUser, isAuthenticated])

  useEffect(() => {
    if (!message) return
    if (isInitialMount.current) {
      isInitialMount.current = false
    }
    if (
      message === 'No current user' ||
      message.code === 'NotAuthorizedException'
    ) {
      clearSession()
    } else {
      try {
        AntMessage[message?.type](message?.value, message?.duration)
      } catch (e) {
        console.error(e)
        console.log('message', message)
      }
    }
    // eslint-disable-next-line
  }, [message])

  useEffect(() => {
    AnalyticsManager.getInstance().initialize()
  }, [])

  useEffect(() => {
    const HUB_CHANNEL = 'auth'
    const hubCallback = async ({ payload: { event, data } }) => {
      switch (event) {
        case 'autoSignIn': {
          setAutoSignIn(true)
          login(true)
          break
        }
        case 'autoSignIn_failure': {
          const queryParams = location.search
          history.push(`/signin${queryParams}`)
          break
        }
        case 'signIn': {
          setSigninSuccess(true)
          break
        }
        case 'cognitoHostedUI': {
          setHostedUiLogin(true)
          if (
            window.localStorage.getItem('inital-signup-provider') === 'Google'
          ) {
            setInitialGoogleSignin(true)
          }
          window.localStorage.removeItem('inital-signup-provider') // maybe on logout
          break
        }
        case 'oAuthSignOut': {
          window.localStorage.removeItem('externally-managed')
          window.localStorage.removeItem('okta')
          break
        }
        default:
          console.log('event not handled', event)
      }
    }
    Hub.listen(HUB_CHANNEL, hubCallback)
    return () => {
      Hub.remove(HUB_CHANNEL, hubCallback)
    }
    // eslint-disable-next-line
  }, [login])

  useEffect(() => {
    if (
      signinSuccess &&
      currentUser?.id &&
      !initialGoogleSignin &&
      !autoSignIn
    ) {
      setSigninSuccess(false)
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'login_success',
        action_type: 'login_success',
        user_id: currentUser?.id,
        user_email: currentUser?.email,
        method: hostedUiLogin ? isMethodEmailOrGoogle() : 'email'
      })
    }
    if (initialGoogleSignin && currentUser?.id) {
      setSigninSuccess(false)
      setInitialGoogleSignin(false)
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'signup_success',
        action_type: 'signup_success',
        user_id: currentUser?.id,
        user_email: currentUser?.email,
        method: 'google'
      })
      const hubspotFields = {
        email: currentUser?.email,
        username: currentUser?.name,
        loginMethod: 'google'
      }
      submitHubspotForms(config?.hubspot?.signUpForm, hubspotFields)
    }
  }, [
    signinSuccess,
    currentUser,
    initialGoogleSignin,
    hostedUiLogin,
    autoSignIn
  ])

  return (
    <WindowDimensionsContext.Provider
      value={{
        height,
        width,
        menuVisible,
        toggleMenuVisible
      }}
    >
      <MessageContext.Provider
        value={{
          message,
          setMessage
        }}
      >
        <UserContext.Provider
          value={{
            isAuthenticated,
            login,
            clearSession,
            accountId,
            setCurrentAccountId,
            accountDetails,
            accountPermissions,
            paymentCardExpired,
            currentUserEmail,
            pendingInvites,
            language,
            setLanguage,
            plans,
            setPlans,
            cart,
            setCart,
            currentUser,
            currentUserLoading,
            currentAccountLoading,
            expirationBarPadding,
            setExpirationBarPadding,
            handleLogout
          }}
        >
          <ConfigProvider locale={language}>
            <Suspense fallback='loading'>
              <IntercomProvider appId={config.intercomAppId}>
                <Routes
                  kustomerIsStarted={kustomerIsStarted}
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                  planName={accountDetails?.planName}
                  currentAccountId={currentAccountId}
                  setCurrentAccountId={setCurrentAccountId}
                  accountDetails={accountDetails}
                />
              </IntercomProvider>
            </Suspense>
          </ConfigProvider>
        </UserContext.Provider>
      </MessageContext.Provider>
    </WindowDimensionsContext.Provider>
  )
}

export default App
