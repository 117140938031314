export const EVENT_CATEGORIES = {
  USER_MANAGEMENT: 'user_management',
  UI_INTERACTION: 'ui_interaction',
  SIGN_UP: 'sign_up',
  LOG_IN: 'log_in',
  ACCOUNT_PAGE: 'account_page',
  FUTURE_SUB: 'future_subscription',
  TEAM_MANAGEMENT: 'team_management',
  CHECKOUT: 'checkout',
  RESET_PASSWORD: 'reset_password',
  CONNECT_DOMAIN: 'connect_domain'
}
