import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'

import './index.less'

export function RestoreInProgress ({ t, sideBar }) {
  return (
    <div className={`restore-progress ${sideBar ? 'restore-side-bar' : ''}`}>
      <LoadingOutlined /> {t('restore_in_progress')}
    </div>
  )
}
