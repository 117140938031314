import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@Context/UserContext'

import { Modal, Button, Spin, Typography } from 'antd'
import StratticName from '@Images/logos/StratticName'
import Success from '@Images/icons/success-tick.svg'
import {
  billingChangeMessage,
  formatDate
} from '@Lib/helpers/subscriptionHelpers'
import { pluralStringIfNeeded } from '@Lib/helpers'

const { Text, Title } = Typography

const PaymentLoadingModal = ({
  showLoadingModal,
  processing,
  error,
  isFutureSubscription
}) => {
  const userContext = useUserContext()
  const [currentAccount] = useState(userContext?.accountDetails)
  const [cart] = useState(userContext?.cart)

  return (
    <Modal
      open={showLoadingModal}
      closable={false}
      footer={false}
      bodyStyle={{ textAlign: 'center', minHeight: '30vw' }}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      className='payment-loading-modal'
    >
      {processing ? (
        <Processing isFutureSubscription={isFutureSubscription} />
      ) : error ? (
        <ErrorMessage />
      ) : (
        <SuccessMessage
          isFutureSubscription={isFutureSubscription}
          currentAccount={currentAccount}
          cart={cart}
        />
      )}
    </Modal>
  )
}

const Processing = ({ isFutureSubscription }) => {
  const { t } = useTranslation(['common'])
  return (
    <div>
      <StratticName />
      <p className='loading-text'>
        <Text>{isFutureSubscription ? t('updating') : t('cc_processing')}</Text>
      </p>
      <Spin tip={t('processing')} />
    </div>
  )
}

const ErrorMessage = () => {
  const { t } = useTranslation(['common'])
  return (
    <>
      <p className='sl-sorryText sorry-text'>{t('sorry_upgrade')}</p>
      <p className='sl-contactSupport'>
        {t('please_contact')}{' '}
        <a href='mailto:support@strattic.com'>support@strattic.com</a>{' '}
        {t('happy_to_help')}
      </p>
      <Button
        className='sl-returnToStratticButton return-to-strattic'
        href='/account'
        type='primary'
      >
        {t('return_to_strattic')}
      </Button>
    </>
  )
}

const SuccessMessage = ({ isFutureSubscription, currentAccount, cart }) => {
  const { t } = useTranslation(['common'])
  return (
    <>
      <Success />
      {isFutureSubscription ? (
        <>
          <Title level={4} className='sl-awesomeText'>
            {t('success_exclamation')}
          </Title>
          <br />
          <div className='billing_change_message sl-billing_change_message'>
            {!billingChangeMessage(currentAccount, cart)
              ? t('billing_cycle_change_message', {
                  currentPlanName: currentAccount?.planName,
                  dateString: formatDate(cart?.startDate)
                })
              : t('billing_change_message', {
                  currentPlanName: currentAccount?.planName,
                  currentSiteAllowance: currentAccount?.sitesUsage?.max,
                  currentSiteOrSites: pluralStringIfNeeded(
                    'site',
                    currentAccount?.sitesUsage?.max
                  ),
                  cartPlanName: cart?.name,
                  cartAllowanceSites: cart?.allowances?.websitesAmount,
                  siteOrSites: pluralStringIfNeeded(
                    'site',
                    cart?.allowances?.websitesAmount
                  ),
                  startDate: formatDate(cart?.startDate)
                })}
          </div>
        </>
      ) : (
        <>
          <Title level={4} className='sl-awesomeText'>
            {t('you_awesome')} {t('thank_you')}
          </Title>
          <br />
          <p className='sl-planName'>
            {t('you_are_now_signed_up_plan', {
              planName: cart?.name
            })}
          </p>
          <p className='sl-joiningStrattic'>{t('thanks_again')} </p>
        </>
      )}
      <p className='sl-customerSupport for-any-q'>
        {t('for_any_questions')}{' '}
        <a href='mailto:support@strattic.com'>support@strattic.com</a>
      </p>
      <Button
        className='sl-returnToStratticButton return-to-strattic'
        href='/account'
        type='primary'
      >
        {t('return_to_strattic')}
      </Button>
    </>
  )
}

export default PaymentLoadingModal
