import React, { useReducer } from 'react'
import { DateTime } from 'luxon'

const RulesetContext = React.createContext()

const ACTIONS = {
  GET_RULESET: 'get_ruleset',
  DELETE_RULE: 'delete_rule',
  ADD_RULE: 'add_rule',
  UPDATE_RULE: 'update_rule',
  REORDER_RULESET: 'reorder_ruleset'
}

const deepClone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

const rulesetReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.DELETE_RULE: {
      const newState = deepClone(state)
      const ruleToDelete = newState.rules.find(
        rule => rule.id === action.ruleId
      )
      const index = newState.rules.indexOf(ruleToDelete)
      if (index > -1) {
        newState.rules.splice(index, 1)
      }
      newState.hasUndeployedChanges = true
      newState.optimisticLockVersion++
      return newState
    }
    case ACTIONS.ADD_RULE: {
      const newState = deepClone(state)
      action.data.lastUpdated = DateTime.utc().toUnixInteger()
      newState.rules.push(Object.assign({ status: 'active' }, action.data))
      newState.hasUndeployedChanges = true
      newState.optimisticLockVersion++
      return newState
    }
    case ACTIONS.UPDATE_RULE: {
      const newState = deepClone(state)
      const ruleToUpdate = newState.rules.find(
        rule => rule.id === action.data.id
      )
      ruleToUpdate.lastUpdated = DateTime.utc().toUnixInteger()
      Object.assign(ruleToUpdate, action.data)
      newState.hasUndeployedChanges = true
      newState.optimisticLockVersion++
      return newState
    }
    case ACTIONS.GET_RULESET: {
      return deepClone(action.ruleset)
    }
    case ACTIONS.REORDER_RULESET: {
      const newState = deepClone(state)
      const ruleToMove = newState.rules.find(rule => rule.id === action.ruleId)
      const oldIndex = newState.rules.indexOf(ruleToMove)

      newState.rules.splice(oldIndex, 1)
      newState.rules.splice(action.newIndex, 0, ruleToMove)
      newState.hasUndeployedChanges = true
      newState.optimisticLockVersion++
      return newState
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const RulesetProvider = ({ children, API }) => {
  const api = {
    getRules: API.useGetRules(),
    deployRuleset: API.useDeployRuleset(),
    revertChanges: API.useRevertChanges(),
    deleteRule: API.useDeleteRule(),
    reorderRuleset: API.useReorderRuleset(),
    importRuleset: API.useImportRuleset(),
    addRule: API.useAddRule(),
    updateRule: API.useUpdateRule()
  }

  const [state, dispatch] = useReducer(rulesetReducer, {})

  const { doCall: getRules } = api.getRules
  api.getRules.doCall = (ruleType) => {
    return getRules(ruleType).then(response => {
      if (response?.data.id) {
        dispatch({
          type: ACTIONS.GET_RULESET,
          ruleset: response.data
        })
      }
      return response
    })
  }

  const { doCall: deleteRule } = api.deleteRule
  api.deleteRule.doCall = (ruleset, ruleId) => {
    return deleteRule(ruleset, ruleId).then(response => {
      if (response) {
        dispatch({
          type: ACTIONS.DELETE_RULE,
          ruleId
        })
      }
      return response
    })
  }

  const { doCall: addRule } = api.addRule
  api.addRule.doCall = (ruleset, data) => {
    return addRule(ruleset, data).then(response => {
      if (response?.data) {
        const newRuleset = { ...data, ...{ id: response.data.id } }
        dispatch({
          type: ACTIONS.ADD_RULE,
          data: newRuleset
        })

        return newRuleset
      }
      return response
    })
  }

  const { doCall: updateRule } = api.updateRule
  api.updateRule.doCall = (ruleset, rule, data) => {
    return updateRule(ruleset, rule, data).then(response => {
      if (response) {
        const updatedRule = { ...data, ...{ id: rule.id } }
        dispatch({
          type: ACTIONS.UPDATE_RULE,
          data: updatedRule
        })
        return updatedRule
      }
      return response
    })
  }

  const { doCall: reorderRuleset } = api.reorderRuleset
  api.reorderRuleset.doCall = (ruleset, ruleId, newIndex) => {
    return reorderRuleset(ruleset, ruleId, newIndex).then(response => {
      if (response) {
        dispatch({
          type: ACTIONS.REORDER_RULESET,
          ruleId,
          newIndex
        })
      }
      return response
    })
  }

  const value = { state, dispatch, api }
  return (
    <RulesetContext.Provider value={value}>{children}</RulesetContext.Provider>
  )
}

export { RulesetContext, RulesetProvider, ACTIONS }
