import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Card, Typography } from 'antd'
import { getDate } from '@Lib/dates'
import Clock from '@Images/icons/clocksmall.svg'
import { SUBSCRIPTION_STATUS } from '@Constants'

import './index.less'

const { Title, Text } = Typography

const FreeTrialCard = ({ accountDetails, hasPermissionToUpgrade }) => {
  const { t } = useTranslation(['common'])
  const [isExpired, setIsExpired] = useState(
    accountDetails?.value?.subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED
  )

  useEffect(() => {
    if (
      accountDetails?.value?.subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED
    ) {
      setIsExpired(true)
    } else {
      setIsExpired(false)
    }
  }, [accountDetails])

  return (
    <Card
      className={`free-trial ${isExpired ? 'expired' : 'not-expired'}`}
      title={t('plan_details')}
    >
      {isExpired && <Clock className='clock' />}
      <Title level={3} className='sl-plan-name'>
        {accountDetails?.value?.planName} {isExpired && t('expired')}
      </Title>
      <Text
        className={`sl-start-end sl-start-${getDate(
          accountDetails?.value?.planStartDate
        )} sl-end-${getDate(accountDetails?.value?.planEndDate)}`}
      >
        {t('free_trial_description', {
          startedOn: getDate(accountDetails?.value?.planStartDate),
          expires: isExpired ? t('expired_regular') : t('expires'),
          expiresOn: getDate(accountDetails?.value?.planEndDate)
        })}
      </Text>
      {isExpired ? (
        <>
          <Text className='need-more-time'>{t('need_more_time')}</Text>
          {hasPermissionToUpgrade && (
            <Button type='link'>
              <Link
                to={`/account/${accountDetails?.value?.accountId}/available-plans`}
              >
                {t('browse_plans')}
              </Link>
            </Button>
          )}
        </>
      ) : hasPermissionToUpgrade ? (
        <Button type='link' className='sl-upgrade-now'>
          <Link
            to={`/account/${accountDetails?.value?.accountId}/available-plans`}
          >
            {t('upgrade_now')}
          </Link>
        </Button>
      ) : (
        <div className='contact-acc-owner'>
          <Text>{t('contact_acc_owner')}</Text>
        </div>
      )}
    </Card>
  )
}

export default FreeTrialCard
