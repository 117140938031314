import { Analytics } from 'aws-amplify'

export default class AwsProvider {
  enable = () => {
    Analytics.enable()
  }

  disable = () => {
    Analytics.disable()
  }
}
