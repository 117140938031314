import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Popover } from 'antd'
import { CheckSquareTwoTone, CloseSquareTwoTone } from '@ant-design/icons'
import './index.less'

const CheckIcon = () => <CheckSquareTwoTone twoToneColor='green' />
const CloseIcon = () => <CloseSquareTwoTone twoToneColor='red' />

export const Overlay = ({ password }) => {
  const { t } = useTranslation(['common'])

  return (
    <div className='password-strength'>
      <p>Required</p>
      <ul>
        <li>
          <IsLongEnough password={password} /> {t('eight_characters')}
        </li>
        <li>
          <HasLowerCase password={password} /> {t('contains_lowercase')}
        </li>
        <li>
          <HasUpperCase password={password} /> {t('contains_uppercase')}
        </li>
        <li>
          <HasNumber password={password} /> {t('contains_number')}
        </li>
      </ul>
    </div>
  )
}

const IsLongEnough = ({ password }) => {
  if (password?.length > 7) return <CheckIcon />
  else return <CloseIcon />
}
const HasLowerCase = ({ password }) => {
  if (/(?=.*[a-z])/.test(password)) return <CheckIcon />
  else return <CloseIcon />
}
const HasUpperCase = ({ password }) => {
  if (/(?=.*[A-Z])/.test(password)) return <CheckIcon />
  else return <CloseIcon />
}
const HasNumber = ({ password }) => {
  if (/(?=.*[0-9])/.test(password)) return <CheckIcon />
  else return <CloseIcon />
}

const validPassword = password => {
  return (
    password?.length > 7 &&
    /(?=.*[a-z])/.test(password) &&
    /(?=.*[A-Z])/.test(password) &&
    /(?=.*[0-9])/.test(password)
  )
}

const PasswordStrengthTooltip = ({ children, password }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isInitial, setIsInitial] = useState(true)
  const timer = useRef(null)

  useEffect(() => {
    setIsVisible(false)

    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const hideWithTimeout = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setIsVisible(false)
    }, 1000)
  }

  const show = () => {
    setIsInitial(false)
    clearTimeout(timer.current)
    setIsVisible(true)
  }

  useEffect(() => {
    if (isInitial) {
      setIsVisible(false)
    } else {
      if (validPassword(password)) {
        hideWithTimeout()
      } else {
        show()
      }
    }
  }, [password, isInitial])

  const onVisibleChange = visible => {
    if (visible) {
      if (validPassword(password)) {
        show()
        hideWithTimeout()
      } else {
        show()
      }
    } else {
      setIsVisible(false)
    }
  }

  return (
    <Popover
      overlayClassName='password-strength-tooltip'
      placement='bottomLeft'
      content={<Overlay password={password} />}
      open={isVisible}
      onOpenChange={onVisibleChange}
      trigger='focus'
    >
      {children}
    </Popover>
  )
}

export default PasswordStrengthTooltip
