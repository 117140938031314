import { useState, useEffect, useCallback } from 'react'

export default function useLocalStorage (
  key,
  initialValue,
  persistClear = false
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      let value = initialValue
      if (item) {
        try {
          value = JSON.parse(item)
        } catch (error) {}
      }
      return value
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    value => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        console.error(error)
      }
    },
    [setStoredValue, key, storedValue]
  )

  useEffect(() => {
    const listener = event => {
      if (event.key === key) {
        let value = event.newValue
        try {
          value = JSON.parse(value)
        } catch (error) {}
        setStoredValue(value)
      } else if (!event.key) {
        setStoredValue(null)
      }
    }
    window.addEventListener('storage', listener)
    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [key])

  useEffect(() => {
    const clearListener = () => {
      if (!persistClear) {
        setValue(null)
        window.localStorage.removeItem(key)
      }
    }
    window.addEventListener('clear-localStorage', clearListener)
    return () => {
      window.removeEventListener('clear-localStorage', clearListener)
    }
  }, [key, persistClear, setValue])

  return [storedValue, setValue]
}
