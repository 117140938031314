import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography, Input, Row, Col, Form, message } from 'antd'
import { EnterOutlined } from '@ant-design/icons'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { EditButtonIcon } from '../Icons'

const { Title } = Typography

const SiteName = ({ canUpdate, displayName, siteId }) => {
  const { t } = useTranslation(['common', 'errors'])
  const [isEditing, setIsEditing] = useState(false)
  const [siteDisplayName, setSiteDisplayName] = useState()

  const path = `sites/${siteId}`
  const [
    { loading: isApiLoading, error: apiError },
    executePut
  ] = useStratticApi(
    {
      url: path,
      method: 'PUT'
    },
    { manual: true }
  )

  useErrorMessage(apiError)

  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        isEditing
      ) {
        cancelEditing()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, isEditing])

  useEffect(() => {
    setSiteDisplayName(displayName)
    // eslint-disable-next-line
  }, [displayName])

  const startEditing = () => {
    setIsEditing(true)
  }

  const cancelEditing = () => {
    setIsEditing(false)
  }

  const onFinish = async values => {
    const result = await executePut({ data: { displayName: values.site_name } })
    if (result) {
      message.success(t('site_name_update_success'))
      setSiteDisplayName(values.site_name)
      cancelEditing()
    }
  }

  return (
    <div ref={wrapperRef}>
      {isEditing ? (
        <EditForm
          t={t}
          displayName={siteDisplayName}
          isApiLoading={isApiLoading}
          onFinish={onFinish}
        />
      ) : (
        <Data
          t={t}
          displayName={siteDisplayName}
          canUpdate={canUpdate}
          startEditing={startEditing}
        />
      )}
    </div>
  )
}

const EditForm = ({ onFinish, displayName, isApiLoading, t }) => {
  const minMaxRule = {
    pattern: /^.{3,45}$/,
    message: t('min_max')
  }

  return (
    <Form layout='inline' onFinish={onFinish}>
      <Input.Group>
        <Row>
          <Col>
            <Form.Item
              initialValue={displayName}
              name='site_name'
              rules={[
                {
                  required: true,
                  message: t('field_required')
                },
                minMaxRule
              ]}
              validateTrigger='onChange'
            >
              <Input
                suffix={
                  <Button
                    type='link'
                    size='small'
                    loading={isApiLoading}
                    block
                    htmlType='submit'
                    style={{ margin: 0, minWidth: 'auto' }}
                    className='submit-billing-email'
                  >
                    <EnterOutlined />
                  </Button>
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
    </Form>
  )
}
const Data = ({ t, displayName, canUpdate, startEditing }) => (
  <Title level={2}>
    {displayName}
    {displayName && canUpdate && (
      <>
        {' '}
        <Button type='link' className='edit-link' onClick={startEditing}>
          <EditButtonIcon />
        </Button>
      </>
    )}
  </Title>
)

export default SiteName
