import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, message } from 'antd'
import { RulesetContext } from '../../RulesetContext'

const MoveDownButton = ({ record, index }) => {
  const { t } = useTranslation(['common'])
  const { state: ruleset, api } = useContext(RulesetContext)
  const { doCall: reorderRuleset } = api.reorderRuleset

  const doReorder = (rule, newIndex) => {
    reorderRuleset(ruleset, rule.id, newIndex).then(response => {
      if (response) {
        message.success(t('ruleset_reordered_successfully'))
      }
    })
  }

  const moveDown = (rule, index) => {
    if (index >= ruleset.rules.length - 1) return
    const newIndex = index + 1
    doReorder(rule, newIndex)
  }

  return (
    <Button
      className='sl-moveDown'
      type='link'
      onClick={() => {
        moveDown(record, index)
      }}
    >
      {t('move_down')}
    </Button>
  )
}

export default MoveDownButton
