import React, { useEffect, useState } from 'react'
import PubSub from 'pubsub-js'
import { SITE_EVENTS } from '../constants/WebsocketEvents'

const SiteContext = React.createContext()

const SiteProvider = ({ siteData, children, refetchCurrentSite }) => {
  const [status, setStatus] = useState(siteData?.status)
  const onStatusChanged = (topic, event) => {
    if (event.aggregateId !== siteData?.id) {
      return
    }
    setStatus(event.data.newStatus)
  }
  useEffect(() => {
    let subscription
    if (siteData) subscription = PubSub.subscribe(SITE_EVENTS.SITE_STATUS_CHANGED, onStatusChanged)
    return () => {
      PubSub.unsubscribe(subscription)
    }
    // eslint-disable-next-line
  }, [siteData])

  const value = { status, refetchCurrentSite }
  return (
    <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
  )
}

export { SiteProvider, SiteContext }
