import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import {
  Modal,
  Button,
  Form,
  Typography,
  Input,
  Tooltip,
  Divider,
  Alert,
  Select,
  Row,
  Col
} from 'antd'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { useMessageContext } from '@Context/MessageContext'
import useExpiredSubscriptionTooltip from '@Hooks/useExpiredSubscriptionTooltip'
import { useUserContext } from '@Context/UserContext'
import useSites from '@Hooks/useSites'

import { SUBSCRIPTION_STATUS, FORM_STATUS, SITE_STATUS } from '@Constants'
import { formatArrLiveDistDomainName } from '@Lib/helpers/sites'

import './index.less'

const { Text, Title } = Typography

const RestoreForm = ({
  currentSite,
  isModalVisible,
  triggerRestoreBackup,
  handleCancel,
  siteName,
  isCreating,
  selectedForRestore
}) => {
  const { t } = useTranslation(['common'])
  const [form] = Form.useForm()
  const [restoreToCurrentSite, setRestoreToCurrentSite] = useState('true')
  const currentSiteName = currentSite?.value?.liveDistribution?.domainName
  const currentSiteId = currentSite?.value?.siteId
  const [sites] = useSites()
  const [formattedSitesArr, setFormattedSitesArr] = useState([])
  const [targetSiteId, setTargetSiteId] = useState(false)
  const [targetSiteName, setTargetSiteName] = useState(siteName)

  useEffect(() => {
    if (sites?.value) {
      const formattedArr = formatArrLiveDistDomainName(sites.value)
      const filterCurrentSite = formattedArr?.filter(
        site => site.id !== currentSiteId
      )
      setFormattedSitesArr(filterCurrentSite)
    }
  }, [sites, currentSiteId])

  return (
    <Modal
      destroyOnClose
      width={825}
      height={478}
      open={isModalVisible}
      onCancel={() => {
        handleCancel()
        setRestoreToCurrentSite('true')
        setTargetSiteId(false)
        setTargetSiteName(siteName)
      }}
      footer={
        <div className='modal-footer'>
          <Button
            disabled={restoreToCurrentSite === 'false' && !targetSiteId}
            loading={isCreating}
            className='confirm-btn'
            key='submit'
            type='primary'
            onClick={() => {
              form
                .validateFields()
                .then(async values => {
                  await triggerRestoreBackup({
                    targetSiteId,
                    siteDisplayName: values.siteDisplayName
                  })
                  form.resetFields()
                })
                .catch(info => {
                  console.error('Validate Failed:', info)
                })
            }}
          >
            {t('restore_now')}
          </Button>
          <Button
            disabled={isCreating}
            key='back'
            onClick={() => {
              handleCancel()
              setRestoreToCurrentSite('true')
            }}
          >
            {t('cancel')}
          </Button>
        </div>
      }
      bodyStyle={{ textAlign: 'center', paddingBottom: 0 }}
      className='trigger-restore-modal'
    >
      <>
        <Title level={3}>{t('restore_dialog_header')}</Title>
        <div className='site-restore-details'>
          <Text strong>{t('you_are_about_to_restore')}</Text>
          <br />
          <Text>
            {t('site')}: {siteName}
          </Text>
          <br />
          <Text>
            {t('from_backup')}:{' '}
            {selectedForRestore?.lastCreated
              ? selectedForRestore?.lastCreated
              : selectedForRestore?.createdAt}{' '}
            - {selectedForRestore?.description}
          </Text>
          <br />
          <Row style={{ marginTop: 10 }}>
            <Col
              style={{
                paddingTop: 6
              }}
            >
              <Text strong>{t('to_site')}:</Text>
            </Col>
            <Col>
              <Select
                defaultValue='true'
                style={{
                  width: 350,
                  marginLeft: 7,
                  marginBottom: 14
                }}
                options={[
                  {
                    value: 'true',
                    label: t('this_site_domain', { domain: currentSiteName })
                  },
                  {
                    value: 'false',
                    label: t('diff_site_on_acc')
                  }
                ]}
                onChange={e => {
                  setRestoreToCurrentSite(e)
                  setTargetSiteId(false)
                  setTargetSiteName(e === 'true' ? siteName : false)
                }}
                disabled={!formattedSitesArr.length}
              />
            </Col>
          </Row>

          {restoreToCurrentSite === 'false' && (
            <Row>
              <Col offset={2}>
                <Select
                  placeholder='Please select a site'
                  style={{
                    width: 350,
                    marginBottom: 14
                  }}
                  options={formattedSitesArr.map(site => {
                    return {
                      value: site.id,
                      label: site.displayName + ' (' + site.domainName + ')',
                      title: site.displayName,
                      disabled: site.status !== 'active'
                    }
                  })}
                  disabled={!formattedSitesArr.length}
                  labelInValue
                  onChange={e => {
                    setTargetSiteName(e.title)
                    setTargetSiteId(e.value)
                  }}
                />
              </Col>
            </Row>
          )}
          <Text strong> {t('static_sites_not_effected')}</Text>
          <Divider />
          <Alert
            type='info'
            showIcon
            message={<>{t('trigger_restore_for_site')}</>}
          />
        </div>
        <Text strong>
          <br />
          {t('anything_not_backed_up')}
          <br />
        </Text>
        <br />
        {targetSiteName && (
          <div className='form-section'>
            <Text strong>
              {t('type_siteName_to_restore', { targetSiteName })}
            </Text>
            <Form
              form={form}
              layout='vertical'
              name='restore_form_in_modal'
              requiredMark={false}
            >
              <Form.Item
                name='siteDisplayName'
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || !value.length) {
                        return Promise.reject(new Error(t('field_required')))
                      }
                      if (value !== targetSiteName) {
                        return Promise.reject(new Error(t('incorrect_input')))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input width={256} />
              </Form.Item>
            </Form>
          </div>
        )}
      </>
    </Modal>
  )
}

const TriggerRestore = ({
  currentSite,
  fetchBackups,
  selectedRowKeys,
  refetchCurrentSite,
  resetSelectedRows,
  selectedForRestore
}) => {
  const { t } = useTranslation(['common'])
  const messageContext = useMessageContext()
  const userContext = useUserContext()
  const expiredTooltip = useExpiredSubscriptionTooltip()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isExpired, setIsExpired] = useState()
  const showModal = () => {
    setIsModalVisible(true)
  }

  const [
    { data, loading: isCreating, error: CreatingError },
    initiateRestore
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(CreatingError)

  useEffect(() => {
    if (data?.result) {
      fetchBackups()
      messageContext.setMessage({
        value: t('restore_started'),
        type: FORM_STATUS.SUCCESS
      })
    }
    // eslint-disable-next-line
  }, [data])

  const triggerRestoreBackup = async value => {
    const body = {
      siteDisplayName: value?.siteDisplayName,
      backupId: selectedRowKeys[0]
    }
    let targetSiteId = currentSite?.value?.siteId
    if (value.targetSiteId) {
      targetSiteId = value.targetSiteId
    }
    try {
      await initiateRestore({
        url: `sites/${targetSiteId}/restore`,
        method: 'POST',
        data: body
      })
      refetchCurrentSite()
      resetSelectedRows()
      setIsModalVisible(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    setIsExpired(
      userContext.accountDetails?.subscriptionStatus ===
        SUBSCRIPTION_STATUS.EXPIRED
    )
  }, [userContext.accountDetails])

  if (currentSite?.value?.status === SITE_STATUS.RESTORING) {
    return (
      <Tooltip title={t('restore_in_progress_tooltip')}>
        <Button disabled type='primary' style={{ marginRight: 15 }}>
          {t('restore')}
        </Button>
      </Tooltip>
    )
  }
  return (
    <>
      <Button
        onClick={showModal}
        disabled={
          isCreating ||
          isExpired ||
          selectedRowKeys?.length !== 1 ||
          selectedForRestore?.status !== 'completed'
        }
        title={isExpired ? expiredTooltip : undefined}
      >
        {t('restore')}
      </Button>
      <RestoreForm
        currentSite={currentSite}
        selectedForRestore={selectedForRestore}
        isCreating={isCreating}
        siteName={currentSite?.value?.name}
        isModalVisible={isModalVisible}
        triggerRestoreBackup={triggerRestoreBackup}
        handleCancel={handleCancel}
      />
    </>
  )
}

export default TriggerRestore
