import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Typography, Collapse, Divider, Button } from 'antd'

import { useUserContext } from '@Context/UserContext'
import AddSiteCard from '@Components/AddSiteCard'
import VideoModalCard from '@Components/VideoModal'
import {
  videoHelp,
  faqContent,
  faqContentNoAcc
} from '@Lib/config/getting-started'

import './index.less'
import { isMethodEmailOrGoogle } from '../../lib/helpers/gtmEventHelpers'

const { Title, Text } = Typography
const { Panel } = Collapse

const EmptyDashWithFAQ = ({ accountId }) => {
  const { t } = useTranslation(['common', 'errors'])
  const show = () => window.Kustomer?.open()

  if (accountId) {
    return <WithAccount accountId={accountId} t={t} showChat={show} />
  }
  return <WithoutAccount t={t} showChat={show} />
}

const WithAccount = ({ accountId, t, showChat }) => {
  return (
    <>
      <AddSiteCard accountId={accountId} />
      <Title level={4} className='getting-started-title'>
        {t('getting_started')}
      </Title>
      <Row>
        {videoHelp?.map((card, index) => (
          <VideoModalCard
            key={index}
            imageSrc={card.imageSrc}
            altTxt={t(card.altTx)}
            videoLength={card.videoLength}
            videoDescription={t(card.videoDescription)}
            videoUrl={card.videoUrl}
          />
        ))}
      </Row>

      <Divider type='horizontal' />
      <Title level={4} className='faq-title'>
        {t('faq')}
      </Title>
      <Collapse
        defaultActiveKey={['0']}
        accordion
        className='site-collapse-custom-collapse'
        bordered={false}
        expandIconPosition='right'
      >
        {faqContent?.map((panel, index) => (
          <Panel
            header={t(panel.header)}
            key={index}
            className='site-collapse-custom-panel'
          >
            <Text>{t(panel.content)}</Text>{' '}
            {panel.intercomLink && (
              <Button
                id='launch-chat-btn'
                type='link'
                onClick={() => showChat()}
              >
                {t(panel.intercomLink)}
              </Button>
            )}{' '}
            <Text>{t(panel.content_2)}</Text>
            <a
              href={panel.linkAddress}
              target={panel.newTab && '_blank'}
              rel={panel.newTab && 'noopener noreferrer'}
            >
              {t(panel.linkTitle)}
            </a>{' '}
            <Text>{t(panel.secondaryContent)}</Text>
          </Panel>
        ))}
      </Collapse>
    </>
  )
}

const WithoutAccount = ({ t, showChat }) => {
  const userContext = useUserContext()
  useEffect(() => {
    if (userContext?.currentUser) {
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'update_organization_impression',
        action_type: 'update_organization_impression',
        user_id: userContext?.currentUser?.id,
        user_email: userContext?.currentUserEmail,
        method: isMethodEmailOrGoogle()
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='set-org-name'>
        <Text id='part-of-org'>{t('already_part_of_org')}</Text>
      </div>
      <Divider type='horizontal' />
      <Title level={4} className='getting-started-title'>
        {t('getting_started')}
      </Title>
      <Row>
        {videoHelp?.map((card, index) => (
          <VideoModalCard
            key={index}
            imageSrc={card.imageSrc}
            altTxt={t(card.altTx)}
            videoLength={card.videoLength}
            videoDescription={t(card.videoDescription)}
            videoUrl={card.videoUrl}
          />
        ))}
      </Row>

      <Divider type='horizontal' />
      <Title level={4} className='faq-title'>
        {t('faq')}
      </Title>
      <Collapse
        defaultActiveKey={['0']}
        accordion
        className='site-collapse-custom-collapse'
        bordered={false}
        expandIconPosition='right'
      >
        {faqContentNoAcc?.map((panel, index) => (
          <Panel
            header={t(panel.header)}
            key={index}
            className='site-collapse-custom-panel'
          >
            <Text>{t(panel.content)}</Text>{' '}
            {panel.bulletPoints?.length && (
              <ul>
                {panel.bulletPoints?.map((point, index) => {
                  return (
                    <li key={index}>
                      <Text>{t(point)}</Text>
                    </li>
                  )
                })}
              </ul>
            )}
            {panel.intercomLink && (
              <Button
                id='launch-chat-btn'
                type='link'
                onClick={() => showChat()}
              >
                {t(panel.intercomLink)}
              </Button>
            )}{' '}
            <Text>{t(panel.content_2)}</Text>
            <a
              href={panel.linkAddress}
              target={panel.newTab && '_blank'}
              rel={panel.newTab && 'noopener noreferrer'}
            >
              {t(panel.linkTitle)}
            </a>{' '}
            <Text>{t(panel.secondaryContent)}</Text>
          </Panel>
        ))}
      </Collapse>
    </>
  )
}

export default EmptyDashWithFAQ
