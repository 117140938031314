import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { Button, Dropdown, Table, Alert, Space, message } from 'antd'
import { EllipsisOutlined, DownOutlined } from '@ant-design/icons'

import { RulesetContext } from '../RulesetContext'
import { SiteContext } from '../../SiteContext'
import ImportButton from '../ActionButtons/ImportButton'
import ExportButton from '../ActionButtons/ExportButton'
import RevertButton from '../ActionButtons/RevertButton'

import AddButton from '../ActionButtons/AddButton'
import DeployButton from '../ActionButtons/DeployButton'
import DeleteButton from '../ActionButtons/DeleteButton'
import EditButton from '../ActionButtons/EditButton'
import MoveToLastButton from '../ActionButtons/MoveToLastButton'
import MoveDownButton from '../ActionButtons/MoveDownButton'
import MoveToFirstButton from '../ActionButtons/MoveToFirstButton'
import MoveUpButton from '../ActionButtons/MoveUpButton'
import ExportCsvButton from '../ActionButtons/ExportCsvButton'
import ImportCsvButton from '../ActionButtons/ImportCsvButton'
import WrappingCol from './WrappingCol'

import { PERMISSIONS_CONST } from '../Constants/PermissionConst'
import { SITE_STATUSES } from '../../constants/SiteConstants'

const jsxJoin = (array, str) => {
  return array.length > 0
    ? array.reduce((result, item) => (
      <>
        {result}
        {str}
        {item}
      </>
    ))
    : null
}

const LastDeployed = ({ ruleset }) => {
  const { t } = useTranslation(['common'])
  if (!ruleset?.lastDeployed) {
    return ''
  }
  return (
    <span style={{ marginBottom: 15, marginLeft: 30, lineHeight: '30px', display: 'inline-block' }}>
      {t('last_deployed')}: {DateTime.fromSeconds(ruleset.lastDeployed, { zone: 'utc' }).toFormat('dd MMM yy | HH:mm')}
    </span>
  )
}

const RulesTable = ({ ruleType, addons, permissionsArr = false, hiddenColumnsArr }) => {
  const { t } = useTranslation(['common'])
  const [ruleModalVisible, setRuleModalVisible] = useState(false)
  const [editingRule, setEditingRule] = useState(null)
  const { state: ruleset, api } = useContext(RulesetContext)
  const { status: siteStatus } = useContext(SiteContext)
  const { loading: loadingGet, doCall: getRules } = api.getRules
  const { loading: loadingRevert } = api.revertChanges
  const { loading: loadingDelete } = api.deleteRule
  const { loading: loadingReorder } = api.reorderRuleset
  const { loading: loadingImport } = api.importRuleset
  const [canManageRules, setCanManageRules] = useState(true)
  const [canDeployRules, setCanDeployRules] = useState(true)

  useEffect(() => {
    if (permissionsArr) {
      setCanManageRules(
        permissionsArr.includes(
          PERMISSIONS_CONST.RULES_ENGINE_MANAGE)
      )
      setCanDeployRules(
        permissionsArr.includes(
          PERMISSIONS_CONST.RULES_ENGINE_DEPLOY)
      )
    }
  }, [permissionsArr])

  useEffect(() => {
    getRules(ruleType)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (hiddenColumnsArr?.length) {
      // hides columns
      setCols(baseCols.filter(col => !hiddenColumnsArr.includes(col.title)))
    }
    if (addons?.columns) {
      const additionalColumns = addons.columns
      setCols(current => {
        const newCols = [...current]
        for (let i = 0; i < additionalColumns.length; i++) {
          newCols.splice(i + 3, 0, additionalColumns[i])
        }
        return newCols
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const action = {
      title: t('action'),
      key: 'action',
      render: (text, record, index) => {
        const items = [
          {
            key: 'edit', label: <EditButton openEditModal={openEditModal} record={record} />
          }, {
            key: 'move_up', label: <MoveUpButton record={record} index={index} />
          }, {
            key: 'move_to_first', label: <MoveToFirstButton record={record} />
          }, {
            key: 'move_down', label: <MoveDownButton record={record} index={index} />
          }, {
            key: 'move_to_last', label: <MoveToLastButton record={record} />
          }, {
            type: 'divider'
          }, {
            key: 'delete', label: <DeleteButton record={record} />
          }
        ]
        const menuProps = {
          items
        }

        return (
          <Dropdown menu={menuProps} trigger={['click']} placement='bottomRight'>
            <Button className='sl-ellipsis' type='link'>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        )
      }
    }
    if (canManageRules) {
      setCols(current => {
        const newCols = [...current]
        if (newCols.find(col => col.key !== 'action')) {
          return [...newCols, action]
        }
        return newCols
      })
    }
    if (!canManageRules) {
      setCols(current => {
        const newCols = [...current]
        return newCols.filter(col => col.title !== 'Action')
      })
    }
  }, [canManageRules, t])



  const baseCols = [
    {
      title: t('status'),
      dataIndex: 'status'
    },
    {
      title: t('operation'),
      dataIndex: 'action'
    },
    {
      title: t('source_uri'),
      dataIndex: 'uri',
      width: '15%',
      render: (text, record) => (
        <WrappingCol text={text} />
      )
    },
    {
      title: t('conditions'),
      dataIndex: 'conditions',
      render: data => {
        const stringsArr = data.map(item => {
          return (
            item.type +
            ' ' +
            (item.operand1 || '') +
            ' ' +
            item.operation +
            ' ' +
            (item.operand2 || '')
          )
        })

        return jsxJoin(stringsArr, <br />)
      }
    },
    {
      title: t('description'),
      dataIndex: 'description',
      width: '15%',
      render: (text, record) => <WrappingCol text={text} />
    },
    {
      title: t('last_updated'),
      dataIndex: 'lastUpdated',
      render: data => {
        if (data) {
          return DateTime.fromSeconds(data, { zone: 'utc' }).toFormat('dd MMM yy | HH:mm')
        }
        return ''
      }
    }
  ]

  const [cols, setCols] = useState(baseCols)

  const openEditModal = rule => {
    setEditingRule(rule)
    setRuleModalVisible(true)
  }

  const onSuccess = () => {
    if (editingRule) {
      message.success(t('rule_updated_successfully'))
    } else {
      message.success(t('rule_created_successfully'))
    }

    setRuleModalVisible(false)
    setEditingRule(null)
  }

  const onCancel = () => {
    setRuleModalVisible(false)
    setEditingRule(null)
  }

  return (
    <>
      {ruleset?.hasUndeployedChanges
        && (
          <Alert
            className='sl-alert'
            type='info'
            message={t('your_changes_are_not_deployed_yet')}
            style={{ marginBottom: 20 }}
            action={
              <Space >
                <DeployButton
                  canDeployRules={canDeployRules}
                  disabled={
                    loadingGet ||
                    loadingImport ||
                    !ruleset?.hasUndeployedChanges ||
                    siteStatus !== SITE_STATUSES.ACTIVE
                  }
                  ruleType={ruleType}
                  api={api}
                />
                <RevertButton
                  canManageRules={canManageRules}
                />
              </Space>
            }
          />
        )
      }

      <div>
        <Space>
          <AddButton
            canManageRules={canManageRules}
            addons={addons}
            onSuccess={onSuccess}
            visible={ruleModalVisible}
            onCancel={() => onCancel()}
            rule={editingRule}
            ruleType={ruleType}
            setRuleModalVisible={(val) => setRuleModalVisible(val)}
          />
          <LastDeployed ruleset={ruleset} />
        </Space>

        {canManageRules && (
          <Dropdown
            menu={additionalActionsMenu(addons)}
            trigger={['click']}
            placement='bottomRight'
          >
            <Button style={{ float: 'right' }}>
              <Space>
                {t('additional_actions')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
      </div>

      <br />

      <Table
        columns={cols}
        dataSource={ruleset.rules}
        loading={
          loadingGet ||
          loadingReorder ||
          loadingDelete ||
          loadingRevert ||
          loadingImport
        }
        pagination={false}
        rowKey='id'
      />
    </>
  )
}

const additionalActionsMenu = (addons) => {

  let items = [
    {
      key: 'import',
      title: null,
      label: <ImportButton />,
      className: 'ant-dropdown-menu-item'
    }, {
      key: 'export',
      title: null,
      label: <ExportButton />,
      className: 'ant-dropdown-menu-item'
    }, {
      key: 'importCsv',
      title: null,
      label: <ImportCsvButton csvObjToRule={addons.csvObjToRule} />,
      className: 'ant-dropdown-menu-item'
    }, {
      key: 'exportCsv',
      title: null,
      label: <ExportCsvButton columnNames={addons.csvColumnsNames} ruleToCsvArray={addons.ruleToCsvArray} />,
      className: 'ant-dropdown-menu-item'
    }, {
      type: 'divider'
    }
  ]

  if (addons?.customMenuButtons) {
    const addonItems = []
    addons.customMenuButtons.map((button, index) => (
      addonItems.push({
        key: index,
        className: 'ant-dropdown-menu-item',
        title: null,
        label: button
      })
    ))
    items = [...items, {
      type: 'divider'
    }, ...addonItems]
  }
  return (
    {
      items
    }
  )
}

export default RulesTable
