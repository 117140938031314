import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button, Card, Typography, Spin, Tooltip } from 'antd'
import { FORM_STATUS, BILLING_CYCLE } from '@Constants'
import { useUserContext } from '@Context/UserContext'
import { useMessageContext } from '@Context/MessageContext'

import PlanFeatureList from '@Components/PlanFeatureList'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { mathCeilHalf } from '@Lib/helpers'

import './index.less'
import {
  customPlanButtonClickEvent,
  formatAddToCartObjEvent
} from '@Lib/helpers/gtmEventHelpers'

const { Meta } = Card
const { Text } = Typography

const PlanCard = ({
  plan,
  isLoading,
  isAnnual,
  showFullComparison,
  isValidatingPlan,
  setIsValidatingPlan,
  hasFutureSubscription,
  currentUsersOnAcc,
  isModalVisible,
  setIsModalVisible
}) => {
  const { t } = useTranslation(['common', 'errors'])
  const history = useHistory()
  const userContext = useUserContext()
  const messageContext = useMessageContext()
  const accountDetails = userContext?.accountDetails
  const [isCurrentPlan, setIsCurrentPlan] = useState(false)
  const [isSupported, setIsSupported] = useState({ value: true })
  const [formStatus, setFormStatus] = useState('')
  const [unsupportedMsg, setUnsupportedMsg] = useState('')

  const [
    { data: validateSubscription, error: validateSubscriptionsError },
    validateSubscriptions
  ] = useStratticApi({}, { manual: true })

  useErrorMessage(validateSubscriptionsError)

  useEffect(() => {
    if (validateSubscription) {
      const cartPlan = Object.assign({}, plan, validateSubscription?.result)
      cartPlan.isAnnual = isAnnual
      userContext.setCart(cartPlan)
      history.push(
        `/payment-information/?isAnnual=${isAnnual}&planId=${plan?.id}`
      )
    }
    if (validateSubscriptionsError) {
      setIsValidatingPlan(false)
      setFormStatus(null)
    }
    // eslint-disable-next-line
  }, [validateSubscription, validateSubscriptionsError])

  const checkIfPlanIsSupported = () => {
    if (
      accountDetails?.sitesUsage?.current > plan.websitesAmount ||
      (plan?.usersAmount && currentUsersOnAcc > plan?.usersAmount)
    ) {
      if (
        accountDetails?.sitesUsage?.current > plan.websitesAmount &&
        currentUsersOnAcc > plan?.usersAmount
      ) {
        setUnsupportedMsg(
          t('plan_doesnt_support_usage_sites_and_users', {
            sitesNum: accountDetails?.sitesUsage?.current,
            usersNum: currentUsersOnAcc
          })
        )
      } else if (accountDetails?.sitesUsage?.current > plan.websitesAmount) {
        setUnsupportedMsg(
          t('plan_doesnt_support_usage_sites', {
            sitesNum: accountDetails?.sitesUsage?.current
          })
        )
      } else {
        setUnsupportedMsg(
          t('plan_doesnt_support_usage_users', {
            usersNum: currentUsersOnAcc
          })
        )
      }
      return false
    } else return true
  }

  const getClassNames = () => {
    let classNames = ''
    if (plan.id === 0) classNames += ' custom'
    if (plan.name === 'Business') classNames += ' most-popular'
    if (isCurrentPlan === true) classNames += ' current'
    return classNames
  }
  const billingCycle = isAnnual ? BILLING_CYCLE.ANNUAL : BILLING_CYCLE.MONTHLY

  const handleSelectPlan = async event => {
    setFormStatus(FORM_STATUS.SUBMITTING)
    setIsValidatingPlan(true)
    if (plan?.id !== 0) {
      formatAddToCartObjEvent(
        userContext?.currentUser?.id,
        userContext?.accountDetails,
        plan,
        billingCycle
      )
      try {
        const url = `accounts/${userContext?.accountId}/subscriptions/validate-checkout/?planId=${plan?.id}&billingCycle=${billingCycle}`
        await validateSubscriptions({
          url,
          method: 'GET'
        })
      } catch (error) {
        messageContext.setMessage({
          value: t(`errors:${error.code}`),
          type: 'error'
        })
      }
    } else {
      customPlanButtonClickEvent(
        userContext?.currentUser?.id,
        userContext?.accountDetails
      )
      setFormStatus(null)
      setIsValidatingPlan(false)
      setIsModalVisible(true)
    }
  }

  useEffect(() => {
    const isCurrentPlanCheck =
      accountDetails?.planId === plan.id &&
      accountDetails?.planFrequency === billingCycle
    setIsSupported(checkIfPlanIsSupported())
    setIsCurrentPlan(isCurrentPlanCheck)
    // eslint-disable-next-line
  }, [billingCycle])

  return (
    <Card
      className={`sl-plan-card sl-plan-card-${
        plan?.name
      } plan-card ${getClassNames()}`}
      hoverable
      loading={isLoading}
    >
      <PlanHeader
        isCurrentPlan={isCurrentPlan}
        plan={plan}
        isAnnual={isAnnual}
        formStatus={formStatus}
        isValidatingPlan={isValidatingPlan}
        isModalVisible={isModalVisible}
        isSupported={isSupported}
        handleSelectPlan={handleSelectPlan}
        setIsModalVisible={value => setIsModalVisible(value)}
        accountDetails={accountDetails}
        unsupportedMsg={unsupportedMsg}
        hasFutureSubscription={hasFutureSubscription}
      />

      <PlanFeatureList
        plan={plan}
        showFullComparison={showFullComparison}
        classNames='icon-only'
      />
    </Card>
  )
}

const PlanHeader = ({
  isCurrentPlan,
  plan,
  isAnnual,
  formStatus,
  isValidatingPlan,
  isModalVisible,
  isSupported,
  handleSelectPlan,
  setIsModalVisible,
  accountDetails,
  unsupportedMsg,
  hasFutureSubscription
}) => {
  const { t } = useTranslation(['common', 'errors'])
  const [selectBtnTitle, setSelectBtnTitle] = useState('')

  useEffect(() => {
    if (hasFutureSubscription) {
      setSelectBtnTitle(t('switch_plan_with_future_subscription_btn_title'))
    } else {
      setSelectBtnTitle('')
    }
  }, [t, hasFutureSubscription])

  return (
    <div className='plan-header'>
      <Text className='feature most-popular'>{t('best_value')}</Text>
      <Meta className='sl-plan-title' title={plan?.name} />
      <div className='tagline sl-plan-tagline'>
        <Text type='secondary'>{plan?.description}</Text>
      </div>

      {isSupported || isCurrentPlan ? (
        <Spin spinning={formStatus === FORM_STATUS.SUBMITTING}>
          {isCurrentPlan ? (
            <Text className='your-plan sl-your-plan'>{t('your_plan')}</Text>
          ) : hasFutureSubscription && plan.id !== 0 ? (
            <Tooltip title={selectBtnTitle}>
              <Button
                className={`sl-${plan?.name}-disabled sl-plan-disabled`}
                disabled
                id='disabled-future-plan-select'
              >
                {t('select')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              className={`sl-${plan?.name}-select sl-plan-select`}
              onClick={handleSelectPlan}
              disabled={isValidatingPlan}
            >
              {t('select')}
            </Button>
          )}
        </Spin>
      ) : (
        <Tooltip title={unsupportedMsg}>
          <Text className='plan-description sl-plan-unsupported'>
            {t('plan_doesnt_support_usage')}
          </Text>
        </Tooltip>
      )}
      {plan.id === 0 ? (
        <p className='price'>
          <Text className='sl-plan-price'>{t('talk_to_us')}</Text>
        </p>
      ) : (
        <p className={`price sl-plan-price ${isAnnual ? 'annual' : 'monthly'}`}>
          <Text>{t('$')}</Text>
          {isAnnual ? (
            <>
              <span className='value sl-plan-price'>
                {mathCeilHalf(parseFloat(plan.priceYear / 12, 10))}{' '}
              </span>
              <Text className='label'>{t('per_month')}</Text>
            </>
          ) : (
            <>
              <span className='value sl-plan-price'>
                {parseInt(plan.priceMonth, 10)}{' '}
              </span>
              <Text className='label'>{t('per_month')}</Text>
            </>
          )}
        </p>
      )}
    </div>
  )
}

export const LoadingCard = () => (
  <Card className='sl-plan-card-loading' loading />
)

export default PlanCard
