import { DateTime } from 'luxon'
import {
  FREE_TRIAL,
  SUBSCRIPTION_STATUS,
  BILLING_CYCLE_OPTIONS
} from '@Constants'

export const getCurrentSub = currentAccount => {
  return currentAccount?.currentSubscription
}

export const isFreeTrial = planName => {
  return planName === FREE_TRIAL
}

export const getPlanGroupId = accountDetails => {
  if (!accountDetails) {
    return null
  }
  return accountDetails?.planGroupId
}

export const getDaysLeftOnSubscription = endDate => {
  if (!endDate) {
    return null
  }

  const timeLeft = DateTime.fromSeconds(endDate).diff(DateTime.local(), 'days')

  return timeLeft.days
}

export const hasSubscriptionExpired = currentAccount => {
  return currentAccount?.subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED
}

export const getTodayLuxon = () => {
  return DateTime.utc().startOf('day')
}

export const getStartLuxon = startDate => {
  let startLuxon
  if (startDate) {
    startLuxon = DateTime.fromSeconds(startDate)
  } else {
    startLuxon = getTodayLuxon()
  }
  return startLuxon
}
const todayLuxon = getTodayLuxon()

export const formatDate = startDate =>
  getStartLuxon(startDate).toFormat('LLLL dd, yyyy')

export const getIsFutureSubscription = startDate => {
  return getStartLuxon(startDate) > todayLuxon
}

export const getIsBillingCycleChange = (
  currentPlanId,
  futurePlanId,
  currentBillingCycle,
  futureBillingCycle,
  currentWebsites,
  futureWebsites
) => {
  return (
    currentPlanId === futurePlanId &&
    currentBillingCycle === BILLING_CYCLE_OPTIONS.ANNUAL &&
    futureBillingCycle === BILLING_CYCLE_OPTIONS.MONTHLY &&
    currentWebsites === futureWebsites
  )
}

export const billingChangeMessage = (currentAccount, cart) => {
  if (!cart) {
    return null
  }
  const {
    planId: currentPlanId,
    planFrequency: currentBillingCycle,
    sitesUsage
  } = currentAccount
  const currentWebsitesAllowance = sitesUsage?.max
  const { billingCycle, allowances, startDate } = cart

  if (!getIsFutureSubscription(startDate)) {
    return null
  }

  const isBillingCycleChange = getIsBillingCycleChange(
    currentPlanId,
    cart?.id,
    currentBillingCycle,
    billingCycle,
    currentWebsitesAllowance,
    allowances?.websitesAmount
  )
  if (isBillingCycleChange) {
    return 'billing_cycle_change_message'
  }
  return 'billing_change_message'
}

export const getSubscriptionName = sub => {
  if (!sub) {
    return null
  }
  if (sub.name) {
    return sub.name
  }
  if (sub.plan) {
    return sub.plan?.name
  }
  return null
}

export const getFormattedCardBillingDetail = card => {
  if (!card) {
    return null
  }
  const formattedCard = {
    brand: card?.card?.brand || null,
    country: card?.card?.country || null,
    exp_month: card?.card?.exp_month || null,
    exp_year: card?.card?.exp_year || null,
    last4: card?.card?.last4 || null,
    name: card?.billing_details?.name || null,
    line1: card?.billing_details?.address?.line1 || null,
    line2: card?.billing_details?.address?.line2 || null,
    city: card?.billing_details?.address?.city || null,
    state: card?.billing_details?.address?.state || null,
    postal_code: card?.billing_details?.address?.postal_code || null,
    email: card?.billing_details?.email || null
  }
  return formattedCard
}

export const isCardExpired = card => {
  // date library for you amitay 🫥
  const cardDate = DateTime.fromObject({
    month: card?.exp_month,
    year: card?.exp_year
  })
  return cardDate < DateTime.utc()
}
