import { useEffect, useState } from 'react'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useAccountPermissions from '@Hooks/useAccountPermissions'

import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'
import { isCardExpired, isFreeTrial } from '../lib/helpers/subscriptionHelpers'

const useAccountPaymentDetails = (currentAccountId, accountDetails) => {
  const [freeTrial] = useState(isFreeTrial(accountDetails?.planName))
  const [paymentCardExpired, setPaymentCardExpired] = useState(false)
  const hasPermissionToViewBilling = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_VIEW_BILLING
  )

  const [
    { data: cardData, loading: cardDataLoading, error: cardDataError },
    fetchCardData
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(cardDataError)

  useEffect(() => {
    if (currentAccountId && !freeTrial && hasPermissionToViewBilling) {
      fetchCardData({
        url: `/accounts/${currentAccountId}/cards`,
        method: 'GET'
      })
    }
    // eslint-disable-next-line
  }, [currentAccountId, freeTrial, hasPermissionToViewBilling])

  useEffect(() => {
    if (cardData && !cardDataLoading) {
      setPaymentCardExpired(isCardExpired(cardData?.[0]?.card))
    }
  }, [cardData, cardDataLoading])

  return [{ paymentCardExpired, cardData }]
}

export default useAccountPaymentDetails
