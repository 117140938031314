import React, { useContext, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams } from 'react-router-dom'

import {
  Button,
  Form,
  Input,
  message,
  Tabs,
  Typography,
  Select,
  Checkbox
} from 'antd'

import { Template } from '@Templates/Site'
import useCurrentSite from '@Hooks/useCurrentSite'
import { useUserContext } from '@Context/UserContext'
import { SiteProvider } from '../../packages/SiteContext'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import { DIST_TYPES } from '@Constants'
import { SLASH_REDIRECT_CODES } from '@Constants/siteConstants'

import {
  ACTIONS as CONFIRMATION_MODAL_ACTIONS,
  ConfirmationModalContext,
  ConfirmationModalProvider
} from '@Packages/ConfirmationModal/ConfirmationModalContext'

import {
  RulesetContext,
  RulesetProvider
} from '@Packages/RulesEngine/RulesetContext'
import RulesTable from '@Packages/RulesEngine/RulesTable'
import WrappingCol from '@Packages/RulesEngine/RulesTable/WrappingCol'
import ApiFactory from '../SiteHeadersRules/ApiFactory'
import { mapStratticPermissionsToRulesPermissions } from '@Lib/helpers/permissionsHelpers'

const { Text } = Typography
const { Item } = Form

const LiveAPI = ApiFactory('live')
const PreviewAPI = ApiFactory('preview')

const getOtherDistributionType = distributionType => {
  if (distributionType === DIST_TYPES.LIVE) {
    return DIST_TYPES.PREVIEW
  }

  return DIST_TYPES.LIVE
}

const CopyFromButton = ({ distributionType }) => {
  const { t } = useTranslation(['common'])
  const { siteId } = useParams()
  const { state: ruleset, api } = useContext(RulesetContext)
  const { doCall: getRules } = api.getRules
  const { dispatch: dispatchConfirmationModal } = useContext(
    ConfirmationModalContext
  )

  const [{ error: errorCopy }, copyRuleset] = useStratticApi(
    {
      method: 'POST'
    },
    { manual: true }
  )
  useErrorMessage(errorCopy)

  const handleCopyFrom = () => {
    dispatchConfirmationModal({
      type: CONFIRMATION_MODAL_ACTIONS.SHOW,
      data: {
        title: t('are_you_sure'),
        text: t('copy_rules_confirmation_text'),
        okText: t('yes_proceed'),
        onConfirm: () => {
          dispatchConfirmationModal({
            type: CONFIRMATION_MODAL_ACTIONS.START_LOADING
          })

          copyRuleset({
            url: `sites/${siteId}/ruleset/${ruleset.id}/copy?optimisticLockVersion=${ruleset.optimisticLockVersion}`,
            data: { direction: 'to' }
          }).then(response => {
            if (response) {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.HIDE
              })
              message.success(t('ruleset_copied_successfully'))
              getRules('redirects')
            } else {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.STOP_LOADING
              })
            }
          })
        }
      }
    })
  }

  return (
    <Button type='link' onClick={handleCopyFrom}>
      {t('sync_from') + ' ' + t(getOtherDistributionType(distributionType))}
    </Button>
  )
}

const CopyToButton = ({ distributionType }) => {
  const { t } = useTranslation(['common'])
  const { siteId } = useParams()
  const { state: ruleset } = useContext(RulesetContext)

  const { dispatch: dispatchConfirmationModal } = useContext(
    ConfirmationModalContext
  )

  const [{ error: errorCopy }, copyRuleset] = useStratticApi(
    {
      method: 'POST'
    },
    { manual: true }
  )
  useErrorMessage(errorCopy)

  const handleCopyTo = () => {
    dispatchConfirmationModal({
      type: CONFIRMATION_MODAL_ACTIONS.SHOW,
      data: {
        title: t('are_you_sure'),
        text: t('copy_rules_confirmation_text'),
        okText: t('yes_proceed'),
        onConfirm: () => {
          dispatchConfirmationModal({
            type: CONFIRMATION_MODAL_ACTIONS.START_LOADING
          })

          copyRuleset({
            url: `sites/${siteId}/ruleset/${ruleset.id}/copy?optimisticLockVersion=${ruleset.optimisticLockVersion}`,
            data: { direction: 'from' }
          }).then(response => {
            if (response) {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.HIDE
              })
              message.success(t('ruleset_copied_successfully'))
            } else {
              dispatchConfirmationModal({
                type: CONFIRMATION_MODAL_ACTIONS.STOP_LOADING
              })
            }
          })
        }
      }
    })
  }

  return (
    <Button type='link' onClick={handleCopyTo}>
      {t('sync_to') + ' ' + t(getOtherDistributionType(distributionType))}
    </Button>
  )
}

const SiteRedirectsRulesPage = () => {
  const { t } = useTranslation(['common'])
  const [currentSite, refetchCurrentSite] = useCurrentSite()
  const userContext = useUserContext()
  const sitePermissions = currentSite?.value?.allowedPermissions
  const [permissionsArr, setPermissionsArr] = useState([])
  useEffect(() => {
    const sitePermissionsArr = mapStratticPermissionsToRulesPermissions(
      sitePermissions
    )
    setPermissionsArr(sitePermissionsArr)
  }, [sitePermissions])
  const addons = useMemo(() => {
    const additionalColumns = [
      {
        title: t('redirect'),
        dataIndex: 'redirect',
        width: '15%',
        render: data => {
          if (data === undefined) {
            return ''
          }
          return <WrappingCol text={data.action + ': ' + data.value} />
        }
      },
      {
        title: t('forward_query_params'),
        dataIndex: 'redirect',
        render: data => {
          if (data === undefined) {
            return ''
          }

          return data.forwardQueryParams ? 'true' : 'false'
        }
      }
    ]

    const additionalFields = (
      <>
        <Item
          name='redirect_action'
          labelAlign='left'
          labelCol={{ span: 6 }}
          label={<Text strong>{t('redirect_action')}</Text>}
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              message: 'required'
            }
          ]}
          initialValue={301}
        >
          <Select className='sl-redirectAction'>
            {Object.values(SLASH_REDIRECT_CODES).map(value => {
              return (
                <Select.Option value={value} key={value}>
                  {value}
                </Select.Option>
              )
            })}
          </Select>
        </Item>
        <Item
          name='redirect_target'
          labelAlign='left'
          labelCol={{ span: 6 }}
          label={<Text strong>{t('redirect_target')}</Text>}
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              message: 'required'
            }
          ]}
        >
          <Input
            className='sl-redirectTarget'
            placeholder='https://your-domain.com/target-url-path/'
          />
        </Item>
        <Item
          name='forward_query_params'
          labelAlign='left'
          labelCol={{ span: 6 }}
          initialValue
          valuePropName='checked'
        >
          <Checkbox className='sl-forwardQueryParams'>
            {t('forward_query_params')}
          </Checkbox>
        </Item>
        <Item
          name='last'
          labelAlign='left'
          labelCol={{ span: 6 }}
          initialValue
          valuePropName='checked'
        >
          <Checkbox className='sl-last'>{t('excecute_last')}</Checkbox>
        </Item>
      </>
    )

    return {
      columns: additionalColumns,
      fields: additionalFields,
      fieldsValuesToObj: (obj, fieldsValues) => {
        obj.redirect = {
          action: fieldsValues.redirect_action,
          value: fieldsValues.redirect_target,
          forwardQueryParams: fieldsValues.forward_query_params,
          last: fieldsValues.last
        }
      },
      objToFieldsValues: (obj, fieldsValues) => {
        fieldsValues.redirect_action = obj.redirect.action
        fieldsValues.redirect_target = obj.redirect.value
        fieldsValues.forward_query_params = obj.redirect.forwardQueryParams
        fieldsValues.last = obj.redirect.last
      },
      csvObjToRule: csvObj => ({
        uri: csvObj.uri,
        description: csvObj.description,
        status: csvObj.status,
        conditions: JSON.parse(csvObj.conditions),
        redirect: {
          value: csvObj.target,
          action: csvObj.action,
          forwardQueryParams: csvObj.forwardqueryparams,
          last: csvObj.last
        }
      }),
      ruleToCsvArray: rule => [
        rule.uri,
        rule.redirect?.value,
        rule.redirect?.action,
        rule.redirect?.forwardQueryParams,
        rule.redirect?.last,
        rule.description,
        rule.conditions,
        rule.status
      ],
      csvColumnsNames: [
        'uri',
        'target',
        'action',
        'forward query params',
        'last',
        'description',
        'conditions',
        'status'
      ]
    }
  }, [t])
  const liveAddons = useMemo(() => {
    return {
      ...addons,
      customMenuButtons: [
        <CopyFromButton distributionType='live' key='1' />,
        <CopyToButton distributionType='live' key='2' />
      ]
    }
  }, [addons])
  const previewAddons = useMemo(() => {
    return {
      ...addons,
      customMenuButtons: [
        <CopyFromButton distributionType='preview' key='1' />,
        <CopyToButton distributionType='preview' key='2' />
      ]
    }
  }, [addons])
  const hiddenColumnsArr = [t('operation')]

  if (
    userContext?.accountDetails &&
    !userContext?.accountDetails?.allowRulesEngine
  ) {
    return <Redirect to='/' />
  }
  return (
    <Template
      currentSite={currentSite}
      title={t('redirect_rules')}
      classNames='headers-rules'
      beta
    >
      <SiteProvider
        siteData={currentSite?.value}
        refetchCurrentSite={refetchCurrentSite}
      >
        <ConfirmationModalProvider>
          <Tabs
            defaultActiveKey='1'
            destroyInactiveTabPane
            items={[
              {
                key: '1',
                label: t('live_site'),
                children: (
                  <RulesetProvider API={LiveAPI}>
                    <RulesTable
                      permissionsArr={permissionsArr}
                      ruleType='redirects'
                      addons={liveAddons}
                      hiddenColumnsArr={hiddenColumnsArr}
                    />
                  </RulesetProvider>
                )
              },
              {
                key: '2',
                label: t('preview_site'),
                children: (
                  <RulesetProvider API={PreviewAPI}>
                    <RulesTable
                      permissionsArr={permissionsArr}
                      ruleType='redirects'
                      addons={previewAddons}
                      hiddenColumnsArr={hiddenColumnsArr}
                    />
                  </RulesetProvider>
                )
              }
            ]}
          />
        </ConfirmationModalProvider>
      </SiteProvider>
    </Template>
  )
}

export default SiteRedirectsRulesPage
