import { Auth } from 'aws-amplify'
import passwordValidator from 'password-validator'
import { getRequestParams, makeApiRequest } from '@Lib/api/'
import { ApiError } from '@Packages/StratticApi/error'
import AnalyticsManager from '@Managers/analyticsManager'

const PasswordValidator = passwordValidator

export const login = async (email, password) => {
  try {
    const authUser = await Auth.signIn(email, password)
    return authUser
  } catch (error) {
    throw new ApiError(error.message, error.code, error.status)
  }
}

export const logout = async () => {
  await Auth.signOut()
  AnalyticsManager.getInstance().logout()
}

export const forgotPassword = async email => {
  try {
    const results = await Auth.forgotPassword(email)
    return results
  } catch (error) {
    throw new ApiError(error.message, error.code)
  }
}

export const forgotPasswordSubmit = async (email, code, newPassword) => {
  try {
    const results = await Auth.forgotPasswordSubmit(email, code, newPassword)
    return results
  } catch (error) {
    throw new ApiError(error.message, error.code, error.status)
  }
}

export const isValidPassword = password => {
  const schema = new PasswordValidator()
  schema
    .is()
    .min(8)
    .is()
    .max(64)
    .has()
    .uppercase(1)
    .has()
    .lowercase(1)
    .has()
    .digits(1)

  return schema.validate(password, { list: true })
}

export const isValidEmail = async email => {
  try {
    const requestParams = await getRequestParams({
      path: 'validate/email',
      method: 'post',
      data: { email }
    })
    return makeApiRequest(requestParams)
  } catch (error) {
    console.error(error)
    throw new ApiError(error?.response?.data?.message, error.code, error.status)
  }
}

export const signup = async (name, email, password) => {
  const data = {
    name,
    email,
    password
  }
  const requestParams = await getRequestParams({
    path: 'auth/signup',
    method: 'post',
    data
  })
  const response = await makeApiRequest(requestParams)
  return response.data
}
