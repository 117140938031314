import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Modal } from 'antd'
import csvToJson from 'convert-csv-to-json'

export const TYPE_JSON = 'application/json'
export const TYPE_CSV = 'text/csv'

const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const isValidCsv = str => {
  try {
    csvToJson
      .fieldDelimiter(',')
      .formatValueByType()
      .supportQuotedField(true)
      .csvStringToJson(str)
  } catch (e) {
    return false
  }
  return true
}

const ImportModal = ({ visible, onCancel, onOk, loading, fileType }) => {
  const { t } = useTranslation(['common'])
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [isFileValid, setIsFileValid] = useState(false)
  const [content, setContent] = useState()

  const validate = text => {
    if (fileType === TYPE_JSON) {
      return isJsonString(text)
    }
    if (fileType === TYPE_CSV) {
      return isValidCsv(text)
    }
    return false
  }

  const changeHandler = event => {
    const reader = new FileReader()
    reader.onload = () => {
      const text = reader.result
      if (validate(reader.result)) {
        setIsFileValid(true)
        setContent(text)
      } else {
        setIsFileValid(false)
      }
    }
    reader.readAsText(event.target.files[0])

    setIsFilePicked(true)
  }

  const handleOk = () => {
    onOk(content)
  }

  const handleCancel = () => {
    if (loading) return

    onCancel()
  }

  return (
    <Modal
      className='confirmation-modal'
      open={visible}
      onCancel={handleCancel}
      footer={[
        <div className='modal-footer'>
          <Button
            className='confirm-btn'
            key='submit'
            onClick={handleOk}
            type='primary'
            disabled={!isFilePicked || !isFileValid}
            loading={loading}
          >
            {t('import')}
          </Button>
          <Button key='back' onClick={handleCancel} disabled={loading}>
            {t('cancel')}
          </Button>
        </div>
      ]}
    >
      <div className='heading'>
        <h1>{t('import_rules')}</h1>
      </div>
      {isFilePicked && !isFileValid && (
        <>
          <Alert
            message={t('not_valid_header_rules_import_file')}
            type='error'
          />
          <br />
        </>
      )}

      <input
        type='file'
        name='file'
        accept={fileType}
        onChange={changeHandler}
        className='file-import'
      />
    </Modal>
  )
}

export default ImportModal
