import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Typography, Row, Col, Space, Button } from 'antd'
import { getFormattedCardBillingDetail } from '@Lib/helpers/subscriptionHelpers'

import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import './index.less'

const { Text, Title } = Typography

const BillingDetails = ({ cardData, countryList, setChangeCard }) => {
  const { t } = useTranslation(['common'])
  const [activeCard] = useState(getFormattedCardBillingDetail(cardData))
  const countryName = code => {
    const country = countryList?.result?.find(i => i.code === code)
    if (country) {
      return country.name
    }
  }

  return (
    <>
      <Space direction='vertical'>
        <Row justify='space-between'>
          <Space direction='horizontal' size='large'>
            <Col>
              <Text strong className='card-type'>
                {activeCard?.brand}:
              </Text>{' '}
              XXXX XXXX XXXX {activeCard?.last4}
            </Col>
            <Col>
              <Text strong>{t('expiration_date')}:</Text>{' '}
              {activeCard?.exp_month} / {activeCard?.exp_year}
            </Col>
          </Space>
        </Row>

        <div>
          <Row justify='space-between'>
            <Col>
              <Title level={4}>{t('billing_details')}</Title>
            </Col>
            <Col
              onClick={() => {
                setChangeCard(true)
                AnalyticsManager.getInstance().trackClick({
                  action: 'change_card',
                  label: EVENT_CATEGORIES.CHECKOUT
                })
              }}
            >
              <Button className='edit-billing-btn' type='link'>
                {t('edit')}
              </Button>
            </Col>
          </Row>
          <Row justify='space-between'>
            <Col>{activeCard?.name}</Col>
          </Row>
          <Row justify='space-between'>
            <Col>{activeCard?.line1}</Col>
            <Col>{activeCard?.line2}</Col>
          </Row>
          <Row justify='space-between'>
            <Col>{activeCard?.city}</Col>
            <Col>{countryName(activeCard?.country)}</Col>
          </Row>
          <Row justify='space-between'>
            <Col>{activeCard?.postal_code}</Col>
            <Col>{activeCard?.email}</Col>
          </Row>
        </div>
      </Space>
    </>
  )
}

export default BillingDetails
