import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
// import CookieBot from 'react-cookiebot/lib/CookieBot'
import '@i18n'
import App from './App'
import MaintenanceMode from '@Pages/MaintenanceMode'
import config from '@Lib/config'
import { IntercomProvider } from 'react-use-intercom'

import './index.less'
import OneTrustScripts from './managers/cookie-manager'
// commenting out for now will remove at a later date if works as expected
// https://strattic.atlassian.net/browse/STR-8979
// const COOKIEBOT_DOMAIN_ID = process.env.COOKIEBOT_DOMAIN_ID

// setting kustomer api dynamically
const formatScriptTags = scriptContent => {
  const script = document.createElement('script')
  script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js'
  script.setAttribute('data-kustomer-api-key', config.kustomerApiKey)
  return script
}
const scriptTag = formatScriptTags()
document.body.append(scriptTag)

const AppIndex = () => {
  const [kustomerIsStarted, setKustomerIsStarted] = useState(false)
  useEffect(() => {
    const handleKustomerInitiated = () => {
      window.Kustomer.start({ brandId: config.kustomerBrandId }, () =>
        setKustomerIsStarted(true)
      )
    }
    window.addEventListener('kustomerLoaded', handleKustomerInitiated)
    return () => {
      window.removeEventListener('kustomerLoaded', handleKustomerInitiated)
    }
  }, [])
  // config file needs parsing to convert to boolean
  if (JSON.parse(config.maintenanceMode)) {
    return (
      <IntercomProvider appId={config.intercomAppId}>
        <MaintenanceMode />
      </IntercomProvider>
    )
  }
  return (
    <Router>
      <>
        <OneTrustScripts />
        {/* <CookieBot domainGroupId={COOKIEBOT_DOMAIN_ID} /> */}
        <App kustomerIsStarted={kustomerIsStarted} />
      </>
    </Router>
  )
}

ReactDOM.render(<AppIndex />, document.getElementById('root'))
