import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AccountExpiredCard from '@Components/AccountExpiredCard'
import { useUserContext } from '@Context/UserContext'
import { Button, Card, Typography } from 'antd'
import Globe from '@Images/icons/globe.svg'
import { hasSubscriptionExpired } from '@Lib/helpers/subscriptionHelpers'
import './index.less'
const { Text } = Typography

const DomainAddCard = ({ siteId }) => {
  const { t } = useTranslation(['common'])
  const userContext = useUserContext()

  if (hasSubscriptionExpired(userContext.accountDetails)) {
    return <AccountExpiredCard context='domain' />
  }

  return (
    <div className='domain-add-card'>
      <div className='back-shadow' />
      <Card className='narrow'>
        <div className='icon-wrapper'>
          <Globe />
        </div>
        <h2>{t('setup_custom_domain')}</h2>
        <main>
          <p>
            <Text>{t('connect_custom_domain_description')}</Text>
          </p>
          <p>
            <Button type='primary'>
              <Link to={`/site/${siteId}/domain-setup`}>
                {t('connect_your_domain')}
              </Link>
            </Button>
          </p>
        </main>
      </Card>
    </div>
  )
}

export default DomainAddCard
