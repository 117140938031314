import { useEffect, useState, useRef } from 'react'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useLocalStorage from '@Hooks/useLocalStorage'

import { useUserContext } from '@Context/UserContext'
import usePrevious from './usePrevious'
import { SITE_STATUS } from '@Constants'

const useSites = () => {
  const timer = useRef(null)
  const userContext = useUserContext()
  const accountId = userContext?.accountId
  const hasAccount = !!userContext?.currentUser?.accounts.length
  const prevAccountId = usePrevious(accountId)

  const [
    { data: getSites, loading: isLoading, error: getError },
    getSitesCall
  ] = useStratticApi({ url: `accounts/${accountId}/sites` }, { manual: true })
  useErrorMessage(getError)
  const [sitesStorage, setSitesStorage] = useLocalStorage('sites')
  const [initialLoad, setInitialLoad] = useState(!sitesStorage)
  const [sites, setSites] = useState({
    isLoading: !sitesStorage && isLoading,
    value: sitesStorage,
    error: null
  })
  // if account changes cancel timeout then call checkSite with new account
  useEffect(() => {
    if (prevAccountId && accountId !== prevAccountId) {
      setSitesStorage(null)
      clearTimeout(timer.current)
      checkSite()
    }
    // eslint-disable-next-line
  }, [accountId, prevAccountId])

  // check local storage if sites don't show loading just display sites

  const checkSite = async () => {
    if (accountId) {
      await getSitesCall({ url: `accounts/${accountId}/sites` })
    }
  }

  useEffect(() => {
    checkSite()
    if (!hasAccount) {
      setInitialLoad(false)
    }
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [accountId])

  useEffect(() => {
    if (!getSites?.result && !getError) {
      if (!isLoading) {
        timer.current = setTimeout(() => {
          checkSite()
        }, 30 * 1000)
      }
      return
    }

    const newSites = getSites?.result
    setSites({
      isLoading: !sitesStorage && isLoading,
      value: newSites,
      error: getError
    })
    setSitesStorage(newSites)
    if (!isLoading) {
      let ms = 30 * 1000
      if (newSites && hasSiteInCreatingState(newSites)) {
        ms = 1 * 1000
      }
      timer.current = setTimeout(() => {
        checkSite()
      }, ms)
    }

    // eslint-disable-next-line
  }, [getSites?.result, isLoading])

  useEffect(() => {
    if (sitesStorage && !isLoading) {
      setInitialLoad(false)
    }
  }, [sitesStorage, isLoading])

  return [sites, initialLoad]
}

const hasSiteInCreatingState = sites => {
  for (const site of sites) {
    if (site.status === SITE_STATUS.CREATING) {
      return true
    }
  }

  return false
}

export default useSites
