import StratticWalkthrough from '@Images/getting-started/strattic_walkthrough.jpg'
import HowToPublish from '@Images/getting-started/how_to_publish.png'
import HowToMigrate from '@Images/getting-started/how_to_migrate.png'

export const videoHelp = [
  {
    imageSrc: StratticWalkthrough,
    altTxt: 'strattic_walkthrough',
    videoLength: '11:29',
    videoDescription: 'strattic_walkthrough',
    videoUrl: 'https://www.youtube.com/embed/jDNDOC2CX_Y'
  },
  {
    imageSrc: HowToMigrate,
    altTxt: 'how_to_migrate',
    videoLength: '04:59',
    videoDescription: 'how_to_migrate',
    videoUrl: 'https://www.youtube.com/embed/wmfR2EE6Igw'
  },
  {
    imageSrc: HowToPublish,
    altTxt: 'how_to_publish',
    videoLength: '01:54',
    videoDescription: 'how_to_publish',
    videoUrl: 'https://www.youtube.com/embed/Hvn15BidjSs'
  }
]

export const faqContent = [
  {
    header: 'easiest_migrate_question',
    content: 'easiest_migrate',
    linkAddress:
      'https://support.strattic.com/en/articles/2574394-how-to-migrate-a-copy-of-your-wordpress-site-to-strattic-using-migrate-guru',
    linkTitle: 'step_by_step',
    secondaryContent: null,
    newTab: true
  },
  {
    header: 'how_migrate_copy_question',
    content: 'we_recommend_wp_migration',
    linkAddress:
      'https://support.strattic.com/en/articles/3816257-how-to-migrate-to-strattic-from-your-local-development-environment',
    linkTitle: 'wp_migration_tutorial_link',
    secondaryContent: 'wp_migration_tutorial_link',
    newTab: true
  },
  {
    header: 'can_you_help',
    content: 'dont_panic',
    intercomLink: 'the_chat_widget',
    content_2: 'bottom_right_corner',
    linkAddress: 'mailto: support@strattic.com',
    linkTitle: 'support_email',
    secondaryContent: null,
    newTab: false
  }
]

export const faqContentNoAcc = [
  {
    header: 'can_not_assign_org',
    content: 'reasons_assign_org',
    bulletPoints: ['you_were_inv', 'you_were_removed', 'registered_no_inv'],
    linkAddress: null,
    linkTitle: null,
    secondaryContent: null,
    newTab: true
  },
  {
    header: 'invited_no_org',
    content: 'invited_no_org_response',
    linkAddress: null,
    linkTitle: null,
    secondaryContent: null,
    newTab: true
  },
  {
    header: 'can_you_help',
    content: 'dont_panic',
    intercomLink: 'the_chat_widget',
    content_2: 'bottom_right_corner',
    linkAddress: 'mailto: support@strattic.com',
    linkTitle: 'support_email',
    secondaryContent: null,
    newTab: 'false'
  }
]
