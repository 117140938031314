import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'

import { Button, message } from 'antd'

import ImportModal, { TYPE_JSON } from '../../ImportModal'
import { RulesetContext } from '../../RulesetContext'

const ImportButton = () => {
  const { t } = useTranslation(['common'])
  const [importModalVisible, setImportModalVisible] = useState(false)

  const { state: ruleset, api } = useContext(RulesetContext)

  const { loading: loadingGet, doCall: getRules } = api.getRules
  const { loading: loadingImport, doCall: importRuleset } = api.importRuleset

  const handleImport = () => {
    setImportModalVisible(true)
  }

  const onCancelImport = () => {
    setImportModalVisible(false)
  }

  const doImport = data => {
    importRuleset(ruleset, data).then(result => {
      getRules(ruleset.ruleType)
      setImportModalVisible(false)
      message.success(t('ruleset_imported_successfully'))
    })
  }

  return (
    <>
      <Button type='link' onClick={handleImport} disabled={loadingGet}>
        {t('import')}
      </Button>
      <ImportModal
        visible={importModalVisible}
        onCancel={onCancelImport}
        fileType={TYPE_JSON}
        onOk={doImport}
        loading={loadingImport}
      />
    </>
  )
}

export default ImportButton
