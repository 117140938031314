import amplitude from 'amplitude-js'
import config from '@Lib/config'

export default class AmplitudeProvider {
  initialize = () => {
    amplitude.getInstance().init(config.analytics.amplitudeId)
  }

  enable = () => {
    amplitude.getInstance().setOptOut(false)
  }

  disable = () => {
    amplitude.getInstance().setOptOut(true)
  }

  login = userInfo => {
    if (!userInfo) {
      return
    }
    amplitude.getInstance().setUserId(userInfo.id)
    amplitude.getInstance().setUserProperties({
      name: userInfo.name,
      email: userInfo.email
    })
    amplitude.getInstance().logEvent('user_login_success')
  }

  logout = () => {
    amplitude.getInstance().logEvent('user_logout')
    amplitude.getInstance().setUserId(null) // not string 'null'
    amplitude.getInstance().regenerateDeviceId()
  }

  trackEvent = (eventProperties, category) => {
    amplitude.getInstance().logEvent(eventProperties.action, {
      category,
      ...eventProperties
    })
  }
}
