import React from 'react'
import Icon from '@ant-design/icons'
import StratticSVG from '@Images/logos/logo-3.svg'
import StratticFullSVG from '@Images/logos/strattic-logo.svg'
import DashboardSVG from '@Images/icons/main-menu-icon-dashboard.svg'
import AccountSVG from '@Images/icons/main-menu-icon-account.svg'
import TeamSVG from '@Images/icons/main-menu-icon-team.svg'
import SupportSVG from '@Images/icons/main-menu-icon-support.svg'
import DetailsSVG from '@Images/icons/details.svg'
import WordPressSVG from '@Images/icons/wordpress.svg'
import WordPressSVGDisabled from '@Images/icons/wordpress-grey.svg'
import ClockSVG from '@Images/icons/clock.svg'
import TrashSVG from '@Images/icons/trash.svg'
import CopySVG from '@Images/icons/copy.svg'
import QuestionMarkSVG from '@Images/icons/question-mark.svg'
import EyeSVG from '@Images/icons/eye.svg'
import ExternalLinkSVG from '@Images/icons/iconmonstr-external-link-thin.svg'
import EditIcon from '@Images/icons/edit-button.svg'
import GoogleSVG from '@Images/logos/google-normal.svg'

import './index.less'

const AntIconWrapper = props => <Icon {...props} />
const IconWrapper = ({ component }) => component

export const StratticIcon = () => <IconWrapper component={<StratticSVG />} />
export const StratticLogo = props => (
  <IconWrapper component={<StratticFullSVG {...props} />} />
)
export const DashboardIcon = () => <IconWrapper component={<DashboardSVG />} />
export const AccountIcon = () => <IconWrapper component={<AccountSVG />} />
export const TeamIcon = () => <IconWrapper component={<TeamSVG />} />
export const SupportIcon = () => <IconWrapper component={<SupportSVG />} />
export const ClockIcon = props => (
  <IconWrapper component={<ClockSVG {...props} />} />
)
export const TrashIcon = props => (
  <IconWrapper component={<TrashSVG {...props} />} />
)
export const DetailsIcon = () => <AntIconWrapper component={DetailsSVG} />
export const WordPressIcon = () => <AntIconWrapper component={WordPressSVG} />
export const WordPressIconDisabled = () => (
  <AntIconWrapper component={WordPressSVGDisabled} />
)
export const CopyIcon = props => (
  <AntIconWrapper component={CopySVG} {...props} />
)
export const QuestionMarkIcon = () => (
  <AntIconWrapper component={QuestionMarkSVG} />
)
export const EyeIcon = props => <AntIconWrapper component={EyeSVG} {...props} />
export const ExternalLinkIcon = () => (
  <IconWrapper component={<ExternalLinkSVG />} />
)
export const EditButtonIcon = () => <IconWrapper component={<EditIcon />} />
export const GoogleIcon = () => <IconWrapper component={<GoogleSVG />} />
