import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { CopyIcon } from '@Components/Icons'

const { Paragraph } = Typography

const CopyText = ({ text }) => {
  const { t } = useTranslation(['common'])

  return (
    <Paragraph
      style={{ display: 'inline' }}
      copyable={{
        text,
        icon: [<CopyIcon key='copy-icon' />, <CopyIcon key='copied-icon' />],
        tooltips: [t('copy_to_clipboard'), t('copied_message')]
      }}
    />
  )
}

export default CopyText
