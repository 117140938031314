import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { DateTime } from 'luxon'
import { Button } from 'antd'

import { RulesetContext } from '../../RulesetContext'

const rulesetToArrayOfStrings = (ruleset, ruleToCsvArray) => {
  const rulesetArr = []
  for (const rule of ruleset.rules) {

    const ruleArr = []
    for (const value of ruleToCsvArray(rule)) {
      if (typeof value === 'undefined') {
        ruleArr.push('')
        continue
      }

      if (typeof value === 'object') {
        ruleArr.push(JSON.stringify(value))
      } else {
        ruleArr.push(value.toString())
      }
    }
    rulesetArr.push(ruleArr)
  }
  return rulesetArr
}

const rulesetToCsv = (ruleset, columnNames, ruleToCsvArray) => {
  const arr = rulesetToArrayOfStrings(ruleset, ruleToCsvArray)
  let csvContent = columnNames.join(',') + '\n'
  const rows = []
  for (const row of arr) {
    const line = []
    for (const value of row) {
      line.push('"' + value.replace(/"/g, '""') + '"')
    }
    rows.push(line)
  }

  csvContent += rows.map(e => e.join(',')).join('\n')

  return csvContent
}

const ExportCsvButton = ({ filenameGenerator, columnNames, ruleToCsvArray }) => {
  const { t } = useTranslation(['common'])
  const [exportUrl, setExportUrl] = useState('')
  const [exportFilename, setExportFilename] = useState('')
  const exportLink = useRef(null)

  const { state: ruleset } = useContext(RulesetContext)

  useEffect(() => {
    if (exportUrl) {
      exportLink.current.click()
      URL.revokeObjectURL(exportUrl)
      setExportUrl('')
    }
  }, [exportUrl])

  useEffect(() => {
    if (ruleset) {
      let filename
      if (filenameGenerator) {
        filename = filenameGenerator(ruleset)
      } else {
        filename = 'rules-' + DateTime.local().toFormat('yyyy-MM-dd-HH-mm') + '.csv'
      }

      setExportFilename(filename)
    }
  }, [ruleset, filenameGenerator])

  const handleExport = () => {
    const output = rulesetToCsv(ruleset, columnNames, ruleToCsvArray)
    const blob = new Blob([output])
    setExportUrl(URL.createObjectURL(blob))
  }

  return (
    <>
      <a
        download={exportFilename}
        href={exportUrl}
        ref={exportLink}
        style={{ display: 'none' }}
      >
        download
      </a>
      <Button type='link' onClick={handleExport}>
        {t('export_csv')}
      </Button>
    </>
  )
}

export default ExportCsvButton
