import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, message } from 'antd'
import { RulesetContext } from '../../RulesetContext'

const MoveToFirstButton = ({ record }) => {
  const { t } = useTranslation(['common'])
  const { state: ruleset, api } = useContext(RulesetContext)
  const { doCall: reorderRuleset } = api.reorderRuleset

  const doReorder = (rule, newIndex) => {
    reorderRuleset(ruleset, rule.id, newIndex).then(response => {
      if (response) {
        message.success(t('ruleset_reordered_successfully'))
      }
    })
  }

  const moveToFirst = rule => {
    doReorder(rule, 0)
  }

  return (
    <Button
      className='sl-moveToFirst'
      type='link'
      onClick={() => {
        moveToFirst(record)
      }}
    >
      {t('move_to_first')}
    </Button>
  )
}

export default MoveToFirstButton
