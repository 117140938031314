import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { Drawer, Form, Input, Select, Typography, Button, Row, Col } from 'antd'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import { useUserContext } from '@Context/UserContext'

import './index.less'

const { Text, Title } = Typography
const { Item } = Form
const { Option } = Select

const UpdatePaymentDetails = ({
  updatePaymentDetailsVisible,
  setUpdatePaymentDetailsVisible,
  countryList,
  cardData,
  accountId,
  refetchCard
}) => {
  const { t } = useTranslation(['common'])
  const [formMessage, setFormMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [activeCard, setActiveCard] = useState(cardData?.[0])
  const userContext = useUserContext()
  const intentPath = `/accounts/${accountId}/cards/init`
  const [{ data: secret, error: secretError }, refetchSecret] = useStratticApi({
    url: intentPath,
    method: 'POST',
    data: { type: 'setup' }
  })
  useErrorMessage(secretError)

  useEffect(() => {
    setActiveCard(cardData?.[0])
  }, [cardData])

  const closeEditCard = () => {
    elements.getElement('card').clear()
    setFormMessage(null)
    setUpdatePaymentDetailsVisible(false)
  }
  const paymentMethod = (values, card) => {
    if (card) {
      return {
        payment_method: {
          card,
          billing_details: {
            address: {
              city: values.city,
              country: values.country,
              line1: values.line1,
              line2: values.line2,
              postal_code: values.postal_code,
              state: values.state
            },
            email: userContext?.accountDetails?.contactEmail,
            name: values.name
          }
        }
        // commented out for now, will introduce feature in future
        // receipt_email: values.email
      }
    }
  }
  const onFinish = async values => {
    const card = elements.getElement('card')
    const pm = paymentMethod(values, card)

    if (!stripe || !elements || !secret) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    setLoading(true)
    stripe.confirmCardSetup(secret?.result, pm).then(async payload => {
      if (payload.error) {
        AnalyticsManager.getInstance().trackEvent({
          action: 'update_payment_details_error',
          label: EVENT_CATEGORIES.ACCOUNT_PAGE
        })
        setFormMessage(`Error: ${payload.error.message}`)
        setLoading(false)
      } else {
        AnalyticsManager.getInstance().trackEvent({
          action: 'update_payment_details_success',
          label: EVENT_CATEGORIES.ACCOUNT_PAGE
        })
        await refetchCard()
        await refetchSecret()
        setLoading(false)
        closeEditCard()
      }
    })
  }
  return (
    <Drawer
      className='update-payment-drawer'
      width='50vw'
      placement='left'
      onClose={() => closeEditCard()}
      open={updatePaymentDetailsVisible}
    >
      <Title level={3}>{t('update_payment_details')}</Title>
      <Form
        wrapperCol={{
          sm: {
            span: 24
          },
          lg: {
            span: 20
          },
          xl: {
            span: 16
          }
        }}
        name='payment-info'
        className='payment-info-form'
        initialValues={{
          remember: true,
          name: activeCard?.billing_details?.name,
          email: userContext?.accountDetails?.contactEmail,
          country: activeCard?.billing_details?.address?.country,
          line1: activeCard?.billing_details?.address?.line1,
          line2: activeCard?.billing_details?.address?.line2,
          city: activeCard?.billing_details?.address?.city,
          state: activeCard?.billing_details?.address?.state,
          postal_code: activeCard?.billing_details?.address?.postal_code
        }}
        onFinish={onFinish}
      >
        <div className='billing-details-subhead'>{t('invoice_info')}</div>
        <Item
          name='name'
          rules={[
            {
              required: true,
              message: t('required')
            }
          ]}
          validateTrigger='onBlur'
        >
          <Input
            autoComplete='name'
            className='sl-company-input'
            placeholder={t('company_name')}
            aria-label={t('company_name')}
          />
        </Item>
        <Item
          name='email'
          rules={[
            {
              required: true,
              message: t('required')
            }
          ]}
          validateTrigger='onBlur'
        >
          <Input
            type='email'
            autoComplete='email'
            className='sl-email-input'
            placeholder={t('email')}
            aria-label={t('email')}
            disabled
          />
        </Item>
        <Item
          name='country'
          rules={[
            {
              required: true,
              message: t('required')
            }
          ]}
          validateTrigger='onBlur'
        >
          <Select
            showSearch
            autoComplete='country'
            className='sl-countryInput'
            placeholder={t('country')}
            aria-label={t('country')}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countryList?.result?.map(country => (
              <Option key={country.code} className={`sl-${country.code}`}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name='line1'
          rules={[
            {
              required: true,
              message: t('required')
            }
          ]}
          validateTrigger='onBlur'
        >
          <Input
            autoComplete='line1'
            className='sl-line1'
            placeholder={t('address_line1')}
            aria-label={t('address_line1')}
          />
        </Item>
        <Item name='line2'>
          <Input
            autoComplete='line2'
            className='sl-line2'
            placeholder={t('address_line2')}
            aria-label={t('address_line2')}
          />
        </Item>
        <Item
          name='city'
          rules={[
            {
              required: true,
              message: t('required')
            },
            {
              pattern: /^[,\-@_0-9a-zA-Z.\s]{2,}$/,
              message: t('city_rules')
            }
          ]}
          validateTrigger='onBlur'
        >
          <Input
            autoComplete='city'
            className='sl-city'
            placeholder={t('city')}
            aria-label={t('city')}
          />
        </Item>
        <Item
          name='state'
          rules={[
            {
              required: true,
              message: t('required')
            }
          ]}
          validateTrigger='onBlur'
        >
          <Input
            autoComplete='state'
            className='sl-state'
            placeholder={t('state')}
            aria-label={t('state')}
          />
        </Item>
        <Item
          name='postal_code'
          rules={[
            {
              required: true,
              message: t('required')
            },
            {
              pattern: /^[,\-@_0-9a-zA-Z.\s]{4,}$/,
              message: t('invalid_zip')
            }
          ]}
          validateTrigger='onBlur'
        >
          <Input
            autoComplete='zip-code'
            className='sl-zip-code'
            placeholder={t('zip_code')}
            aria-label={t('zip_code')}
          />
        </Item>

        <div className='billing-details-subhead'>{t('payment')}</div>
        <Row>
          <Col sm={24} lg={20} xl={16} className='cc-box'>
            <CardElement
              onFocus={() => setFormMessage(null)}
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontFamily: 'Roboto, Open Sans',
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4'
                    }
                  },
                  invalid: {
                    color: '#9e2146'
                  }
                }
              }}
            />
          </Col>
        </Row>
        <Text type='danger'>{formMessage}</Text>
        <div className='stripe-authorize-warn sl-stripe-authorize-warn'>
          {t('stripe_authorize')}
        </div>
        <div className='update-payment-action-btns'>
          <Button
            className='update-payment-action-save sl-update-payment-action-save'
            type='primary'
            htmlType='submit'
            loading={loading}
          >
            {t('save')}
          </Button>
          <Button
            className='update-payment-action-cancel update-payment-action-cancel'
            onClick={() => {
              setUpdatePaymentDetailsVisible(false)
              AnalyticsManager.getInstance().trackClick({
                action: 'update_payment_details',
                label: EVENT_CATEGORIES.ACCOUNT_PAGE
              })
            }}
            loading={loading}
          >
            {t('cancel')}
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default UpdatePaymentDetails
