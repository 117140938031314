import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { FORM_STATUS } from '@Constants'

import FormMessage from '@Components/FormMessage'
import PasswordStrengthTooltip from '@Components/PasswordStrengthTooltip'
import { isValidEmail } from '@Lib/api/auth'
import { submitHubspotForms } from '@Lib/api/hubspot'
import config from '@Lib/config'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'
import GoogleButton from '@Components/GoogleButton'

import { Form, Input, Button, Spin, Typography } from 'antd'

import './index.less'

const { Item } = Form
const { Password } = Input
const { Text } = Typography

const SignUpForm = ({
  givenEmail,
  givenCompany,
  givenName,
  invited,
  buyNow
}) => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const [email, setEmail] = useState(givenEmail)
  const [name, setName] = useState(givenName)
  const [password, setPassword] = useState('')
  const [formStatus, setFormStatus] = useState('')
  const [formMessage, setFormMessage] = useState('')

  const containsSpecialCharsRule = {
    pattern: /^[a-zA-Z0-9]+(([',. -][0-9a-zA-Z ])?[a-zA-Z0-9 ]*)*$/,
    message: t('contains_special_characters')
  }
  const containsNumbersRule = {
    pattern: /^([^0-9]*)$/,
    message: t('contains_number')
  }

  const minMaxRule = {
    pattern: /^.{3,45}$/,
    message: t('min_max')
  }
  // antd takes the message as the key and a none unique error message throws console error
  const passwdMinLengthRule = {
    pattern: /^.{8,}$/,
    message: ''
  }

  const passwdContainsLowercaseRule = {
    pattern: /^.*[a-z].*$/,
    message: ' '
  }

  const passwdContainsUppercaseRule = {
    pattern: /^.*[A-Z].*$/,
    message: '  '
  }

  const passwdContainsNumberRule = {
    pattern: /^.*[0-9].*$/,
    message: '   '
  }

  // sign up behaviour works differently based on inviteId or organic
  const onFinish = async values => {
    setFormMessage('')
    setFormStatus(FORM_STATUS.SUBMITTING)

    AnalyticsManager.getInstance().enable()

    if (!email || !name || !password) {
      setFormMessage(t('all_fields_required'))
      setFormStatus(FORM_STATUS.SUCCESS)
      return
    }

    // Verify email
    try {
      let emailResponse
      if (!invited && !buyNow) {
        emailResponse = await isValidEmail(email)
      }

      if (
        !!invited ||
        parseInt(emailResponse?.data?.result?.success) === 1 ||
        !!buyNow
      ) {
        // If valid email create account

        const signUpFields = {
          userName: name
        }

        if (invited) {
          signUpFields.invited = invited
        }

        await Auth.signUp({
          username: email,
          password,
          validationData: signUpFields,
          autoSignIn: {
            enabled: true
          }
        })
        if (invited) {
          AnalyticsManager.getInstance().trackEvent({
            action: 'invited_signup_success',
            label: 'invited_signup'
          })
          window.dataLayer.push({
            event: 'fireEvent',
            event_name: 'signup_success',
            action_type: 'signup_success',
            is_invited: !!invited,
            user_email: email,
            method: 'email'
          })
        } else {
          AnalyticsManager.getInstance().trackEvent({
            action: 'signup_success',
            label: EVENT_CATEGORIES.SIGN_UP
          })
          window.dataLayer.push({
            event: 'fireEvent',
            event_name: 'signup_success',
            action_type: 'signup_success',
            is_invited: !!invited,
            user_email: email,
            method: 'email'
          })
          if (!buyNow) {
            const hubspotFields = {
              email,
              username: name
            }
            await submitHubspotForms(config?.hubspot?.signUpForm, hubspotFields)
          }
        }
        setFormStatus(FORM_STATUS.SUCCESS)
        history.push(`/confirm/?email=${encodeURIComponent(email)}`)
        // Redirect to signup/confirm
      }
    } catch (error) {
      setFormMessage(error.message)
      setFormStatus(FORM_STATUS.SUCCESS)
      if (invited) {
        AnalyticsManager.getInstance().trackEvent({
          action: 'invited_signup_error',
          label: 'invited_signup'
        })
      } else {
        AnalyticsManager.getInstance().trackEvent({
          action: 'signup_error',
          label: EVENT_CATEGORIES.SIGN_UP
        })
      }
    }
    // Send to Hubspot?
  }

  return (
    <Spin
      spinning={formStatus === FORM_STATUS.SUBMITTING}
      tip={t('creating_account')}
    >
      <div className='wrapper'>
        <Form
          name='login'
          className='sl-login-form'
          onFinish={onFinish}
          noValidate
          initialValues={{
            email,
            name
          }}
        >
          {formMessage && <FormMessage message={formMessage} />}
          <Item>
            <GoogleButton signup invited={invited} />
          </Item>
          <Item
            name='name'
            rules={[
              {
                required: true,
                message: t('name_please')
              },
              minMaxRule,
              containsSpecialCharsRule,
              containsNumbersRule
            ]}
            validateTrigger='onBlur'
          >
            <Input
              autoComplete='name'
              className='sl-login-name-input'
              placeholder={t('name')}
              aria-label={t('name')}
              onChange={e => setName(e.target.value.trim())}
            />
          </Item>

          <Item
            name='email'
            rules={[
              {
                required: true,
                message: t('email_please')
              },
              {
                type: 'email',
                message: t('email_invalid')
              }
            ]}
          >
            <Input
              type='email'
              autoComplete='username'
              className='sl-login-email-input'
              placeholder={t('work_email')}
              aria-label={t('work_email')}
              onChange={e => setEmail(e.target.value)}
            />
          </Item>
          <PasswordStrengthTooltip password={password}>
            <Item
              name='password'
              className='signup-password-wrapper'
              rules={[
                {
                  required: true,
                  message: t('password_please')
                },
                passwdMinLengthRule,
                passwdContainsLowercaseRule,
                passwdContainsUppercaseRule,
                passwdContainsNumberRule
              ]}
            >
              <Password
                className='sl-login-password-input'
                placeholder={t('password')}
                autoComplete='current-password'
                aria-label={t('password')}
                onChange={e => setPassword(e.target.value)}
              />
            </Item>
          </PasswordStrengthTooltip>
          {!buyNow && (
            <Item
              wrapperCol={{
                offset: 2,
                span: 20
              }}
            >
              <Text>
                {t('creation_tos')}{' '}
                <a
                  className='tos-link'
                  href='https://www.strattic.com/terms-of-service/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('tos')}
                </a>{' '}
                &{' '}
                <a
                  className='privacy-policy-link'
                  href='https://www.strattic.com/privacy-policy/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('privacy_policy')}
                </a>
              </Text>
            </Item>
          )}

          <Item
            wrapperCol={{
              offset: 2,
              span: 20
            }}
          >
            <Button
              className='sl-login-submit-button'
              size='large'
              type='primary'
              htmlType='submit'
              disabled={formStatus === FORM_STATUS.SUBMITTING}
              onClick={() =>
                window.dataLayer.push({
                  event: 'fireEvent',
                  event_name: 'element_click',
                  action_type: 'signup_attempt',
                  is_invited: !!invited,
                  method: 'email'
                })
              }
            >
              {buyNow ? t('continue') : t('create_account')}
            </Button>
          </Item>
        </Form>
      </div>
    </Spin>
  )
}
export default SignUpForm
