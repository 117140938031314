import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { DateTime } from 'luxon'
import { Button } from 'antd'

import { RulesetContext } from '../../RulesetContext'

const ExportButton = ({ filenameGenerator }) => {
  const { t } = useTranslation(['common'])
  const [exportUrl, setExportUrl] = useState('')
  const [exportFilename, setExportFilename] = useState('')
  const exportLink = useRef(null)

  const { state: ruleset } = useContext(RulesetContext)

  useEffect(() => {
    if (exportUrl) {
      exportLink.current.click()
      URL.revokeObjectURL(exportUrl)
      setExportUrl('')
    }
  }, [exportUrl])

  useEffect(() => {
    if (ruleset) {
      let filename
      if (filenameGenerator) {
        filename = filenameGenerator(ruleset)
      } else {
        filename = 'rules-' + ruleset.ruleType + '-' + DateTime.local().toFormat('yyyy-MM-dd-HH-mm') + '.json'
      }

      setExportFilename(filename)
    }
  }, [ruleset, filenameGenerator])

  const handleExport = () => {
    for(const rule of ruleset.rules) {
      if (rule.redirect && rule.redirect.last === undefined) {
        rule.redirect.last = false
      }
    }
    const output = JSON.stringify(ruleset)
    const blob = new Blob([output])
    setExportUrl(URL.createObjectURL(blob))
  }

  return (
    <>
      <a
        download={exportFilename}
        href={exportUrl}
        ref={exportLink}
        style={{ display: 'none' }}
      >
        download
      </a>
      <Button type='link' onClick={handleExport}>
        {t('export')}
      </Button>
    </>
  )
}

export default ExportButton
