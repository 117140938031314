import React from 'react'

import { useTranslation } from 'react-i18next'
import { Template } from '@Templates/MigrateSite'
import Illustration from '@Images/site-nav-illustration.svg'
import { useUserContext } from '@Context/UserContext'
import Migrate from '@Images/icons/migrate.svg'
import SiteLogo from '@Images/icons/new-site.svg'
import { Col, Collapse, Divider, Row } from 'antd'

import './index.less'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const { Panel } = Collapse

const SiderContent = () => {
  const { t } = useTranslation(['common', 'errors'])
  return (
    <div className='side-panel'>
      <div className='title'>{t('welcome')}</div>
      <div className='content'>{t('welcome_content')}</div>
      <Illustration />
    </div>
  )
}

const AddSiteNavScreen = () => {
  const { t } = useTranslation(['common', 'errors'])
  const userContext = useUserContext()
  const history = useHistory()

  return (
    <Template
      classNames='add-site-nav'
      siderContent={<SiderContent />}
      title='Site Welcome'
    >
      <Row className='page-content'>
        <Col className='content-col'>
          <div className='content'>
            {t('how_would_you_like_to_start')}
            <br />
            <Row justify='space-between'>
              <div
                className='card'
                onClick={() =>
                  history.push(`/account/${userContext.accountId}/migrate-site`)
                }
              >
                <Row>
                  <Migrate />
                  {t('migrate_site')}
                </Row>
              </div>
              <div
                className='card'
                onClick={() =>
                  history.push(`/account/${userContext.accountId}/new-site`)
                }
              >
                <Row>
                  <SiteLogo />
                  {t('create_new_site')}
                </Row>
              </div>
            </Row>
            <Divider />
            {t('freq_asked_q')}
            <br />
            <FaqCollapse
              key='0'
              header={t('what_is_site_creation_wizard')}
              content={t('what_is_site_creation_wizard_answer')}
            />
            <FaqCollapse
              key='1'
              header={t('how_choose_site_name')}
              content={t('how_choose_site_name_answer')}
            />
            <FaqCollapse
              key='2'
              header={t('what_is_migrate_guru')}
              content={t('what_is_migrate_guru_answer')}
            />
            <FaqCollapse
              key='3'
              header={t('why_add_strattic_email')}
              content={t('why_add_strattic_email_answer')}
            />
            <FaqCollapse
              key='4'
              header={t('what_are_sftp')}
              content={t('what_are_sftp_answer')}
            />
          </div>
        </Col>
      </Row>
    </Template>
  )
}

export default AddSiteNavScreen

const FaqCollapse = ({ key, header, content }) => {
  return (
    <Collapse
      accordion
      className='site-welcome-custom-collapse'
      bordered={false}
      expandIconPosition='end'
    >
      <Panel header={header} key={key}>
        {content}
      </Panel>
    </Collapse>
  )
}
