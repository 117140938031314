import { useCallback, useState, useEffect } from 'react'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useInterval from '@Hooks/useInterval'

import useLocalStorage from '@Hooks/useLocalStorage'

const useStratticSession = () => {
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser')
  const [isAuthenticated, setIsAuthenticated] = useState(!!currentUser)

  const [
    { loading: currentUserLoading, error: currentUserError },
    getCurrentUser
  ] = useStratticApi({
    url: 'users/current',
    method: 'GET'
  })

  const handleError = error => {
    if (!error) return
    if (
      error.code === 'NotAuthorizedException' ||
      error === 'No current user'
    ) {
      setIsAuthenticated(false)
      clearStorage()
    }
    return error
  }

  useErrorMessage(currentUserError, handleError)

  useEffect(() => {
    !currentUser && setIsAuthenticated(false)
  }, [currentUser])

  const checkSession = async () => {
    const currentUserData = await getCurrentUser()
    const userData = currentUserData?.data?.result
    if (!userData) return
    setCurrentUser(userData)
    return userData
  }

  useInterval(() => {
    checkSession()
  }, 30)

  const login = useCallback(async () => {
    const userData = await checkSession()
    !currentUserError && setIsAuthenticated(true)
    return userData
    // eslint-disable-next-line
  }, [])

  const clearStorage = () => {
    // eslint-disable-next-line
    const event = new Event('clear-localStorage')
    window.dispatchEvent(event)
  }

  const clearSession = useCallback(() => {
    setIsAuthenticated(false)
    clearStorage()
    // eslint-disable-next-line
  }, [])

  return [
    { isAuthenticated, currentUserLoading, currentUser },
    login,
    clearSession
  ]
}

export default useStratticSession
