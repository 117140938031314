import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useLocalStorage from '@Hooks/useLocalStorage'

import useAccountPermissions from '@Hooks/useAccountPermissions'
import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'

import { useUserContext } from '@Context/UserContext'
import AccountExpiredCard from '@Components/AccountExpiredCard'

import { Button, Card, Typography } from 'antd'
import Globe from '@Images/icons/globe.svg'

import { hasSubscriptionExpired } from '@Lib/helpers/subscriptionHelpers'

import './index.less'

const { Text } = Typography

const DomainUpgradeCard = () => {
  const [accountId] = useLocalStorage('accountId')

  const userContext = useUserContext()

  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )

  if (hasSubscriptionExpired(userContext.accountDetails)) {
    return <AccountExpiredCard context='domain' />
  }

  if (hasPermissionToUpgrade) {
    return <CardUpgrade accountId={accountId} />
  }

  return <CardContactOwner />
}

const CardContactOwner = () => {
  const { t } = useTranslation(['common'])

  return (
    <div className='domain-upgrade-card'>
      <div className='back-shadow' />
      <Card className='narrow'>
        <div className='icon-wrapper'>
          <Globe />
        </div>
        <h2>{t('upgrade_to_connect')}</h2>
        <main>
          <p>
            <Text>{t('upgrade_trial_account_contact_owner')}</Text>
          </p>
        </main>
      </Card>
    </div>
  )
}

const CardUpgrade = ({ accountId }) => {
  const { t } = useTranslation(['common'])

  return (
    <div className='domain-upgrade-card'>
      <div className='back-shadow' />
      <Card className='narrow'>
        <div className='icon-wrapper'>
          <Globe />
        </div>
        <h2>{t('upgrade_to_connect')}</h2>
        <main>
          <p>
            <Text>{t('upgrade_to_connect_description')}</Text>
          </p>
          <p>
            <Button type='primary' className='sl-upgradePlan'>
              <Link to={`/account/${accountId}/available-plans`}>
                {t('upgrade_here')}
              </Link>
            </Button>
          </p>
        </main>
      </Card>
    </div>
  )
}

export default DomainUpgradeCard
