import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Typography } from 'antd'
const { Title } = Typography

const SectionTitle = ({ title, label }) => {
  const { t } = useTranslation(['common'])
  return (
    <Row className='section-title'>
      <Col span={5}>
        <Title level={4}>{t(title)}</Title>
      </Col>
      <Col span={13}>{label && t(label)}</Col>
    </Row>
  )
}
export default SectionTitle
