import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, Typography } from 'antd'
import { pluralStringIfNeeded } from '@Lib/helpers'
import './index.less'

const { Text, Title } = Typography

const DeleteBackupModal = ({
  isVisible,
  handleCancel,
  handleDelete,
  processing,
  num
}) => {
  const { t } = useTranslation(['common'])

  const footer = (
    <div className='modal-footer'>
      <Button
        className='confirm-delete-btn'
        key='submit'
        type='primary'
        loading={processing}
        onClick={() => {
          if (!processing) {
            handleDelete()
          }
        }}
      >
        {t('delete')}
      </Button>
      <Button
        key='back'
        onClick={() => {
          if (!processing) {
            handleCancel()
          }
        }}
      >
        {t('cancel')}
      </Button>
    </div>
  )

  return (
    <Modal
      className='delete-backup-modal'
      open={isVisible}
      footer={footer}
      width={825}
      height={478}
      onCancel={() => {
        if (!processing) {
          handleCancel()
        }
      }}
    >
      <div className='heading'>
        <Title level={3}>{t('delete_backups')}</Title>
        <Text className='warning-text'>
          {t('delete_backup_confirm_text', {
            num,
            backupOrBackups: pluralStringIfNeeded('backup', num)
          })}
        </Text>
      </div>
    </Modal>
  )
}

export default DeleteBackupModal
