import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Typography,
  Input,
  Row,
  Col,
  Form,
  message,
  Tooltip
} from 'antd'
import { SendOutlined } from '@ant-design/icons'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import { QuestionMarkIcon } from '@Components/Icons'

const { Text } = Typography

const Name = ({ name, refetchCurrentUser, cantEditName }) => {
  const { t } = useTranslation(['common', 'errors'])
  const [isEditing, setIsEditing] = useState(false)

  const path = 'users/current'
  const [
    { loading: isApiLoading, error: apiError },
    executePut
  ] = useStratticApi(
    {
      url: path,
      method: 'PUT'
    },
    { manual: true }
  )
  useErrorMessage(apiError)

  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        isEditing
      ) {
        cancelEditing()
      }
    }

    const handleEsc = event => {
      if (event.key === 'Escape' && isEditing) {
        cancelEditing()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEsc)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEsc)
    }
  }, [wrapperRef, isEditing])

  const startEditing = () => {
    setIsEditing(true)
  }

  const cancelEditing = () => {
    setIsEditing(false)
  }

  const onFinish = async values => {
    const result = await executePut({ data: values })
    if (result) {
      await refetchCurrentUser()
      message.success(t('user_name_update_success'))
      cancelEditing()
    }
  }

  return (
    <div ref={wrapperRef}>
      {isEditing ? (
        <EditForm
          t={t}
          name={name}
          isApiLoading={isApiLoading}
          onFinish={onFinish}
        />
      ) : (
        <Data
          t={t}
          name={name}
          startEditing={startEditing}
          cantEditName={cantEditName}
        />
      )}
    </div>
  )
}

const EditForm = ({ onFinish, name, isApiLoading, t }) => {
  const containsSpecialCharsRule = {
    pattern: /^[a-zA-Z0-9]+(([',. -][0-9a-zA-Z ])?[a-zA-Z0-9]*)*$/,
    message: t('contains_special_characters')
  }
  const containsNumbersRule = {
    pattern: /^([^0-9]*)$/,
    message: t('contains_number')
  }

  const minMaxRule = {
    pattern: /^.{3,45}$/,
    message: t('min_max')
  }
  return (
    <Form layout='inline' onFinish={onFinish}>
      <Input.Group>
        <Row>
          <Col span={7}>
            <Text type='secondary'>{t('name')}</Text>
          </Col>
          <Col span={13}>
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  message: t('name_please')
                },
                minMaxRule,
                containsSpecialCharsRule,
                containsNumbersRule
              ]}
              initialValue={name}
            >
              <Input className='user-name-input' />
            </Form.Item>
          </Col>
          <Col>
            <Button
              type='default'
              loading={isApiLoading}
              block
              htmlType='submit'
              style={{ margin: 0, minWidth: 'auto' }}
              className='submit-user-name'
            >
              <SendOutlined />
            </Button>
          </Col>
        </Row>
      </Input.Group>
    </Form>
  )
}
const Data = ({ t, name, startEditing, cantEditName }) => {
  return (
    <Row justify='space-between'>
      <Col span={7}>
        <Text type='secondary'>{t('name')}</Text>
      </Col>
      <Col span={13}>
        <Text type='secondary'>{name}</Text>
      </Col>
      <Col>
        {cantEditName ? (
          <CantEdit t={t} />
        ) : (
          <Edit startEditing={startEditing} t={t} />
        )}
      </Col>
    </Row>
  )
}
export default Name

function CantEdit ({ t }) {
  return (
    <>
      <Text disabled>{t('edit')}</Text>
      <Tooltip title={t('externally_managed_no_edit')}>
        {' '}
        <QuestionMarkIcon />
      </Tooltip>
    </>
  )
}

function Edit ({ startEditing, t }) {
  return (
    <Button type='link' className='edit-link' onClick={startEditing}>
      {t('edit')}
    </Button>
  )
}
