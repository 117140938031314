const warn = s => {
  console.warn('[react-gtm]', s)
}

// https://developers.google.com/tag-manager/quickstart

const Snippets = {
  tags: function ({
    id,
    events,
    dataLayer,
    dataLayerName,
    preview,
    auth,
    cookies = ''
  }) {
    const gtmAuth = auth ? `&gtm_auth=${auth}` : ''
    const gtmPreview = preview ? `&gtm_preview=${preview}` : ''
    const stratticTagManagerUrl = 'gtm.strattic.com'
    if (!id) warn('GTM Id is required')

    const iframe = `
      <iframe src="https://${stratticTagManagerUrl}/ns.html?id=${id}${gtmAuth}${gtmPreview}${cookies}"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
          events
        ).slice(1, -1)}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://${stratticTagManagerUrl}/gtm.js?id='+i+dl+'${gtmAuth}${gtmPreview}${cookies}';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName)

    return {
      iframe,
      script,
      dataLayerVar
    }
  },
  dataLayer: function (dataLayer, dataLayerName) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`
  }
}

module.exports = Snippets
