// Constants on site objects

export const SITE_TYPES = {
  DEFAULT: 'default',
  TEMP_BASIC: 'temp_basic',
  TEMP_RESTORE: 'temp_restore'
}

export const DISTRO_TYPES = {
  LIVE: 'live',
  PREVIEW: 'preview'
}

export const SITE_STATUSES = {
  ACTIVE: 'active',
  CREATING: 'creating',
  UPDATING: 'updating',
  DELETING: 'deleting',
  DELETED: 'deleted',
  RESTORING: 'restoring'
}

export const SITE_RUNNING_STATUSES = {
  STOPPED: 'stopped',
  STARTING: 'starting',
  RUNNING: 'running',
  STOPPING: 'stopping',
  FAILED: 'failed'
}

export const INITIATOR_TIMING = {
  SCHEDULED: 'scheduled',
  IMMEDIATE: 'immediate'
}

export const BACKUP_STATUSES = {
  PENDING: 'pending',
  BUSY: 'busy',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELED: 'canceled',
  DUPLICATE: 'duplicate'
}

export const BACKUP_TRIGGERED_BY = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  AUTO: 'auto'
}

export const DELETED_STATUSES = [SITE_STATUSES.DELETING, SITE_STATUSES.DELETED]

export const PUBLISH_STATUSES = {
  ABORTED: 'aborted',
  FAILED: 'failed',
  BUSY: 'busy',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed'
}

export const PUBLISH_TYPE = {
  FULL: 'full',
  QUICK: 'quick',
  SELECTIVE: 'selective',
  FORCED: 'forced'
}

export const STATUSCAKE_STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  UNKNOWN: 'unknown',
  PARTIAL: 'partial'
}

export const CUSTOM_DOMAIN_STATUS = {
  DISABLED: 'Disabled',
  PENDING: 'Pending'
}

export const PUBLISH_CONTAINER_STATUS = {
  DISABLED: 'disabled',
  ENABLED: 'enabled'
}

export const PHP_VERSIONS = {
  7.4: '7.4',
  8.1: '8.1'
}

export const PUBLISH_INITIATOR = {
  WORDPRESS: 'wordpress',
  STRATTIC_ADMIN: 'strattic-admin',
  STRATTIC_USER: 'strattic-user'
}

export const PUBLISH_INITIATOR_TYPES = {
  USER: 'user',
  ADMIN: 'admin',
  SYSTEM: 'system',
  CRON: 'cron'
}

export const PUBLISH_INITIATOR_SOURCES = {
  WORDPRESS: 'wordpress',
  CUSTOMER_DASH: 'customer_dash',
  ADMIN: 'admin',
  API: 'api',
  CUSTOM_DOMAIN: 'custom_domain'
}

export const QUERY_PARAM_CACHE_TYPE = {
  NONE: 'none',
  ALL: 'all',
  WHITELIST: 'whitelist'
}

export const RESTORE_STATUSES = {
  PENDING: 'pending',
  BUSY: 'busy',
  COMPLETED: 'completed',
  FAILED: 'failed'
}

export const RESTORE_TYPES = {
  URL: 'url',
  BACKUP: 'backup'
}
// Default to 30 seconds
export const cronFetchTimer = 30 * 1000

export const SLASH_REDIRECT_CODES = {
  301: '301',
  302: '302',
  307: '307',
  308: '308'
}
