export const WWW = 'www.'

export const CSS = {
  SITE_NAV_WIDTH: '289px',
  SITE_NAV_THEME: 'light'
}

export const NAV_STYLE = {
  MAX: 992,
  MED: 768,
  MOBILE: 576
}

export const FORM_STATUS = {
  SUBMITTING: 'submitting',
  SUCCESS: 'success',
  DANGER: 'danger'
}

export const CONFIRMATIONS = {
  DELETE: 'DELETE'
}

export const DNS_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING'
}

export const LOADING_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const DIST_TYPES = {
  LIVE: 'live',
  PREVIEW: 'preview',
  ALL: 'all'
}

export const DOMAIN_STATUS = {
  PENDING: 'PENDING_VALIDATION',
  ISSUED: 'ISSUED',
  VALIDATION_TIMED_OUT: 'VALIDATION_TIMED_OUT'
}

export const SITE_STATUS = {
  RUNNING: 'running',
  STOPPED: 'stopped',
  ACTIVE: 'active',
  CREATING: 'creating',
  UPDATING: 'updating',
  DELETING: 'deleting',
  DELETED: 'deleted',
  RESTORING: 'restoring'
}

export const DELETED_STATUSES = [SITE_STATUS.DELETING, SITE_STATUS.DELETED]

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc'
}

export const PUBLISH_TYPES = {
  ALL: 'all',
  QUICK: 'quick',
  FULL: 'full',
  SELECTIVE: 'selective'
}

export const DATE_TIMES = {
  MED: 'DATETIME_MED'
}

export const ERROR_CODES = {
  CANCELLED_API_REQUEST: 'cancelled_api_request'
}

export const DATA_SIZES = {
  KiB: 'KiB',
  MiB: 'MiB',
  GiB: 'GiB',
  TiB: 'TiB'
}

export const BILLING_CYCLE = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly'
}

export const DATA_SIZES_ARRAY = [
  DATA_SIZES.KiB,
  DATA_SIZES.MiB,
  DATA_SIZES.GiB,
  DATA_SIZES.TiB
]

export const SITE_TYPES = {
  DEFAULT: 'default',
  TEMP_BASIC: 'temp_basic',
  TEMP_RESTORE: 'temp_restore'
}

export const ROLE_TYPE = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MANAGER: 'manager',
  COLLABORATOR: 'collaborator'
}

export const PAGINATION_POSITIONS = {
  BOTTOM_LEFT: 'bottomLeft'
}

export const INVITATION_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending'
}

export const OWNER_OR_ADMIN = [ROLE_TYPE.OWNER, ROLE_TYPE.ADMIN]

export const MODAL_WIDTH = '500px'

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  EXPIRED: 'expired'
}

export const FREE_TRIAL = 'Free trial'

export const DOMAIN_CONNECTION = {
  UNCONNECTED: 0,
  CONNECTED: 1,
  RESETTING: 2
}
export const NUMBER_WP_USERS = {
  ONE: '1',
  TWO_TO_FIVE: '2-5',
  SIX_TO_TEN: '6-10',
  ELEVEN_PLUS: '11+'
}

export const PHP_VER = {
  LATEST_SUPPORTED: '7.4'
}

export const PLAN_TYPES = {
  ENTERPRISE: 'Enterprise',
  BUSINESS: 'Business',
  SOLO: 'Solo'
}

export const BILLING_CYCLE_OPTIONS = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly'
}

export const PLAN_NAMES = {
  PLAN_ONE: 'Starter',
  PLAN_TWO: 'Professional',
  PLAN_THREE: 'Business',
  CUSTOM: 'Enterprise'
}
