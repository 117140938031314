import React from 'react'

import { useTranslation } from 'react-i18next'
import { Modal, Button, Skeleton, Typography, Row, Col } from 'antd'
import { upperFirst } from '@Lib/helpers/text'

import { MODAL_WIDTH } from '@Constants'
import './index.less'

const { Text, Title } = Typography

const UserDetails = ({ title, record }) => (
  <Row justify='space-between'>
    <Col span={5} offset={1} className='delete-title'>
      <Text strong>{title}:</Text>
    </Col>
    <Col span={15} className='delete-record sl-delete-record'>
      {record}
    </Col>
  </Row>
)

const DeleteTeamMember = ({
  visible,
  onCreate,
  onCancel,
  sites,
  loading,
  record
}) => {
  const { t } = useTranslation(['common'])

  return (
    <Modal
      open={visible}
      title={<Text strong> {t('remove_team_member')}</Text>}
      onCancel={onCancel}
      onOk={onCreate}
      className='add-member-modal'
      width={MODAL_WIDTH}
      footer={[
        <Button
          key='submit'
          onClick={onCreate}
          className='add-member-ok sl-ok'
          loading={loading}
        >
          Delete
        </Button>,
        <Button
          key='back'
          onClick={onCancel}
          className='add-member-cancel sl-cancel'
        >
          Cancel
        </Button>
      ]}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <Title level={4}>{t('are_you_sure_delete_user')}</Title>
          <UserDetails title='Email' record={record?.userEmail} />
          <UserDetails title='Role' record={upperFirst(record?.roleType)} />
        </>
      )}
    </Modal>
  )
}

export default DeleteTeamMember
