const Session = {
  get: (toGet, storage = 'local') => {
    let data
    if (storage === 'local') {
      data = window.localStorage.getItem(toGet)
    } else {
      data = window.sessionStorage.getItem(toGet)
    }

    if (!data || data === 'undefined') return null

    let value = data
    value = JSON.parse(data)
    return value
  },

  set: (toSet, value, storage = 'local') => {
    let newValue = value
    newValue = JSON.stringify(value)

    if (storage === 'local') {
      window.localStorage.setItem(toSet, newValue)
    } else {
      window.sessionStorage.setItem(toSet, newValue)
    }
  },

  clear: () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
  }
}

export default Session
