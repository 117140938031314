// React
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { SITE_STATUS, PHP_VER } from '@Constants'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'

import { Checkbox, Col, Card, Row, Tabs, Spin, Tooltip, Typography } from 'antd'
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

// Low level internals
import useCurrentSite from '@Hooks/useCurrentSite'
import {
  getEncodedUserPass,
  combineUserPass,
  generateRandomPass
} from '@Lib/helpers'
import config from '@Lib/config'
import { getDate, getSiteCardDate, publishedLessThanWeek } from '@Lib/dates'

import { Template } from '@Templates/Site'
import DefinitionHeader from '@Components/DefinitionHeader'
import DefinitionData from '@Components/DefinitionData'

import { permissionGranted } from '@Lib/helpers/permissionsHelpers'
import { SITE_PERMISSIONS } from '@Constants/permissionsConstants'

import './index.less'
import { latestWpVersion } from '@Lib/api/sites'

const { Link } = Typography

const WPConnectionInfo = ({ t, currentSite }) => {
  return (
    <>
      <section>
        <dl>
          <Row>
            <Col md={24} lg={7}>
              <DefinitionHeader
                label='http_basic_auth_credentials'
                tooltip='http_basic_auth_tooltip'
              />
            </Col>
            <Col md={24} lg={17}>
              <DefinitionData
                label='user_name'
                value={currentSite?.value?.httpUsername}
              />
              <DefinitionData
                label='password'
                value={currentSite?.value?.httpBasicPassword}
                showHide
              />
            </Col>
          </Row>
        </dl>
      </section>
      {currentSite?.value?.sftpUsername && currentSite?.value?.sftpPassword && (
        <section>
          <dl>
            <Row>
              <Col md={24} lg={7}>
                {/* TODO: Add reset functionality */}
                <DefinitionHeader
                  label='sftp_credentials'
                  tooltip='sftp_credentials_tooltip'
                  actionLabel='reset'
                  onClick={() => {
                    console.log('Reset Credentials')
                  }}
                />
              </Col>
              <Col md={24} lg={17}>
                <DefinitionData
                  label='sftp_address_host'
                  value={config.siteConnectionInfo?.sftpAddress}
                />
                <DefinitionData
                  label='port'
                  value={currentSite?.siteConnectionInfo?.sftpPort || 22}
                />
                <DefinitionData
                  label='user_name'
                  value={currentSite?.value?.sftpUsername}
                />
                <DefinitionData
                  label='password'
                  value={currentSite?.value?.sftpPassword}
                  showHide
                />
              </Col>
            </Row>
          </dl>
        </section>
      )}
      {currentSite?.value?.sqlUsername && (
        <section>
          <dl>
            <Row>
              <Col md={24} lg={7}>
                <DefinitionHeader
                  label='sql_credentials'
                  tooltip={<SqlCredTooltip t={t} />}
                />
              </Col>
              <Col md={24} lg={17}>
                <DefinitionData
                  label='sql_host'
                  value={config.siteConnectionInfo?.sqlHost}
                />
                <DefinitionData
                  label='database'
                  value={currentSite?.value?.sqlDatabase}
                />
                <DefinitionData
                  label='user_name'
                  value={currentSite?.value?.sqlUsername}
                />
                <DefinitionData
                  label='password'
                  value={currentSite?.value?.sqlPassword}
                  showHide
                />
              </Col>
            </Row>
          </dl>
        </section>
      )}
      <section>
        <dl>
          <Row>
            <Col md={24} lg={7}>
              <DefinitionHeader
                label='wp_url'
                addHttps
                tooltip={
                  <>
                    {t('wp_url_tooltip')}
                    <Link
                      href='https://support.strattic.com/en/articles/2574394-how-to-migrate-a-copy-of-your-wordpress-site-to-strattic-using-migrate-guru'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('guide')}
                    </Link>
                  </>
                }
              />
            </Col>
            <Col md={24} lg={17}>
              <DefinitionData value={currentSite?.value?.wpURL} addHttps />
            </Col>
          </Row>
        </dl>
      </section>
    </>
  )
}

const SiteDetailsPage = () => {
  const { t } = useTranslation(['common', 'errors'])
  const [currentSite, refetchCurrentSite, cancelCurrentSite] = useCurrentSite()
  const [isUpdatingSite, setIsUpdatingSite] = useState(true)
  const [distributionToUpdate, setDistributionToUpdate] = useState(false)
  const [siteStatus, setSiteStatus] = useState(currentSite?.value?.status)
  const [latestWp, setLatestWp] = useState()
  const timer = useRef(null)

  const [{ error }, updateDistributionCall] = useStratticApi(
    {},
    { manual: true }
  )
  useErrorMessage(error)

  const getWpVer = async () => {
    const wpVer = await latestWpVersion()
    wpVer && setLatestWp(wpVer)
  }

  useEffect(() => {
    setSiteStatus(currentSite?.value?.status)
  }, [currentSite])

  useEffect(() => {
    getWpVer()
  }, [])

  useEffect(() => {
    if (!currentSite?.value?.status) return
    if (currentSite?.value?.status === SITE_STATUS.UPDATING) {
      checkSite()
    } else {
      setIsUpdatingSite(false)
    }
    return () => {
      cancelCurrentSite()
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [currentSite?.value?.status])

  useEffect(() => {
    if (!distributionToUpdate) return

    updateDistribution()

    return () => {
      cancelCurrentSite()
      clearTimeout(timer.current)
    }

    // eslint-disable-next-line
  }, [distributionToUpdate])

  const checkSite = async () => {
    setIsUpdatingSite(true)
    const response = await refetchCurrentSite()
    const newSiteInfo = response?.data?.result
    if (
      newSiteInfo?.status === SITE_STATUS.UPDATING ||
      typeof newSiteInfo?.status === 'undefined'
    ) {
      timer.current = setTimeout(() => {
        checkSite()
      }, 2500)
    } else {
      setIsUpdatingSite(false)
    }
  }

  const updateDistribution = async () => {
    setIsUpdatingSite(true)
    let params
    if (distributionToUpdate?.auth) {
      params = { httpAuthBase64: null }
    } else {
      params = combineUserPass(
        currentSite?.value?.httpUsername,
        generateRandomPass(8)
      )
    }

    await updateDistributionCall({
      method: 'PUT',
      url: `distributions/${distributionToUpdate?.id}`,
      data: params
    })

    checkSite()
  }

  return (
    <Template
      currentSite={currentSite}
      classNames='site-details'
      title={t('site_details')}
      siteStatus={siteStatus}
    >
      <div className='data-list'>
        <Spin spinning={!currentSite?.value?.httpBasicPassword}>
          {currentSite?.isLoading === false && (
            <div className='site-detail-cards'>
              <LastPublishedCard
                t={t}
                distribution={currentSite?.value?.liveDistribution}
                label={t('latest_live')}
                customClass='last-live-bar'
              />
              <LastPublishedCard
                t={t}
                distribution={currentSite?.value?.previewDistribution}
                label={t('latest_preview')}
                customClass='last-preview-bar'
              />
              {currentSite?.value?.wpVersion && (
                <Card className='site-detail wp-ver'>
                  <span className='bar wp-ver-bar' />
                  <label>{t('wp_ver')}</label>
                  <h2>{currentSite?.value?.wpVersion}</h2>
                  <h3 className='latest-wp'>
                    {t('latest')} {latestWp}{' '}
                    {currentSite?.value?.wpVersion !== latestWp && (
                      <Tooltip title={t('wp_ver_tip')}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    )}
                  </h3>
                </Card>
              )}
              <Card className='site-detail php-ver'>
                <span className='bar php-ver-bar' />
                <label>
                  {t('php_ver')}
                  {currentSite?.value?.phpVersion !==
                    PHP_VER.LATEST_SUPPORTED && (
                    <Tooltip
                      title={t('to_upgrade_to_latest_php_contact_support', {
                        version: PHP_VER.LATEST_SUPPORTED
                      })}
                    >
                      &nbsp;
                      <QuestionCircleOutlined />
                    </Tooltip>
                  )}
                </label>
                <h2>{currentSite?.value?.phpVersion}</h2>
              </Card>
              {/* <Card className='site-detail created-at'>
                <span className='bar site-created-bar' />
                <label>{t('site_created')}</label>
                <h2>
                  {getSiteCardDate(currentSite?.value?.createdAt).month}{' '}
                  {getSiteCardDate(currentSite?.value?.createdAt).day}{' '}
                  <span className='year'>
                    {getSiteCardDate(currentSite?.value?.createdAt).year}
                  </span>
                </h2>
                <h3>
                  {t('at')}{' '}
                  {getDate(currentSite?.value?.createdAt, 'TIME_SIMPLE')}
                </h3>
              </Card> */}
              {/* Commenting this out until site storage API is available */}
              {/*
              <Card className='site-detail site-storage'>
              <span className='bar' />
              <label>{t('site_storage')}</label>
              <h2>XX.X</h2>
              <h3>{t('gb')}</h3>
              </Card>
             */}
            </div>
          )}
          <Tabs
            defaultActiveKey='1'
            items={[
              {
                label: t('wp_connection_info'),
                key: '1',
                children: <WPConnectionInfo t={t} currentSite={currentSite} />
              },
              {
                label: t('preview_connection_info'),
                key: '2',
                children: (
                  <ConnectionInfo
                    currentSite={currentSite}
                    distribution={currentSite?.value?.previewDistribution}
                    isUpdatingSite={isUpdatingSite}
                    setDistributionToUpdate={setDistributionToUpdate}
                  />
                )
              },
              {
                label: t('live_connection_info'),
                key: '3',
                children: (
                  <ConnectionInfo
                    currentSite={currentSite}
                    distribution={currentSite?.value?.liveDistribution}
                    isUpdatingSite={isUpdatingSite}
                    setDistributionToUpdate={setDistributionToUpdate}
                  />
                )
              }
            ]}
          />
        </Spin>
      </div>
    </Template>
  )
}

const ConnectionInfo = ({
  liveDistribution,
  currentSite,
  distribution,
  isUpdatingSite,
  setDistributionToUpdate
}) => {
  const { t } = useTranslation(['common', 'errors'])

  const [hasPermissionToSetPassword, setHasPermissionSetPassword] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_SET_SITE_PROTECTED_PASSWORD
    )
  )

  useEffect(() => {
    setHasPermissionSetPassword(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_SET_SITE_PROTECTED_PASSWORD
      )
    )
  }, [currentSite])
  const domainName = distribution?.domainName
  const url = domainName?.startsWith('http')
    ? domainName
    : `https://${domainName}`
  return (
    <>
      <section>
        <dl>
          <Row>
            <Col md={24} lg={7}>
              <DefinitionHeader
                label={liveDistribution ? 'live_url' : 'preview_url'}
              />
            </Col>
            <Col md={24} lg={17}>
              <DefinitionData isLink value={url} />
            </Col>
          </Row>
        </dl>
      </section>
      <Spin tip={t('site_details_updating')} spinning={isUpdatingSite}>
        <section>
          <dl>
            <Row>
              <Col md={24} lg={7}>
                <dt>
                  <h3>{t('site_protection')}</h3>
                </dt>
              </Col>
              <Col md={24} lg={17}>
                <dd>
                  <Checkbox
                    disabled={!hasPermissionToSetPassword}
                    checked={!!distribution?.auth}
                    onChange={() => {
                      setDistributionToUpdate(distribution)
                    }}
                  >
                    {t('password_protect_live')}
                  </Checkbox>
                </dd>
              </Col>
            </Row>
          </dl>

          {distribution?.auth && (
            <dl>
              <Row>
                <Col md={24} lg={7}>
                  <dt>
                    <h3>{t('http_basic_auth')}</h3>
                  </dt>
                </Col>
                <Col md={24} lg={17}>
                  <DefinitionData
                    label='user_name'
                    value={getEncodedUserPass(distribution?.auth)?.username}
                  />
                  <DefinitionData
                    label='password'
                    value={getEncodedUserPass(distribution?.auth)?.password}
                    showHide
                  />
                </Col>
              </Row>
            </dl>
          )}
        </section>
      </Spin>
    </>
  )
}

const LastPublishedCard = ({ distribution, label, t, customClass }) => {
  const lastPublish = distribution?.lastPublishes?.[0]?.endTime
  const wasPublishedThisWeek = publishedLessThanWeek(lastPublish)
  return (
    <Card className='site-detail last-published'>
      <span className={`bar ${customClass}`} />
      <label>{label}</label>
      {lastPublish ? (
        wasPublishedThisWeek ? (
          <>
            <h2>{wasPublishedThisWeek[0]}</h2>
            <h3 className='last-pub-words'>
              {wasPublishedThisWeek?.slice(1)?.join(' ')}{' '}
              <Tooltip
                title={
                  <>
                    {getSiteCardDate(lastPublish, 'iso').day}{' '}
                    {getSiteCardDate(lastPublish, 'iso').month}{' '}
                    {getSiteCardDate(lastPublish, 'iso').year}
                    <br />
                    {t('at')} {getDate(lastPublish, 'TIME_SIMPLE', 'iso')}
                  </>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </h3>
          </>
        ) : (
          <>
            <h2>
              {getSiteCardDate(lastPublish, 'iso').month}{' '}
              {getSiteCardDate(lastPublish, 'iso').day}
              <br />
              <span className='year'>
                {getSiteCardDate(lastPublish, 'iso').year}
              </span>
            </h2>
            <h3>
              {t('at')} {getDate(lastPublish, 'TIME_SIMPLE', 'iso')}
            </h3>
          </>
        )
      ) : (
        <h2>N/A</h2>
      )}
    </Card>
  )
}

const SqlCredTooltip = ({ t }) => {
  return (
    <>
      {t('sql_tooltip_one')}
      <Link href='https://www.adminer.org/' target='_blank' rel='noreferrer'>
        {t('adminer')}
      </Link>{' '}
      {t('sql_tooltip_two')}{' '}
      <Link
        href='https://support.strattic.com/en/articles/4054940-how-to-install-and-use-adminer-for-accessing-your-site-database'
        target='_blank'
        rel='noreferrer'
      >
        {t('learn_more')}
      </Link>
    </>
  )
}

export default SiteDetailsPage
