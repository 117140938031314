import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Space, Row, Col, Typography, Modal } from 'antd'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Template } from '@Templates/NewSite'
import useWindowDimensions from '@Hooks/useWindowDimensions'
import { useUserContext } from '@Context/UserContext'

import NewSiteNav from '@Components/NewSiteNav'
import DashboardFooter from '@Components/DashboardFooter'
import featuredImage from '@Images/wp-add-admin-user.png'

import Success from '@Images/icons/success-tick.svg'

import './index.less'

const { Title } = Typography

const MigrationRequestPage = () => {
  const { t } = useTranslation(['common', 'errors'])
  const userContext = useUserContext()
  const contentPadding = 51
  const topPadding = userContext.expirationBarPadding ? 50 : 0
  const topIndent = 76 + topPadding
  const { height } = useWindowDimensions()
  return (
    <Template title={t('new_site')} classNames='new-site'>
      <table>
        <tbody>
          <tr>
            <td style={{ height: height - topIndent }} className='nav-col'>
              <Title>{t('new_site')}</Title>
              <NewSiteNav selectedKey='new-site' />
            </td>
            <td className='migrate-content-col'>
              <div
                className='content'
                style={{ height: height - topIndent - contentPadding }}
              >
                <Row>
                  <Col span={11}>
                    <span className='migrate-title'>
                      {t('create_temp_wp_user')}
                    </span>
                    <div className='migrate-content'>
                      <strong>
                        {t('lets_do_this')}{' '}
                        <a
                          href='https://link.strattic.com/temp-wp-admin/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {t('steps_to_create_temp_wp_admin')}
                        </a>{' '}
                        {t('on_existing_wp_site')}
                      </strong>
                      <br />
                      <br />
                      <ol>
                        <li>{t('migrate_step_one')}</li>
                        <br />
                        <li>
                          {t('migrate_step_two_one')}
                          <br />
                          {t('migrate_step_two_two')}
                        </li>
                        <br />
                        <li>
                          {t('migrate_step_three_header')}
                          <ul>
                            <MigrationBulletPoint
                              title='username'
                              description='use'
                              inBold='migrate_email'
                              copy
                            />
                            <MigrationBulletPoint
                              title='email'
                              description='use'
                              inBold='migrate_email'
                              copy
                            />
                            <MigrationBulletPoint
                              title='password'
                              description='password_rec'
                            />
                            <MigrationBulletPoint
                              title='role'
                              description='slect'
                              inBold='administrator'
                              copy
                            />
                          </ul>
                        </li>
                        <br />
                        <li>{t('migrate_step_four')}</li>
                      </ol>
                      <br />
                      <Space>
                        <Button href='new-site' id='back-btn'>
                          {t('back')}
                        </Button>
                        <MigrationRequestModal />
                      </Space>
                      <br />
                      <span className='detailed-instructions'>
                        <InfoCircleOutlined />
                        {t('detailed_instructions')}{' '}
                        <a
                          href='https://link.strattic.com/migration-guide/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {t('here')}
                        </a>
                      </span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <img
                      className='wp-admin-user'
                      src={featuredImage}
                      alt='Illustrated screenshot of the Strattic admin interface'
                    />
                  </Col>
                </Row>
              </div>
              <DashboardFooter />
            </td>
          </tr>
        </tbody>
      </table>
    </Template>
  )
}

export default MigrationRequestPage

const MigrationBulletPoint = ({ title, description, inBold, copy }) => {
  const { t } = useTranslation(['common'])
  return (
    <li>
      <strong>{t(title)}:</strong> {t(description)} <strong>{t(inBold)}</strong>
    </li>
  )
}

const MigrationRequestModal = () => {
  const { t } = useTranslation(['common'])
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button type='primary' onClick={() => setOpen(true)}>
        {t('done')}
      </Button>
      <Modal
        open={open}
        closable={false}
        width={825}
        className='migration-confirm-modal'
        footer={[
          <Button key='back' href='/' className='back-to-dash-btn'>
            {t('back_to_dashboard')}
          </Button>
        ]}
      >
        <Success />
        <div className='modal-text'>
          <Title level={4}>{t('we_received_migration_req')}</Title>
          {t('migration_confirmation_line_one')}
          <br />
          {t('migration_confirmation_line_two')}
          <br />
          {t('migration_confirmation_line_three')}
        </div>
      </Modal>
    </>
  )
}
