import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Input, Select, Space, Typography } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { COUNTRY_CODES } from '../Constants/CountryCodes'
import { RulesetContext } from '../RulesetContext'

const countryOptions = []
for (const [code, name] of Object.entries(COUNTRY_CODES)) {
  countryOptions.push({
    label: name,
    value: code
  })
}

const { Text } = Typography
const { Item } = Form
const { Option } = Select

const RuleForm = ({
  customer,
  form,
  rule,
  onSuccess,
  onFail,
  addons
}) => {
  const { t } = useTranslation(['common'])
  const { state: ruleset, api } = useContext(RulesetContext)

  const { error: addError, doCall: addRule } = api.addRule
  const { error: updateError, doCall: updateRule } = api.updateRule

  useEffect(() => {
    if (updateError) {
      onFail(updateError)
    }
    if (addError) {
      onFail(addError)
    }
  }, [updateError, addError, onFail])

  const formDataToPostData = data => {
    if (!data) return

    const post = {
      uri: data.uri,
      description: data.description || ''
    }

    const conditions = []
    if (data.conditions) {
      for (const condition of data.conditions) {
        const postCondition = Object.assign({}, condition)
        if (condition.operation === 'in' || condition.operation === 'nin') {
          if (postCondition.operand2 instanceof String) {
            postCondition.operand2 = postCondition.operand2
              .split(',')
              .map(item => item.trim())
          }
        }
        conditions.push(postCondition)
      }
    }

    post.conditions = conditions

    if (addons?.fieldsValuesToObj) {
      addons.fieldsValuesToObj(post, data)
    }

    return post
  }

  const handleOk = data => {
    const post = formDataToPostData(data)

    if (rule) {
      post.status = data.status
      updateRule(ruleset, rule, post).then(result => {
        if (result) {
          onSuccess()
          form.resetFields()
        }
      })
    } else {
      addRule(ruleset, post).then(result => {
        if (result) {
          onSuccess()
          form.resetFields()
        }
      })
    }
  }

  return (
    <Form
      form={form}
      size='large'
      name='header-rule-frm'
      layout='vertical'
      onFinish={handleOk}
      onFinishFailed={onFail}
    >
      {rule
        ? (
          <Item
            name='status'
            labelAlign='left'
            labelCol={{ span: 6 }}
            label={<Text strong>{t('status')}</Text>}
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('required')
              }
            ]}
          >
            <Select>
              <Option value='active'>Active</Option>
              <Option value='disabled'>Disabled</Option>
            </Select>
          </Item>
        )
        : null}

      <Item
        name='uri'
        labelAlign='left'
        labelCol={{ span: 6 }}
        label={<Text strong>{t('source_uri')}</Text>}
        validateTrigger={['onBlur']}
        rules={[
          {
            required: true,
            message: t('required')
          }
        ]}
      >
        <Input className='sl-sourceURI' placeholder='/origin-url-path/' />
      </Item>

      {addons?.fields}

      <Item
        name='description'
        labelAlign='left'
        labelCol={{ span: 6 }}
        label={<Text strong>{t('description')}</Text>}
        validateTrigger={['onBlur']}
      >
        <Input className='sl-description' placeholder='My sample redirect' />
      </Item>

      <>
        <br />
        <h4>{t('conditions')}</h4>

        <Conditions form={form} t={t} />
      </>
    </Form>
  )
}

const Conditions = ({ form, t }) => {
  const typesNeedOperand1 = ['header', 'cookie', 'query']
  const singleOperandOperations = ['exists', 'nexists']

  return (
    <Form.List name='conditions'>
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space key={key} align='baseline' style={{ display: 'flex' }}>
                  <ConditionTypeSelectItem name={name} restField={restField} />

                  <Item noStyle shouldUpdate={(prevValues, currentValues) => true}>
                    {({ getFieldValue }) =>
                      typesNeedOperand1.includes(
                        getFieldValue(['conditions', name, 'type'])
                      )
                        ? (
                          <Item
                            {...restField}
                            name={[name, 'operand1']}
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <Input />
                          </Item>
                        )
                        : null}
                  </Item>

                  <OperationSelectItem restField={restField} name={name} />

                  <Item noStyle shouldUpdate={(prevValues, currentValues) => true}>
                    {({ getFieldValue }) =>
                      singleOperandOperations.includes(
                        getFieldValue(['conditions', name, 'operation'])
                      )
                      || getFieldValue(['conditions', name, 'type']) === 'geolocation'
                        ? null
                        : (
                          <Item
                            {...restField}
                            name={[name, 'operand2']}
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <Input />
                          </Item>
                        )}
                  </Item>

                  <Item noStyle shouldUpdate={(prevValues, currentValues) => true}>
                    {({ getFieldValue }) =>
                      getFieldValue(['conditions', name, 'type']) !== 'geolocation'
                        ? null
                        : (
                          <CountrySelect
                            operation={getFieldValue(['conditions', name, 'operation'])}
                            name={name}
                            restField={restField}
                          />
                        )}
                  </Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              )
            })}

            <Form.ErrorList errors={errors} />

            <Item>
              <Button
                type='link' onClick={() => {
                  add({
                    type: 'query',
                    operand1: null,
                    operation: getOperationOptions('query')[0].value,
                    operand2: null
                  })
                }}
              >
                <PlusOutlined />
                {t('add_new_condition')}
              </Button>
            </Item>
          </>
        )
      }}
    </Form.List>
  )
}

const ConditionTypeSelectItem = ({ restField, name, setFieldValue }) => {
  const internal = ({ setFieldValue }) => {
    const onChange = (newConditionType) => {
      setFieldValue(['conditions', name, 'operation'], getOperationOptions(newConditionType)[0].value)
      setFieldValue(['conditions', name, 'operand1'], null)
      setFieldValue(['conditions', name, 'operand2'], null)
    }

    return (
      <Item {...restField} name={[name, 'type']} initialValue='query'>
        <Select style={{ width: 100 }} onChange={onChange}>
          <Option value='query'>Query</Option>
          <Option value='geolocation'>Country</Option>
          {/*<Option value='header'>Header</Option>*/}
          {/*<Option value='cookie'>Cookie</Option>*/}
          {/*<Option value='host'>Host</Option>*/}
          {/*<Option value='request_method'>Request method</Option>*/}
          {/*<Option value='result_code'>Result code</Option>*/}
          {/*<Option value='ip'>IP</Option>*/}
        </Select>
      </Item>
    )
  }

  return (
    <Item noStyle shouldUpdate={(prevValues, currentValues) => true}>
      { internal }
    </Item>
  )
}

const getOperationOptions = (conditionType) => {
  const operationOptions = [
    {
      label: 'Equal to (=)',
      value: 'eq'
    },
    {
      label: 'Not equal to (!=)',
      value: 'neq'
    },
    {
      label: 'In',
      value: 'in'
    },
    {
      label: 'Not in',
      value: 'nin'
    },
    {
      label: 'Exists',
      value: 'exists'
    },
    {
      label: 'Not exists',
      value: 'nexists'
    }
  ]
  const excludeOperations = {
    query: ['in', 'nin'],
    geolocation: ['exists', 'nexists']
  }

  return operationOptions.filter(opt => !excludeOperations[conditionType].includes(opt.value))
}

const OperationSelectItem = ({ restField, name }) => {
  const internal = ({ getFieldValue, setFieldValue }) => {
    const options = getOperationOptions(getFieldValue(['conditions', name, 'type']))
    const onChange = (newValue) => {
      const operand2Value = getFieldValue(['conditions', name, 'operand2'])
      if (newValue === 'in' || newValue === 'nin') {
        if (!operand2Value) {
          setFieldValue(['conditions', name, 'operand2'], [])
        }
      } else {
        if (Array.isArray(operand2Value) && operand2Value?.length > 1) {
          setFieldValue(['conditions', name, 'operand2'], operand2Value[0])
        }
      }
    }
    return (
      <Item
        {...restField}
        name={[name, 'operation']}
      >
        <Select style={{ width: 150 }} options={options} onChange={onChange} />
      </Item>
    )
  }

  return <Item noStyle shouldUpdate={(prevValues, currentValues) => true}>
    { internal }
  </Item>
}

const CountrySelect = ({ operation, restField, name }) => {
  const [mode, setMode] = useState(null)
  useEffect(() => {
    if (operation === 'in' || operation === 'nin') {
      setMode('multiple')
    } else {
      setMode(null)
    }
    // eslint-disable-next-line
  }, [operation])

  return <Item
    {...restField}
    name={[name, 'operand2']}
    initialValue={[]}
    rules={[
      {
        required: true
      }
    ]}
  >
    <Select mode={mode} showSearch style={{ width: 150 }} options={countryOptions} />
  </Item>
}

export default RuleForm
