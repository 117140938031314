import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { Template, Col1, Col2 } from '@Templates/Login'
import SignUpForm from '@Components/SignUpForm'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import featuredImage from '@Images/strattic-illustration.png'

import './index.less'

const { Text, Title } = Typography
const useQuery = () => new URLSearchParams(useLocation().search)

const SignUp = () => {
  const { t } = useTranslation(['common'])
  const query = useQuery()
  const [email] = useState(query.get('email'))
  const [company] = useState(query.get('company'))
  const [name] = useState(query.get('name'))
  const [invited] = useState(query.get('invited') || false)
  const [buyNow] = useState(query.get('buy-now') || false)
  useEffect(() => {
    if (buyNow) {
      window.localStorage.setItem('buy-now', true)
    }
  }, [buyNow])

  return (
    <Template
      title={buyNow ? t('complete_your_acc') : t('create_an_account')}
      classNames='signup'
    >
      <Col1>
        <Title>
          {buyNow ? t('complete_your_acc') : t('create_an_account')}
        </Title>
        <Text type='secondary'>
          {!invited
            ? buyNow
              ? t('finish_acc_setup')
              : t('create_account_description')
            : t('create_account_description_invited')}
        </Text>
        <SignUpForm
          buyNow={buyNow}
          invited={invited}
          givenName={name}
          givenEmail={email}
          givenCompany={company}
        />{' '}
        <p>
          {t('have_account')}
          <Link
            onClick={() => {
              window.dataLayer.push({
                event: 'fireEvent',
                event_name: 'element_click',
                action_type: 'login_link'
              })
              AnalyticsManager.getInstance().trackClick({
                action: 'back_to_login',
                label: EVENT_CATEGORIES.SIGN_UP
              })
            }}
            className='login-next-step'
            to='/signin'
          >
            {t('login')}
          </Link>
        </p>
        <div className='spacer' />
        <footer />
      </Col1>
      <Col2 featuredImage={featuredImage} />
    </Template>
  )
}

export default SignUp
