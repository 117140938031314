import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import AnalyticsManager from '@Managers/analyticsManager'

import { FORM_STATUS } from '@Constants'

import FormMessage from '@Components/FormMessage'

import { Form, Input, Button, Spin, Typography } from 'antd'

import './index.less'
const { Text } = Typography

const { Item } = Form

const SignUpConfirmForm = () => {
  const { t } = useTranslation(['common'])
  const location = useLocation()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [email] = useState(params.email)
  const [code, setCode] = useState(params.code)
  const [formStatus, setFormStatus] = useState('')
  const [formMessage, setFormMessage] = useState('')
  const [hasCodeOnPageLoad] = useState(!!params.code)

  const handleResendCode = async () => {
    setFormStatus(FORM_STATUS.SUBMITTING)
    window.dataLayer.push({
      event: 'fireEvent',
      event_name: 'element_click',
      action_type: 'resend_confirmation_code'
    })
    AnalyticsManager.getInstance().trackClick({
      action: 'resend_code',
      label: 'sign_up_confirm'
    })
    try {
      await Auth.resendSignUp(email)
      setFormMessage(t('code_resent'))
      setFormStatus(FORM_STATUS.SUCCESS)
    } catch (error) {
      setFormMessage(error.message)
      setFormStatus(FORM_STATUS.SUCCESS)
    }
  }

  const onFinish = async () => {
    setFormMessage('')
    setFormStatus(FORM_STATUS.SUBMITTING)

    if (!code) {
      setFormMessage(t('code_please'))
      setFormStatus(FORM_STATUS.SUCCESS)
      return
    }
    window.localStorage.setItem('amplify-auto-sign-in', true)
    try {
      if (hasCodeOnPageLoad) {
        setFormMessage(t('process_then_redirect'))
      }
      await Auth.confirmSignUp(email, code)
      AnalyticsManager.getInstance().trackEvent({
        action: 'sign_up_confirm_success',
        label: 'sign_up_confirm'
      })
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'signup_confirm_success',
        action_type: 'signup_confirm_success',
        is_invited: false,
        user_email: email,
        method: 'email'
      })
      if (!hasCodeOnPageLoad) {
        setFormMessage(t('account_created_logging_in'))
      }
      window.localStorage.removeItem('buy-now')
    } catch (error) {
      AnalyticsManager.getInstance().trackEvent({
        action: 'sign_up_confirm_error',
        label: 'sign_up_confirm'
      })
      setFormMessage(error.message)
      setFormStatus(FORM_STATUS.SUCCESS)
    }
  }
  return (
    <div className='wrapper'>
      <Form
        name='login'
        className='sl-login-form'
        onFinish={onFinish}
        initialValues={{ email, code }}
      >
        {formMessage && <FormMessage message={formMessage} />}
        <Spin
          spinning={formStatus === FORM_STATUS.SUBMITTING}
          tip={t('processing')}
        >
          <Item
            name='code'
            rules={[
              {
                required: true,
                message: t('code_please')
              }
            ]}
          >
            <Input
              className='sl-login-code-input'
              placeholder={t('code')}
              aria-label={t('code')}
              onChange={e => setCode(e.target.value)}
            />
          </Item>

          <Item
            wrapperCol={{
              offset: 2,
              span: 20
            }}
          >
            <Button
              className='sl-login-submit-button'
              size='large'
              type='primary'
              htmlType='submit'
              disabled={formStatus === FORM_STATUS.SUBMITTING}
              onClick={() => {
                window.dataLayer.push({
                  event: 'fireEvent',
                  event_name: 'element_click',
                  action_type: 'signup_confirm_attempt',
                  is_invited: false,
                  user_email: email,
                  method: 'email'
                })
              }}
            >
              {t('confirm_account')}
            </Button>
          </Item>
        </Spin>
      </Form>
      <Text>
        <Button
          type='link'
          className='login-next-step'
          onClick={handleResendCode}
        >
          {t('resend_code')}
        </Button>
      </Text>
    </div>
  )
}
export default SignUpConfirmForm
