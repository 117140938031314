import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Modal, Row, Typography, Col } from 'antd'

import RuleForm from '../RuleForm'
import './index.less'

const { Text, Link, Title } = Typography

const RuleModal = ({ onSuccess, onCancel, visible, rule, addons, ruleType }) => {
  const { t } = useTranslation(['common'])
  const [loading, setLoading] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalDescription, setModalDescription] = useState('')
  const [form] = Form.useForm()

  useEffect(() => {
    if (rule) {
      const values = {
        description: rule.description,
        uri: rule.uri,
        conditions: rule.conditions,
        status: rule.status
      }

      if (addons?.objToFieldsValues) {
        addons.objToFieldsValues(rule, values)
      }
      form.setFieldsValue(values)
    }
  }, [rule, addons, form])

  const handleCancel = () => {
    onCancel()
    form.resetFields()
    setLoading(false)
  }

  const onFail = () => {
    setLoading(false)
  }

  const handleOk = () => {
    setLoading(true)
    form.submit()
  }

  const handleSuccess = data => {
    setLoading(false)
    onSuccess(data)
  }

  const ModalExplain = ({ description }) => {
    return (
      <>
        {description}
        <Link
          href={ruleType === 'headers' ? 'https://link.strattic.com/learn-rules-engine-headers' : 'https://link.strattic.com/learn-rules-engine-redirect'}
          target="_blank"
        >
          {t('learn_more')}
        </Link>
      </>
    )
  }

  useEffect(() => {
    switch (ruleType) {
      case 'headers':
        rule ? setModalTitle(t('update_header_rule')) : setModalTitle(t('add_header_rule'))
        setModalDescription(<ModalExplain description={t('headers_rule_description')} />)
        break
      case 'redirects':
        rule ? setModalTitle(t('update_redirect_rule')) : setModalTitle(t('add_redirect_rule'))
        setModalDescription(<ModalExplain description={t('redirect_rule_description')} />)
        break
      default:
        break
    }
  }, [ruleType, rule, t])

  return (
    <>
      <Modal
        open={visible}
        className='rules-engine-form'
        onCancel={handleCancel}
        onOk={handleOk}
        width={1080}
        footer={[
          <Button
            className='sl-submit'
            key='submit'
            onClick={handleOk}
            type='primary'
            loading={loading}
          >
            {rule ? t('update') : t('add')}
          </Button>,
          <Button className='sl-cancel' key='back' onClick={handleCancel} disabled={loading}>
            {t('cancel')}
          </Button>
        ]}
      >
        <Row>
          <Col span={6} className='rules-engine-form-title'>
            <Title level={4}>{modalTitle}</Title>
            <Text type='secondary'>{modalDescription}</Text>
          </Col>
          <Col><div id='divider'></div></Col>
          <Col span={17} className='rules-engine-form-content'>
            <RuleForm
              form={form}
              rule={rule}
              onSuccess={handleSuccess}
              onFail={onFail}
              addons={addons}
            />
          </Col>
        </Row >
      </Modal>
    </>
  )
}

export default RuleModal
