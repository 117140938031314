import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Typography } from 'antd'

import { useTranslation } from 'react-i18next'
import { Template } from '@Templates/FullWidth'

import NotFoundRobot from '@Images/404-robot.png'

import './index.less'

const { Title, Text } = Typography

const NotFoundPage = () => {
  const { t } = useTranslation(['common'])
  return (
    <Template classNames='404 not-found'>
      <main>
        <img src={NotFoundRobot} alt={t('not_found_robot')} />
        <Title level={1}>{t('error_404')}</Title>
        <p>
          <Text>{t('error_404_message')}</Text>
        </p>
        <Button type='primary'>
          <Link to='/'>{t('go_home')}</Link>
        </Button>
      </main>
    </Template>
  )
}

export default NotFoundPage
