import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { useUserContext } from '@Context/UserContext'
import { useMessageContext } from '@Context/MessageContext'
import { forgotPasswordSubmit, isValidPassword } from '@Lib/api/auth'
import { Form, Input, Button, Typography, Spin } from 'antd'
import PasswordStrengthTooltip from '@Components/PasswordStrengthTooltip'
import { FORM_STATUS } from '@Constants'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import './index.less'

const useQuery = () => new URLSearchParams(useLocation().search)

const { Text } = Typography
const { Item } = Form
const { Password } = Input

const ResetPasswordForm = () => {
  const { t } = useTranslation(['common'])
  const query = useQuery()
  const passwordField = useRef(null)
  const isInitialMount = useRef(true)
  const [email, setEmail] = useState(query.get('email'))
  const [code, setCode] = useState(query.get('code'))
  const [password, setPassword] = useState('')
  const [formStatus, setFormStatus] = useState('')
  const [formMessage, setFormMessage] = useState('')
  const [form] = Form.useForm()
  // const userContext = useUserContext()
  const messageContext = useMessageContext()
  const history = useHistory()

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setPassword(passwordField.current.value)
    }
  }, [])

  const onFinish = async values => {
    setFormStatus(FORM_STATUS.SUBMITTING)
    setFormMessage('')
    const validationResults = isValidPassword(password)
    if (validationResults.length === 0) {
      try {
        await forgotPasswordSubmit(email, code, password)
        AnalyticsManager.getInstance().trackEvent({
          action: 'password-reset',
          label: EVENT_CATEGORIES.RESET_PASSWORD
        })
        messageContext.setMessage({
          value: t('password_reset_success'),
          type: FORM_STATUS.SUCCESS
        })
        window.dataLayer.push({
          event: 'fireEvent',
          event_name: 'reset_password_success',
          action_type: 'reset_password_success'
        })
        history.push('/signin')
      } catch (error) {
        console.error(error)
        AnalyticsManager.getInstance().trackEvent({
          action: 'reset_password_error',
          label: EVENT_CATEGORIES.RESET_PASSWORD
        })
        setFormStatus(FORM_STATUS.DANGER)
        if (error.message) setFormMessage(error.message)
        else setFormMessage(t('error_occurred'))
      }
    } else {
      setFormStatus(FORM_STATUS.DANGER)
      const ErrorList = () => (
        <ul>
          {validationResults.map(value => (
            <li key={value}>{t(value)}</li>
          ))}
        </ul>
      )
      setFormMessage(<ErrorList />)
    }
  }

  const onFinishFailed = errorInfo => {
    console.error('Error:', errorInfo)
  }

  return (
    <Spin
      spinning={formStatus === FORM_STATUS.SUBMITTING}
      tip={t('submitting')}
    >
      <div className='wrapper'>
        <Form
          form={form}
          name='login'
          className='sl-reset-password-submit-form'
          initialValues={{
            email,
            code
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {formMessage && (
            <Text
              className='sl-form-message form-message'
              strong
              type={
                formStatus === FORM_STATUS.SUCCESS
                  ? formStatus
                  : FORM_STATUS.DANGER
              }
            >
              {formMessage}
            </Text>
          )}

          <Item
            name='email'
            rules={[
              {
                required: true,
                message: t('email_please')
              }
            ]}
          >
            <Input
              type='email'
              placeholder={t('email')}
              aria-label={t('email')}
              onChange={e => setEmail(e.target.value)}
              className='sl-reset-password-submit-email-input'
            />
          </Item>

          <Item
            name='code'
            rules={[
              {
                required: true,
                message: t('confirmation_code')
              }
            ]}
          >
            <Input
              type='text'
              placeholder={t('verification_code')}
              aria-label={t('code')}
              onChange={e => setCode(e.target.value)}
              className='sl-reset-password-code-input'
            />
          </Item>
          <PasswordStrengthTooltip password={password}>
            <Item
              name='new_password'
              rules={[
                {
                  required: true,
                  message: t('new_password_please')
                }
              ]}
            >
              <Password
                placeholder={t('new_password')}
                aria-label={t('new_password')}
                className='sl-reset-password-password-input'
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </Item>
          </PasswordStrengthTooltip>

          <Item
            wrapperCol={{
              offset: 2,
              span: 20
            }}
          >
            <Button
              size='large'
              type='primary'
              htmlType='submit'
              className='sl-reset-password-submit-button'
              loading={formStatus === FORM_STATUS.SUBMITTING}
              onClick={() =>
                window.dataLayer.push({
                  event: 'fireEvent',
                  event_name: 'element_click',
                  action_type: 'reset_password_attempt'
                })
              }
            >
              {formStatus === FORM_STATUS.SUBMITTING
                ? t('submitting')
                : t('submit')}
            </Button>
          </Item>
        </Form>
      </div>
    </Spin>
  )
}

export default ResetPasswordForm
