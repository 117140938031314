import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useAccountDetails from '@Hooks/useAccountDetails'
import useAccountPermissions from '@Hooks/useAccountPermissions'
import { useUserContext } from '@Context/UserContext'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from '@Lib/config'

import { Template } from '@Templates/Dashboard'
import PlanDetailsCard from '@Components/PlanDetailsCard'
import LoadingCard from '@Components/LoadingCard'
import RecentInvoicesCard from '@Components/RecentInvoicesCard'
import UsageStatisticsCard from '@Components/UsageStatisticsCard'
import PaymentDetailsCard from '@Components/PaymentDetailsCard'
import FreeTrialCard from '@Components/FreeTrialCard'
import UpdatePaymentDetails from '@Components/UpdatePaymentDetails'
// import InvoiceDrawer from '@Components/InvoiceDrawer'

import { ACCOUNT_PERMISSIONS } from '@Constants/permissionsConstants'

import { Col, Row } from 'antd'
import './index.less'

const stripePromise = loadStripe(config.stripePublicApi)

const AccountPage = () => {
  const { t } = useTranslation(['common'])
  const [accountDetails] = useAccountDetails()
  const userContext = useUserContext()
  const [futureSubscription, setFutureSubscription] = useState(
    accountDetails?.value?.futureSubscription
  )
  // const [invoiceVisible, setInvoiceVisible] = useState(false)
  const [
    updatePaymentDetailsVisible,
    setUpdatePaymentDetailsVisible
  ] = useState(false)
  // permissions
  const hasPermissionToUpgrade = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_UPGRADE
  )
  const hasPermissionToViewSubscriptions = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_VIEW_SUBSCRIPTIONS
  )
  const hasPermissionToViewBilling = useAccountPermissions(
    ACCOUNT_PERMISSIONS.ACCOUNT_VIEW_BILLING
  )
  const getCountriesPath = 'processor/countries'
  const [
    { data: countryList, error: countryListError },
    fetchCountryList
  ] = useStratticApi(
    {
      url: getCountriesPath,
      method: 'GET'
    },
    { manual: true }
  )
  useErrorMessage(countryListError)

  const getCardPath = `/accounts/${userContext?.accountId}/cards`
  const [
    { data: cardData, loading: cardDataLoading, error: cardDataError },
    fetchCardData
  ] = useStratticApi(
    {
      url: getCardPath,
      method: 'GET'
    },
    { manual: true }
  )
  useErrorMessage(cardDataError)

  useEffect(() => {
    if (hasPermissionToViewBilling) {
      fetchCountryList()
      fetchCardData()
    }
    // eslint-disable-next-line
  }, [hasPermissionToViewBilling])

  // for future subscriptions
  // const canCancelSubscription = useAccountPermissions(ACCOUNT_PERMISSIONS.ACCOUNT_SUBSCRIPTION_CANCEL)
  const [pageTitle, setPageTitle] = useState(t('account'))
  useEffect(() => {
    if (accountDetails?.value?.accountName) {
      setPageTitle(accountDetails?.value?.accountName)
    }
  }, [accountDetails])

  if (!hasPermissionToViewSubscriptions) return <Redirect to='/' />

  return (
    <Template title={pageTitle}>
      <div className='account-page'>
        {/* left for version two
        <InvoiceDrawer
          invoiceVisible={invoiceVisible}
          setInvoiceVisible={setInvoiceVisible}
        /> */}
        {hasPermissionToViewBilling && (
          <Elements stripe={stripePromise}>
            <UpdatePaymentDetails
              updatePaymentDetailsVisible={updatePaymentDetailsVisible}
              setUpdatePaymentDetailsVisible={setUpdatePaymentDetailsVisible}
              countryList={countryList}
              cardData={cardData}
              accountId={userContext?.accountId}
              refetchCard={fetchCardData}
            />
          </Elements>
        )}

        <Row gutter={[16, 16]}>
          <LoadingCard
            colSpan={8}
            title={t('plan_details')}
            accountDetails={accountDetails}
          />
          {accountDetails?.value?.planGroupId === 1 && (
            <FreeTrialCard
              accountDetails={accountDetails}
              hasPermissionToUpgrade={hasPermissionToUpgrade}
            />
          )}
          {accountDetails?.value?.planGroupId !== 1 &&
            accountDetails?.isLoading === false && (
              <>
                <Col md={16} sm={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <PlanDetailsCard
                        accountDetails={accountDetails}
                        canUpdateSubscriptionOrCard={hasPermissionToUpgrade}
                        futureSubscription={futureSubscription}
                        setFutureSubscription={setFutureSubscription}
                        refreshSession={userContext?.login}
                      />
                    </Col>
                  </Row>
                  {hasPermissionToViewBilling && (
                    <Row gutter={[16]}>
                      <Col span={12}>
                        <RecentInvoicesCard />
                      </Col>
                      <Col span={12}>
                        <PaymentDetailsCard
                          cardData={cardData}
                          cardDataLoading={cardDataLoading}
                          setUpdatePaymentDetailsVisible={
                            setUpdatePaymentDetailsVisible
                          }
                          canUpdateSubscriptionOrCard={hasPermissionToUpgrade}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <UsageStatisticsCard accountDetails={accountDetails} />
                </Col>
              </>
            )}
        </Row>
      </div>
    </Template>
  )
}

export default AccountPage
