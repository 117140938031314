import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Dropdown, Button } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import { useUserContext } from '@Context/UserContext'
import { useWindowDimensionsContext } from '@Context/WindowDimensionsContext'
import { NAV_STYLE } from '@Constants'

import './index.less'

const AccountMenu = () => {
  const { t } = useTranslation(['common'])
  const { width, toggleMenuVisible, menuVisible } = useWindowDimensionsContext()
  const userContext = useUserContext()
  const currentUser = userContext?.currentUser
  const currentAccountId = userContext?.accountId
  const [availableAccounts, setAvailableAccounts] = useState(
    currentUser?.accounts?.map(account => ({
      id: account.id,
      name: account.name
    }))
  )
  const [currentAccountName, setCurrentAccountName] = useState(
    availableAccounts?.find(account => account.id === currentAccountId)?.name
  )
  const [isVisible, setIsVisible] = useState(false)
  const [items, setMenuItems] = useState([])

  useEffect(() => {
    if (currentUser) {
      const formatAvailableAcc = currentUser?.accounts?.map(account => ({
        id: account.id,
        name: account.name
      }))
      setAvailableAccounts(formatAvailableAcc)
    }
    return () => setAvailableAccounts(null)
  }, [currentUser])

  useEffect(() => {
    if (availableAccounts && currentAccountId) {
      setCurrentAccountName(
        availableAccounts?.find(account => account.id === currentAccountId)
          ?.name
      )
    }
  }, [currentAccountId, availableAccounts])

  useEffect(() => {
    if (availableAccounts?.length > 1) {
      const items = []
      const filteredNoneActiveAccounts = availableAccounts?.filter(
        acc => acc?.id !== currentAccountId
      )
      for (const acc of filteredNoneActiveAccounts) {
        items.push({
          label: <Button type='link'>{acc.name}</Button>,
          key: acc.id
        })
      }
      setMenuItems(items)
    }
  }, [availableAccounts, currentAccountId])

  if (!availableAccounts?.length) return null

  if (availableAccounts.length === 1) {
    return (
      <div className='dropdown account-menu account-menu-dropdown'>
        <span>{currentAccountName} </span>
      </div>
    )
  }

  const onDropdownVisibleChange = isVisible => {
    setIsVisible(isVisible)
    menuVisible &&
      width > NAV_STYLE.MOBILE &&
      width < NAV_STYLE.MED &&
      toggleMenuVisible()
  }

  const handleMenuClick = e => {
    setIsVisible(false)
    userContext.setCurrentAccountId(parseInt(e.key, 10))
    userContext.setExpirationBarPadding(false)
    width < NAV_STYLE.MOBILE && toggleMenuVisible()
  }

  const menuProps = {
    items,
    onClick: handleMenuClick
  }

  return (
    <Dropdown
      className='account-menu'
      overlayClassName='account-menu-overlay'
      menu={menuProps}
      trigger={['click']}
      open={isVisible}
      onOpenChange={onDropdownVisibleChange}
    >
      <div className='dropdown account-menu-dropdown'>
        <span>{currentAccountName || t('account')} </span>
        {isVisible ? <UpOutlined /> : <DownOutlined />}
      </div>
    </Dropdown>
  )
}

export default AccountMenu
