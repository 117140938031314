import React, { useState, useEffect } from 'react'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'
import { Card, Col, Row, Tooltip, Typography } from 'antd'
import Progress from '@Components/Progress'
import { convertToBytes } from '@Lib/helpers'
import { DATA_SIZES } from '@Constants'
import './index.less'

const { Text } = Typography
const colSize = {
  row: {
    gutter: 3
  },
  progress: {
    xs: 12,
    sm: 6,
    md: 10,
    xl: 6,
    xxl: 9
  },
  label: {
    xs: 6,
    xl: 5
  },
  title: {
    xs: 24,
    sm: 6,
    md: 24,
    xl: 8,
    xxl: 5
  }
}

const UsageStatisticsCard = ({ accountDetails }) => {
  const { t } = useTranslation(['common'])
  // in order to use numeral with scalable naming the numbers in GB are converted to bytes

  const [bandwidthLimit, setBandwidthLimit] = useState()
  const [storageLimit, setStorageLimit] = useState()
  useEffect(() => {
    setBandwidthLimit(
      convertToBytes(accountDetails?.value?.bandwidthUsage.max, DATA_SIZES.GiB)
    )
    setStorageLimit(
      convertToBytes(accountDetails?.value?.storageUsage.max, DATA_SIZES.GiB)
    )
    // eslint-disable-next-line
  }, [accountDetails?.value])

  return (
    <Card className='usage-statistics' title={t('usage_statistics')}>
      <Text className='created-at sl-start-date' type='secondary'>
        {t('account_created')}
        <span>{accountDetails?.value?.createdAt}</span>
      </Text>
      <ul>
        <UsageDisplay
          title='sites'
          currentUsage={accountDetails?.value?.sitesUsage.current}
          limit={accountDetails?.value?.sitesUsage.max}
        />
        <UsageDisplay
          title='visits'
          currentUsage={accountDetails?.value?.visitsUsage.current}
          limit={accountDetails?.value?.visitsUsage.max}
        />
        <UsageDisplay
          title='bandwidth'
          currentUsage={accountDetails?.value?.bandwidthUsage.current}
          limit={bandwidthLimit}
        />
        <UsageDisplay
          title='storage'
          currentUsage={accountDetails?.value?.storageUsage.current}
          limit={storageLimit}
        />
        <UsageDisplay
          title='users'
          currentUsage={accountDetails?.value?.usersUsage.current}
          limit={accountDetails?.value?.usersUsage.max}
        />
      </ul>
    </Card>
  )
}

const UsageDisplay = ({ title, currentUsage, limit }) => {
  const { t } = useTranslation(['common'])
  const [maxedOut, setMaxedOut] = useState()
  useEffect(() => {
    if (currentUsage && limit) {
      setMaxedOut(currentUsage > limit)
    }
  }, [currentUsage, limit])

  if (limit === null) {
    return null
  }
  return (
    <li className={`sl-${title}-usage`}>
      <Row {...colSize.row}>
        <Col {...colSize.title}>
          {maxedOut ? (
            <Tooltip title={t('exceeded_allowance')}>
              <Text className='warning' type='secondary'>
                {t(title)}
              </Text>
            </Tooltip>
          ) : (
            <Text type='secondary'>{t(title)}</Text>
          )}
        </Col>
        <NumberWithSuffix title={title} num={currentUsage} />
        <Col {...colSize.progress}>
          <Progress current={currentUsage} max={limit} />
        </Col>
        <NumberWithSuffix title={title} num={limit} />
      </Row>
    </li>
  )
}

const NumberWithSuffix = ({ title, num }) => {
  switch (title) {
    case 'bandwidth':
    case 'storage':
      return (
        <Col {...colSize.label} className={`sl-${title}-usage-number`}>
          <Text type='secondary'>{numeral(num).format('0 ib')}</Text>
        </Col>
      )
    default:
      return (
        <Col {...colSize.label} className={`sl-${title}-usage-number`}>
          <Text type='secondary'>{numeral(num).format('0 a')}</Text>
        </Col>
      )
  }
}

export default UsageStatisticsCard
