import { useEffect } from 'react'
import { useMessageContext } from '@Context/MessageContext'
import { useTranslation } from 'react-i18next'
import { ApiError } from '@Packages/StratticApi/error'
import AnalyticsManager from '@Managers/analyticsManager'

const useErrorMessage = (getError, handler) => {
  const messageContext = useMessageContext()
  const { t } = useTranslation(['common', 'errors'])

  useEffect(() => {
    if (getError) {
      let message = getError
      if (handler) {
        message = handler(getError, t)
      }
      if (message instanceof ApiError) {
        if (getError.code) {
          message = {
            value: t(`errors:${getError.code}`),
            type: 'error'
          }
        } else {
          message = {
            value: t('errors:error'),
            type: 'error'
          }
        }
      } else if (message instanceof Error) {
        message = {
          // we can change this later to customize the error report
          value: getError.message,
          type: 'error'
        }
      } else if (message.message) {
        message = {
          value: message.message,
          type: 'error'
        }
      }
      if (message && messageContext) {
        AnalyticsManager.getInstance().trackEvent({
          action: 'API_ERROR',
          label: message.value?.replace(/ +/g, '-')
        })
        messageContext.setMessage(message)
      }
    }
    // eslint-disable-next-line
  }, [getError])
}

export default useErrorMessage

// example of custom error handling
// useErrorMessage(getError, (error, t) => {
//   if (error.statusCode === 401) {
//     return {
//       value: t(`error:${getError.code}`),
//       type: 'success'
//     }
//   }
//   return error
// })
