import React, { useState, useEffect } from 'react'

import { Auth } from 'aws-amplify'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import {
  Col,
  Typography,
  Button,
  Switch,
  Modal,
  Form,
  Input,
  Spin,
  Tooltip
} from 'antd'
import { LockOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Lock from '@Images/icons/lock.svg'
import QRCode from 'qrcode.react'
import { QuestionMarkIcon } from '@Components/Icons'

import './index.less'

const { Text, Title } = Typography
const { confirm } = Modal

const MfaSettings = ({
  t,
  visible,
  onCancel,
  onCreate,
  qrString,
  loading,
  mfaSet,
  setChecked,
  error
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFields([
      {
        name: 'mfaValidation',
        errors: [error.message]
      }
    ])
  }, [error, form])

  const handleOk = () => {
    form.validateFields().then(values => {
      onCreate(values)
    })
  }
  if (mfaSet) {
    return (
      <Modal
        className='set-mfa-modal'
        open={visible}
        onCancel={() => {
          setChecked(true)
          onCancel()
        }}
        footer={[
          <Button
            key='submit'
            onClick={() => {
              setChecked(true)
              onCancel()
              form.resetFields()
            }}
            className='reset-password sl-ok'
            loading={loading}
          >
            {t('ok')}
          </Button>
        ]}
      >
        <Lock />
        <div className='mfa-success-text'>
          <Title level={2}>{t('mfa_set')}</Title>
          <Text>{t('next_time_login')}</Text>
        </div>
      </Modal>
    )
  }
  return (
    <Modal
      open={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      title={<Text strong>{t('two_factor_auth')}</Text>}
      className='set-mfa-modal'
      onOk={handleOk}
      footer={[
        <Button
          key='back'
          onClick={() => {
            onCancel()
            form.resetFields()
          }}
          className='reset-password-cancel sl-cancel'
          disabled={loading}
        >
          {t('cancel')}
        </Button>,
        <Button
          form='myForm'
          htmlType='submit'
          key='submit'
          onClick={handleOk}
          className='reset-password sl-ok'
          loading={loading}
        >
          {t('verify')}
        </Button>
      ]}
    >
      <div>
        <Text>{t('please_scan_qr_auth')}</Text>
        <br />
        {qrString ? (
          <QRCode value={qrString} className='qrCode' />
        ) : (
          <Spin style={{ marginTop: 24 }} />
        )}

        <br />
        <Form
          id='myForm'
          form={form}
          layout='vertical'
          name='form_in_modal'
          initialValues={{
            modifier: 'public'
          }}
          style={{ paddingTop: 24 }}
        >
          <Form.Item
            name='mfaValidation'
            rules={[
              {
                required: true,
                message: t('six_digits_still')
              },
              {
                min: 6,
                max: 6,
                message: t('six_digits_still')
              }
            ]}
            validateTrigger='onBlur'
          >
            <Input
              type='number'
              placeholder={t('enter_6_code')}
              className='mfa-validate-input'
              prefix={<LockOutlined />}
              autoFocus
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export function SetMfa ({ t, mfaActive, ssoManaged }) {
  const [visible, setVisible] = useState(false)
  const [checked, setChecked] = useState(mfaActive)
  const [mfaSet, setMfaSet] = useState(false)
  const [qrString, setQrString] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const path = 'users/setSelfMfa'
  const [{ error: apiError }, updateMfaStatus] = useStratticApi(
    {
      url: path,
      method: 'POST'
    },
    { manual: true }
  )

  useErrorMessage(apiError)

  useEffect(() => {
    setChecked(mfaActive)
  }, [mfaActive])

  async function fetchData () {
    const authUser = await Auth.currentAuthenticatedUser()
    const code = await Auth.setupTOTP(authUser)
    const qrString = `otpauth://totp/Strattic:${authUser.attributes.email}?secret=${code}&issuer=strattic.com`
    setQrString(qrString)
  }
  async function removeMfa () {
    setLoading(true)
    await updateMfaStatus({ data: { mfaSetUp: false } })
    setChecked(false)
    setLoading(false)
  }
  const changeSwitch = async value => {
    if (value) {
      setVisible(true)

      fetchData()
    } else {
      confirm({
        className: 'confirm-remove-mfa',
        title: t('you_sure'),
        icon: <ExclamationCircleOutlined />,
        onOk () {
          removeMfa()
        },
        onCancel () {}
      })
    }
  }
  const onCancel = () => {
    setVisible(false)
    setMfaSet(false)
  }
  const onCreate = async values => {
    setLoading(true)
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.verifyTotpToken(currentUser, values.mfaValidation)
      await updateMfaStatus({ data: { mfaSetUp: true } })
      setLoading(false)
      setMfaSet(true)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }
  return (
    <Col offset={13}>
      <MfaSettings
        t={t}
        visible={visible}
        onCancel={onCancel}
        qrString={qrString}
        loading={loading}
        onCreate={onCreate}
        mfaSet={mfaSet}
        setChecked={setChecked}
        error={error}
      />
      <Switch
        checkedChildren={t('on')}
        unCheckedChildren={t('off')}
        checked={checked}
        defaultChecked={mfaActive}
        onChange={changeSwitch}
        loading={loading}
        disabled={ssoManaged}
      />
      {ssoManaged && (
        <Tooltip title={t('sso_2fa_warn')}>
          {' '}
          <QuestionMarkIcon />
        </Tooltip>
      )}
    </Col>
  )
}
