import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Typography } from 'antd'
import Globe from '@Images/icons/globe.svg'

import './index.less'

const { Text } = Typography

const NoActiveSites = () => {
  const { t } = useTranslation(['common'])

  return (
    <div className='no-active-wrapper'>
      <Card className='no-active-card'>
        <div className='ellipse-1'>
          <div className='ellipse-2'>
            <div className='ellipse-3'>
              <div className='icon-wrapper'>
                <Globe />
              </div>
            </div>
          </div>
        </div>

        <h2>{t('no_active_sites')}</h2>
        <main>
          <p>
            <Text type='secondary'>{t('no_active_sites_content')}</Text>
          </p>
        </main>
      </Card>
    </div>
  )
}

export default NoActiveSites
