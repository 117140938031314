import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Menu, Typography } from 'antd'

import { useWindowDimensionsContext } from '@Context/WindowDimensionsContext'

import {
  DashboardIcon,
  AccountIcon,
  TeamIcon,
  SupportIcon,
  ExternalLinkIcon
} from '@Components/Icons'
import AnalyticsManager from '@Managers/analyticsManager'

const { Text } = Typography

const FullScreenMenu = ({
  currentAccountId,
  hasPermissionToViewAccount,
  mobile
}) => {
  const { t } = useTranslation(['common'])
  const location = useLocation()
  const history = useHistory()
  const { toggleMenuVisible } = useWindowDimensionsContext()

  const getItem = (label, onClick, icon, key, className, title = false) => {
    return {
      label,
      onClick,
      icon,
      key,
      className,
      title
    }
  }

  const items = currentAccountId
    ? [
        getItem(
          <Link to='/'>
            <Text>{t('dashboard')}</Text>
          </Link>,
          () => {
            history.push('/')
            toggleMenuVisible()
            AnalyticsManager.getInstance().trackClick('menu_dashboard')
          },
          <DashboardIcon />,
          '/',
          'dashboard sl-dashboard'
        ),
        hasPermissionToViewAccount &&
          getItem(
            <Link to='/account'>
              <Text>{t('account')}</Text>
            </Link>,
            () => {
              toggleMenuVisible()
              AnalyticsManager.getInstance().trackClick('menu_account')
            },
            <AccountIcon />,
            '/account',
            'account sl-account'
          ),
        getItem(
          <Link to='/accounts/team'>
            <Text>{t('team')}</Text>
          </Link>,
          () => {
            toggleMenuVisible()
            AnalyticsManager.getInstance().trackClick('menu_team')
          },
          <TeamIcon />,
          '/accounts/team',
          'team sl-team'
        )
      ]
    : null
  return (
    <>
      <Menu
        mode='vertical'
        className={mobile ? 'main-menu-mobile' : 'main-menu'}
        defaultSelectedKeys={[location.pathname]}
        items={items}
      />
      <ul
        className={`ant-menu ant-menu-root ant-menu-vertical ant-menu-light ${
          mobile ? 'main-menu-mobile' : 'main-menu'
        } ant-menu-inline-collapsed`}
      >
        <li className='support ant-menu-item sl-support'>
          <a
            href='https://support.strattic.com/'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() =>
              AnalyticsManager.getInstance().trackClick('menu_support')
            }
          >
            <span id={mobile ? 'external-sign-mobile' : 'external-sign'}>
              <ExternalLinkIcon />
            </span>
            <SupportIcon />
            <Text>{t('support')}</Text>
          </a>
        </li>
      </ul>
    </>
  )
}

export default FullScreenMenu
