import React, { useState, useEffect } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Input, Button, Spin } from 'antd'

import { Auth } from 'aws-amplify'
import qs from 'qs'

import useAxios from 'axios-hooks'
import { useUserContext } from '@Context/UserContext'
import { login } from '@Lib/api/auth'
import FormMessage from '@Components/FormMessage'
import { FORM_STATUS } from '@Constants'
import AnalyticsManager from '@Managers/analyticsManager'
import { CloseOutlined } from '@ant-design/icons'
import { isAuthManagedExternally } from '@Lib/helpers'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import GoogleButton from '@Components/GoogleButton'

import './index.less'

const { Item } = Form
const { Password } = Input

const LoginForm = ({ redirectTo, search, setLoginState, loginState }) => {
  const { t } = useTranslation(['common'])
  const location = useLocation()
  const history = useHistory()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [email, setEmail] = useState(params?.email)
  const [password, setPassword] = useState('')
  const [formStatus, setFormStatus] = useState('')
  const [formMessage, setFormMessage] = useState('')
  const [step, setStep] = useState('preLogin')
  const [activeCognitoUser, setCognitoUser] = useState()
  const userContext = useUserContext()
  const [
    { data: preLoginResponse, error: preLoginError },
    preLoginCall
  ] = useAxios({}, { manual: true })

  const preLogin = async preLoginParams => {
    setFormStatus(FORM_STATUS.SUBMITTING)
    try {
      await preLoginCall({
        url: `${process.env.API_ENDPOINT}/auth/prelogin`,
        method: 'POST',
        data: preLoginParams
      })
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'pre_login_success',
        action_type: 'pre_login_success',
        user_email: email,
        method: 'email'
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (preLoginError || !preLoginResponse) return
    if (preLoginResponse?.result?.sso) {
      Auth.federatedSignIn({ provider: preLoginResponse?.result?.providerId })
      window.localStorage.setItem('okta', true)
    } else {
      setStep('loginWithPassword')
      setFormStatus('')
    }
  }, [preLoginResponse, preLoginError])

  const finishLogin = async () => {
    const userData = await userContext.login(true)
    AnalyticsManager.getInstance().login(userData)
  }

  const onFinish = async values => {
    switch (step) {
      case 'preLogin': {
        const preLoginParams = {
          email
        }
        AnalyticsManager.getInstance().trackEvent({
          action: 'preLogin',
          label: EVENT_CATEGORIES.LOG_IN
        })
        preLogin(preLoginParams)
        break
      }
      case 'loginWithPassword': {
        setFormMessage('')
        setFormStatus(FORM_STATUS.SUBMITTING)
        try {
          AnalyticsManager.getInstance().trackEvent({
            action: 'loginWithPassword',
            label: EVENT_CATEGORIES.LOG_IN
          })
          const cognitoUser = await login(email, password)
          setCognitoUser(cognitoUser)
          if (cognitoUser?.challengeName === 'SOFTWARE_TOKEN_MFA') {
            setLoginState(false)
            setFormStatus(FORM_STATUS.SUCCESS)
            setStep('verifyMfa')
          } else {
            finishLogin()
          }
          isAuthManagedExternally(cognitoUser)
        } catch (error) {
          if (error?.message === 'User is not confirmed.') {
            try {
              await Auth.resendSignUp(email)
              history.push(`/confirm/?email=${encodeURIComponent(email)}`)
            } catch (error) {
              setFormMessage(error.message || t(`errors:${error.code}`))
            }
            return
          }
          setFormStatus(FORM_STATUS.DANGER)
          setFormMessage(error.message || t(`errors:${error.code}`))
          AnalyticsManager.getInstance().trackEvent({
            action: error.message || Object.keys(error).join(', '),
            label: 'sign_in_error'
          })
        }
        break
      }
      case 'verifyMfa': {
        setFormMessage('')
        setFormStatus(FORM_STATUS.SUBMITTING)
        try {
          await Auth.confirmSignIn(
            activeCognitoUser,
            values?.mfaValidation,
            'SOFTWARE_TOKEN_MFA'
          )
          finishLogin()
        } catch (error) {
          setFormStatus(FORM_STATUS.DANGER)
          setFormMessage(error.message || t(`errors:${error.code}`))
          AnalyticsManager.getInstance().trackEvent({
            action: error.message || Object.keys(error).join(', '),
            label: 'sign_in_error'
          })
        }
        break
      }
      default: {
        setFormStatus(FORM_STATUS.DANGER)
        setFormMessage(t('errors:error'))
      }
    }
  }

  const backToPreLogin = () => {
    setStep('preLogin')
    setLoginState(true)
    setFormMessage('')
  }

  return (
    <Spin
      spinning={formStatus === FORM_STATUS.SUBMITTING}
      tip={t('logging_in')}
    >
      <div className='wrapper'>
        <Form
          name='login'
          className='sl-login-form'
          initialValues={{
            remember: true,
            email
          }}
          onFinish={onFinish}
        >
          {formMessage && <FormMessage message={formMessage} />}
          {loginState ? (
            <Item>
              <GoogleButton />
            </Item>
          ) : null}
          <Item
            name='email'
            rules={[
              {
                required: true,
                message: t('email_please')
              }
            ]}
          >
            {step === 'preLogin' ? (
              <Input
                type='email'
                autoComplete='username'
                className='sl-login-email-input'
                placeholder={t('work_email')}
                aria-label={t('work_email')}
                onChange={e => setEmail(e.target.value)}
                disabled={step === 'loginWithPassword'}
              />
            ) : (
              <div id='email-disabled'>
                <div>{email}</div>
                <div>
                  <CloseOutlined
                    onClick={backToPreLogin}
                    style={{ paddingTop: 4, color: '#000000d9' }}
                  />
                </div>
              </div>
            )}
          </Item>
          {step === 'loginWithPassword' && (
            <Item
              name='password'
              rules={[
                {
                  required: true,
                  message: t('password_please')
                }
              ]}
            >
              <Password
                className='sl-login-password-input'
                placeholder={t('password')}
                autoComplete='current-password'
                aria-label={t('password')}
                onChange={e => setPassword(e.target.value)}
              />
            </Item>
          )}
          {step === 'verifyMfa' && (
            <Item
              name='mfaValidation'
              rules={[
                {
                  required: true,
                  message: t('six_digits_still')
                },
                {
                  min: 6,
                  max: 6,
                  message: t('six_digits_still')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input
                type='number'
                placeholder={t('enter_your_6_code')}
                className='mfa-validate-input'
              />
            </Item>
          )}
          <Item
            wrapperCol={{
              offset: 2,
              span: 20
            }}
          >
            <SubmitButton
              formStatus={formStatus}
              step={step}
              t={t}
              email={email}
            />
          </Item>
        </Form>
      </div>
    </Spin>
  )
}
export default LoginForm

function SubmitButton ({ formStatus, step, t, email }) {
  const [buttonText, setButtonText] = useState()
  useEffect(() => {
    switch (step) {
      case 'loginWithPassword':
        setButtonText(t('login'))
        break
      case 'preLogin':
        setButtonText(t('continue'))
        break
      case 'verifyMfa':
        setButtonText(t('verify_code'))
        break
      default:
        setButtonText(t('continue'))
    }
  }, [step, t])

  return (
    <Button
      className='sl-login-submit-button'
      size='large'
      type='primary'
      htmlType='submit'
      disabled={formStatus === FORM_STATUS.SUBMITTING}
      onClick={() => {
        if (step === 'preLogin') {
          window.dataLayer.push({
            event: 'fireEvent',
            event_name: 'element_click',
            action_type: 'pre_login_attempt',
            method: 'email'
          })
        }
        if (step === 'loginWithPassword') {
          window.dataLayer.push({
            event: 'fireEvent',
            event_name: 'element_click',
            action_type: 'login_attempt',
            user_email: email,
            method: 'email'
          })
        }
      }}
    >
      {buttonText}
    </Button>
  )
}
