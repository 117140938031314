import { parseDomain } from 'parse-domain'
import { DIST_TYPES } from '@Constants'

//  checks if a siteName is a valid url if so it strips the prefix and or suffix
export const returnOnlySiteName = siteName => {
  try {
    const url = new URL(siteName)
    const { domain } = parseDomain(url.hostname)
    if (domain) {
      return domain
    }
    return siteName
  } catch (error) {
    if (parseDomain(siteName)) {
      const { domain } = parseDomain(siteName)
      if (domain) {
        return domain
      }
    }
    return siteName
  }
}

export const isRootDomain = domainName => {
  const parsedDomain = parseDomain(domainName)
  if (parsedDomain?.subDomains?.length > 0) return false
  return true
}

export const formatArrLiveDistDomainName = sites => {
  const formattedSitesArr = []
  sites.forEach(site => {
    const liveDistribution = site?.distributions?.filter(
      site => site.type === DIST_TYPES.LIVE
    )[0]
    const newSiteObj = {
      id: site?.id,
      displayName: site?.displayName,
      domainName:
        liveDistribution?.publishDomainOverride || liveDistribution?.domainName,
      status: site?.status
    }
    formattedSitesArr.push(newSiteObj)
  })
  return formattedSitesArr
}
