import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '@Context/UserContext'
import { useIntercom } from 'react-use-intercom'
import { logout } from '@Lib/api/auth'
import { Button } from 'antd'

const LogoutButton = () => {
  const { t } = useTranslation(['common'])
  const userContext = useUserContext()
  const { shutdown } = useIntercom()

  const onLogout = async () => {
    shutdown()
    userContext.handleLogout()
    window.Kustomer.logout()
    await logout()
  }

  return (
    <Button
      className='sl-logout-button logout-button'
      onClick={onLogout}
      type='link'
    >
      {t('logout')}
    </Button>
  )
}

export default LogoutButton
