import React from 'react'

const OneTrustScripts = () => {
  const dataDomainScript = `1c07bc1c-5cb9-4c09-870e-d1d89da319ba${
    process.env.STAGE_INFRA !== 'prod' ? '-test' : ''
  }`
  const srcScript = () => {
    const script = document.createElement('script')

    script.setAttribute(
      'src',
      'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    )
    script.setAttribute('charset', 'UTF-8')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('data-domain-script', dataDomainScript)
    return script
  }

  const scriptFuncs = () => {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.innerHTML = scriptInnerHtml
    return script
  }

  const createMetatag = (name, content) => {
    const metaTag = document.createElement('meta')
    metaTag.name = name
    metaTag.content = content
    return metaTag
  }

  const head = document.querySelector('html > head')
  head.insertBefore(srcScript(), document.head.childNodes[0])
  head.insertBefore(scriptFuncs(), document.head.childNodes[1])
  head.insertBefore(
    createMetatag('referrer', 'no-referrer-when-downgrade'),
    document.head.childNodes[2]
  )
  process.env.STAGE_INFRA !== 'prod' &&
    head.insertBefore(
      createMetatag('robots', 'noindex'),
      document.head.childNodes[2]
    )
  return (
    <script
      src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      type='text/javascript'
      charSet='UTF-8'
      data-domain-script={dataDomainScript}
      async
    />
  )
}

const scriptInnerHtml =
  'function OptanonWrapper(){}function insureCookieBanner(n,o){window.setTimeout(function(){window[n]?o(window[n]):insureCookieBanner(n,o)},800)}insureCookieBanner("OneTrust",function(n){OneTrust.LoadBanner()});'
export default OneTrustScripts
