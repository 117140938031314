import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  Pagination,
  Table,
  Popover,
  Checkbox,
  Tooltip,
  Typography
} from 'antd'

import { SORT_ORDER, PUBLISH_TYPES, DIST_TYPES, DATE_TIMES } from '@Constants'
import { PUBLISH_STATUSES, INITIATOR_TIMING } from '@Constants/siteConstants'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useCurrentSite from '@Hooks/useCurrentSite'

import AnalyticsManager from '@Managers/analyticsManager'
import { convertOrderByToAnt } from '@Lib/helpers'

import { getDate, formatDuration } from '@Lib/dates'

import { upperFirst } from '@Lib/helpers/text'

import { Template } from '@Templates/Site'

import TableIcon from '@Images/icons/table.png'
import TableIconRetina from '@Images/icons/table-retina.png'

import './index.less'

import {
  FilterFilled,
  ThunderboltOutlined,
  GroupOutlined,
  MinusSquareOutlined,
  ScheduleTwoTone,
  RocketTwoTone
} from '@ant-design/icons'
import { PUBLISH_INITIATOR_SOURCES } from '../../constants/siteConstants'

const { Text } = Typography
const useQuery = () => new URLSearchParams(useLocation().search)

const defaultSortBy = 'startTime'
const defaultOrderBy = SORT_ORDER.DESC

const SitePublishHistoryPage = () => {
  const { t } = useTranslation(['common'])
  const { siteId } = useParams()

  const query = useQuery()

  const [DEFAULT_PAGE_SIZE] = useState(10)
  const [initiallyLoading, setInitiallyLoading] = useState(true)

  const [params, setParams] = useState({
    publishType: query.get('type') || PUBLISH_TYPES.ALL,
    distributionType: query.get('siteType') || DIST_TYPES.ALL,
    page: parseInt(query.get('page')) || 1,
    byColumn: query.get('sortBy') || defaultSortBy,
    order: query.get('orderBy') || defaultOrderBy,
    records: parseInt(query.get('records')) || DEFAULT_PAGE_SIZE
  })

  const [isEmptyFilter, setIsEmptyFilter] = useState()
  const timer = useRef(null)
  const [path] = useState(`sites/${siteId}/publishes`)
  const [
    { data: getData, loading: getIsLoading, error: getError },
    getPublishData
  ] = useStratticApi({ url: path, params })

  useErrorMessage(getError)

  useEffect(() => {
    getPublishData()
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [])

  const [publishTypeOptions] = useState([
    { label: t(PUBLISH_TYPES.FULL), value: PUBLISH_TYPES.FULL },
    { label: t(PUBLISH_TYPES.QUICK), value: PUBLISH_TYPES.QUICK },
    { label: t(PUBLISH_TYPES.SELECTIVE), value: PUBLISH_TYPES.SELECTIVE }
  ])

  const [siteTypeOptions] = useState([
    { label: t('live_site'), value: DIST_TYPES.LIVE },
    { label: t('preview_site'), value: DIST_TYPES.PREVIEW }
  ])
  const [currentSite] = useCurrentSite()
  const [publishHistory, setPublishHistory] = useState({
    isLoading: true
  })

  const sortOrderByQuery = val => {
    return query.get('sortBy') === val
      ? convertOrderByToAnt(query.get('orderBy'))
      : ''
  }
  const formatInitiatorDisplayName = record => {
    switch (record?.initiatorDisplayName) {
      case PUBLISH_INITIATOR_SOURCES.ADMIN:
        return record?.initiatorDisplayName
      case PUBLISH_INITIATOR_SOURCES.CUSTOM_DOMAIN:
        return t('custom_domain')
      case PUBLISH_INITIATOR_SOURCES.CUSTOMER_DASH:
        return t('customer_dash')
      case PUBLISH_INITIATOR_SOURCES.API:
        return 'API'
      case PUBLISH_INITIATOR_SOURCES.WORDPRESS:
        return record?.initiatorEmail
      default:
        return returnEmailOrEllipsis(record)
    }
  }

  const returnEmailOrEllipsis = record => {
    if (record?.initiatorNotes?.length) {
      return (
        <Tooltip title={record.initiatorDisplayName}>
          <Text style={{ width: 100 }} ellipsis>
            {record.initiatorDisplayName}
          </Text>
        </Tooltip>
      )
    }
    return record.initiatorDisplayName
  }
  const cols = [
    {
      title: t('site_type'),
      dataIndex: 'distributionType',
      key: 'distributionType',
      defaultSortOrder: sortOrderByQuery('distributionType'),
      width: '12%',
      sorter: true,
      render: text => {
        return t(text + '_site')
      }
    },
    {
      title: t('date'),
      dataIndex: 'startTime',
      key: 'startTime',
      defaultSortOrder: sortOrderByQuery('startTime'),
      sorter: true,
      width: '15%',
      render: text => (
        <span className='light-text'>
          {getDate(text, DATE_TIMES.MED, 'iso')}
        </span>
      )
    },
    {
      title: t('initiator_display_name'),
      dataIndex: 'initiatorDisplayName',
      key: 'initiatorDisplayName',
      defaultSortOrder: sortOrderByQuery('initiatorDisplayName'),
      sorter: true,
      width: '15%',
      render: (_, record) => formatInitiatorDisplayName(record)
    },
    {
      title: t('timing'),
      align: 'center',
      dataIndex: 'initiatorTiming',
      width: '5%',
      render: record => formatTiming(record)
    },
    {
      title: t('publish_type'),
      dataIndex: 'publishType',
      key: 'publishType',
      defaultSortOrder: sortOrderByQuery('publishType'),
      sorter: true,
      width: '15%',
      render: text => {
        let icon
        switch (text) {
          case PUBLISH_TYPES.FULL:
            icon = <GroupOutlined />
            break
          case PUBLISH_TYPES.QUICK:
            icon = <ThunderboltOutlined />
            break
          case PUBLISH_TYPES.SELECTIVE:
            icon = <MinusSquareOutlined />
            break
          default:
            icon = ''
            break
        }
        return (
          <span className={text}>
            {icon} &nbsp; {upperFirst(text)}
          </span>
        )
      }
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder: sortOrderByQuery('status'),
      sorter: true,
      width: '10%',
      className: 'publish-table-status',
      render: text => {
        return upperFirst(text)
      }
    },
    {
      title: t('publishing_time'),
      dataIndex: 'publishingTime',
      key: 'publishingTime',
      width: '10%',
      align: 'center',
      defaultSortOrder: sortOrderByQuery('publishingTime'),
      sorter: true,
      render: text => {
        if (text === 2147483647) {
          return 'Running'
        }
        if (text === -1) {
          return 'N/A'
        }
        return formatDuration(text)
      }
    },
    {
      title: t('num_urls'),
      dataIndex: 'totalUrls',
      key: 'totalUrls',
      width: '13%',
      align: 'center',
      defaultSortOrder: sortOrderByQuery('totalUrls'),
      sorter: true
    }
  ]

  useEffect(() => {
    const isEmpty =
      (params.distributionType === 'all' ||
        params.distributionType.length === 0) &&
      (params.publishType === 'all' || params.publishType.length === 0)
    setIsEmptyFilter(isEmpty)
  }, [params])

  useEffect(() => {
    if (!getData?.result && !getError) return
    setPublishHistory({
      isLoading: getIsLoading,
      value: getData?.result,
      paginationTotal: getData?.count,
      error: getError
    })
    setInitiallyLoading(false)
    const newPublish = getData?.result
    let duration = 30 * 1000
    if (newPublish?.find(publish => PUBLISH_STATUSES.BUSY === publish.status)) {
      duration = 5 * 1000
    } else {
      duration = 30 * 1000
    }
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      getPublishData()
    }, duration)
    // eslint-disable-next-line
  }, [getData?.result])

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <Button>{t('prev')}</Button>
    }
    if (type === 'next') {
      return <Button>{t('next')}</Button>
    }
    return originalElement
  }

  const fetch = newParams => {
    // setInitiallyLoading(true)
    setPublishHistory({ isLoading: true, value: null, error: null })

    const paramsObj = {
      ...params,
      ...newParams
    }

    setParams(paramsObj)

    window.history.pushState(null, t('publish_history'), makeUrl(paramsObj))
  }

  const makeUrl = urlParams => {
    let url =
      `/site/${siteId}/publishing-history?` +
      `siteType=${urlParams.distributionType}&page=${urlParams.page}&type=${urlParams.publishType}&records=${urlParams.records}`
    if (urlParams.byColumn) {
      url += `&sortBy=${urlParams.byColumn}`
    }
    if (urlParams.order) {
      url += `&orderBy=${urlParams.order}`
    }

    return url
  }

  const onTableChange = (pagination, filters, sorter) => {
    if (!sorter.order) {
      fetch({
        byColumn: null,
        order: null
      })
      return
    }

    let order

    switch (sorter.order) {
      case convertOrderByToAnt(SORT_ORDER.ASC):
        order = SORT_ORDER.ASC
        break
      case convertOrderByToAnt(SORT_ORDER.DESC):
        order = SORT_ORDER.DESC
        break
      case '':
      default:
        order = ''
        break
    }
    fetch({
      byColumn: sorter.field,
      order
    })
  }

  const onChangePublishType = checkedValues => {
    AnalyticsManager.getInstance().trackClick('publish_filter_publish_type')
    fetch({
      publishType: checkedValues,
      page: 1
    })
  }

  const onChangeSiteType = checkedValues => {
    AnalyticsManager.getInstance().trackClick('publish_filter_site_type')
    fetch({
      distributionType: checkedValues,
      page: 1
    })
  }

  const onPaginationChange = (page, pageSize) => {
    fetch({
      page,
      records: pageSize
    })
  }

  return (
    <Template
      siteStatus={currentSite?.value?.status}
      currentSite={currentSite}
      className='publishing-history'
      isLoading={initiallyLoading}
      title={t('publishing_history')}
      titleButton={
        <div className='primary-buttons'>
          <Popover
            placement='bottomRight'
            content={
              <PopoverContent
                t={t}
                selectedPublishTypes={query.get('type') || PUBLISH_TYPES.ALL}
                publishTypeOptions={publishTypeOptions}
                onChangePublishType={onChangePublishType}
                siteTypeOptions={siteTypeOptions}
                selectedSiteTypes={query.get('siteType') || DIST_TYPES.ALL}
                onChangeSiteType={onChangeSiteType}
              />
            }
            trigger='click'
            overlayClassName='filter-popover-overlay'
          >
            {(publishHistory?.value?.length > 0 || !isEmptyFilter) && (
              <Button className='filter-btn'>
                <FilterFilled /> Filter
              </Button>
            )}
          </Popover>
        </div>
      }
    >
      {publishHistory?.value?.length === 0 ? (
        <Card className='empty'>
          <img src={TableIcon} srcSet={TableIconRetina + ' 2x'} alt='' />
          <h2>{t('no_publishes')}</h2>
        </Card>
      ) : (
        <div className='publishing-history'>
          <Table
            rowKey={record => record.id}
            loading={publishHistory?.isLoading}
            pagination={false}
            dataSource={publishHistory?.value}
            columns={cols}
            onChange={onTableChange}
            locale={{
              emptyText: publishHistory?.isLoading ? t('loading') : t('no_data')
            }}
          />
          {!publishHistory?.isLoading && publishHistory?.paginationTotal > 10 && (
            <div className='pagination-wrapper'>
              <Pagination
                onChange={onPaginationChange}
                defaultCurrent={parseInt(query.get('page')) || 1}
                total={publishHistory?.paginationTotal}
                itemRender={itemRender}
                defaultPageSize={params.records}
                current={params.page}
                showSizeChanger
              />
            </div>
          )}
        </div>
      )}
    </Template>
  )
}

const PopoverContent = ({
  t,
  publishTypeOptions,
  selectedPublishTypes,
  onChangePublishType,
  siteTypeOptions,
  selectedSiteTypes,
  onChangeSiteType
}) => (
  <div className='publishing-history-filter'>
    <h3>{t('pub_type')}</h3>
    <Checkbox.Group
      options={publishTypeOptions}
      onChange={onChangePublishType}
      defaultValue={selectedPublishTypes}
    />

    <h3 style={{ marginTop: 20 }}>{t('site_type')}</h3>
    <Checkbox.Group
      options={siteTypeOptions}
      onChange={onChangeSiteType}
      defaultValue={selectedSiteTypes}
    />
  </div>
)

const formatTiming = record => {
  switch (record) {
    case INITIATOR_TIMING.IMMEDIATE:
      return (
        <Tooltip title={upperFirst(INITIATOR_TIMING.IMMEDIATE)}>
          <RocketTwoTone twoToneColor='#5D3FD3' />
        </Tooltip>
      )
    case INITIATOR_TIMING.SCHEDULED:
      return (
        <Tooltip title={upperFirst(INITIATOR_TIMING.SCHEDULED)}>
          <ScheduleTwoTone />
        </Tooltip>
      )
    default:
      return null
  }
}

export default SitePublishHistoryPage
