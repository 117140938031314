import React from 'react'
import { Button, Typography, Divider } from 'antd'

import { useTranslation } from 'react-i18next'

import { GoogleIcon } from '../Icons'
import { Auth } from 'aws-amplify'

import './index.less'

const { Text } = Typography

const GoogleButton = ({ signup, invited }) => {
  const { t } = useTranslation(['common'])
  const clickGoogleEvent = () => {
    if (signup) {
      window.localStorage.setItem('google-signup', true)
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'element_click',
        action_type: 'signup_attempt',
        is_invited: !!invited,
        method: 'google'
      })
    } else {
      window.dataLayer.push({
        event: 'fireEvent',
        event_name: 'element_click',
        action_type: 'login_attempt',
        method: 'google'
      })
    }
  }
  return (
    <>
      <Button
        icon={<GoogleIcon />}
        size='large'
        id='google-button'
        onClick={() => {
          clickGoogleEvent()
          Auth.federatedSignIn({ provider: 'Google' })
        }}
      >
        {t('continue_with_google')}
      </Button>
      <Divider
        style={{ marginBottom: -2, fontSize: 14, color: 'rgba(0, 0, 0, 0.45)' }}
      >
        <Text type='secondary'>{t('or')}</Text>
      </Divider>
    </>
  )
}

export default GoogleButton
