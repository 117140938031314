import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useStratticApi from '@Packages/StratticApi/useStratticApi'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import {
  Form,
  Input,
  Select,
  Typography,
  Button,
  Checkbox,
  Skeleton,
  Row,
  Col
} from 'antd'
import { FORM_STATUS, BILLING_CYCLE } from '@Constants'
import BillingDetails from './BillingDetails'
import PaymentLoadingModal from './PaymentLoadingModal'
import { getFormattedCardBillingDetail } from '@Lib/helpers/subscriptionHelpers'

import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'
import {
  checkoutPageLoadEvent,
  paymentAttemptEvent,
  purchaseCompletedEvent
} from '@Lib/helpers/gtmEventHelpers'

import './index.less'

const { Text } = Typography
const { Item } = Form
const { Option } = Select

const paymentMethod = (values, card, cardData) => {
  if (card) {
    return {
      payment_method: {
        card,
        billing_details: {
          address: {
            city: values.city,
            country: values.country,
            line1: values.line1,
            line2: values.line2,
            postal_code: values.postal_code,
            state: values.state
          },
          email: values.email,
          name: values.name
        }
      }
      // commented out for now, will introduce feature in future
      // receipt_email: values.email
    }
  }
  return {
    payment_method: cardData?.id
    // receipt_email: cards?.billing_details?.email
  }
}

const MAX_VALIDATION_ATTEMPTS = 10
const VALIDATION_DELAY = 2000

const PaymentInfoForm = ({
  setFormStatus,
  formStatus,
  accountId,
  countryList,
  cardData,
  changeCard,
  isFutureSubscription,
  isAnnual,
  secret,
  planId,
  sessionCall,
  spinning,
  setChangeCard,
  userId,
  accountDetails,
  plan
}) => {
  const { t } = useTranslation(['common'])
  const [formMessage, setFormMessage] = useState('')
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState('')
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [int, setInt] = useState(1)
  const [validationBody, setValidationBody] = useState(null)
  const [billingCycle] = useState(
    isAnnual ? BILLING_CYCLE.ANNUAL : BILLING_CYCLE.MONTHLY
  )
  const [activeCard, setActiveCard] = useState(
    getFormattedCardBillingDetail(cardData?.[0])
  )
  const stripe = useStripe()
  const elements = useElements()
  const [upgradeSubscriptionPath] = useState(
    `/accounts/${accountId}/subscriptions/upgrade`
  )
  const [
    { data: upgradeSubscriptionData, error: upgradeSubscriptionError },
    upgradeSubscription
  ] = useStratticApi({}, { manual: true })

  const [validateSubscriptionPath] = useState(
    `/accounts/${accountId}/subscriptions/validate-upgrade-completed`
  )
  const [
    { data: validateSubscriptionData, error: validateSubscriptionError },
    validateSubscription
  ] = useStratticApi({}, { manual: true })

  useEffect(() => {
    setActiveCard(getFormattedCardBillingDetail(cardData?.[0]))
  }, [cardData])

  useEffect(() => {
    if (upgradeSubscriptionError) {
      setError(true)
      setProcessing(false)
    }
    if (validateSubscriptionError) {
      if (int < MAX_VALIDATION_ATTEMPTS) {
        setInt(int + 1)
        setTimeout(() => attemptValidation(validationBody), VALIDATION_DELAY)
      } else {
        // last attempt
        setError(true)
        setProcessing(false)
        AnalyticsManager.getInstance().trackEvent({
          action: 'upgrade_sub_error',
          label: EVENT_CATEGORIES.CHECKOUT
        })
      }
    }
    if (
      !validateSubscriptionError &&
      (upgradeSubscriptionData || validateSubscriptionData)
    ) {
      onSuccess()
    }
    // eslint-disable-next-line
  }, [
    upgradeSubscriptionError,
    validateSubscriptionError,
    upgradeSubscriptionData,
    validateSubscriptionData
  ])
  const onSuccess = async () => {
    await sessionCall()
    purchaseCompletedEvent(userId, accountDetails, plan, billingCycle)
    AnalyticsManager.getInstance().trackEvent({
      action: 'upgrade_sub_success',
      label: EVENT_CATEGORIES.CHECKOUT
    })
    setProcessing(false)
    setFormStatus(FORM_STATUS.SUCCESS)
  }
  const onFinish = async values => {
    const cardElement = elements.getElement('card')
    const pm = paymentMethod(values, cardElement, cardData)
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    AnalyticsManager.getInstance().trackClick({
      action: 'submit_payment',
      label: EVENT_CATEGORIES.CHECKOUT
    })
    setFormMessage('')
    setFormStatus(FORM_STATUS.SUBMITTING)
    delete values.agreement
    if (!cardData.length && !isFutureSubscription) {
      confirmCardPayment(values, pm)
    } else {
      if (changeCard) {
        changeCardPurchase(pm)
      } else {
        setFormStatus(FORM_STATUS.SUCCESS)
        simplePurchase(values)
      }
    }
  }

  const simplePurchase = async () => {
    const body = {
      billingCycle,
      planId
    }
    setShowLoadingModal(true)
    setProcessing(true)
    try {
      await upgradeSubscription({
        url: upgradeSubscriptionPath,
        method: 'POST',
        data: body
      })
      AnalyticsManager.getInstance().trackEvent({
        action: 'upgrade_sub_success',
        label: EVENT_CATEGORIES.CHECKOUT
      })
    } catch (error) {
      AnalyticsManager.getInstance().trackEvent({
        action: 'upgrade_sub_error',
        label: EVENT_CATEGORIES.CHECKOUT
      })
      setError(error)
      setProcessing(false)
    }
  }

  const changeCardPurchase = async pm => {
    stripe.confirmCardSetup(secret, pm).then(async payload => {
      if (payload.error) {
        setFormMessage(`Error: ${payload.error.message}`)
        AnalyticsManager.getInstance().trackEvent({
          action: 'confirm_card_payment_error',
          label: EVENT_CATEGORIES.CHECKOUT
        })
        setFormStatus(FORM_STATUS.SUCCESS)
      } else {
        setShowLoadingModal(true)
        setProcessing(true)
        const body = {
          billingCycle,
          planId,
          setupIntentId: payload.setupIntent.id,
          clientSecret: secret
        }
        try {
          await upgradeSubscription({
            url: upgradeSubscriptionPath,
            method: 'POST',
            data: body
          })
          AnalyticsManager.getInstance().trackEvent({
            action: 'upgrade_card_sub_success',
            label: EVENT_CATEGORIES.CHECKOUT
          })
        } catch (error) {
          AnalyticsManager.getInstance().trackEvent({
            action: 'upgrade_card_sub_error',
            label: EVENT_CATEGORIES.CHECKOUT
          })
          setError(error)
          setProcessing(false)
        }

        setFormStatus(FORM_STATUS.SUCCESS)
      }
    })
  }

  const confirmCardPayment = async (values, pm) => {
    pm.receipt_email = values.email
    stripe.confirmCardPayment(secret, pm).then(payload => {
      if (payload.error) {
        setFormMessage(`Error: ${payload.error.message}`)
        AnalyticsManager.getInstance().trackEvent({
          action: 'confirm_card_payment_error',
          label: EVENT_CATEGORIES.CHECKOUT
        })
        setFormStatus(FORM_STATUS.SUCCESS)
      } else {
        setShowLoadingModal(true)
        setProcessing(true)
        const body = {
          billingCycle,
          planId,
          paymentIntentId: payload?.paymentIntent?.id
        }
        setValidationBody(body)
        attemptValidation(body)
        setFormStatus(FORM_STATUS.SUCCESS)
      }
    })
  }

  const attemptValidation = async body => {
    await validateSubscription({
      url: validateSubscriptionPath,
      method: 'POST',
      data: body
    })
  }

  useEffect(() => {
    secret &&
      !spinning &&
      checkoutPageLoadEvent(userId, accountDetails, plan, billingCycle)
    // eslint-disable-next-line
  }, [spinning, secret])

  return (
    <Skeleton paragraph={{ rows: 10 }} loading={spinning} active>
      <PaymentLoadingModal
        processing={processing}
        error={error}
        showLoadingModal={showLoadingModal}
        isFutureSubscription={isFutureSubscription}
      />
      <Form
        wrapperCol={{
          sm: {
            span: 24
          },
          lg: {
            span: 20
          },
          xl: {
            span: 16
          }
        }}
        name='payment-info'
        className='payment-info-form'
        initialValues={{
          remember: true,
          name: activeCard?.name,
          email: activeCard?.email,
          country: activeCard?.country,
          line1: activeCard?.line1,
          line2: activeCard?.line2,
          city: activeCard?.city,
          state: activeCard?.state,
          postal_code: activeCard?.postal_code
        }}
        onFinish={onFinish}
      >
        {changeCard ? (
          <>
            <div className='billing-details-subhead'>{t('invoice_info')}</div>
            <Item
              name='name'
              rules={[
                {
                  required: true,
                  message: t('required')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input
                autoComplete='name'
                className='sl-company-input'
                placeholder={t('company_name')}
                aria-label={t('company_name')}
              />
            </Item>
            <Item
              name='email'
              rules={[
                {
                  required: true,
                  message: t('required')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input
                type='email'
                autoComplete='email'
                className='sl-email-input'
                placeholder={t('email')}
                aria-label={t('email')}
              />
            </Item>
            <Item
              name='country'
              rules={[
                {
                  required: true,
                  message: t('required')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Select
                showSearch
                autoComplete='country'
                className='sl-countryInput'
                placeholder={t('country')}
                aria-label={t('country')}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countryList?.result?.map(country => (
                  <Option key={country.code} className={`sl-${country.code}`}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name='line1'
              rules={[
                {
                  required: true,
                  message: t('required')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input
                autoComplete='line1'
                className='sl-line1'
                placeholder={t('address_line1')}
                aria-label={t('address_line1')}
              />
            </Item>
            <Item name='line2'>
              <Input
                autoComplete='line2'
                className='sl-line2'
                placeholder={t('address_line2')}
                aria-label={t('address_line2')}
              />
            </Item>
            <Item
              name='city'
              rules={[
                {
                  required: true,
                  message: t('required')
                },
                {
                  pattern: /^[,\-@_0-9a-zA-Z.\s]{2,}$/,
                  message: t('city_rules')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input
                autoComplete='city'
                className='sl-city'
                placeholder={t('city')}
                aria-label={t('city')}
              />
            </Item>
            <Item
              name='state'
              rules={[
                {
                  required: true,
                  message: t('required')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input
                autoComplete='state'
                className='sl-state'
                placeholder={t('state')}
                aria-label={t('state')}
              />
            </Item>
            <Item
              name='postal_code'
              rules={[
                {
                  required: true,
                  message: t('required')
                },
                {
                  pattern: /^[,\-@_0-9a-zA-Z.\s]{4,}$/,
                  message: t('invalid_zip')
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input
                autoComplete='zip-code'
                className='sl-zip-code'
                placeholder={t('zip_code')}
                aria-label={t('zip_code')}
              />
            </Item>

            <div className='billing-details-subhead'>{t('payment')}</div>
            <Row>
              <Col sm={24} lg={20} xl={16} className='cc-box'>
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontFamily: 'Roboto, Open Sans',
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4'
                        }
                      },
                      invalid: {
                        color: '#9e2146'
                      }
                    }
                  }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <BillingDetails
            cardData={cardData?.[0]}
            countryList={countryList}
            setChangeCard={setChangeCard}
          />
        )}
        <Text type='danger'>{formMessage}</Text>
        <div className='stripe-authorize-warn sl-stripe-authorize-warn'>
          {t('stripe_authorize')}
        </div>
        <Item
          name='agreement'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('please_accept_terms')))
            }
          ]}
        >
          <Checkbox className='sl-checkbox'>
            {t('accept_our')}{' '}
            <a
              className='tos-link'
              href='https://www.strattic.com/terms-of-service/'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('tos')}
            </a>
          </Checkbox>
        </Item>
        <Button
          type='primary'
          className='sl-submit'
          htmlType='submit'
          loading={formStatus === FORM_STATUS.SUBMITTING}
          onClick={() =>
            paymentAttemptEvent(userId, accountDetails, plan, billingCycle)
          }
        >
          {t('pay')}
        </Button>
      </Form>
    </Skeleton>
  )
}

export default PaymentInfoForm
