import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import { Layout, Skeleton, Typography, Row, Alert } from 'antd'

import MainMenu from '@Components/MainMenu'
import TopBar from '@Components/TopBar'
import SiteNav from '@Components/SiteNav'
import DashboardFooter from '@Components/DashboardFooter'
import { useUserContext } from '@Context/UserContext'
import { TopWarningBar } from '@Components/TopWarningBar'
import { Scrollbars } from 'react-custom-scrollbars'
import useWindowDimensions from '@Hooks/useWindowDimensions'
import { SITE_STATUS } from '@Constants'

import './index.less'

const { Title } = Typography
const { Content } = Layout

export const Template = ({
  currentSite,
  title,
  classNames,
  customMenu,
  children,
  isLoading,
  titleButton,
  siteStatus,
  beta = false
}) => {
  const { t } = useTranslation(['common'])
  const userContext = useUserContext()
  const [footerHeight] = useState(72)
  const [contentPaddings] = useState(45)
  const [topPadding, setTopPadding] = useState(
    userContext.expirationBarPadding ? 50 : 0
  )
  const [topIndent, setTopIndent] = useState(76 + topPadding)
  const [restorePadding, setRestorePadding] = useState(0)
  const { height } = useWindowDimensions()

  useEffect(() => {
    if (userContext.expirationBarPadding) {
      setTopPadding(56)
    } else {
      setTopPadding(0)
    }
  }, [userContext.expirationBarPadding])

  useEffect(() => {
    setTopIndent(76 + topPadding)
  }, [topPadding])

  useEffect(() => {
    if (siteStatus === SITE_STATUS.RESTORING) {
      setRestorePadding(40)
    } else {
      setRestorePadding(0)
    }
  }, [siteStatus])

  return (
    <>
      <TopWarningBar />
      <Layout style={{ paddingTop: topPadding }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <MainMenu />
        <Layout className={`page-wrapper sites ${classNames}`}>
          <TopBar />
          {siteStatus === SITE_STATUS.RESTORING && (
            <Alert
              message={<> {t('restore_message')}</>}
              style={{ textAlign: 'center' }}
            />
          )}
          <Layout>
            <SiteNav
              currentSite={currentSite}
              restorePadding={restorePadding}
            />
            <Content>
              <Scrollbars
                style={{ height: height - topIndent - restorePadding }}
                autoHide
              >
                <div
                  className='site-layout-content'
                  style={{
                    minHeight:
                      height -
                      footerHeight -
                      contentPaddings -
                      topIndent -
                      restorePadding
                  }}
                >
                  <Row justify='space-between'>
                    <Title>
                      {title}
                      {beta && <Beta />}
                    </Title>
                    {titleButton}
                  </Row>
                  <Skeleton loading={isLoading} active>
                    {children}
                  </Skeleton>
                </div>
                <DashboardFooter />
              </Scrollbars>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

const Beta = () => <span style={{ color: '#fb4246', fontSize: 10 }}> Beta</span>
