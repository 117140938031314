import { ROLE_TYPE, OWNER_OR_ADMIN } from '@Constants'
import { SITE_PERMISSIONS } from '@Constants/permissionsConstants'
import { PERMISSIONS_CONST } from '@Packages/RulesEngine/Constants/PermissionConst'

export const permissionGranted = (permissionsArray, queriedPermission) => {
  if (Array.isArray(permissionsArray)) {
    return permissionsArray.includes(queriedPermission)
  }
  return false
}

export const hasHigherRoleHierarchy = (roleA, roleB) => {
  const rolesScores = {}
  rolesScores[ROLE_TYPE.COLLABORATOR] = 0
  rolesScores[ROLE_TYPE.MANAGER] = 1
  rolesScores[ROLE_TYPE.ADMIN] = 2
  rolesScores[ROLE_TYPE.OWNER] = 3

  return rolesScores[roleA] > rolesScores[roleB]
}

export const canCurrentUserManage = (
  currentUser,
  queryRecord,
  currentAccountId
) => {
  const currentAccountRole = roleTypeByAccount(currentUser, currentAccountId)
  switch (currentAccountRole) {
    case ROLE_TYPE.OWNER:
      return true
    case ROLE_TYPE.ADMIN:
      return queryRecord.roleType !== ROLE_TYPE.OWNER
    case ROLE_TYPE.MANAGER:
      return (
        !OWNER_OR_ADMIN.includes(queryRecord.roleType) &&
        userHasAllSites(currentUser, currentAccountId)
      )
    case ROLE_TYPE.COLLABORATOR:
      return false
    default:
      return false
  }
}

export const userHasAllSites = (currentUser, currentAccountId) => {
  const activeInvitation = currentUser?.invitations.find(
    invite => invite.accountId === parseInt(currentAccountId, 10)
  )
  return activeInvitation && activeInvitation.allSites
}

export const roleTypeByAccount = (userData, accountId) => {
  if (userData && userData.invitations) {
    const activeInvitation = userData.invitations.find(
      invite => invite.accountId === parseInt(accountId, 10)
    )
    if (activeInvitation) {
      return activeInvitation.roleType
    }
  }
}

export const mapStratticPermissionsToRulesPermissions = sitePermissions => {
  let sitePermissionsArr = []
  if (sitePermissions?.includes(SITE_PERMISSIONS.SITE_RULES_ENGINE_DEPLOY)) {
    sitePermissionsArr = [
      ...sitePermissionsArr,
      PERMISSIONS_CONST.RULES_ENGINE_DEPLOY
    ]
  }
  if (sitePermissions?.includes(SITE_PERMISSIONS.SITE_RULES_ENGINE_MANAGE)) {
    sitePermissionsArr = [
      ...sitePermissionsArr,
      PERMISSIONS_CONST.RULES_ENGINE_MANAGE
    ]
  }
  return sitePermissionsArr
}
