import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import useLocalStorage from '@Hooks/useLocalStorage'
import { getDate } from '@Lib/dates'
import { downloadResponse } from '@Lib/helpers'

import { Card, Empty, Typography } from 'antd'

import './index.less'

const { Title, Link } = Typography

const RecentInvoicesCard = ({ setInvoiceVisible }) => {
  const { t } = useTranslation(['common', 'errors'])
  const [accountId] = useLocalStorage('accountId')
  const [path] = useState(`accounts/${accountId}/invoices?page=1&records=5`)
  const [
    { data: getData, loading: getIsLoading, error: getError }
  ] = useStratticApi(path)
  useErrorMessage(getError)
  const [invoices, setInvoices] = useState()

  useEffect(() => {
    let results = []
    if (getData?.results) {
      // Filter out all zero amount invoices
      results = getData.results.filter(invoice => invoice.amount > 0)
    }

    setInvoices({
      isLoading: getIsLoading,
      value: results,
      error: getError
    })
  }, [getIsLoading, getData, getError])

  return (
    <Card
      className='recent-invoices sl-recent-invoices'
      title={t('invoices')}
      loading={invoices?.isLoading}
    >
      {invoices?.value?.length ? (
        <>
          <Title level={3}>{t('most_recent')}</Title>
          <ul>
            {invoices?.value?.map((item, index) =>
              item?.invoice ? (
                <li key={index}>
                  <Link
                    className='sl-invoice-date'
                    onClick={() => downloadResponse(item?.invoice?.pdf)}
                  >
                    {getDate(item?.createdAt)}
                  </Link>
                  <span className='total sl-invoice-total'>
                    {t('$')}
                    {item?.amount}
                  </span>
                </li>
              ) : (
                <li key={index}>
                  <Link
                    href={item?.receiptUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {getDate(item?.createdAt)}
                  </Link>
                  <span className='total'>
                    {t('$')}
                    {item?.amount}
                  </span>
                </li>
              )
            )}
          </ul>
        </>
      ) : (
        <Empty description={t('empty')} />
      )}
      {/* prep for v2.1
       <Button type='link' onClick={() => setInvoiceVisible(true)}>{t('view_all')}</Button> */}
    </Card>
  )
}

export default RecentInvoicesCard
