import React from 'react'
import { useTranslation } from 'react-i18next'
import { Template } from '@Templates/Dashboard'

const SupportPage = () => {
  const { t } = useTranslation(['common'])
  return <Template title={t('support')} />
}

export default SupportPage
