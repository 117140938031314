import React, { useState, useEffect } from 'react'
import { Carousel, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { StratticIcon } from '@Components/Icons'
import './index.less'

const BenefitsCarousel = () => {
  const { t } = useTranslation(['common', 'errors'])
  const [shuffledArr, setShuffledArr] = useState([])

  const shuffle = arr => [...arr].sort(() => Math.random() - 0.5)

  // randomise the order of messages on page render
  // inside the useEffect do to unwanted behaviour if parent rerenders
  useEffect(() => {
    const messagingArr = [
      t('benefit_messaging_1'),
      t('benefit_messaging_2'),
      t('benefit_messaging_3'),
      t('benefit_messaging_4'),
      t('benefit_messaging_5'),
      t('benefit_messaging_6'),
      t('benefit_messaging_7'),
      t('benefit_messaging_8'),
      t('benefit_messaging_9'),
      t('benefit_messaging_10'),
      t('benefit_messaging_11'),
      t('benefit_messaging_12'),
      t('benefit_messaging_13'),
      t('benefit_messaging_14')
    ]
    const shuffled = shuffle(messagingArr)
    setShuffledArr(shuffled)
  }, [t])

  return (
    <Carousel autoplay autoplaySpeed={6000} dots={false} effect='fade'>
      {shuffledArr.map((benefit, index) => {
        return (
          <div key={index}>
            <Row className='benefit-carousel' align='middle'>
              <Col span={2} offset={1} id='benefits-logo'>
                <StratticIcon />
              </Col>
              <Col span={20}>
                <h3>{benefit}</h3>
              </Col>
            </Row>
          </div>
        )
      })}
    </Carousel>
  )
}

export default BenefitsCarousel
