import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Card, Typography } from 'antd'

import BillingEmail from './BillingEmail'
import {
  getFormattedCardBillingDetail,
  isCardExpired
} from '@Lib/helpers/subscriptionHelpers'

import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import './index.less'

const { Text, Link, Paragraph } = Typography

const PaymentDetailsCard = ({
  canUpdateSubscriptionOrCard,
  setUpdatePaymentDetailsVisible,
  cardData,
  cardDataLoading
}) => {
  const { t } = useTranslation(['common', 'errors'])
  const [card, setCard] = useState()
  const [expired, setExpired] = useState(false)

  useEffect(() => {
    setCard({
      isLoading: cardDataLoading,
      value: getFormattedCardBillingDetail(cardData?.[0]) // Get first card
    })
  }, [cardData, cardDataLoading])

  useEffect(() => {
    setExpired(isCardExpired(cardData?.[0]?.card))
  }, [cardData])

  const getClassNames = () => {
    let classNames = ''
    if (expired) classNames += ' expired'
    return classNames
  }

  const getCardName = () => {
    if (expired) {
      return (
        <Paragraph>
          {t('credit_card_expired_please')}
          <Link
            onClick={() => {
              setUpdatePaymentDetailsVisible(true)
              AnalyticsManager.getInstance().trackClick({
                action: 'open_update_expired_card',
                label: EVENT_CATEGORIES.ACCOUNT_PAGE
              })
            }}
          >
            {t('update_lc')}
          </Link>
        </Paragraph>
      )
    }
    return t('payment_details')
  }
  if (!card?.isLoading && !card?.value) {
    return null
  }

  return (
    <Card
      className={`payment-details  ${getClassNames()} sl-payment-details`}
      title={getCardName()}
      loading={card?.isLoading}
    >
      {card?.isLoading === false && (
        <>
          <Text className='sl-payment-name' type='secondary'>
            {card?.value?.name}
          </Text>
          <Text className='sl-payment-address' type='secondary'>
            {card?.value?.line1}
          </Text>
          <Text className='sl-payment-email' type='secondary'>
            {card?.value?.line2}
          </Text>
          <BillingEmail canUpdate={canUpdateSubscriptionOrCard} />

          <div className='card-details'>
            <Text className='sl-payment-card-name' strong type='secondary'>
              {card?.value?.name}
            </Text>
            <Text className='card-number sl-card-number' type='secondary'>
              XXXX XXXX XXXX {card?.value?.last4}{' '}
              <span className='sl-payment-card-expiration'>
                {card?.value?.brand.toUpperCase()} {card?.value?.exp_month}/
                {card?.value?.exp_year.toString().substr(-2)}
              </span>
            </Text>
          </div>
          {canUpdateSubscriptionOrCard && (
            <Button
              type='link'
              className='sl-update-payment-details'
              onClick={() => {
                setUpdatePaymentDetailsVisible(true)
                AnalyticsManager.getInstance().trackClick({
                  action: 'update_payment_details',
                  label: EVENT_CATEGORIES.ACCOUNT_PAGE
                })
              }}
            >
              {t('update_payment_details')}
            </Button>
          )}
        </>
      )}
    </Card>
  )
}

export default PaymentDetailsCard
