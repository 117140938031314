export const ACCOUNT_PERMISSIONS = {
  ACCOUNT_USER_INVITATION_MANAGE: 'ACCOUNT_USER_INVITATION_MANAGE',
  ACCOUNT_USER_INVITATION_VIEW: 'ACCOUNT_USER_INVITATION_VIEW',
  ACCOUNT_SITE_CREATE: 'ACCOUNT_SITE_CREATE',
  ACCOUNT_VIEW_SUBSCRIPTIONS: 'ACCOUNT_VIEW_SUBSCRIPTIONS',
  ACCOUNT_SUBSCRIPTION_CANCEL: 'ACCOUNT_SUBSCRIPTION_CANCEL',
  ACCOUNT_VIEW_BILLING: 'ACCOUNT_VIEW_BILLING',
  ACCOUNT_SUBSCRIPTION_UPGRADE: 'ACCOUNT_SUBSCRIPTION_UPGRADE',
  ACCOUNT_VIEW_ACCOUNT_SITES: 'ACCOUNT_VIEW_ACCOUNT_SITES',
  ACCOUNT_VIEW: 'ACCOUNT_VIEW',
  ACCOUNT_USER_INVITATION_ACCEPT: 'ACCOUNT_USER_INVITATION_ACCEPT',
  ACCOUNT_VIEW_AVAILABLE_PLANS: 'ACCOUNT_VIEW_AVAILABLE_PLANS',
  ACCOUNT_USER_INVITATION_VIEW_MFA: 'ACCOUNT_USER_INVITATION_VIEW_MFA',
  ACCOUNT_VIEW_INVOICE_LINK: 'ACCOUNT_VIEW_INVOICE_LINK'
}

export const SITE_PERMISSIONS = {
  SITE_RESTORE_FROM_ZIP: 'SITE_RESTORE_FROM_ZIP',
  SITE_BACKUP_DELETE: 'SITE_BACKUP_DELETE',
  SITE_BACKUP_TRIGGER: 'SITE_BACKUP_TRIGGER',
  SITE_DELETE: 'SITE_DELETE',
  SITE_RESET_PASS: 'SITE_RESET_PASS',
  SITE_VIEW_FULL_SITE: 'SITE_VIEW_FULL_SITE',
  SITE_IMAGE_SYNC_COMPLETE: 'SITE_IMAGE_SYNC_COMPLETE',
  SITE_PUBLISH_UPDATE: 'SITE_PUBLISH_UPDATE',
  SITE_PUBLISH: 'SITE_PUBLISH',
  SITE_SCHEDULE_POST: 'SITE_SCHEDULE_POST',
  SITE_SET_SHUTDOWN: 'SITE_SET_SHUTDOWN',
  SITE_START: 'SITE_START',
  SITE_STOP: 'SITE_STOP',
  SITE_UPDATE: 'SITE_UPDATE',
  SITE_VIEW_BACKUP_URL: 'SITE_VIEW_BACKUP_URL',
  SITE_VIEW_BACKUPS: 'SITE_VIEW_BACKUPS',
  SITE_VIEW_METRICS: 'SITE_VIEW_METRICS',
  SITE_VIEW: 'SITE_VIEW',
  SITE_CREATE_CUSTOM_DOMAIN: 'SITE_CREATE_CUSTOM_DOMAIN',
  SITE_DELETE_CUSTOM_DOMAIN: 'SITE_DELETE_CUSTOM_DOMAIN',
  SITE_DISTRIBUTION_UPDATE: 'SITE_DISTRIBUTION_UPDATE',
  SITE_S3_SYNC: 'SITE_S3_SYNC',
  SITE_VIEW_CERTIFICATE_INFO: 'SITE_VIEW_CERTIFICATE_INFO',
  SITE_CANCEL_SCRAPE_JOB: 'SITE_CANCEL_SCRAPE_JOB',
  SITE_VIEW_SCRAPE_JOB: 'SITE_VIEW_SCRAPE_JOB',
  SITE_SET_SITE_PROTECTED_PASSWORD: 'SITE_SET_SITE_PROTECTED_PASSWORD',
  SITE_RULES_ENGINE_READ: 'SITE_RULES_ENGINE_READ',
  SITE_RULES_ENGINE_MANAGE: 'SITE_RULES_ENGINE_MANAGE',
  SITE_RULES_ENGINE_DEPLOY: 'SITE_RULES_ENGINE_DEPLOY'
}

export const MAX_BACKUPS = 200
