import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'

const EditButton = ({ record, openEditModal }) => {
  const { t } = useTranslation(['common'])

  return (
    <Button
      className='sl-edit'
      type='link'
      onClick={() => {
        openEditModal(record)
      }}
    >
      {t('edit')}
    </Button>
  )
}

export default EditButton
