import React from 'react'
import AnalyticsManager from '@Managers/analyticsManager'

const ExternalLink = ({ to, title = '', opensNewTab = true, children }) => {
  const url = to.startsWith('http') ? to : `https://${to}`
  return (
    <a
      href={url}
      target={opensNewTab ? '_blank' : ''}
      rel={opensNewTab ? '_blank' : 'noopener noreferrer'}
      title={url || title}
      onClick={() =>
        AnalyticsManager.getInstance().trackClick(`clicked-link-${url}`)
      }
    >
      {children}
    </a>
  )
}

export default ExternalLink
