import React from 'react'
import { Card, Col } from 'antd'

import SiteImage from '@Components/SiteImage'
import LoadingMockup from '@Images/mockups/screenshot-loading.png'

import './index.less'

const LoadingCard = ({
  colSpan = 8,
  classNames,
  styles = {},
  title = '',
  accountDetails
}) => {
  if (accountDetails?.isLoading === false) return null
  return (
    <Col span={colSpan}>
      <Card
        className='sl-site-loading'
        loading
        title={title}
        cover={<SiteImage screenshot={LoadingMockup} />}
        style={styles}
      />
    </Col>
  )
}

export default LoadingCard
