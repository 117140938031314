import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Row, Col, Typography, Divider } from 'antd'
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  billingChangeMessage,
  formatDate,
  getIsFutureSubscription
} from '@Lib/helpers/subscriptionHelpers'
import { pluralStringIfNeeded } from '@Lib/helpers'
import './index.less'

const { Title, Text } = Typography

const CartCard = ({ cart, currentAccount }) => {
  const { t } = useTranslation(['common'])
  const [subtotal] = useState(cart?.priceTotal)
  const [proratedAdjustment] = useState(cart?.proration)
  const [totalCost, setTotalCost] = useState()
  const [noticeText, setNoticeText] = useState()
  const [isFuture, setIsFuture] = useState(false)
  const [cardClass, setCardClass] = useState('cart')

  useEffect(() => {
    if (getIsFutureSubscription(cart?.startDate)) {
      setIsFuture(true)
      setCardClass('cart cart-future')
    } else {
      setIsFuture(false)
      setCardClass('cart')
    }
  }, [cart])

  useEffect(() => {
    if (
      billingChangeMessage(currentAccount, cart) ===
      'billing_cycle_change_message'
    ) {
      setNoticeText(
        t('billing_cycle_change_message', {
          currentPlanName: currentAccount?.planName,
          dateString: formatDate(cart?.startDate)
        })
      )
    } else {
      setNoticeText(
        t('billing_change_message', {
          currentPlanName: currentAccount?.planName,
          currentSiteAllowance: currentAccount?.sitesUsage?.max,
          currentSiteOrSites: pluralStringIfNeeded(
            'site',
            currentAccount?.sitesUsage?.max
          ),
          cartPlanName: cart?.name,
          cartAllowanceSites: cart?.allowances?.websitesAmount,
          siteOrSites: pluralStringIfNeeded(
            'site',
            cart?.allowances?.websitesAmount
          ),
          startDate: formatDate(cart?.startDate)
        })
      )
    }
  }, [currentAccount, cart, t])

  useEffect(() => {
    if (proratedAdjustment) {
      setTotalCost((subtotal - proratedAdjustment).toFixed(2))
    } else {
      setTotalCost(subtotal)
    }
  }, [proratedAdjustment, subtotal])

  return (
    <Card className={cardClass}>
      <Row>
        <Col span={20}>
          {cart && (
            <Title level={3} className='sl-title'>
              {cart?.name || t('starter')}
            </Title>
          )}
        </Col>
        <Col span={4} className='sl-currency'>
          {t('$ USD')}
        </Col>
      </Row>
      <Row className='billing-frequency'>
        <Col span={20}>
          {cart?.isAnnual === true ? (
            <Text>{t('pay_annually')}</Text>
          ) : (
            <Text>{t('pay_monthly')}</Text>
          )}
        </Col>
        <Col span={4}>
          {cart?.isAnnual ? (
            <Text className='sl-frequency-price'>
              {t('$')}
              {cart.priceYear}
            </Text>
          ) : (
            <Text className='sl-frequency-price'>
              {t('$')}
              {cart.priceMonth}
            </Text>
          )}
        </Col>
      </Row>
      {proratedAdjustment ? (
        <Row className='proration sl-proration'>
          <Col span={18}>
            <Text>{t('prorated_adjustment')}</Text>
          </Col>
          <Col span={6}>
            <Text className='sl-proration-adjustment'>
              {t('$')}
              {proratedAdjustment}
            </Text>
          </Col>
        </Row>
      ) : null}
      <Row className='total'>
        <Col span={18}>
          <Title level={4}>{t('total')}</Title>
        </Col>
        <Col span={6}>
          <Title level={4} className='sl-total-price'>
            {t('$')}
            {totalCost}
          </Title>
        </Col>
      </Row>
      <RenderDowngradeInfo currentAccount={currentAccount} />
      <Row className='plan-includes'>
        <Col span={24} key='title'>
          <Title level={4}>{t('plan_includes', { plan: cart.name })}</Title>
        </Col>
        <Col span={12} key='sites' className='feature-sites'>
          <CheckCircleOutlined />
          <Text className='sl-feature-sites'>{cart?.websitesAmount}</Text>
          <Text className='label'>
            {' '}
            {pluralStringIfNeeded('site', cart?.websitesAmount)}
          </Text>
        </Col>
        <Col span={12} key='visits' className='feature-visits'>
          <CheckCircleOutlined />
          <Text className='sl-feature-visits'>{t('unlimited_visits')}</Text>
        </Col>
        <Col span={12} key='bandwidth' className='feature-bandwidth'>
          <CheckCircleOutlined />
          <Text className='sl-feature-bandwidth'>
            {cart?.bandwidthGb} GB {t('bandwidth')}
          </Text>
        </Col>
        <Col span={12} key='storage' className='feature-storage'>
          <CheckCircleOutlined />
          <Text className='label sl-feature-storage'>
            {cart?.storageGb} GB {t('storage')}
          </Text>
        </Col>
      </Row>
      {isFuture && (
        <Row className='plan-change-date'>
          <Col span={24}>
            <p>
              <InfoCircleOutlined /> {noticeText}
            </p>
          </Col>
        </Row>
      )}
    </Card>
  )
}

const RenderDowngradeInfo = ({ cart, currentAccount }) => {
  const { t } = useTranslation(['common'])
  const [noticeText, setNoticeText] = useState()

  useEffect(() => {
    if (
      billingChangeMessage(currentAccount, cart) ===
      'billing_cycle_change_message'
    ) {
      setNoticeText(
        t('billing_cycle_change_message', {
          currentPlanName: currentAccount?.planName,
          dateString: formatDate(cart?.startDate)
        })
      )
    } else {
      setNoticeText(
        t('billing_change_message', {
          currentPlanName: currentAccount?.planName,
          currentSiteAllowance: currentAccount?.sitesUsage?.max,
          currentSiteOrSites: pluralStringIfNeeded(
            'site',
            currentAccount?.sitesUsage?.max
          ),
          cartPlanName: cart?.name,
          cartAllowanceSites: cart?.allowances?.websitesAmount,
          siteOrSites: pluralStringIfNeeded(
            'site',
            cart?.allowances?.websitesAmount
          ),
          startDate: formatDate(cart?.startDate)
        })
      )
    }
  }, [currentAccount, cart, t])
  if (!cart?.allowances || !cart?.startDate) {
    return null
  }
  return (
    <>
      <Divider />
      <Text type='secondary'>
        <InfoCircleOutlined /> {noticeText}
      </Text>
    </>
  )
}

export default CartCard
