import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleTwoTone } from '@ant-design/icons'

const MyTooltip = ({ roleType, title }) => {
  return (
    <>
      {roleType}{' '}
      <Tooltip title={title}>
        <QuestionCircleTwoTone twoToneColor='#D4D4D4' />
      </Tooltip>
    </>
  )
}

export default MyTooltip
