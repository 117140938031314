import React, { useState, useEffect, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Skeleton,
  Divider,
  Card,
  Typography
} from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'

import useCurrentSite from '@Hooks/useCurrentSite'
import useStratticApi from '@Packages/StratticApi/useStratticApi'
import useErrorMessage from '@Hooks/useErrorMessage'
import AnalyticsManager from '@Managers/analyticsManager'
import { EVENT_CATEGORIES } from '@Constants/AnalyticsConstants'

import { Template } from '@Templates/Site'
import { WWW, DOMAIN_STATUS } from '@Constants'

import SectionTitle from '@Components/SectionTitle'
import DefinitionHeader from '@Components/DefinitionHeader'
import DefinitionData from '@Components/DefinitionData'
import CaaCheck from '@Components/CaaCheck'
import DnsData from '@Components/DnsData'
import SiteDistError from '@Components/SiteDistError'

import { isDomainValid, getPendingDomainName } from '@Lib/helpers'

import './index.less'

const { Item } = Form
const { confirm } = Modal
const { Title, Text } = Typography

const SiteDomainSetupPage = () => {
  const [currentSite, refetchCurrentSite] = useCurrentSite()
  const [isInitialMount, setInitialMount] = useState(true)
  const [newDomain, setNewDomain] = useState('www.')
  const [missingWWW, setMissingWWW] = useState(false)
  const [pendingSslCerts, setPendingSslCerts] = useState()
  const { t } = useTranslation(['common'])
  const timer = useRef(null)

  // Submit new domain name
  const [
    { loading: submitCustomDomainLoading, error: submitCustomDomainError },
    submitCustomDomain
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(submitCustomDomainError)

  const [
    { loading: finalizeCustomDomainLoading, error: finalizeCustomDomainError },
    finalizeCustomDomain
  ] = useStratticApi({}, { manual: true })
  useErrorMessage(finalizeCustomDomainError)

  const checkSite = () => {
    refetchCurrentSite()
    timer.current = setTimeout(() => {
      checkSite()
    }, 60 * 1000)
  }

  useEffect(() => {
    checkSite()
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line
  }, [])

  const handleNewDomain = event => {
    setNewDomain(event.target.value)
    if (!event.target.value.startsWith('www.')) {
      setMissingWWW(true)
    } else {
      setMissingWWW(false)
    }
  }

  const validateDomain = (rule, value) => {
    if (value === WWW || !isDomainValid(value)) {
      return Promise.reject(t('enter_valid_domain'))
    } else {
      return Promise.resolve()
    }
  }
  const pendingSsl = currentSite?.value?.liveDistribution.pendingSslDns

  useEffect(() => {
    if (pendingSsl) {
      setPendingSslCerts(formatPendingSslDns(pendingSsl))
    }
  }, [pendingSsl])

  const formatPendingSslDns = pendingSslDns => {
    const splitSslDns = pendingSslDns.split(' ')
    if (splitSslDns.length < 3) {
      return
    }
    const dnsTableData = [
      {
        name: splitSslDns[0],
        type: splitSslDns[1],
        value: splitSslDns[2]
      }
    ]
    return dnsTableData
  }

  useEffect(() => {
    if (isInitialMount && newDomain !== WWW) {
      setInitialMount(false)
    }
  }, [newDomain, isInitialMount])

  const onFinish = async fieldsValue => {
    if (isInitialMount) {
      setInitialMount(false)
    }
    let body = fieldsValue
    if (missingWWW) {
      body = { ...fieldsValue, acceptRootDomain: true }
    }
    await submitCustomDomain({
      url: `/distributions/${currentSite?.value?.liveDistribution?.id}/certificate`,
      method: 'POST',
      data: body
    })
    AnalyticsManager.getInstance().trackEvent({
      action: 'submit_domain_name',
      label: EVENT_CATEGORIES.CONNECT_DOMAIN
    })
    // console.log('TODO: Need to save registrar information')
    refetchCurrentSite()
  }

  const showResetConfirm = () => {
    confirm({
      title: t('reset_domain_warning', {
        pendingDomainName: getPendingDomainName(currentSite)
      }),
      content: `for ${currentSite?.value?.liveDistribution?.domainName}`,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'danger',
      onOk: async () => {
        await submitCustomDomain({
          url: `/distributions/${currentSite?.value?.liveDistribution?.id}/certificate`,
          method: 'DELETE'
        })
        AnalyticsManager.getInstance().trackEvent({
          action: 'delete_ssl_certificate',
          label: EVENT_CATEGORIES.CONNECT_DOMAIN
        })
        refetchCurrentSite()
      },
      className: 'confirm-reset-domain',
      afterClose: refetchCurrentSite()
    })
  }

  if (currentSite?.isLoading === undefined || currentSite?.isLoading) {
    return (
      <Template
        currentSite={currentSite}
        title={t('custom_domain')}
        classNames='domain-setup-page'
      >
        <Skeleton />
      </Template>
    )
  }
  if (
    currentSite?.value?.customDomain ||
    currentSite?.value?.hasDomainOverride
  ) {
    return <Redirect to={`/site/${currentSite?.value?.siteId}/domain`} />
  }

  return (
    <Template
      currentSite={currentSite}
      title={
        currentSite?.value?.liveDistribution?.certificate?.status !==
          DOMAIN_STATUS.ISSUED && t('custom_domain')
      }
      classNames='domain-setup-page'
      // Reset domain button
      titleButton={
        getPendingDomainName(currentSite) &&
        currentSite?.value?.liveDistribution?.certificate?.status !==
          DOMAIN_STATUS.ISSUED && (
          <div className='reset-button'>
            <Button type='primary' onClick={showResetConfirm}>
              {t('reset')}
            </Button>
          </div>
        )
      }
    >
      {!currentSite?.value?.liveDistribution?.certificate ? (
        <>
          <div className='data-list bordered'>
            <section>
              <dl>
                <Row>
                  <Col span={5}>
                    <DefinitionHeader label='domain_being_connected' />
                  </Col>
                  <Col span={13}>
                    <DefinitionData
                      value={`https://${currentSite?.value?.liveDistribution?.domainName}`}
                      showCopy={false}
                    />
                  </Col>
                </Row>
              </dl>
            </section>
            <SectionTitle title='enter_domain' />
            <Form
              name='enter-domain-name'
              onFinish={onFinish}
              initialValues={{ domainName: newDomain }}
            >
              <section>
                <dl>
                  <Row>
                    <Col span={5} style={{ paddingTop: 10 }}>
                      <DefinitionHeader
                        label='your_domain'
                        tooltip='your_domain_tooltip'
                      />
                    </Col>
                    <Col span={13}>
                      <dd className='domain-name-input'>
                        <div>
                          <Item
                            name='domainName'
                            validateTrigger='onBlur'
                            rules={[{ validator: validateDomain }]}
                          >
                            <Input
                              addonBefore='https://'
                              placeholder='www.example.com'
                              onChange={handleNewDomain}
                            />
                          </Item>
                        </div>
                      </dd>
                      <p>
                        <Button
                          size='large'
                          type='primary'
                          htmlType='submit'
                          loading={submitCustomDomainLoading}
                        >
                          {t('continue')}
                        </Button>
                      </p>
                    </Col>
                  </Row>
                </dl>
              </section>
            </Form>
          </div>
        </>
      ) : currentSite?.value?.liveDistribution?.certificate?.status ===
        DOMAIN_STATUS.ISSUED ? (
        <>
          <div className='reset-button'>
            <Button type='primary' onClick={showResetConfirm}>
              {t('reset')}
            </Button>
          </div>
          <Card className='domain-validation-success-card'>
            <Title level={2}>{t('domain_validation_success')}</Title>
            <Text disabled>
              {t('site_has_been_validated_and_republished', {
                siteName: `https://${currentSite?.value?.liveDistribution?.domainName}`
              })}
              <br />
              {t('please_note_republish')}
            </Text>
            <Row>
              <Col span={8} offset={4} className='validated-site-data'>
                <Text strong>{t('site_being_connected')}</Text> <br />
                <Text disabled>
                  {`https://${currentSite?.value?.liveDistribution?.domainName}`}
                </Text>
              </Col>
              <Col span={8} className='validated-site-data'>
                <Text strong>{t('your_custom_domain')}</Text> <br />
                <Text disabled>
                  {`https://${getPendingDomainName(currentSite)}`}
                </Text>
              </Col>
            </Row>
            <Button
              className='continue-btn'
              loading={finalizeCustomDomainLoading}
              onClick={async () => {
                AnalyticsManager.getInstance().trackEvent({
                  action: 'finalise_domain',
                  label: EVENT_CATEGORIES.CONNECT_DOMAIN
                })
                await finalizeCustomDomain({
                  url: `distributions/${currentSite?.value?.liveDistribution?.id}/domain`,
                  method: 'POST'
                })
                await refetchCurrentSite()
              }}
            >
              {t('continue_and_republish')}
            </Button>
          </Card>
        </>
      ) : currentSite?.value?.liveDistribution?.certificate?.status ===
          DOMAIN_STATUS.PENDING || DOMAIN_STATUS.VALIDATION_TIMED_OUT ? (
        <>
          <div className='data-list bordered'>
            <SiteDistError
              distribution={currentSite?.value?.liveDistribution}
              siteId={currentSite?.value?.siteId}
            />
            <section>
              <dl>
                <Row>
                  <Col span={5}>
                    <DefinitionHeader label='site_being_connected' />
                  </Col>
                  <Col span={13}>
                    <DefinitionData
                      value={`https://${currentSite?.value?.liveDistribution?.domainName}`}
                      showCopy={false}
                    />
                  </Col>
                </Row>
              </dl>
            </section>
            <section>
              <dl>
                <Row>
                  <Col span={5}>
                    <DefinitionHeader label='your_domain' />
                  </Col>
                  <Col span={13}>
                    <DefinitionData
                      value={`https://${getPendingDomainName(currentSite)}`}
                      showCopy={false}
                    />
                  </Col>
                </Row>
              </dl>
            </section>
            <CaaCheck domain={getPendingDomainName(currentSite)} />
            <h3 className='section-header'>{t('ssl_cert_confirm')}</h3>
            {pendingSslCerts?.map(record => (
              <DnsData key={record?.value} record={record} />
            ))}
          </div>
          <Row>
            <Col span={1} offset={1}>
              <InfoCircleTwoTone
                twoToneColor='#1AC3DA'
                style={{ marginTop: '0.5rem', fontSize: 24 }}
              />
            </Col>
            <Col>
              <Divider type='vertical' className='validate-ssl-divider' />
            </Col>
            <Col span={10}>{t('validate_ssl_info')}</Col>
          </Row>
        </>
      ) : null}
    </Template>
  )
}

export default React.memo(SiteDomainSetupPage)
