import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Layout } from 'antd'
import Logo from '@Images/logos/strattic-logo-color.svg'
import LogoWhite from '@Images/logos/logo-horizontal-white.svg'
import MobileMenu from '@Images/icons/mobile-menu.svg'
import MobilMenuExit from '@Images/icons/mobile-menu-exit.svg'
// import SiteSearch from '@Components/SiteSearch'
import AccountMenu from '@Components/AccountMenu'
import ProfileMenu from '@Components/ProfileMenu'
import { useWindowDimensionsContext } from '@Context/WindowDimensionsContext'
import { NAV_STYLE } from '@Constants'
import './index.less'

const { Header } = Layout

const TopBar = () => {
  const { width, toggleMenuVisible, menuVisible } = useWindowDimensionsContext()
  if (width < NAV_STYLE.MED) {
    return (
      <Header className='top-bar-mobile'>
        <Row justify='space-between' className='top-bar-row'>
          <Col className='home-col'>
            <Link to='/'>
              <LogoWhite className='logo-white' />
            </Link>
          </Col>
          <Col>
            <Row>
              <Col>
                {width > NAV_STYLE.MOBILE && (
                  <AccountMenu className='account-menu' />
                )}
              </Col>
              <Col>
                {menuVisible ? (
                  <MobilMenuExit
                    className='mobile-menu-exit'
                    onClick={() => toggleMenuVisible()}
                  />
                ) : (
                  <MobileMenu
                    className='mobile-menu'
                    onClick={() => toggleMenuVisible()}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
    )
  }
  return (
    <Header className='top-bar'>
      <Row justify='space-between'>
        <Col className='home-col'>
          <Link id='strattic-home' to='/'>
            <Logo className='logo' />
          </Link>
          {/* commented out for future use */}
          {/* <SiteSearch /> */}
        </Col>
        <Col className='account-col'>
          <AccountMenu />
          <ProfileMenu />
        </Col>
      </Row>
    </Header>
  )
}
export default TopBar
