import { getDaysLeftOnSubscription } from '@Lib/helpers/subscriptionHelpers'

import { removeEmptyValueFromObject } from '@Lib/helpers'

export const purchaseCompletedEvent = (
  currentUserId,
  currentAccount,
  plan,
  billingCycle,
  key
) => {
  const payload = {
    event: 'fireEvent',
    event_name: 'purchase',
    action_type: 'purchase_success',
    plan_group_id: plan?.planGroupId || null,
    plan_id: plan?.id || null,
    plan_name: plan?.name || null,
    purchase_type: getPurchaseType(currentAccount, plan, billingCycle),
    billing_cycle: billingCycle || null,
    account_id: currentAccount?.accountId || null,
    trial_period_days: currentAccount?.freePlanLength || null, // if no value, do not send
    days_in_trial: daysIntoTrial(currentAccount) || null, // if no value, do not send
    payment_gateway: 'stripe',
    user_id: currentUserId || null,
    user_email: currentAccount?.contactEmail || null,
    subscription_id: currentAccount?.subscriptionId || null,
    ecommerce: {
      transaction_id: `${currentUserId}_${Date.now()}`,
      value: planCostFromBillingCycle(plan, billingCycle),
      currency: 'USD',
      coupon: null, // if no value, do not send
      items: [
        {
          item_id: plan?.id || null,
          item_name: plan?.name || null,
          item_category: 'hosting', // hard coded for now
          item_variant: billingCycle,
          price: planCostFromBillingCycle(plan, billingCycle),
          quantity: 1
        }
      ]
    }
  }
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push(removeEmptyValueFromObject(payload)) // only push key/value pairs that are not null
}

export const paymentAttemptEvent = (
  currentUserId,
  currentAccount,
  plan,
  billingCycle
) => {
  const payload = {
    event: 'fireEvent',
    event_name: 'element_click',
    action_type: 'payment_attempt',
    plan_group_id: plan?.planGroupId || null,
    plan_id: plan?.id || null,
    plan_name: plan?.name || null,
    purchase_type: getPurchaseType(currentAccount, plan, billingCycle),
    billing_cycle: billingCycle || null,
    account_id: currentAccount?.accountId || null,
    trial_period_days: currentAccount?.freePlanLength || null, // if no value, do not send
    days_in_trial: daysIntoTrial(currentAccount) || null, // if no value, do not send
    payment_gateway: 'stripe',
    user_id: currentUserId || null,
    user_email: currentAccount?.contactEmail || null
  }
  window.dataLayer.push(removeEmptyValueFromObject(payload)) // only push key/value pairs that are not null
}

export const checkoutPageLoadEvent = (
  currentUserId,
  currentAccount,
  plan,
  billingCycle
) => {
  const payload = {
    event: 'fireEvent',
    event_name: 'begin_checkout',
    action_type: 'begin_checkout',
    plan_group_id: plan?.planGroupId || null,
    plan_id: plan?.id || null,
    plan_name: plan?.name || null,
    purchase_type: getPurchaseType(currentAccount, plan, billingCycle),
    billing_cycle: billingCycle || null,
    account_id: currentAccount?.accountId || null,
    trial_period_days: currentAccount?.freePlanLength || null, // if no value, do not send
    days_in_trial: daysIntoTrial(currentAccount) || null, // if no value, do not send
    user_id: currentUserId || null,
    user_email: currentAccount?.contactEmail || null,
    ecommerce: {
      currency: 'USD', // hard coded for now
      value: planCostFromBillingCycle(plan, billingCycle),
      items: [
        {
          currency: 'USD',
          item_id: plan?.id || null,
          item_name: plan?.name || null,
          item_category: 'hosting', // hard coded for now
          item_variant: billingCycle,
          price: planCostFromBillingCycle(plan, billingCycle),
          quantity: 1
        }
      ]
    }
  }
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push(removeEmptyValueFromObject(payload)) // only push key/value pairs that are not null
}

export const customPlanFormOnSubmitEvent = (
  currentUserId,
  currentAccount,
  formId
) => {
  const payload = {
    event: 'fireEvent',
    event_name: 'generate_lead',
    action_type: 'form_submit_success',
    form_id: formId || null,
    plan_group_id: 6, // hard coded to enterprise - same across all enviroments
    plan_name: 'enterprise',
    account_id: currentAccount?.accountId || null,
    trial_period_days: currentAccount?.freePlanLength || null, // if no value, do not send
    days_in_trial: daysIntoTrial(currentAccount) || null, // if no value, do not send
    user_id: currentUserId || null,
    user_email: currentAccount?.contactEmail || null
  }
  window.dataLayer.push(removeEmptyValueFromObject(payload)) // only push key/value pairs that are not null
}

export const customPlanButtonClickEvent = (currentUserId, currentAccount) => {
  const payload = {
    event: 'fireEvent',
    event_name: 'element_click',
    action_type: 'custom_pricing_cta',
    plan_group_id: 6, // hard coded to enterprise - same across all enviroments
    plan_name: 'enterprise',
    account_id: currentAccount?.accountId || null,
    trial_period_days: currentAccount?.freePlanLength || null, // if no value, do not send
    days_in_trial: daysIntoTrial(currentAccount) || null, // if no value, do not send
    user_id: currentUserId || null,
    user_email: currentAccount?.contactEmail || null
  }
  window.dataLayer.push(removeEmptyValueFromObject(payload)) // only push key/value pairs that are not null
}

export const formatAddToCartObjEvent = (
  currentUserId,
  currentAccount,
  plan,
  billingCycle
) => {
  const payload = {
    event: 'fireEvent',
    event_name: 'add_to_cart',
    action_type: 'add_to_cart',
    plan_group_id: plan?.planGroupId || null,
    plan_id: plan?.id || null,
    plan_name: plan?.name || null,
    purchase_type: getPurchaseType(currentAccount, plan, billingCycle),
    billing_cycle: billingCycle || null,
    account_id: currentAccount?.accountId || null,
    trial_period_days: currentAccount?.freePlanLength || null, // if no value, do not send
    days_in_trial: daysIntoTrial(currentAccount) || null, // if no value, do not send
    user_id: currentUserId || null,
    user_email: currentAccount?.contactEmail || null,
    ecommerce: {
      currency: 'USD', // hard coded for now
      value: planCostFromBillingCycle(plan, billingCycle),
      items: [
        {
          currency: 'USD',
          item_id: plan?.id || null,
          item_name: plan?.name || null,
          item_category: 'hosting', // hard coded for now
          item_variant: billingCycle,
          price: planCostFromBillingCycle(plan, billingCycle),
          quantity: 1
        }
      ]
    }
  }
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push(removeEmptyValueFromObject(payload)) // only push key/value pairs that are not null
}

export const daysIntoTrial = currentAccount => {
  if (!currentAccount) return
  return Math.round(
    currentAccount?.freePlanLength -
      getDaysLeftOnSubscription(currentAccount?.planEndDate)
  )
}

export const planCostFromBillingCycle = (plan, billingCycle) => {
  if (billingCycle === 'annual') return plan?.priceYear
  if (billingCycle === 'monthly') return plan?.priceMonth
  return null
}

export const getPurchaseType = (currentAccount, plan, billingCycle) => {
  if (!currentAccount || !plan || !billingCycle) return null
  if (currentAccount.planGroupId === 1) {
    return PURCHASE_TYPES.NEW
  }
  if (currentAccount.planTier < plan?.tier) return PURCHASE_TYPES.UPGRADE
  if (currentAccount.planTier > plan?.tier) return PURCHASE_TYPES.DOWNGRADE
  if (currentAccount.planTier === plan?.tier) {
    if (billingCycle === 'annual') {
      return PURCHASE_TYPES.UPGRADE
    }
    return PURCHASE_TYPES.DOWNGRADE
  }
  // Keeping in for now incase we decide to revert calculations
  // if (currentAccount?.planPrice < planCostFromBillingCycle(plan, billingCycle)) {
  //   return PURCHASE_TYPES.UPGRADE
  // }
  // if (currentAccount?.planPrice > planCostFromBillingCycle(plan, billingCycle)) {
  //   return PURCHASE_TYPES.DOWNGRADE
  // }
}

export const isMethodEmailOrGoogle = () => {
  const isAuthManagedExternally = window.localStorage.getItem(
    'externally-managed'
  )
  const isManagedByOkta = window.localStorage.getItem('okta')

  if (!JSON.parse(isAuthManagedExternally)) {
    return 'email'
  }
  if (isManagedByOkta) {
    return 'okta'
  }
  return 'google'
}

export const PURCHASE_TYPES = {
  NEW: 'new',
  UPGRADE: 'upgrade',
  RENEWAL: 'renewal',
  DOWNGRADE: 'downgrade'
}
