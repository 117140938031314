import React, { useCallback, useEffect, useState } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import PrivateRoute from '@Components/PrivateRoute'
import Dashboard from '@Pages/Dashboard'
import SiteDetailsPage from '@Pages/SiteDetails'
import SitePublishHistoryPage from '@Pages/SitePublishHistory'
import SiteDomainPage from '@Pages/SiteDomain'
import SiteDomainSetupPage from '@Pages/SiteDomainSetup'
import SiteBackupsPage from '@Pages/SiteBackups'
import SiteHeadersRulesPage from '@Pages/SiteHeadersRules'
import SiteRedirectRulesPage from '@Pages/SiteRedirectRules'
import NewSitePage from '@Pages/NewSite'
import MigrationRequestPage from '@Pages/MigrationRequest'
import LaunchWPPage from '@Pages/LaunchWP'
import AccountPage from '@Pages/Account'
import PlansPage from '@Pages/Plans'
import PaymentInformation from '@Pages/PaymentInformation'
import TeamPage from '@Pages/Team'
import SupportPage from '@Pages/Support'
import SignUpPage from '@Pages/SignUp'
import SignUpConfirmPage from '@Pages/SignUpConfirm'
import LoginPage from '@Pages/Login'
import ForgotPasswordPage from '@Pages/ForgotPassword'
import ResetPasswordPage from '@Pages/ResetPassword'
import OauthCallbackPage from '@Pages/OauthCallback'
import UnavailablePage from '@Pages/Unavailable'
import NotFoundPage from '@Pages/NotFound'
import MyProfile from '@Pages/MyProfile'
import RestoringPage from '@Pages/Restoring'
import AddSiteNavScreen from '@Pages/AddSiteNavScreen'
import MigrateSitePage from '@Pages/MigrateSite'

const useQuery = () => new URLSearchParams(useLocation().search)

const Routes = ({
  kustomerIsStarted,
  isAuthenticated,
  currentUser,
  planName,
  currentAccountId,
  accountDetails,
  setCurrentAccountId
}) => {
  const { boot, update } = useIntercom()
  const [hasAccount, setHasAccount] = useState(true)
  const [kustomerIsLoggedin, setKustomerIsLoggedin] = useState(false)
  const location = useLocation()
  const query = useQuery()
  const supportQuery = parseInt(query.get('support')) || null

  const updateWithProps = React.useCallback(() => {
    const email = currentUser?.email
    const name = currentUser?.name
    const hashedEmail = currentUser?.intercomHash
    update({
      // user_id: currentAccountId || null,
      email: email || null,
      userHash: hashedEmail,
      name: name || null,
      customAttributes: {
        planName: planName || null,
        // hard coded for now to go to prod
        stratticAccount:
          `https://admin.strattic.com/accounts/${currentAccountId}/subscriptions` ||
          null,
        subscription_status: accountDetails?.subscriptionStatus,
        appPath: location?.pathname
      }
      // eslint-disable-next-line
    })
  }, [
    update,
    currentAccountId,
    planName,
    currentUser,
    accountDetails,
    location
  ])
  // Intercom section
  useEffect(() => {
    boot()
    if (isAuthenticated) {
      updateWithProps()
    }
    // eslint-disable-next-line
  }, [isAuthenticated, currentUser, planName, currentAccountId])

  const kustomer = window.Kustomer
  const kustomerJwt = currentUser?.kustomerJwt
  const subscriptionStatus = accountDetails?.subscriptionStatus
  const userEmail = currentUser?.email
  const currentUserId = currentUser?.id

  useEffect(() => {
    if (
      kustomerIsStarted &&
      !kustomerIsLoggedin &&
      isAuthenticated &&
      kustomerJwt
    ) {
      kustomer.isLoggedIn({ email: userEmail }, (isLoggedIn, error) => {
        if (error) {
          console.error(error)
        } else {
          if (isLoggedIn) {
            setKustomerIsLoggedin(true)
          } else {
            kustomer.login({ jwtToken: kustomerJwt }, () => {
              setKustomerIsLoggedin(true)
            })
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [isAuthenticated, kustomer, kustomerJwt, kustomerIsStarted])

  useEffect(() => {
    kustomerIsLoggedin &&
      kustomer.describeCustomer({
        customAttributes: {
          planNameStr: planName || null,
          stratticSubscriptionStatusStr: subscriptionStatus,
          // hard coded for now to go to prod
          stratticAccountUrlStr:
            `https://admin.strattic.com/accounts/${currentAccountId}/subscriptions` ||
            null,
          appPathStr: location?.pathname,
          stratticIdStr: currentUserId?.toString()
        }
      })
  }, [
    kustomer,
    kustomerIsStarted,
    currentAccountId,
    location,
    subscriptionStatus,
    planName,
    userEmail,
    currentUserId,
    kustomerIsLoggedin
  ])

  const onConversationCreateHandler = useCallback(
    function (response, error) {
      if (error) {
        console.error(error)
        return
      }
      kustomer.describeConversation(
        {
          conversationId: response.conversationId,
          customAttributes: {
            appPathStr: location?.pathname,
            // hard coded for now to go to prod
            stratticAccountUrlStr:
              `https://admin.strattic.com/accounts/${currentAccountId}/subscriptions` ||
              null
          }
        },
        function (response, error) {
          if (error) {
            console.error(error)
          }
        }
      )
    },
    [location, kustomer, currentAccountId]
  )

  useEffect(() => {
    if (kustomerIsStarted)
      kustomer.addListener('onConversationCreate', onConversationCreateHandler)

    return () => {
      if (kustomerIsStarted)
        kustomer.removeListener(
          'onConversationCreate',
          onConversationCreateHandler
        )
    }
  }, [kustomer, kustomerIsStarted, onConversationCreateHandler])

  useEffect(() => {
    if (kustomerIsStarted && supportQuery === 1) kustomer.open()
  }, [supportQuery, kustomer, kustomerIsStarted])

  useEffect(() => {
    if (isAuthenticated && currentUser?.accounts?.length === 0) {
      setHasAccount(false)
      setCurrentAccountId(null)
    } else {
      setHasAccount(true)
    }
  }, [isAuthenticated, currentUser, setCurrentAccountId])

  if (!hasAccount) {
    return (
      <Switch>
        {/*  redirect trailing slash to same path without slash */}
        <Redirect
          from='/:url*(/+)'
          to={location?.pathname?.slice(0, -1) + location?.search}
        />
        <PrivateRoute exact path='/' isAuthenticated={isAuthenticated}>
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/my-profile'
          isAuthenticated={isAuthenticated}
        >
          <MyProfile />
        </PrivateRoute>
        <Route>
          <Redirect to='/' />
        </Route>
      </Switch>
    )
  }
  return (
    <Switch>
      <Redirect
        from='/:url*(/+)'
        to={location?.pathname?.slice(0, -1) + location?.search}
      />
      <Route exact path='/noaccount'>
        <Redirect to='/' />
      </Route>
      <Route exact path='/sites'>
        <Redirect to='/' />
      </Route>
      <PrivateRoute exact path='/' isAuthenticated={isAuthenticated}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId'
        isAuthenticated={isAuthenticated}
      >
        <SiteDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/publishing-history'
        isAuthenticated={isAuthenticated}
      >
        <SitePublishHistoryPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/domain'
        isAuthenticated={isAuthenticated}
      >
        <SiteDomainPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/domain-setup'
        isAuthenticated={isAuthenticated}
      >
        <SiteDomainSetupPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/domain-setup'
        isAuthenticated={isAuthenticated}
      >
        <SiteDomainSetupPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/backups'
        isAuthenticated={isAuthenticated}
      >
        <SiteBackupsPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/headers-rules'
        isAuthenticated={isAuthenticated}
      >
        <SiteHeadersRulesPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/redirect-rules'
        isAuthenticated={isAuthenticated}
      >
        <SiteRedirectRulesPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/launch'
        isAuthenticated={isAuthenticated}
      >
        <LaunchWPPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/site/:siteId/restoring'
        isAuthenticated={isAuthenticated}
      >
        <RestoringPage />
      </PrivateRoute>
      <PrivateRoute exact path='/account' isAuthenticated={isAuthenticated}>
        <AccountPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/account/:accountId/new-site'
        isAuthenticated={isAuthenticated}
      >
        <NewSitePage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/account/:accountId/site-welcome'
        isAuthenticated={isAuthenticated}
      >
        <AddSiteNavScreen />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/account/:accountId/migrate-site'
        isAuthenticated={isAuthenticated}
      >
        <MigrateSitePage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/account/:accountId/migrate-request'
        isAuthenticated={isAuthenticated}
      >
        <MigrationRequestPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/account/:accountId/available-plans'
        isAuthenticated={isAuthenticated}
      >
        <PlansPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/payment-information'
        isAuthenticated={isAuthenticated}
      >
        <PaymentInformation />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/accounts/team'
        isAuthenticated={isAuthenticated}
      >
        <TeamPage />
      </PrivateRoute>
      <PrivateRoute exact path='/support' isAuthenticated={isAuthenticated}>
        <SupportPage />
      </PrivateRoute>
      <PrivateRoute exact path='/my-profile' isAuthenticated={isAuthenticated}>
        <MyProfile />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/signup'
        isAuthenticated={!isAuthenticated}
        redirectLocation='/'
      >
        <SignUpPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/signupinvite'
        isAuthenticated={!isAuthenticated}
        redirectLocation='/'
      >
        <Redirect to='/signup?invited=true' />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/confirm'
        isAuthenticated={!isAuthenticated}
        redirectLocation='/'
      >
        <SignUpConfirmPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/signin'
        isAuthenticated={!isAuthenticated}
        redirectLocation='/'
      >
        <LoginPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/callback'
        isAuthenticated={!isAuthenticated}
        redirectLocation='/'
        forwardQuery={false}
      >
        <OauthCallbackPage />
      </PrivateRoute>
      <Route exact path='/signout'>
        <Redirect to='/signin' />
      </Route>
      <PrivateRoute
        exact
        path='/forgot-password'
        isAuthenticated={!isAuthenticated}
        redirectLocation='/'
      >
        <ForgotPasswordPage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path='/resetpassword'
        isAuthenticated={!isAuthenticated}
        redirectLocation='/'
      >
        <ResetPasswordPage />
      </PrivateRoute>
      <Route exact path='/unavailable'>
        <UnavailablePage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}
export default Routes
