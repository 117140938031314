import { useState, useEffect } from 'react'

import { useUserContext } from '@Context/UserContext'
import usePrevious from '@Hooks/usePrevious'
import useLocalStorage from '@Hooks/useLocalStorage'

import { permissionGranted } from '@Lib/helpers/permissionsHelpers'

const useAccountPermissions = permissionQuery => {
  const userContext = useUserContext()
  const previousAccountId = usePrevious(userContext?.accountId)
  const [accountPermissions] = useLocalStorage('accountPermissions')
  const [hasPermission, setHasPermission] = useState(
    permissionGranted(accountPermissions, permissionQuery)
  )

  useEffect(() => {
    if (previousAccountId && userContext?.accountId !== previousAccountId) {
      setHasPermission(false)
    } else if (userContext?.accountPermissions) {
      setHasPermission(
        permissionGranted(userContext?.accountPermissions, permissionQuery)
      )
    }

    // eslint-disable-next-line
  }, [userContext?.accountPermissions, userContext?.accountId])

  return hasPermission
}

export default useAccountPermissions
