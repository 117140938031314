import numeral from 'numeral'
import i18n from 'i18next'
import domainNameRegex from 'domain-name-regex'

import {
  DIST_TYPES,
  DATA_SIZES_ARRAY,
  SORT_ORDER,
  ERROR_CODES,
  SITE_TYPES,
  WWW
} from '@Constants'
import { getDate } from '@Lib/dates'

export const combineUserPass = (username, password) => {
  if (username && password) {
    const combinedAuth = [username, password].join(':')
    const httpAuthBase64 = window.btoa(combinedAuth)
    return { httpAuthBase64 }
  }
}

export const getEncodedUserPass = value => {
  if (value) {
    const credsCombined = window.atob(value)
    const credsSplit = credsCombined.split(':')
    const user = {
      username: credsSplit[0],
      password: credsSplit[1]
    }
    return user
  }
}

export const isDomainValid = domain => {
  if (domain === WWW) return false
  return domainNameRegex.test(domain)
}

export const generateRandomPass = length => {
  const result = []
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

export const getFormattedSite = site => {
  const liveDistribution = site?.distributions?.filter(
    site => site.type === DIST_TYPES.LIVE
  )[0]
  const previewDistribution = site?.distributions?.filter(
    site => site.type === DIST_TYPES.PREVIEW
  )[0]
  const siteStore = {
    id: site?.id || '',
    siteId: site?.id || '',
    shortname: site?.name || '',
    name: site?.displayName || '',
    status: site?.status || '',
    runningStatus: site?.runningStatus || '',
    screenshot: site?.screenshotUrl || '',
    customDomain: liveDistribution?.customDomain
      ? liveDistribution?.domainName
      : null,
    hasCustomDomain: liveDistribution?.customDomain || '',
    hasDomainOverride: !!liveDistribution?.publishDomainOverride,
    liveDistribution: {
      id: liveDistribution?.id || '',
      certificate: liveDistribution?.certificate || '',
      customDomain: liveDistribution?.customDomain || '',
      domainName:
        liveDistribution?.publishDomainOverride ||
        liveDistribution?.domainName ||
        '',
      pendingDomainName: liveDistribution?.pendingDomainName || '',
      dnsRecords: liveDistribution?.dnsRecords || '',
      auth: liveDistribution?.httpAuthBase64 || '',
      updated: liveDistribution?.updated || '',
      lastPublishes: liveDistribution?.lastPublishes || '',
      hasPublished: !!liveDistribution?.lastPublishes?.length,
      pendingSslDns: liveDistribution?.pendingSslDns,
      stackError: liveDistribution?.stackError
    },
    previewDistribution: {
      id: previewDistribution?.id || '',
      domainName:
        previewDistribution?.publishDomainOverride ||
        previewDistribution?.domainName ||
        '',
      auth: previewDistribution?.httpAuthBase64 || '',
      lastPublishes: previewDistribution?.lastPublishes || '',
      hasPublished: !!previewDistribution?.lastPublishes?.length
    },
    phpVersion: site?.phpVersion || '',
    wpVersion: site?.wpVersion || '',
    stagingDistribution: site?.stagingDomain || '',
    wpURL: site?.stagingDomain || '',
    httpUsername: site?.httpBasicUser || '',
    sftpUsername: site?.sftpUser || '',
    sqlDatabase: site?.sqlDatabase || '',
    sqlUsername: site?.sqlUsername || '',
    createdAt: site?.createdAt || '',
    lastPublished: site?.lastPublishes?.[0]?.endTime || ''
  }
  return siteStore
}

export const getFormattedBackupHistory = history => {
  const formattedHistory = history.map(item => {
    return {
      key: item.id,
      description: item.description,
      lastCreated:
        item.lastCreated && getDate(item.lastCreated, 'DATETIME_MED'),
      createdAt: getDate(item.createdAt, 'DATETIME_MED'),
      fileZipSize: numeral(item.fileZipSize).format('0 b'),
      triggerBy: item.triggerBy,
      status: item.status
    }
  })
  return formattedHistory
}

const canSelfServiceChangePlan = accountDetails => {
  if (accountDetails.currentSubscription?.selfServiceChangePlan !== null) {
    return accountDetails.currentSubscription.selfServiceChangePlan
  }

  return accountDetails.currentSubscription?.plan?.isPublic
}

export const getFormattedAccountDetails = accountDetails => {
  if (!accountDetails) return null
  const accountDetailsStore = {
    accountId: accountDetails.id,
    accountName: accountDetails?.name || '',
    allowRulesEngine:
      accountDetails?.currentSubscription?.allowRulesEngine || false,
    subscriptionStatus: accountDetails.currentSubscription?.status || null,
    contactEmail: accountDetails?.contactEmail || null,
    firstName: accountDetails?.contactFirstName || null,
    futureSubscription: accountDetails?.futureSubscription || null,
    lastName: accountDetails?.contactLastName || null,
    ssoIntegrations: accountDetails?.ssoIntegrations || null,
    autoBill: accountDetails?.currentSubscription?.autoBill ?? null,
    isPlanPublic: accountDetails.currentSubscription?.plan?.isPublic || null,
    freePlanLength: freeTrialLength(
      accountDetails.currentSubscription?.plan?.isTimeLimited,
      accountDetails.currentSubscription?.plan?.timeLimitDays,
      accountDetails.currentSubscription?.plan?.timeLimitMonths
    ),
    planName: accountDetails.currentSubscription?.plan?.name || null,
    planId: accountDetails.currentSubscription?.plan?.id || null,
    planTier: accountDetails.currentSubscription?.plan?.tier || null,
    planFrequency: accountDetails.currentSubscription?.billingCycle || null,
    planRenewsOn: accountDetails.currentSubscription?.nextCharge || null,
    planPrice: accountDetails.currentSubscription?.priceTotal || null,
    planCreatedAt: accountDetails.currentSubscription?.createdAt || null,
    planStartDate: accountDetails.currentSubscription?.startDate || null,
    planEndDate: accountDetails.currentSubscription?.endDate || null,
    planGroupId: accountDetails.currentSubscription?.plan?.planGroupId || null,
    planTimeLimitDays:
      accountDetails.currentSubscription?.plan?.timeLimitDays || null,
    planTimeLimitMonths:
      accountDetails.currentSubscription?.plan?.timeLimitMonths || null,
    subscriptionId: accountDetails.currentSubscription?.id || null,
    allowCustomDomain:
      accountDetails.currentSubscription?.plan?.allowCustomDomain,
    subscriptionName: accountDetails.currentSubscription?.name || null,
    createdAt: getDate(accountDetails?.createdAt) || null,
    usersUsage: {
      current:
        accountDetails.currentSubscription?.latestUsage?.usersCount || null,
      max: accountDetails.currentSubscription?.usersAmount || null
    },
    sitesUsage: {
      current: getDefaultSites(accountDetails?.sites)?.length || null,
      max: accountDetails.currentSubscription?.websitesAmount || null
    },
    visitsUsage: {
      current:
        accountDetails.currentSubscription?.latestUsage?.visitsCount || null,
      max: accountDetails.currentSubscription?.visitsAmount || null
    },
    bandwidthUsage: {
      current:
        accountDetails.currentSubscription?.latestUsage?.bandwidthUsage || null,
      max: accountDetails.currentSubscription?.bandwidthGb || null
    },
    storageUsage: {
      current:
        accountDetails.currentSubscription?.latestUsage?.storageUsage || null,
      max: accountDetails.currentSubscription?.storageGb || null
    },
    canSelfServiceChangePlan: canSelfServiceChangePlan(accountDetails)
  }
  return accountDetailsStore
}

export const getSitesByAvailableId = (permissions, sites) => {
  let newSites = []
  const availableIds = permissions?.map(permission => permission.siteId)
  if (sites && availableIds) {
    sites.map(site => {
      if (availableIds.includes(site?.id)) {
        newSites = [...newSites, { id: site.id, name: site.displayName }]
      }
      return null
    })
  }
  return newSites
}

export const getFormattedSiteAccessData = (permissions, sites) => {
  if (!permissions) {
    return null
  }
  if (permissions.length) {
    const siteNamesArr = getSitesByAvailableId(permissions, sites)
    return siteNamesArr
  }
  return 'All'
}

export const getFormattedFields = fields => {
  const formattedFields = []
  Object.keys(fields).forEach(fieldKey => {
    formattedFields.push({
      Name: fieldKey,
      Value: fields[fieldKey]
    })
  })
  return formattedFields
}
export const convertOrderByToAnt = order => {
  if (order === SORT_ORDER.ASC) return 'ascend'
  if (order === SORT_ORDER.DESC) return 'descend'
  return order
}

export const convertToBytes = (numBytes, size) => {
  const index = DATA_SIZES_ARRAY.indexOf(size)
  if (index < 0) {
    throw Error(i18n.t('invalid_size'))
  }

  return numBytes * Math.pow(1024, index + 1)
}

export const redirectToSite = (site, redirectTo) => {
  let redirectFullPath = redirectTo
    ? `${decodeURIComponent(redirectTo)}`
    : '/wp-admin/'
  if (!redirectFullPath.startsWith('/')) {
    redirectFullPath = `/${redirectFullPath}`
  }
  window.location.href = `https://${site.httpBasicUser}:${site.httpBasicPassword}@${site.stagingDomain}${redirectFullPath}`
}

export const isErrorIgnored = error => {
  if (error.code === ERROR_CODES.CANCELLED_API_REQUEST) {
    return true
  } else {
    return false
  }
}

// exclude temp sites and restoring sites from usage calc
export const getDefaultSites = sites => {
  if (!sites) {
    return null
  }
  return sites.filter(site => site.siteType === SITE_TYPES.DEFAULT)
}

export const pluralStringIfNeeded = (strVal, length) => {
  if (!length || length !== 1) {
    return strVal + 's'
  }
  return strVal
}

export const downloadResponse = url => {
  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blobData = new Blob([url])
    window.navigator.msSaveOrOpenBlob(blobData)
  } else {
    // chrome (before v71)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.setAttribute('href', url)
    document.body.appendChild(link)
    link.click()
    link.parentNode && link.parentNode.removeChild(link)
  }
}

export const getAccountContactEmail = accountDetails =>
  accountDetails?.contactEmail

export const getPendingDomainName = currentSite =>
  currentSite?.value?.liveDistribution?.pendingDomainName

export const getAccountId = accountDetails => {
  if (!accountDetails) {
    return null
  }
  return accountDetails?.value?.accountId
}

export const formatPriceCurrency = (price, currency) => {
  if (!currency) {
    currency = 'USD'
  }
  if (!price) {
    price = 0
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  })

  return formatter.format(price)
}

export const isAuthManagedExternally = cognitoUser => {
  if (cognitoUser?.attributes?.identities?.length > 0) {
    window.localStorage.setItem('externally-managed', true)
  } else {
    window.localStorage.setItem('externally-managed', false)
  }
}

export const getFromLocalStorage = key => {
  return JSON.parse(window.localStorage.getItem(key))
}

export const mathCeilHalf = number => {
  return Math.ceil(number * 2) / 2
}

export const freeTrialLength = (
  isTimeLimited,
  timeLimitDays,
  timeLimitMonths
) => {
  if (!isTimeLimited) return null
  if (!timeLimitMonths) return timeLimitDays
  if (!timeLimitDays) return timeLimitMonths * 30
  return timeLimitMonths * 30 + timeLimitDays
}

// https://stackoverflow.com/a/56697868
export const removeEmptyValueFromObject = obj => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return value === null ? undefined : value
    })
  )
}
