import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'

import { Card, Menu, Typography, Layout, Skeleton, Tooltip } from 'antd'

import { permissionGranted } from '@Lib/helpers/permissionsHelpers'

import SiteImage from '@Components/SiteImage'
import ExternalLink from '@Components/ExternalLink'
import { WordPressButton } from '@Components/Buttons'
import AnalyticsManager from '@Managers/analyticsManager'
import useWindowDimensions from '@Hooks/useWindowDimensions'
import useAccountDetails from '@Hooks/useAccountDetails'

import { DELETED_STATUSES, CSS, SITE_STATUS } from '@Constants'
import { SITE_PERMISSIONS } from '@Constants/permissionsConstants'
import DeleteSite from '../DeleteSite'
import { Scrollbars } from 'react-custom-scrollbars'
import { useUserContext } from '@Context/UserContext'

import './index.less'
import SiteName from '../SiteName'
import { LoadingOutlined } from '@ant-design/icons'

const { Sider } = Layout
const { Text } = Typography

const SiteNav = ({ currentSite, restorePadding }) => {
  const { t } = useTranslation(['common'])
  const location = useLocation()
  const [accountDetails] = useAccountDetails()
  const canViewRulesEngine = accountDetails?.value?.allowRulesEngine
  const [hasPermissionToDeleteSite, setHasPermissionToDeleteSite] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_DELETE
    )
  )
  const [canViewDomain, setCanViewDomain] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_CREATE_CUSTOM_DOMAIN
    ) &&
      (accountDetails?.value?.allowCustomDomain ||
        accountDetails?.value?.planGroupId === 1)
  )
  const [hasPermissionToViewBackups, setHasPermissionToViewBackups] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_VIEW_BACKUPS
    )
  )
  const [
    hasPermissionToUpdateSiteName,
    setHasPermissionToUpdateSiteName
  ] = useState(
    permissionGranted(
      currentSite?.value?.allowedPermissions,
      SITE_PERMISSIONS.SITE_UPDATE
    )
  )
  const userContext = useUserContext()
  const [topPadding, setTopPadding] = useState(0)
  const [topIndent, setTopIndent] = useState(0)
  const [httpAuthObj, setHttpAuthObj] = useState({
    httpBasicUser: currentSite?.value?.httpUsername,
    httpBasicPassword: currentSite?.value?.httpBasicPassword,
    stagingDomain: currentSite?.value?.stagingDistribution
  })
  const [openKeys, setOpenKeys] = useState([])
  useEffect(() => {
    setHttpAuthObj({
      httpBasicUser: currentSite?.value?.httpUsername,
      httpBasicPassword: currentSite?.value?.httpBasicPassword,
      stagingDomain: currentSite?.value?.stagingDistribution
    })
  }, [currentSite])

  useEffect(() => {
    userContext.expirationBarPadding ? setTopPadding(56) : setTopPadding(0)
  }, [userContext.expirationBarPadding])

  useEffect(() => {
    setTopIndent(76 + topPadding)
  }, [topPadding])

  const { height } = useWindowDimensions()

  useEffect(() => {
    setHasPermissionToDeleteSite(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_DELETE
      )
    )
    setCanViewDomain(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_CREATE_CUSTOM_DOMAIN
      ) &&
        (accountDetails?.value?.allowCustomDomain ||
          accountDetails?.value?.planGroupId === 1)
    )
    setHasPermissionToViewBackups(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_VIEW_BACKUPS
      )
    )
    setHasPermissionToUpdateSiteName(
      permissionGranted(
        currentSite?.value?.allowedPermissions,
        SITE_PERMISSIONS.SITE_UPDATE
      )
    )
  }, [currentSite, accountDetails])

  useEffect(() => {
    if (location.pathname.includes('rules')) {
      setOpenKeys(['rules_engine'])
    }
  }, [location.pathname])

  const selectedMenuItems = () => {
    if (
      location.pathname === `/site/${currentSite?.value?.siteId}/domain-setup`
    ) {
      return [`/site/${currentSite?.value?.siteId}/domain`]
    }
    return [location.pathname]
  }
  const items = [
    {
      key: `/site/${currentSite?.value?.siteId}`,
      title: null,
      label: (
        <Link
          to={`/site/${currentSite?.value?.siteId}`}
          onClick={() =>
            AnalyticsManager.getInstance().trackClick('site_menu_details_btn')
          }
        >
          <Text>{t('site_details')}</Text>
        </Link>
      )
    },
    {
      key: `/site/${currentSite?.value?.siteId}/publishing-history`,
      title: null,
      label: (
        <Link
          onClick={() =>
            AnalyticsManager.getInstance().trackClick(
              'site_menu_publish_history'
            )
          }
          to={`/site/${currentSite?.value?.siteId}/publishing-history`}
        >
          <Text>{t('publishing_history')}</Text>
        </Link>
      )
    },
    canViewDomain && {
      key: `/site/${currentSite?.value?.siteId}/domain`,
      title: null,
      label: (
        <Link
          onClick={() =>
            AnalyticsManager.getInstance().trackClick('site_menu_domain')
          }
          to={`/site/${currentSite?.value?.siteId}/domain`}
        >
          <Text>{t('domain')}</Text>
        </Link>
      )
    },
    hasPermissionToViewBackups && {
      key: `/site/${currentSite?.value?.siteId}/backups`,
      title: null,
      label: (
        <Link
          onClick={() =>
            AnalyticsManager.getInstance().trackClick('site_menu_backups')
          }
          to={`/site/${currentSite?.value?.siteId}/backups`}
        >
          <Text>{t('backups')}</Text>
        </Link>
      )
    },
    canViewRulesEngine && {
      key: 'rules_engine',
      title: null,
      label: (
        <Text>
          {t('rules_engine')}{' '}
          <span style={{ color: '#fb4246', fontSize: 8 }}>Beta</span>
        </Text>
      ),
      children: [
        {
          key: `/site/${currentSite?.value?.siteId}/headers-rules`,
          title: null,
          label: (
            <Link to={`/site/${currentSite?.value?.siteId}/headers-rules`}>
              <Text>{t('header_rules_nav')}</Text>
            </Link>
          )
        },
        {
          key: `/site/${currentSite?.value?.siteId}/redirect-rules`,
          title: null,
          label: (
            <Link to={`/site/${currentSite?.value?.siteId}/redirect-rules`}>
              <Text>{t('redirect_rules_nav')}</Text>
            </Link>
          )
        }
      ]
    }
  ]
  return (
    <Sider
      className='site-nav'
      width={CSS.SITE_NAV_WIDTH}
      theme={CSS.SITE_NAV_THEME}
    >
      <Scrollbars
        style={{ height: height - topIndent - restorePadding }}
        autoHide
      >
        <div
          className={`site-nav-content ${
            canViewRulesEngine ? 'rules-engine' : ''
          }`}
        >
          {currentSite?.value?.status === SITE_STATUS.UPDATING && (
            <Card
              className='site-image-overlay'
              cover={
                <div className='updating'>
                  <LoadingOutlined />
                  {t('site_is_updating')}
                </div>
              }
            />
          )}
          <Card
            className='sl-site-card site-card'
            cover={<SiteImage screenshot={currentSite?.value?.screenshot} />}
          />
          {!currentSite?.value ? (
            <>
              <Skeleton active paragraph={{ rows: 4 }} />
            </>
          ) : (
            <>
              <SiteName
                displayName={currentSite?.value?.name}
                canUpdate={hasPermissionToUpdateSiteName}
                siteId={currentSite?.value?.siteId}
              />
              {currentSite?.isLoading && <p>No current site</p>}
              <dl>
                <dt>{t('live_site')}</dt>
                <DisplaySiteName
                  domainName={currentSite?.value?.liveDistribution?.domainName}
                  enabled={currentSite?.value?.liveDistribution?.hasPublished}
                  dist={t('live')}
                  t={t}
                />
                <dt>{t('preview_site')}</dt>
                <DisplaySiteName
                  domainName={
                    currentSite?.value?.previewDistribution?.domainName
                  }
                  enabled={
                    currentSite?.value?.previewDistribution?.hasPublished
                  }
                  dist={t('preview')}
                  t={t}
                />
              </dl>
              <WordPressButton
                siteId={currentSite?.value?.siteId}
                runningStatus={currentSite?.value?.runningStatus}
                httpAuthObj={httpAuthObj}
                siteStatus={currentSite?.value?.status}
              />
              <Menu
                className='site-nav-menu'
                mode='inline'
                openKeys={openKeys}
                onOpenChange={e => setOpenKeys(e)}
                defaultSelectedKeys={selectedMenuItems()}
                items={items}
              />
            </>
          )}
        </div>

        {hasPermissionToDeleteSite &&
          !DELETED_STATUSES.includes(currentSite?.value?.status) && (
            <DeleteSite
              currentSite={currentSite}
              siteStatus={currentSite?.value?.status}
            />
          )}
      </Scrollbars>
    </Sider>
  )
}

const DisplaySiteName = ({ domainName, enabled, t, dist }) => {
  const [displayName, setDisplayName] = useState(domainName)

  useEffect(() => {
    if (domainName?.length > 33 && enabled) {
      setDisplayName(
        <Tooltip title={domainName}>{domainName?.substring(0, 29)}...</Tooltip>
      )
    } else if (domainName?.length > 33) {
      setDisplayName(<>{domainName?.substring(0, 29)}...</>)
    }
  }, [domainName, enabled])

  if (!enabled) {
    return (
      <dd>
        <Tooltip title={t('disabled_strattic_link', { environment: dist })}>
          <Text type='secondary'>{displayName}</Text>
        </Tooltip>
      </dd>
    )
  }
  return (
    <dd>
      <ExternalLink to={domainName}>{displayName}</ExternalLink>
    </dd>
  )
}
export default SiteNav
